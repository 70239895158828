import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  InputAdornment,
  styled,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import "../assets/style.css";
import { Link } from 'react-router-dom';
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
      color: "#777",
      borderRadius: "8px",
      width: '300px',
      height: '3.25rem'
    },
  });

// Customizable Area End

import ViewSingleClientController, {
  Props
} from "./ViewSingleClientController.web";
import Layout from "../../landingpage/src/Layout.web";
import ImportExportData from "../../importexportdata/src/ImportExportData.web";
export default class SingleClient extends ViewSingleClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout
        navigation={this.props.navigation}> 
        {this.state.isLocationImportOpen && this.state.selectedLocationCsv ?  
          <ImportExportData data-test-id="importCsv" file={this.state.selectedLocationCsv} goBack={this.onLocationImportBack} type="locations" navigation={""} id={""} />
        : <>
         <Box sx={webStyles.clientBox}>
               
                <Box sx={webStyles.bgSection}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={10}>
                            <Typography style={webStyles.textBold} variant="h4">{this.state.singleClintData.company_name}</Typography>
                            <Typography style={webStyles.dflexAlignCenter} variant="body1"><RoomIcon/>{this.state.singleClintData.contact_details}</Typography>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Fiscal Id</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.fiscal_id}</Typography>
                                        </Box>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Vat</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.vat}</Typography>
                                        </Box>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Contact Preferences</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.contact_preferences}</Typography>
                                        </Box>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Payment Details</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.payment_details}</Typography>
                                        </Box>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Payment Terms</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.payment_terms}</Typography>
                                        </Box>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Segment</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.segment}</Typography>
                                        </Box>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Assigned Technician</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.assigned_technician}</Typography>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Account manager</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.account_manager_name}</Typography>
                                        </Box>
                                        <Box sx={webStyles.marginTop10px}>
                                        <Typography style={{...webStyles.textBold, ...webStyles.fontTextUpper}} variant="body1">Comments</Typography>
                                        <Typography style={webStyles.dflexMrginTop} variant="body1">{this.state.singleClintData.comments}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2}>
                        {this.state.user_types==="manager" || this.state.user_types==="back_office" || this.state.user_types==="technician"?
                        <>
                        <Box
                             
                             >
                                 <Button
                               className="fontText"
 
                                 data-test-id={"textBtnSubmit"}
                                 onClick={this.editHandle}
                                 color="primary"
                                 style={webStyles.viewBtnRed}
                                 type="button"
 
                               ><EditIcon />&nbsp;
                                 Edit
                               </Button>
                               {this.state.user_types==="manager" && 
                                <Button
                                id="btnExample"
                                onClick={this.handleRemoveModal}
                                className="fontText"
                                color="primary"

                                style={webStyles.viewBtnwhite}
                              >
                                <DeleteIcon/> &nbsp;
                                Delete
                              </Button>
                               }
                              
                               
                             </Box>
                        </>
                        :null
        }
                        </Grid>
                    </Grid>
                </Box>
                <Box style={webStyles.TopBoxViewClient}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography style={webStyles.headingPaddingViewClient} variant="body1">Locations</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div
                      style={{
                        ...webStyles.searchbarHeaderViewClient,
                        ...webStyles.containerSearchViewClient,
                      }}
                    >
                      <div style={webStyles.searchEmpDivViewClient}>
                        <CustomTextField
                          fullWidth
                          className="custom-text-field"
                          data-test-id="searchInput"
                          onChange={this.handleSearchLocation}
                          variant="outlined"
                          placeholder="Search for a Location"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  {this.state.user_types==="manager" || this.state.user_types==="back_office" || this.state.user_types==="technician"?
                  <>
                   <Grid item xs={6} sm={6} md={2}>
                    <input
                        type="file"
                        hidden
                        id="upload"
                        onChange={this.onSelectFile}
                        accept=".csv"
                    />
                    <Button
                      style={webStyles.importCsvViewClient}
                      startIcon={
                        <label htmlFor="upload">
                          <GetAppSharpIcon />
                        </label>
                      }
                    >
                      <label htmlFor="upload">Import CSV</label>
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <Link onClick={this.locationHandle} to="/ClientDb/Location">
                    <Button type="button" style={webStyles.CreateProfileBtnViewClient}><AddIcon /> Add New Location</Button>
                    </Link>
                  </Grid>
                  </>:null
  }
                 
                </Grid>
                </Box>
                <Box style={webStyles.TopBoxViewClient} className="tableDataBox">
                <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={webStyles.tableTdColor}>Name</TableCell>
            <TableCell style={webStyles.tableTdColor} align="right">Address</TableCell>
            <TableCell style={webStyles.tableTdColor} align="right">Date Created</TableCell>
            <TableCell style={webStyles.tableTdColor} align="right">Last Updated</TableCell>
            <TableCell style={webStyles.tableTdColor} align="right">Assets</TableCell>
            <TableCell style={webStyles.tableTdColor} align="right">{""}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.LocationData.length>0 && this.state.LocationData.map((item:any, index:any)=>(
                 <TableRow key={index}>
              
                 <TableCell style={webStyles.tableChildData} component="th" scope="row">
                   {item.location.data.attributes.location_name}
                 </TableCell>
                 <TableCell align="right"> {item.location.data.attributes.address}</TableCell>
                 <TableCell align="right">{item.location.data.attributes.created_at}</TableCell>
                 <TableCell align="right">{item.location.data.attributes.updated_at}</TableCell>
                 <TableCell align="right">{item.location.data.attributes.total_asset_count}</TableCell>
                 <TableCell align="right" data-test-id="clickTableRow" onClick={()=>this.singlLocationClick(item.location.data.id)}><ArrowForwardIosIcon /></TableCell>
               </TableRow>
                ))}
           
        </TableBody>
      </Table>
                </Box>
            </Box>
            <Dialog
            className="modalBox"
            open={this.state.showModalMsg}
            PaperProps={{ style: webStyles.singleBoxModal }}
          >
            <DialogActions>
              <Button
                data-test-id="dialogCloseIcon"
                onClick={ this.closeMsgModal}
                style={webStyles.btnClseMoal}
              >
                <CloseIcon />
              </Button>
            </DialogActions>
            <DialogContent style={webStyles.preTop}>
              <Typography className="widthHeadingPre" style={{fontWeight:600}} variant="h6">Are you sure you want to Delete "{this.state.singleClintData.company_name}" Profile?</Typography>
              <Typography variant="body1" style={{margin:"10px 0"}}>This will be deleted Permanently. You can't undo this Action</Typography>

            </DialogContent>
            <DialogActions style={webStyles.doneModalBtn}>
            <button
              data-test-id="cancelBtn"
                style={webStyles.cancelBtnTag}
                onClick={ this.closeMsgModal}
              >
                Cancel
              </button>
              <button data-test-id="okBtn" 
               onClick={this.deleteSingleClient}
               style={webStyles.doneModalBtnSelection} type="button">
                Confirm
              </button>
            </DialogActions>
          </Dialog>
        </>}
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
    clientLeftIcon: {
        marginTop: "10px"
      },
    clientBox: {
        minHeight: "100vh",
        width: "100%",
        
      },
      clientTypee: {
        fontSize: "20px",
        lineHeight: "45px",
        display: "flex",
        fontWeight:600
      },
      bgSection:{
        background:"#fff",
        padding:"15px",
        borderRadius: "8px",
      },
      tableTdColor:{
        fontWeight:600,
        color:"rgba(21, 86, 157, 0.73)"
      },
      tableChildData:{
        textTransform:"capitalize" as "capitalize",
      },
      dflexAlignCenter:{
        marginTop:"15px",
        display:"flex",
        alignItems:"center"
      },
      dflexMrginTop:{
        display:"flex",
        alignItems:"center"
      },
      textBold:{
        fontWeight:600
      },
      fontTextUpper:{
        textTransform:"uppercase" as "uppercase"
      },
      marginTop10px:{
        marginTop:"15px",
        marginLeft:"5px"
      },
      viewBtnwhite: {
        fontSize: "15px",
        fontWeight:600,
        cursor: "pointer",
        borderRadius: "10px",
        padding: "10px 20px",
        display:"flex",
        width:"70%",
        border: "none",
        margin: "15px 10px 10px 35px",
        color: "rgb(215 31 31 / 84%)",
        background: "rgba(208, 51, 51, 0.24) none repeat scroll 0% 0%"
      },
      viewBtnRed: {
        display:"flex",
        fontWeight:600,
        width:"70%",
        fontSize: "15px",
        cursor: "pointer",
        borderRadius: "10px",
        padding: "10px 20px",
        border: "none",
        margin: "15px 10px 10px 35px",
        background: "rgb(215 31 31 / 84%)",
        color: "#fff"
      },
      viewBoxButton: {
        textTransform: "capitalize",
        marginTop: "20px",
        borderTop: "1px #bbb8b8 solid",
        justifyContent:"end",
        display:"flex"
      },

      headingPaddingViewClient:{
        fontWeight:600,
        padding:"15px 0",
        fontSize:"20px"
          },
          TopBoxViewClient:{
            background:"#fff",
            padding:"15px",
            marginTop:"15px",
            marginBottom:"15px",
            borderRadius: "8px",
          },
          searchbarHeaderViewClient: {
            height: "64px",
            background: "#fff",
            textAlign: "center" as "center",
            width: "100%",
          },
          containerSearchViewClient: { display: "flex" },
          searchEmpDivViewClient: {
            padding: "8px 20px",
            width: "33%",
          },
          importCsvViewClient: {
            color: "#ffffff",
            textTransform: "none" as "none",
            borderRadius:"7px",
            backgroundColor: "#D53535",
            fontSize:" 16px",
            fontWeight: 600,
            width: '100%',
            
          },
          CreateProfileBtnViewClient:{
           
            padding: "11px 14px",
            color: "#D53535",
            textTransform: "none" as "none",
            borderRadius:"7px",
            backgroundColor: "#fff",
            fontSize:" 16px",
            fontWeight: 600,
            width: '100%',
            marginTop:"7px"
          },
          cancelBtnTag: {
            fontWeight:600,
            fontSize: "15px",
            cursor: "pointer",
            borderRadius: "5px",
            padding: "13px 20px",
            border: "none",
            margin: "5px 0",
            boxShadow:"none",
            color: "rgb(215 31 31 / 84%)",
            background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
          },
          btnClseMoal: {
            cursor: "pointer",
            padding: "5px",
            background: "fff",
            color: "#000",
            justifyContent:"end !important"
          },
          doneModalBtnSelection: {
            fontWeight:600,
            fontSize: "15px",
            cursor: "pointer",
            borderRadius: "5px",
            padding: "13px 20px",
            border: "none",
            margin: "0px 16px 0px 12px",
            background: "rgb(215 31 31 / 84%)",
            color: "#fff"
          },
          doneModalBtn: {
            borderTop: "1px #0003 solid"
          },
          singleBoxModal: {
            width: "470px",
            borderRadius: "5px 5px 30px",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
          },
          preTop: {
            fontSize: "20px",
            fontWeight: 500,
            marginTop:"-40px"
          },
    
};

// Customizable Area End