import React from "react";
import { Box, Typography, Dialog, DialogActions, Button, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const DialogMessage = (props: any) => {
  const {
    showModal,
    closeModal,
    modalEmail,
    modalPassword,
    isEdit
  } = props

  const handleCopyText = () => {
    const textToCopy = `${modalEmail}\n${modalPassword}`;
    navigator.clipboard.writeText(textToCopy)
  }

  return (

    <Dialog
      className="modalBox"
      open={showModal}
      onClose={closeModal}
      PaperProps={{ style: webStyle.backOfficeModal }}
    >
      <DialogActions>
        <Button
          onClick={closeModal}
          style={webStyle.btnCloseBackOffice}
        >
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogContent style={webStyle.backOfficeModalContent}>
        <Typography style={webStyle.boxModalh4BoxBackOffice} variant="h4">
          Success!
        </Typography>
        {
          isEdit ? (
            <Typography style={webStyle.boxModalpBoxBackOffice} variant="h4">
            The user is updated successfully
            </Typography>
          ) : (
          <>
          <Typography style={webStyle.boxModalh4BoxBackOffice} variant="h4">
          The user is created, here are the credential for the first login
          </Typography>
          <Box style={webStyle.boxWithCopyBackOffice}>
          <Box>
          <Typography
          style={webStyle.boxModalpBoxBackOffice}
          variant="body1"
          >
          Email: {modalEmail}
          </Typography>
          <Typography
          style={webStyle.boxModalpBoxBackOffice}
          variant="body1"
          >
          Password: {modalPassword}
          </Typography>
          </Box>
          <Box>
          <Button
          id="btnExample"
          variant="contained"
          color="primary"
          style={webStyle.modalCopyBtnRedBackO}
          onClick={handleCopyText}
          >
            <FileCopyIcon />
            </Button>
            </Box>
            </Box>
            <Typography
            style={webStyle.boxModalpBoxBackOffice}
          variant="body1"
            >
            The user will be prompted to update their password after the
            first login
            </Typography>
          </>
          )
        }
      </DialogContent>
      <DialogActions style={webStyle.doneBtnBoxBackOffice}>
        <Button style={webStyle.doneBtnBackOffice} type="button" onClick={closeModal}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const webStyle = {
  boxWithCopyBackOffice: {
    display: "flex",
    margin: "10px 0",
    justifyContent: "space-between"
  },
  btnCloseBackOffice: {
    cursor: "pointer",
    padding: "5px",
    background: "fff",
    color: "#000"
  },
  doneBtnBackOffice: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "5px 22px",
    textTransform: 'capitalize' as 'capitalize',
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
  boxModalh4BoxBackOffice: {
    fontSize: "18px",
    fontWeight: 600
  },
  boxModalpBoxBackOffice: {
    fontSize: "15px"
  },
  doneBtnBoxBackOffice: {
    borderTop: "1px #0003 solid"
  },
  backOfficeModal: {
    width: "500px",
    height: "fit-content",
    borderRadius: "5px 5px 30px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
  },
  backOfficeModalContent: {
    fontSize: "20px",
    fontWeight: 500
  },
  modalCopyBtnRedBackO: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px",
    border: "none",
    margin: "5px 10px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
};


export default DialogMessage;

