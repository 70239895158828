import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton
  // Customizable Area Start
  ,
  Grid,
  TextField,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  BackChevronIcon,
  ClearBlackIcon,
  SearchGrayIcon,
} from "../../landingpage/src/assets";
import { styles } from "../../landingpage/src/ResponsiveDialog.web";
// Customizable Area End

import ImportExportDataController, {
  Props,
  configJSON
} from "./ImportExportDataController.web";

export default class ImportExportData extends ImportExportDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <React.Fragment>
        <div style={impStyles.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6} sm={5} style={impStyles.dFlex}>
              <IconButton
                data-test-id="backIcon"
                style={impStyles.iconBtn}
                onClick={this.props.goBack}
              >
                <Avatar
                  variant="square"
                  style={impStyles.avatar}
                  src={BackChevronIcon}
                />
              </IconButton>
              <Typography style={impStyles.title}>CSV Import</Typography>
            </Grid>
            <Grid item xs={4} sm={3} style={impStyles.alignRight}>
              <Button
                data-test-id="headerImport"
                style={{
                  ...impStyles.importBtn,
                  backgroundColor: this.isImportDisabled()
                    ? "#95a3b9"
                    : "#d23637",
                }}
                onClick={this.importCSV}
                disabled={this.isImportDisabled()}
              >
                {configJSON.importBtn}
              </Button>
            </Grid>
          </Grid>
        </div>
        {this.state.csvFields.map((field: any, index) => (
          <div style={impStyles.container} key={index}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <Typography style={impStyles.lngText}>{field.id}</Typography>
                <Typography style={impStyles.smlText}>
                  (Eg."Candy Store Merve")
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  select
                  data-test-id={`fieldValue${index}`}
                  variant="outlined"
                  color="secondary"
                  inputProps={{ maxLength: 20 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Avatar
                          style={impStyles.searchAvatar}
                          src={SearchGrayIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={field.value}
                  onChange={(e: any) => this.handleFieldChange(e, index)}
                >
                  <option value="static">Don't map this field</option>
                  {this.getOptions(index).map((x: any) => (
                    <option value={x.value}>{x.label}</option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>
        ))}
        {!!this.state.csvFields.length && (
          <div style={impStyles.alignRight}>
            <Button
              data-test-id="backBtn"
              style={impStyles.backBtn}
              onClick={this.props.goBack}
            >
              Go Back
            </Button>
            <Button
              data-test-id="importBtn"
              style={{
                ...impStyles.importBtn,
                backgroundColor: this.isImportDisabled()
                  ? "#95a3b9"
                  : "#d23637",
              }}
              onClick={this.importCSV}
              disabled={this.isImportDisabled()}
            >
              {configJSON.importBtn}
            </Button>
          </div>
        )}
        <Dialog
          maxWidth="sm"
          open={this.state.openDialog}
          disableEnforceFocus
          scroll="paper"
          PaperProps={{ style: { ...styles.dialogWrapper, width: 360 } }}
        >
          <DialogTitle style={styles.titleWrapper}>
            <IconButton
              data-test-id="closeIcon"
              size="small"
              onClick={this.onIconClick}
            >
              <Avatar
                src={ClearBlackIcon}
                alt="Close"
                variant="square"
                style={styles.closeIcon}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography style={impStyles.fBold}>Something is wrong</Typography>
            <Typography style={impStyles.f12}>
              Please check your field and mappings
            </Typography>
          </DialogContent>
          <Divider />
          <DialogActions style={styles.btnWrapper}>
            <Button
              data-test-id="okBtn"
              variant="contained"
              style={styles.okBtn}
              onClick={() => this.props.goBack()}
            >
              Back to Import
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const impStyles = {
  dFlex: { display: "flex" },
  fieldContainer: { marginBottom: 12 },
  container: {
    padding: "8px 16px",
    backgroundColor: "#fff",
    marginBottom: 16,
    borderRadius: 8,
  },
  fBold: { fontWeight: "bold" as "bold" },
  f12: { fontSize: 12 },
  alignRight: { textAlign: "right" as "right" },
  title: { fontSize: 16, margin: "auto 0px" },
  lngText: { fontSize: 14, fontWeight: 700, margin: 0 },
  smlText: { 
    fontWeight: 400, 
    fontSize: 12, 
    margin: 0, 
    color: "#00000070",
  },
  iconBtn: { padding: 0 },
  avatar: { height: 36, width: 36 },
  searchAvatar: { height: 28, width: 28 },
  importBtn: {
    color: "#fff",
    textTransform: "none" as "none",
    fontWeight: 600,
  },
  backBtn: {
    fontWeight: 600,
    color: "#d23637",
    backgroundColor: "#f4cccc",
    textTransform: "none" as "none",
    marginRight: 10,
  },
};
// Customizable Area End
