Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfautomaticallygeneratepdfcertification";
exports.labelBodyText = "cfautomaticallygeneratepdfcertification Body";

exports.btnExampleTitle = "CLICK ME";

exports.getMethodType = "GET";
exports.deleteMethodType = "DELETE";

exports.getCertificatesApiEndpoint = "bx_block_invoice/invoice";
exports.getCertificatesSearchApiEndPoint = "bx_block_invoice/invoice_search";
exports.getCertificatePDFApiEndpoint = "bx_block_invoice/generate_invoice_pdf";
exports.deleteCertificateApiEndPoint = "bx_block_invoice/delete_certificate";
// Customizable Area End