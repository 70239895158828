import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { UserType } from "../../landingpage/src/LayoutController.web";
import {
  DeleteDetail,
  IInvCertDetail,
} from "../../invoicebilling/src/InvoiceDbController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTechnician: boolean;
  deleteCert: DeleteDetail;
  maintainer_id: string;
  certificates: IInvCertDetail[];
  search: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CertificationDbController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCertificatesApiId = "";
  getCertificatesSearchApiId = "";
  getCertificatePDFApiId = "";
  deleteCertificateApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isTechnician: true,
      deleteCert: new DeleteDetail(),
      maintainer_id: "",
      certificates: [],
      search: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getCertificatesApiId:
        case this.getCertificatesSearchApiId:
          this.handleGetCertificates(responseJson);
          break;
        case this.getCertificatePDFApiId:
          this.handleCertificatePDF(responseJson);
          break;
        case this.deleteCertificateApiId:
          this.handleDeleteCertificate(responseJson);
          break;
      }
    }
  };
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    const userDataStr = localStorage.getItem("userData");
    if (userDataStr) {
      const userData = JSON.parse(userDataStr);
      this.setState(
        {
          isTechnician: userData.user_type === UserType.Technician,
          maintainer_id: userData.maintainer_id,
        },
        () => this.getCertificates()
      );
    }
  }

  getCertificates = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCertificatesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCertificatesApiEndpoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCertificatesSearch = (search: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      search_query: search,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCertificatesSearchApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCertificatesSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCertificatePDF = (id: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      certificate_number: id,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCertificatePDFApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCertificatePDFApiEndpoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteCertificate = (id: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      certificate_number: id,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCertificateApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCertificateApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetCertificates = (res: any) => {
    if (res.data) {
      const certificates = res.data
        .filter((x: any) => !!x.attributes.certificate_number)
        .map(
          (x: any) =>
            ({
              id: x.attributes.certificate_number,
              jobOrderId: x.attributes.job_order_id,
              clientCompanyName: x.attributes.client_company_name,
            } as IInvCertDetail)
        );
      this.setState({ certificates });
    }
  };

  handleCertificatePDF = (res: any) => {
    if (res.certificate) window.open(res.certificate, "_blank");
  };

  handleDeleteCertificate = (res: any) => {
    if (res.meta) this.getCertificates();
  };

  onCertDelete = (id: string) =>
    this.setState({ deleteCert: { open: true, id } });

  handleCertDeleteDialog = (isConfirm: boolean) => {
    if (isConfirm) this.deleteCertificate(this.state.deleteCert.id);
    this.setState({ deleteCert: new DeleteDetail() });
  };

  onSearch = (e: any) => {
    const search = e.target.value;
    this.setState({ search });
    if (search) this.getCertificatesSearch(search);
    else this.getCertificates();
  };
  // Customizable Area End
}
