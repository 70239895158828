import React from "react";
// Customizable Area Start
import {
  Grid,
  Button,
  Avatar,
  styled,
  Container,
  Typography,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import TechnicianLandingPageController, {
  ICardProps,
  Props,
} from "./TechnicianLandingPageController.web";
import { ContactPrimaryIcon, MenuGrayIcon, PersonPrimaryIcon } from "./assets";
import Layout from "./Layout.web";
import { CustomTooltip } from "./CompanyLandingPage.web";
import moment from "moment";

export const PageHeaderContainer = styled(Container)((props) => ({
  minWidth: "100%",
  padding: 0,
  display: "flex",
  margin: "0px 0px 14px",
  "& .btn-wrapper": {
    width: "100%",
    textAlign: "right",
    [props.theme.breakpoints.down("xs")]: { textAlign: "center" },
  },
  [props.theme.breakpoints.down("xs")]: { flexDirection: "column-reverse" },
}));

export const CustomCard = (props: ICardProps) => {
  const {
    task,
    getJobTypes,
    getVerticals,
    onTaskClick,
    dragHandleProps,
  } = props;
  return (
    <div
      style={{
        ...cardStyles.mainContainer,
        borderLeft: `4px solid ${
          task.status !== "in_progress" ? "#d96352" : "#63bf7e"
        }`,
      }}
    >
      <div style={cardStyles.content}>
        <div
          data-test-id="mainDiv"
          style={styles.titleStyle}
          onClick={() => onTaskClick(task.id)}
        >
          <div style={cardStyles.lgTitle}>{task.location_name || "-"}</div>
          <div style={cardStyles.lgSubTitle}>
            {task.client_company_name || "-"}
          </div>
        </div>
        <div style={cardStyles.content} />
        <CustomTooltip
          placement="bottom-start"
          title={
            <React.Fragment>
              <Typography className="title">Job Types</Typography>
              {task.documents_type.map((x: any, index: number) => (
                <Typography key={index} component="div" className="job">
                  {x.id}
                </Typography>
              ))}
            </React.Fragment>
          }
        >
          <div style={cardStyles.dFlex}>
            {getJobTypes(task.documents_type).map((x: any, index: number) => (
              <Avatar key={index} style={cardStyles.lgAvatar}>
                {x}
              </Avatar>
            ))}
          </div>
        </CustomTooltip>
      </div>
      <div style={cardStyles.content}>
        <div style={styles.titleStyle}>
          <div style={cardStyles.smSubTitle}>
            {!task.is_self_assign
              ? `${moment(task.start_time).format("HH:mm")} - ${moment(
                  task.end_time
                ).format("HH:mm")}, ${moment(task.start_time).format(
                  "MMMM DD"
                )}`
              : "-"}
          </div>
        </div>
        <div style={cardStyles.content} />
        <CustomTooltip
          title={
            <React.Fragment>
              <Typography className="title">Verticals</Typography>
              {task.asset_ids.map((x: any, index: number) => (
                <Typography key={index} component="div" className="vertical">
                  {x}
                </Typography>
              ))}
            </React.Fragment>
          }
        >
          <div style={cardStyles.dFlex}>
            {getVerticals(task.asset_ids).map((x: any, index: number) => (
              <Avatar key={index} style={cardStyles.smAvatar}>
                {x}
              </Avatar>
            ))}
          </div>
        </CustomTooltip>
      </div>
      <p style={cardStyles.address}>{task.location_address}</p>
      <Typography
        className="comment"
        component="div"
        style={cardStyles.comment}
      >
        {task.location_comments}
      </Typography>
      <Typography
        className="comment"
        component="div"
        style={cardStyles.comment}
      >
        {task.job_order_comments}
      </Typography>
      <div style={{ ...cardStyles.content, height: "20px" }}>
        <div style={cardStyles.content}>
          <img src={PersonPrimaryIcon} style={cardStyles.contactIcon} />{" "}
          <p style={{ ...cardStyles.contact }}>
            {task.contact_preferences || "-"}
          </p>
        </div>
        <a
          href={`tel:${task.contact_details}`}
          style={{ ...cardStyles.content, ...cardStyles.callLink }}
        >
          <img src={ContactPrimaryIcon} style={cardStyles.contactIcon} />{" "}
          <p style={{ ...cardStyles.contact }}>{task.contact_details}</p>
        </a>
        <div style={cardStyles.content} />
        <div {...dragHandleProps}>
          <img src={MenuGrayIcon} style={cardStyles.contactIcon} />
        </div>
      </div>
    </div>
  );
};

// Customizable Area End

export default class TechnicianLandingPage extends TechnicianLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <Layout navigation={this.props.navigation}>
        <PageHeaderContainer>
          <div style={styles.titleStyle}>
            Upcoming Jobs ({this.state.cardList.length})
          </div>
          <div className="btn-wrapper">
            <Button
              data-test-id="calendarBtn"
              variant="contained"
              style={styles.calenderBtn}
              onClick={this.onCalendarClick}
            >
              Calendar
            </Button>
            <Button variant="contained" style={styles.nextAddressBtn}>
              Next Job Address
            </Button>
          </div>
        </PageHeaderContainer>
        <DragDropContext data-test-id="context" onDragEnd={this.handleDrop}>
          <Grid container spacing={1}>
            {this.state.cardList.map((task: any, index: number) => (
              <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                <Droppable data-test-id="drop" droppableId={`${index}`}>
                  {(dropProvided: any) => (
                    <div
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                    >
                      <Draggable draggableId={index.toString()} index={index}>
                        {(provided: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <CustomCard
                              task={task}
                              getJobTypes={this.getJobTypes}
                              getVerticals={this.getVerticals}
                              onTaskClick={this.onTaskClick}
                              dragHandleProps={provided.dragHandleProps}
                            />
                            {dropProvided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    </div>
                  )}
                </Droppable>
              </Grid>
            ))}
          </Grid>
        </DragDropContext>
      </Layout>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  titleStyle: {
    textAlign: "left" as "left",
    minWidth: "max-content",
    padding: "4px 0px",
    fontSize: "16px",
    fontWeight: "bold" as "bold",
    margin: "auto 0px",
  },
  calenderBtn: {
    width: 142,
    fontSize: "12px",
    color: "#d23637",
    backgroundColor: "#f4cbcf",
    marginRight: "10px",
    textTransform: "none" as "none",
    fontWeight: "bold" as "bold",
  },
  nextAddressBtn: {
    padding: "6px 10px",
    width: 142,
    fontSize: "12px",
    color: "#f4cbcf",
    backgroundColor: "#d23637",
    marginRight: "10px",
    textTransform: "none" as "none",
    fontWeight: "bold" as "bold",
  },
};

const cardStyles = {
  dFlex: { display: "flex" },
  mainContainer: { background: "#fff", borderRadius: "4px", padding: "10px" },
  content: { display: "flex", width: "100%" },
  lgAvatar: {
    height: "35px",
    width: "35px",
    backgroundColor: "#5587de",
    marginRight: "3px",
    fontSize: "14px",
  },
  lgTitle: { fontSize: "14px", fontWeight: "bold" as "bold" },
  lgSubTitle: { fontSize: "12px", fontWeight: "normal" as "normal" },
  smSubTitle: { fontSize: 12, marginTop: 6, fontWeight: "normal" as "normal" },
  smAvatar: {
    height: "30px",
    width: "30px",
    color: "#5587de",
    backgroundColor: "#d3e3fa",
    marginRight: "3px",
    fontSize: "10px",
    fontWeight: "bold" as "bold",
  },
  address: {
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
  },
  callLink: { marginLeft: 5, textDecoration: "none", color: "#000" },
  contactIcon: { height: "20px", width: "20px" },
  contact: {
    margin: "4px 2px",
    fontSize: "10px",
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "20px",
  },
  comment: {
    fontSize: 12,
    backgroundColor: "#eae7e7",
    borderRadius: 8,
    marginBottom: 10,
    padding: "6px 0px 6px 6px",
    overflowY: "auto" as "auto",
    maxHeight: 68,
    height: 68,
  },
};
// Customizable Area End
