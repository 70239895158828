import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  withStyles,
  styled,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import "../assets/style.css"
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';



const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#777",
    borderRadius: "8px",
    width: '300px',
    height: '3.25rem'
  },
});
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#fff",
    boxShadow: "4px 4px 4px 4px #0000001A",
    width: "auto",
    padding: "8px 16px",
  },
})(Tooltip);
// Customizable Area End

import ViewProfileController, {
  EmpProps,
  Props,
} from "./ViewProfileController.web";
import Layout from "../../landingpage/src/Layout.web";
import ImportExportData from "../../importexportdata/src/ImportExportData.web";


export default class JobOrder extends ViewProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderClientRow = () => {
    return this.state.ClientData.map((task: any) => (

      this.employeeDetailsRow(task)
    ))
  }

  employeeDetailsRow = ({total_locations,total_assets,updated_at, Type, client_account_id, client_company_name, openEmpDetails }: EmpProps) => {
    return (
      <div style={clientList.containerClient}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={clientList.lgText}>{client_company_name}</Typography>

            <Typography
              style={{
                ...clientList.smText, ...clientList.textOverflow, ...clientList.addressWidth,
              }}
            >
              {"Client"}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={clientList.lgText}>{total_locations}</Typography>
            <Typography style={clientList.smText}>Location</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={clientList.lgText}>{total_assets}</Typography>
            <Typography style={clientList.smText}>Assets</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={clientList.lgText}>{updated_at}</Typography>
            <Typography style={clientList.smText}>Last Updated</Typography>
          </Grid>
          <Grid item xs={3} sm={2} md={2}>
            <Button data-testid="handleOpenEmpDetails" style={clientList.viewClientBtnProfile} onClick={this.handleOpenEmpDetails.bind(this, { client_account_id, client_company_name, Type, total_locations, total_assets, updated_at, openEmpDetails })}>View Profile</Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  // Customizable Area End

  render() {

    // Customizable Area Start
    return (
      <Layout
        navigation={this.props.navigation}>
          {this.state.isImportOpen && this.state.selectedFile ?  
          <ImportExportData data-test-id="importData" file={this.state.selectedFile} goBack={this.onImportBack} type="client" navigation={""} id={""} />
        : <Grid container spacing={2}>
          <Container maxWidth="lg">
            <Box style={webStyleProfile.TopBox}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                  <Typography style={webStyleProfile.headingPadding} variant="body1">Client Database</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div
                    style={{
                      ...webStyleProfile.searchbarHeader,
                      ...webStyleProfile.containerSearch,
                    }}
                  >
                    <div style={webStyleProfile.searchEmpDiv}>
                      <CustomTextField
                        fullWidth
                        data-test-id="searchInput"
                        variant="outlined"
                        placeholder="Search"
                        onChange={this.handleSearch}
                        value={this.state.searchTerm}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Grid>
                {this.state.user_type==="manager" || this.state.user_type==="back_office" || this.state.user_type==="technician"?
                <>
                <Grid item xs={6} sm={6} md={2}>
                  <input
                      type="file"
                      id="upload"
                      onChange={this.handleSelectFile}
                      accept=".csv"
                      hidden
                  />
                  <Button style={webStyleProfile.importCsv} 
                    startIcon={<label htmlFor="upload"><GetAppSharpIcon /></label>}
                    >
                    <label htmlFor="upload">
                      Import CSV
                    </label>
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <Link to="ClientDb/Client">
                    <Button type="button" style={webStyleProfile.CreateProfileBtn}><AddIcon /> Create New Profile</Button>
                  </Link>
                </Grid>
                </>:null
                }
                
              </Grid>

            </Box>
            <Box style={webStyleProfile.empContainer}>
              {this.renderClientRow()}
            </Box>

          </Container>
        </Grid>
          }
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyleProfile = {
  empContainer: { marginBottom: 16 },
  headingPadding: {
    padding: "15px 0",
    fontSize: "20px",
    fontWeight:700,
  },
  TopBox: {
    background: "#fff",
    padding: "15px",
    marginTop: "15px",
    marginBottom: "15px",
    borderRadius: "8px",
  },
  searchbarHeader: {
    height: "64px",
    background: "#fff",
    // padding: "10px 0px",
    textAlign: "center" as "center",
    width: "100%",
  },
  containerSearch: { display: "flex" },
  searchEmpDiv: {
    padding: "8px 20px",
    width: "33%",
  },
  importCsv: {
    backgroundColor: "#D53535",
    fontSize: " 16px",
    fontWeight: 600,
    width: '100%',
    color: "#ffffff",
    textTransform: "none" as "none",
    borderRadius: "7px",
  },
  CreateProfileBtn: {
    backgroundColor: "#fff",
    fontSize: " 16px",
    fontWeight: 600,
    width: '100%',
    padding: "11px 14px",
    color: "#D53535",
    textTransform: "none" as "none",
    borderRadius: "7px",
    marginTop: "7px"
  }
}
const clientList = {
  containerClient: {
    background: "#ffffff",
    marginBottom: 16,
    borderRadius: "8px",
    marginTop: "8px",
    padding: "16px 20px",
    border: "1px solid #d4d4d430",

  },

  addressWidth: { width: 100 },

  textOverflow: {
    overflow: "hidden", textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",

  },
  lgText: {
    fontSize: 16,
    textTransform:"capitalize" as "capitalize",
    fontWeight: 600,
    margin: 0
  },
  smText: {
    fontWeight: 400, fontSize: 10,
    margin: 0, color: "#00000070"
  },
  avatarDiv: {
    display: "flex", gap: 8,
    width: "max-content", fontWeight: 'bold' as 'bold'
  },
  lgAvatar: {
    height: 36, width: 36,
    backgroundColor: "#5183d9", fontSize: 14
  },
  clientAvatar: {
    height: 32,
    width: 32,
    fontSize: 14,
    fontWeight: 700,
    color: "#5183d9",
    backgroundColor: "#d3e3fa",
  },
  createBtn: {
    backgroundColor: "#D53535",
    fontSize: 12,
    fontWeight: 'bold',
    width: '169px',
    padding: "8px 14px",
    color: "#ffffff",
    textTransform: "none" as "none",
  },

  viewfBtn: {
    backgroundColor: "rgb(232, 161, 161)",
    fontSize: 12,
    padding: "8px 14px",
    color: "rgb(213, 53, 53)",
    textTransform: "none" as "none",
  },

  viewClientBtnProfile: {
    fontSize: 12,
    padding: "8px 28px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    border: "1px solid #D53535",
    color: "#D53535",
    fontWeight:600,
    textTransform: "none" as "none",
  },

  viewClienttooltipHeading: {
    fontWeight: "bold" as "bold",
    color: "#000",
    marginBottom: 2,
    fontSize: 14,
  },
  viewtooltipType: { color: "#000", marginTop: 8, fontSize: 12 },
}


// Customizable Area End
