import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgLogo } from "./assets";
import "../assets/style.css";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import HeaderNavigation from "../../../components/src/HeaderNavigation";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import * as Yup from 'yup';

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",

    }
  },
});

const validationSchema: any = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  fullName: Yup.string().required('Name is required'),
  phoneNo: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    workHoursFromSelect: Yup.string().required('Select At least One Value'),
    workHoursToSelect: Yup.string().required('Select At least One Value'),
});

const optionsArrayBack: any = [
  { value: '1:00', label: '1:00' },
  { value: '1:30', label: '1:30' },
  { value: '2:00', label: '2:00' },
  { value: '2:30', label: '2:30' },
  { value: '3:00', label: '3:00' },
  { value: '3:30', label: '3:30' },
  { value: '4:00', label: '4:00' },
  { value: '4:30', label: '4:30' },
  { value: '5:00', label: '5:00' },
  { value: '5:30', label: '5:30' },
  { value: '6:00', label: '6:00' },
  { value: '6:30', label: '6:30' },
  { value: '7:00', label: '7:00' },
  { value: '7:30', label: '7:30' },
  { value: '8:00', label: '8:00' },
  { value: '8:30', label: '8:30' },
  { value: '9:00', label: '9:00' },
  { value: '9:30', label: '9:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' },
  { value: '24:00', label: '24:00' }
];
// Customizable Area End

import BackOfficeController, {
  configJSON,
  Props
} from "./BackOfficeController";
import { Formik } from "formik";
import DialogMessage from "../../../components/src/DialogMessage";

export default class BackOffice extends BackOfficeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
         <Container style={webStylesBackOffice.backGdBoxBackO} maxWidth="lg">
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={webStylesBackOffice.headerSidBarBackO}>
                <Box sx={webStylesBackOffice.width100}>
                  <img width="60" src={imgLogo} />
                </Box>
              </Box>
              <Box sx={webStylesBackOffice.dispatchBoxBackO}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      style={webStylesBackOffice.dispatchTypoBackO}
                    >
                      {" "}
                      <ChevronRightIcon
                        style={webStylesBackOffice.dispatchLeftICon}
                      />{" "}
                      &nbsp; Create new user
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box>
                      <Typography
                        variant="h2"
                        style={{
                          ...webStylesBackOffice.dispatchTypoBoxBackO,
                          ...webStylesBackOffice.dispatchTypoBackO
                        }}
                      >
                        {" "}
                        Select User Type
                      </Typography>
                    </Box>
                    <HeaderNavigation
                      state={this.state}
                      goToDisPatcher={this.goToDisPatcher}
                      goToBackOffice={this.goToBackOffice}
                      goToTechnician={this.goToTechnician}
                    />
                    <Box
                      className="dispatchFormBox"
                      style={webStylesBackOffice.dispatchFormBoxBackO}
                    >
                      <Formik
                        initialValues={{fullName: this.state.backOfficeInfo.fullName,
                          email: this.state.backOfficeInfo.email,
                          back_id: this.state.backOfficeInfo.back_id, 
                          address: this.state.backOfficeInfo.address,
                          phoneNo: this.state.backOfficeInfo.phoneNo,
                          workHoursFromSelect: this.state.backOfficeInfo.workHoursFromSelect,
                          workHoursToSelect: this.state.backOfficeInfo.workHoursToSelect,
                          breakStartSelect: this.state.backOfficeInfo.breakStartSelect,
                          breakEndSelect: this.state.backOfficeInfo.breakEndSelect,

                        }}
                        validationSchema={validationSchema}
                        onSubmit={
                          this.handleSubmit
                        }
                        enableReinitialize
                      >
                        {({ values,
                          errors,
                          resetForm,
                          setFieldTouched,
                          setFieldValue,
                          touched, handleSubmit, setErrors }) => (
                          <form
                            autoComplete="off"
                            style={webStylesBackOffice.marginTop15Back}
                            onSubmit={
                              handleSubmit
                            }
                          >
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.fullName}
                              </p>
                              <TextField
                                fullWidth

                                data-test-id={"txtFirstName"}
                                name={"fullName"}
                                placeholder={configJSON.fullName}
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                value={this.state.backOfficeInfo.fullName}
                                onChange={(event: any) => {
                                  setFieldValue("fullName", event.target.value)
                                  this.setState({
                                    backOfficeInfo: {
                                      ...this.state.backOfficeInfo,
                                      fullName: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.fullName}
                                touched={touched.fullName}
                              />
                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.email}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textEmail"
                                placeholder={configJSON.email}
                                variant="outlined"
                                value={this.state.backOfficeInfo.email}
                                name="email"
                                onChange={(event: any) => {
                                  setFieldValue("email", event.target.value)
                                  this.setState({
                                    backOfficeInfo: {
                                      ...this.state.backOfficeInfo,
                                      email: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.email}
                                touched={touched.email}
                              />
                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.id}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textID"
                                name="id" // Add this line
                                placeholder={configJSON.id}
                                variant="outlined"
                                value={this.state.backOfficeInfo.back_id}
                                onChange={(event) => {
                                  setFieldValue("id", event.target.value);
                                  this.setState({
                                    backOfficeInfo: {
                                      ...this.state.backOfficeInfo,
                                      back_id: event.target.value,
                                    },
                                  });
                                }}
                              />
                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.address}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textAddress"
                                placeholder={configJSON.address}
                                variant="outlined"
                                value={this.state.backOfficeInfo.address}
                                onChange={(event: any) => {
                                  setFieldValue("address", event.target.value)
                                  this.setState({
                                    backOfficeInfo: {
                                      ...this.state.backOfficeInfo,
                                      address: event.target.value,
                                    }
                                  })
                                }}
                              />
                               <FieldError
                                error={errors.address}
                                touched={touched.address}
                              />

                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.phoneNo}
                              </p>
                              <TextField
                                data-test-id="textPhoneNo"
                                fullWidth
                                id="textInput"
                                placeholder={configJSON.phoneNo}
                                variant="outlined"
                                name="phoneNo"
                                value={this.state.backOfficeInfo.phoneNo}
                                onChange={(event: any) => {
                                  setFieldValue("phoneNo", event.target.value)
                                  this.setState({
                                    backOfficeInfo: {
                                      ...this.state.backOfficeInfo,
                                      phoneNo: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.phoneNo}
                                touched={touched.phoneNo}
                              />
                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.workHoursFrom}
                              </p>
                              <FormControl fullWidth variant="outlined">
                                <Select
                                  data-test-id="textWorkHoursFrom"
                                  value={this.state.backOfficeInfo.workHoursFromSelect}
                                  onChange={(event: any) =>{
                                    setFieldValue("workHoursFromSelect", event.target.value)
                                    this.setState({
                                      backOfficeInfo: { ...this.state.backOfficeInfo,
                                        workHoursFromSelect: event.target.value, breakStartSelect:"", breakEndSelect: "", workHoursToSelect: ""}
                                     
                                    })
                                  }
                                   
                                  }
                                  fullWidth
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  placeholder={configJSON.workHoursFrom}
                                  inputProps={{
                                    sx: {
                                      color: "#84989F",
                                      borderRadius: "5px",
                                      backgroundColor: "#fff"
                                    }
                                  }}
                                >
                                  {optionsArrayBack.map((optionBack: any) => (
                                    <option key={optionBack.value} value={optionBack.value}>
                                      {optionBack.label}
                                    </option>
                                  ))}
                                </Select>
                                <FieldError
                                error={errors.workHoursFromSelect}
                                touched={touched.workHoursFromSelect}
                              />
                              </FormControl>
                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.workHoursTo}
                              </p>
                              <FormControl fullWidth variant="outlined">
                                <Select
                                  data-test-id="textWorkHoursTo"
                                  value={this.state.backOfficeInfo.workHoursToSelect}
                                  onChange={(event: any) =>{
                                    setFieldValue("workHoursToSelect", event.target.value)
                                    this.setState({
                                      backOfficeInfo: { ...this.state.backOfficeInfo,
                                        workHoursToSelect: event.target.value},
                                     
                                    })
                                  }
                                   
                                  }
                                  fullWidth
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  placeholder={configJSON.workHoursTo}
                                  
                                  inputProps={{
                                    sx: {
                                      color: "#84989F",
                                      borderRadius: "5px",
                                      backgroundColor: "#fff"
                                    }
                                  }}
                                >
                                  {optionsArrayBack.map((optionItemBack: any) => (
                                    <option key={optionItemBack.value} value={optionItemBack.value}>
                                      {optionItemBack.label}
                                    </option>
                                  ))}
                                </Select>
                                <FieldError
                                error={errors.workHoursToSelect}
                                touched={touched.workHoursToSelect}
                              />
                              </FormControl>
                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              {this.state.showAcordianBox ? (
                                <>
                                  <Box
                                    sx={webStylesBackOffice.dispatcherInputBoxBackO}
                                  >
                                    <p
                                      style={
                                        webStylesBackOffice.dispatcherInputBoxBackOLabel
                                      }
                                    >
                                      {configJSON.breakStart}
                                    </p>
                                    <FormControl fullWidth variant="outlined">
                                      <Select
                                        data-test-id="textBreakStart"
                                        value={this.state.backOfficeInfo.breakStartSelect}
                                        onChange={(event: any) =>{
                                          setFieldValue("breakStartSelect", event.target.value)
                                          this.setState({
                                            backOfficeInfo: { ...this.state.backOfficeInfo,
                                              breakStartSelect: event.target.value},
                                           
                                          })
                                        }
                                         
                                        }
                                        
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.breakStart}
                                        inputProps={{
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          }
                                        }}
                                      >
                                          {
                                          this.getStartingBreakTimeBo(optionsArrayBack, this.state.backOfficeInfo.workHoursFromSelect, this.state.backOfficeInfo.workHoursToSelect).map((option: any) => <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>)
                                        }
                                      </Select>
                                    </FormControl>
                                  </Box>
                                  <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                                    <p style={webStylesBackOffice.dispatcherInputBoxBackOLabel}>
                                      {configJSON.breakEnd}
                                    </p>
                                    <FormControl fullWidth={true} variant="outlined">
                                      <Select
                                        fullWidth
                                        data-test-id="textInputBreakEnd"
                                        value={this.state.backOfficeInfo.breakEndSelect}
                                        onChange={(event: any) =>{
                                          setFieldValue("breakEndSelect", event.target.value)
                                          this.setState({
                                            backOfficeInfo: { ...this.state.backOfficeInfo,
                                              breakEndSelect: event.target.value},
                                           
                                          })
                                        }
                                         
                                        }
                                        placeholder={configJSON.breakEnd}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          }
                                        }}
                                      >
                                         {this.getBreakEndTimeBo(optionsArrayBack, this.state.backOfficeInfo.breakStartSelect, this.state.backOfficeInfo.workHoursToSelect)
                                          .map((itemKeys: any) => (
                                            <option key={itemKeys.value} value={itemKeys.value}>
                                              {itemKeys.label}
                                            </option>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </>
                              ) : (
                                ""
                              )}
                              <Typography
                                data-test-id="clickAcordianBtn"
                                onClick={this.clickAcordianBtn}
                                style={webStylesBackOffice.dispatchBackOAccor}
                                variant="body1"
                              >
                                {this.state.showAcordianBox ? (
                                  <>
                                    <RemoveCircleOutlineIcon />{" "}
                                    <p>Remove Break Timing</p>
                                  </>
                                ) : (
                                  <>
                                    <ControlPointIcon /> <p>Add Break Timing</p>
                                  </>
                                )}
                              </Typography>
                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.salary}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textSalary"
                                placeholder={configJSON.salary}
                                variant="outlined"
                                value={this.state.backOfficeInfo.salary}
                                onChange={(event: any) => {
                                  setFieldValue("salary", event.target.value)
                                  this.setState({
                                    backOfficeInfo: {
                                      ...this.state.backOfficeInfo,
                                      salary: event.target.value,
                                    }
                                  })
                                }}
                              />

                            </Box>
                            <Box sx={webStylesBackOffice.dispatcherInputBoxBackO}>
                              <p
                                style={
                                  webStylesBackOffice.dispatcherInputBoxBackOLabel
                                }
                              >
                                {configJSON.SocialSuqNo}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textSocialSuqNo"
                                placeholder={configJSON.SocialSuqNo}
                                variant="outlined"
                                value={this.state.backOfficeInfo.SocialSuqNo}
                                onChange={(event: any) => {
                                  setFieldValue("SocialSuqNo", event.target.value)
                                  this.setState({
                                    backOfficeInfo: {
                                      ...this.state.backOfficeInfo,
                                      SocialSuqNo: event.target.value,
                                    }
                                  })
                                }}
                              />
                            </Box>
                            <Typography variant="h6" style={{
                              color:'red',
                              fontSize: '14px'
                            }}>
                                {this.state.errorEmailExistBo}
                            </Typography>

                            <Box
                              sx={{
                                ...webStylesBackOffice.dispatcherInputBoxBackO,
                                ...webStylesBackOffice.dispatchBoxButton
                              }}
                            >
                              <Button
                                id="btnExample"
                                variant="contained"
                                color="primary"
                                style={webStylesBackOffice.dispatchBtnWhiteBackO}
                                onClick={this.goToListBO}
                              >
                                Back To List
                              </Button>
                              <Button
                                data-test-id="textBtnSubmitback"
                                variant="contained"
                                color="primary"
                                style={webStylesBackOffice.dispatchBtnRedBackO}
                                type="submit"
                              >
                                Save user
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} />
                </Grid>
              </Box>
            </Box>
          </Box>
          <DialogMessage showModal={this.state.showModal} closeModal={this.closeModal} modalEmail={this.state.modalEmail} modalPassword={this.state.modalPasswordBackOffice} isEdit={this.state.isEdit} />
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStylesBackOffice = {
  backGdBoxBackO: {
    background: "rgba(227, 190, 190, 0.28) none repeat scroll 0% 0%",
    overflowY: "auto" as "auto",
    maxWidth:'100%'
  },
  width100: {
    width: "100%"
  },
  headerSidBarBackO: {
    paddingTop: "10px",
    background: "#fff",
    height: "100vh",
    textAlign: "center",
    width: "18vw",
    zIndex: "9",
    position: "fixed",
    bottom: "0px",
    top: "0px",
    left: "0",
    borderRight: "1px solid rgba(0, 0, 0, 0.18)"
  },
  dispatchBoxBackO: {
    height: "100%",
    width: "83vw",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    marginLeft: "18vw"
  },
  dispatchTypoBackO: {
    fontSize: "20px",
    lineHeight: "45px",
    display: "flex"
  },
  dispatchTypoBoxBackO: {
    borderRadius: "5px 5px 30px 5px",
    padding: "15px 15px 15px 35px",
    background: "#fff"
  },
  dispatchTabButton: {
    display: "flex",
    textAlign: "center",
    padding: "10px",
    margin: "20px 0px",
    borderRadius: "10px",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%",
    justifyContent: "center"
  },
  dispatchBackOAccor: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  marginTop15Back: {
    marginTop: "15px"
  },
  dispatchFormBoxBackO: {
    borderRadius: "10px",
    padding: "15px",
    background: "#fff",
    marginBottom: "20px"
  },
  dispatchLeftICon: {
    marginTop: "10px"
  },
  dispatcherInputBoxBackO: {
    margin: "10px 0"
  },
  dispatcherInputBoxBackOLabel: {
    margin: "5px 0",
    display: "block"
  },
  dispatchBtnWhiteBackO: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  dispatchBtnRedBackO: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },

  dispatchBoxButton: {
    textTransform: "capitalize",
    marginTop: "20px",
    borderTop: "1px #bbb8b8 solid"
  }
};

//@ts-ignore 
function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
// Customizable Area End
