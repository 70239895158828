import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { UserType } from "./LayoutController.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import moment from "moment";

export interface IOption {
  value: string;
  title: string;
}

export class RejectDetail {
  isSpecify: boolean = false;
  reasonId: string = "";
  specificReason: string = "";
  isConfirmDisabled: boolean = true;
}

export const rejectReasonOptions: IOption[] = [
  { value: "No Show", title: "No Show" },
  { value: "No Time", title: "No Time" },
  { value: "Weather Conditions", title: "Weather Conditions" },
  { value: "No Material", title: "No Material" },
  { value: "No Access", title: "No Access" },
  { value: "Others", title: "Others" },
];
const OtherID = "Others";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  maintainer_id: string;
  isTechnician: boolean;
  open: { reject: boolean; delete: boolean; complete: boolean };
  rejectDetail: RejectDetail;
  filter: { anchorEl: any; menus: string[] };
  task: any;
  checklist: any[];
  floor: { current: number[]; filtered: number[]; all: number[] };
  type: { current: string[]; filtered: string[]; all: string[] };
  search: string;
  isInvPdf: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class JobDetailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskDetailApiId = "";
  deleteTaskApiId = "";
  rejectTaskApiId = "";
  startTaskApiId = "";
  completeTaskApiId = "";
  updateChecklistApiId = "";
  getChecklistSearchApiId = "";
  getPdfApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      maintainer_id: "",
      isTechnician: false,
      open: { reject: false, delete: false, complete: false },
      rejectDetail: new RejectDetail(),
      filter: { anchorEl: null, menus: [] },
      task: {},
      checklist: [],
      floor: { current: [], filtered: [], all: [] },
      type: { current: [], filtered: [], all: [] },
      search: "",
      isInvPdf: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.getTaskDetailApiId:
          this.handleTaskDetailRes(responseJson);
          break;
        case this.deleteTaskApiId:
        case this.rejectTaskApiId:
          this.handleDeleteRejectTaskRes(responseJson);
          break;
        case this.startTaskApiId:
          this.handleStartCompleteTaskRes(responseJson);
          break;
        case this.completeTaskApiId:
          this.handleCompleteTaskRes(responseJson);
          break;
        case this.updateChecklistApiId:
          this.handleUpdateChecklistRes(responseJson);
          break;
        case this.getChecklistSearchApiId:
          this.handleChecklistRes(responseJson);
          break;
        case this.getPdfApiId:
          this.handleDownloadPdf(responseJson);
      }
    }
  };

  async componentDidMount() {
    const userDataStr = localStorage.getItem("userData");
    const taskId = this.props.navigation.getParam("id");
    if (userDataStr && taskId) {
      const userData = JSON.parse(userDataStr);
      this.setState({
        isTechnician: userData.user_type === UserType.Technician,
        maintainer_id: userData.maintainer_id,
      });
      this.getTaskDetail(taskId);
    }
  }

  getTaskDetail = (id: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskBlock}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteTask = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskBlock}/${this.state.task.id}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  rejectTask = (reasonBody: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rejectTaskApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskBlock}/${this.state.task.id}/${configJSON.rejectTaskEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reasonBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  startTask = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.startTaskApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskBlock}/${this.state.task.id}/${configJSON.startTaskEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  completeTask = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.completeTaskApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskBlock}/${this.state.task.id}/${configJSON.completeTaskEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateChecklistStatus = (id: number, status: any) => {
    const formData = new FormData();
    formData.append("checklist[asset_status]", status || "In-progress");

    const headers = { Token: localStorage.getItem("token") };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateChecklistApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checklistBlock}/${id}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getChecklistSerach = (search: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      task_id: this.state.task.id,
      search_query: search,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getChecklistSearchApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getChecklistSearchEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPdf = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      task_id: this.state.task.id,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPdfApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPdfApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTaskDetailRes = (res: any) => {
    if (res.data.attributes) {
      this.setState({ task: res.data.attributes });
      this.handleChecklistRes(res.data.attributes.checklists);
    }
  };

  handleChecklistRes = (res: any) => {
    if (res.data) {
      const checklist = res.data.map((x: any) => ({
        checklistId: Number(x.id),
        ...x.attributes,
        asset: {
          ...x.attributes.asset.data.attributes,
          assetId: Number(x.attributes.asset.data.id),
        },
      }));
      let floor: any[] = [];
      let type: any[] = [];
      checklist.forEach((x: any) => {
        let { floor_number, asset_type } = x.asset;
        floor_number = floor_number || 0;
        if (!floor.includes(floor_number)) floor.push(floor_number);
        if (!type.includes(asset_type)) type.push(asset_type);
      });
      this.setState({
        checklist,
        floor: { current: floor, filtered: floor, all: floor },
        type: { current: type, filtered: type, all: type },
      });
    }
  };

  handleDeleteRejectTaskRes = (res: any) => {
    if (res.meta) this.props.navigation.goBack();
  };

  handleStartCompleteTaskRes = (res: any) => {
    if (res.meta) this.getTaskDetail(this.state.task.id);
  };

  handleCompleteTaskRes = (res: any) => {
    if (res.meta)
      this.props.navigation.navigate("JobSummary", { id: this.state.task.id });
  };

  handleUpdateChecklistRes = (res: any) => {
    if (res.data) this.toChecklist(res.data.id);
  };

  handleDownloadPdf = (res: any) => {
    if (this.state.isInvPdf && res.invoice) window.open(res.invoice, "_blank");
    else if (res.certificate) window.open(res.certificate, "_blank");
  };

  toChecklist = (id: any) => {
    this.props.navigation.navigate("ChecklistProcess", {
      taskId: this.state.task.id,
      id,
    });
  };

  toLocation = () => {
    this.props.navigation.navigate("ViewLocation", {
      id: this.state.task.location_id,
    });
  };

  getFilteredAssets = (floor: number) => {
    return this.state.checklist.filter(
      (x: any) =>
        x.asset.floor_number == floor &&
        this.state.type.filtered.includes(x.asset.asset_type)
    );
  };

  isStatusComplete = () => this.state.task.status === "complete";

  getActionClass = (isForTechnician = this.state.isTechnician) => {
    return isForTechnician && this.state.task.status !== "complete"
      ? ""
      : "d-none";
  };

  getCompletionClass = () => (this.isStatusComplete() ? "" : "d-none");

  onBack = () => this.props.navigation.goBack();

  isActionDisabled = (status: any) =>
    status && status !== "assigned" && status !== "un_assigned";

  isChecklistCompletionDisabled = (status: any) => status !== "in_progress";

  getFieldValue = (x: any) => x || "-";

  getNoteClass = (x: any) => (x ? "blue" : "light-blue");

  getResult = (x: any) => {
    let temp = { class: "yellow", status: "Success" };
    if (typeof x.success !== "boolean")
      temp = { class: "gray", status: "Pending" };
    else if (!x.success) temp = { class: "red", status: "Failure" };
    else if (!x.has_notes) temp = { class: "green", status: "Success" };
    return temp;
  };

  getPendingAsset = () => {
    return this.state.checklist.filter(
      (x: any) => x.asset_status != "Completed"
    ).length;
  };

  convertToHour = (x: number) =>
    moment.utc(moment.duration(x, "minutes").asMilliseconds()).format("HH:mm");

  // NOTE: Used in edit job order functionality
  onEdit = () => {
    this.props.navigation.navigate("UpdateJobOrder", {
      id: this.state.task.id,
    });
  };

  // NOTE: Used in reject job order functionality
  onReject = () => {
    this.setState({
      rejectDetail: new RejectDetail(),
      open: { ...this.state.open, reject: true },
    });
  };

  onRejectReasonChange = (e: any) => {
    const value = e.target.value;
    if (value) {
      this.setState({
        rejectDetail: {
          reasonId: value,
          isSpecify: value === OtherID,
          specificReason: "",
          isConfirmDisabled: value === OtherID,
        },
      });
    }
  };

  onSpecificReasonChange = (e: any) => {
    this.setState({
      rejectDetail: {
        ...this.state.rejectDetail,
        specificReason: e.target.value,
        isConfirmDisabled: !e.target.value,
      },
    });
  };

  handleRejectDialog = (isConfirm: boolean) => {
    if (isConfirm) {
      const { reasonId, specificReason } = this.state.rejectDetail;
      this.rejectTask({
        reason: reasonId === OtherID ? specificReason : reasonId,
      });
    }
    this.setState({
      rejectDetail: new RejectDetail(),
      open: { ...this.state.open, reject: false },
    });
  };

  // NOTE: Used in delete job order functionality
  onDelete = () => {
    this.setState({ open: { ...this.state.open, delete: true } });
  };

  handleDeleteDialog = (isConfirm: boolean) => {
    if (isConfirm) this.deleteTask();
    this.setState({ open: { ...this.state.open, delete: false } });
  };

  // NOTE: Used in complete job order functionality
  onComplete = () => {
    this.setState({ open: { ...this.state.open, complete: true } });
  };

  handleCompleteDialog = (isConfirm: boolean) => {
    if (isConfirm) this.completeTask();
    this.setState({ open: { ...this.state.open, complete: false } });
  };

  // NOTE: Used in assets filter functionality
  onOpenCloseFilter = (anchorEl: any) => {
    this.setState({ filter: { anchorEl, menus: [] } }, () =>
      this.setState({
        type: { ...this.state.type, current: this.state.type.filtered },
        floor: { ...this.state.floor, current: this.state.floor.filtered },
      })
    );
  };

  onOptCollapse = (menu: string) => {
    let temp = [...this.state.filter.menus];
    if (temp.includes(menu)) temp = temp.filter((x) => x !== menu);
    else temp.push(menu);
    this.setState({ filter: { ...this.state.filter, menus: temp } });
  };

  onTypeChange = (type: string) => {
    let current = [...this.state.type.current];
    if (current.includes(type)) current = current.filter((x) => x != type);
    else current.push(type);
    this.setState({ type: { ...this.state.type, current } });
  };

  onFloorChange = (floor: number) => {
    let current = [...this.state.floor.current];
    if (current.includes(floor)) current = current.filter((x) => x != floor);
    else current.push(floor);
    this.setState({ floor: { ...this.state.floor, current } });
  };

  onApplyFilter = () => {
    this.setState({
      type: { ...this.state.type, filtered: this.state.type.current },
      floor: { ...this.state.floor, filtered: this.state.floor.current },
    });
  };

  // NOTE: Used in assets search functionality
  onSearch = (e: any) => {
    const search = e.target.value;
    this.setState({ search });
    if (search) this.getChecklistSerach(search);
    else this.getTaskDetail(this.state.task.id);
  };

  // NOTE: Used in doenload pdf functionality
  onDownload = (isInvPdf: boolean) => {
    this.setState({ isInvPdf }, this.getPdf);
  };
  // Customizable Area End
}
