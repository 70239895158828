import React from "react";
// Customizable Area Start
import {
  CssBaseline,
  AppBar,
  Drawer,
  List,
  ListItemText,
  ListItem,
  Hidden,
  Divider,
  styled,
  InputAdornment,
  TextField,
  createTheme,
  ThemeProvider,
  Collapse,
  Container,
  Avatar,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

import { logo } from "../../email-account-login/src/assets";
import {
  BackArrowIcon,
  ClearBlackIcon,
  DownAccordionIcon,
  MenuBlackIcon,
  SearchBlackIcon,
  SearchGrayIcon,
  UpAccordionIcon,
} from "./assets";
import LayoutController, { IRoute, Props } from "./LayoutController.web";
import "../assets/scroll.css";
import GlobalSearch from "../../advancedsearch/src/GlobalSearch.web";

const drawerWidth = 280;

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "#eae7e7",
    height: "30px",
    color: "#777",
    borderRadius: "8px",
  },
  "& .MuiAvatar-root": { height: 22, width: 22 },
});
const NavContainer = styled(Container)((props) => ({
  width: "auto",
  padding: 0,
  [props.theme.breakpoints.up("md")]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));
const CustomAppBar = styled(AppBar)((props) => ({
  background: "#fff",
  [props.theme.breakpoints.up("md")]: {
    background: "#eae7e7",
    color: "000",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#dbaeae30",
    },
    secondary: {
      main: "#d23637",
    },
  },
});
const CustomNavlink = styled(NavLink)({
  width: "100%",
  padding: "0px",
  margin: "0px",
  textDecoration: "none",
  "&.static": { pointerEvents: "none" },
});

const CollapseMenu = (props: {
  mainRoute: IRoute;
  currentPath: string;
  open: boolean;
  isMobile?: boolean;
  onPageReload: (path: string) => void;
}) => {
  const { mainRoute, currentPath, open, isMobile, onPageReload } = props;
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List disablePadding={isMobile}>
        {mainRoute.subRoutes.map((route: any, index: number) => {
          const isSelected =
            currentPath.split("/")[1] === mainRoute.inclusion &&
            currentPath.split("/")[2] === route.inclusion;
          return (
            <React.Fragment key={index}>
              {isMobile && <Divider />}
              <CustomNavlink
                data-test-id={`${index}`}
                to={route.path}
                className={route.path}
                onClick={() => onPageReload(route.path)}
              >
                <ListItem
                  style={{ ...styles.sublistItem, height: isMobile ? 34 : 40 }}
                >
                  {!isMobile && (
                    <div
                      style={{
                        ...styles.subDiv,
                        backgroundColor: isSelected ? "#d23637" : "#ccc",
                      }}
                    />
                  )}
                  <ListItemText
                    primary={route.title}
                    style={styles.subListItemText}
                    primaryTypographyProps={{
                      style: {
                        ...styles.subText,
                        fontWeight: isSelected ? "bold" : "normal",
                      },
                    }}
                  />
                </ListItem>
              </CustomNavlink>
            </React.Fragment>
          );
        })}
      </List>
    </Collapse>
  );
};
const MemoCollapse = React.memo(CollapseMenu);

const ListItemContent = (props: {
  route: IRoute;
  toggle: string[];
  bgColor: string;
  isMobile?: boolean;
  onReload: (path: string) => void;
  onCollapse: (menu: string) => void;
}) => {
  const { route, toggle, isMobile, bgColor, onReload, onCollapse } = props;
  const padding = isMobile ? "0px 0px 0px 12px" : "6px 0px 6px 20px";
  return (
    <>
      {!route.subRoutes.length ? (
        <CustomNavlink
          data-test-id="nav"
          to={route.path}
          className={route.path}
          onClick={() => onReload(route.path)}
        >
          <ListItemText
            primary={route.title}
            primaryTypographyProps={{ style: styles.font14 }}
            style={{
              ...styles.listItemText,
              backgroundColor: bgColor,
              padding,
            }}
          />
        </CustomNavlink>
      ) : (
        <div
          data-test-id="menu"
          style={{ ...styles.listItemDiv, backgroundColor: bgColor }}
          onClick={() => onCollapse(route.title)}
        >
          <ListItemText
            primary={route.title}
            primaryTypographyProps={{ style: styles.font14 }}
            style={{ ...styles.listItemText, padding }}
          />
          <div style={styles.accordionDiv}>
            <img
              style={styles.accordionIcon}
              src={
                toggle.includes(route.title)
                  ? UpAccordionIcon
                  : DownAccordionIcon
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
// Customizable Area End

export default class Layout extends LayoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div style={styles.container}>
          <CssBaseline />
          <CustomAppBar position="fixed">
            <Hidden mdUp implementation="css">
              {/* NOTE: Header for the mobile view */}
              <div style={mobileStyles.drawerToolbar}>
                <div style={mobileStyles.leftDiv}>
                  <img
                    data-test-id="menuIcon"
                    src={MenuBlackIcon}
                    style={mobileStyles.icon}
                    onClick={this.onMenuToggle}
                  />
                </div>
                <div style={mobileStyles.drawerText}>
                  <img src={logo} style={styles.drawerLogo} />
                </div>
                <div style={mobileStyles.rightDiv}>
                  <img
                    data-test-id="searchIcon"
                    src={
                      this.state.openMobileSearch
                        ? ClearBlackIcon
                        : SearchBlackIcon
                    }
                    style={mobileStyles.icon}
                    onClick={this.onSearchToggle}
                  />
                </div>
              </div>
              <div
                style={{
                  ...mobileStyles.searchDiv,
                  display: this.state.openMobileSearch ? "flex" : "none",
                }}
              >
                <CustomTextField
                  disabled
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Avatar src={SearchGrayIcon} />
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.searchText}
                  onChange={this.onSearch}
                />
              </div>
            </Hidden>
            <Hidden smDown implementation="css">
              {/* NOTE: Header for the web view */}
              <div
                style={{
                  ...styles.sidebarHeader,
                  ...styles.container,
                  justifyContent: styles.justifyContent,
                }}
              >
                <div style={styles.searchDiv}>
                  <CustomTextField
                    data-test-id="searchWeb"
                    disabled
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Avatar src={SearchGrayIcon} />
                        </InputAdornment>
                      ),
                    }}
                    value={this.state.searchText}
                    onChange={this.onSearch}
                  />
                </div>
              </div>
            </Hidden>
          </CustomAppBar>

          <NavContainer>
            <Hidden mdUp implementation="css">
              <Drawer
                data-test-id="mobileDrawer"
                variant="temporary"
                anchor="top"
                open={this.state.openMobileDrawer}
                onClose={() => this.onMenuToggle()}
                PaperProps={{ style: mobileStyles.drawerPaper }}
                ModalProps={{ keepMounted: true }}
              >
                <div style={mobileStyles.drawerToolbar}>
                  <div style={mobileStyles.leftDiv}>
                    <img
                      data-test-id="backIcon"
                      src={BackArrowIcon}
                      style={mobileStyles.icon}
                      onClick={() => this.onMenuToggle()}
                    />
                  </div>
                  <p style={mobileStyles.drawerText}>MENU</p>
                  <div style={mobileStyles.rightDiv} />
                </div>
                <Divider />
                <List disablePadding style={mobileStyles.list}>
                  {this.state.routes.map((route: IRoute, index) => (
                    <React.Fragment key={index}>
                      <ListItem style={mobileStyles.listItem}>
                        <ListItemContent
                          data-test-id={`listItem${index}`}
                          isMobile
                          route={route}
                          toggle={this.state.menuToggle}
                          bgColor="none"
                          onReload={this.onPageReload}
                          onCollapse={this.onCollapse}
                        />
                      </ListItem>
                      <MemoCollapse
                        data-test-id={`memoCollapse${index}`}
                        open={
                          !!route.subRoutes.length &&
                          this.state.menuToggle.includes(route.title)
                        }
                        mainRoute={route}
                        currentPath={this.state.currentPath}
                        isMobile
                        onPageReload={this.onPageReload}
                      />
                      {this.state.routes.length - 1 !== index && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                open
                variant="permanent"
                PaperProps={{ style: styles.drawerPaper }}
              >
                <div style={styles.sidebarHeader}>
                  <img src={logo} style={styles.drawerLogo} />
                </div>
                <Divider />
                <List>
                  <ListItem style={styles.listItem}>
                    <ListItemText
                      primary={JSON.parse(localStorage.getItem("userData") || "{}").company_name||"[Company Name]"}
                      style={{
                        ...styles.listItemText,
                        padding: "6px 0px 6px 20px",
                      }}
                      primaryTypographyProps={{
                        style: { fontWeight: "bold", fontSize: 14 },
                      }}
                    />
                  </ListItem>
                  {this.state.routes.map((route: IRoute, index) => (
                    <React.Fragment key={index}>
                      <ListItem style={styles.listItem}>
                        <ListItemContent
                          data-test-id={`listItemWeb${index}`}
                          route={route}
                          toggle={this.state.menuToggle}
                          bgColor={this.getBgColor(route.inclusion)}
                          onReload={this.onPageReload}
                          onCollapse={this.onCollapse}
                        />
                      </ListItem>
                      <MemoCollapse
                        data-test-id={`memoCollapseWeb${index}`}
                        open={
                          !!route.subRoutes.length &&
                          this.state.menuToggle.includes(route.title)
                        }
                        mainRoute={route}
                        currentPath={this.state.currentPath}
                        onPageReload={this.onPageReload}
                      />
                    </React.Fragment>
                  ))}
                </List>
              </Drawer>
            </Hidden>
          </NavContainer>

          <main
            style={{
              ...styles.mainContainer,
              marginTop: this.state.openMobileSearch ? "108px" : "64px",
            }}
          >
            <div style={styles.mainDiv}>
              {this.state.searchText ? (
                <GlobalSearch
                  navigation={this.props.navigation}
                  isTechnician={this.state.isTechnician}
                />
              ) : (
                this.props.children
              )}
            </div>
          </main>
        </div>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  container: { display: "flex" },
  mainContainer: {
    flexGrow: 1,
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
  mainDiv: {
    padding: "10px",
    backgroundColor: "#eae7e7",
    width: "100%",
    minHeight: "100%",
  },
  listItem: { padding: "6px 30px 6px 16px" },
  listItemDiv: {
    borderRadius: 8,
    width: "100%",
    display: "flex",
    alignItems: "center" as "center",
    cursor: "pointer",
  },
  listItemText: { borderRadius: 8, margin: 0, color: "#000" },
  sublistItem: { padding: "0px 30px 0px 60px" },
  subListItemText: { margin: "auto" as "auto", color: "#000" },
  subText: { fontSize: 12 },
  subDiv: { height: 40, width: 3, marginRight: 20 },
  drawerPaper: { width: `${drawerWidth}px` },
  sidebarHeader: {
    height: "64px",
    textAlign: "center" as "center",
    padding: "10px 0px",
    background: "#fff",
    width: "100%",
  },
  drawerLogo: { height: "44px" },
  navlink: { padding: "0px", margin: "0px", textDecoration: "none" },
  searchDiv: { padding: "8px 20px", width: "33%" },
  font14: { fontSize: 14 },
  accordionDiv: { marginRight: 10, textAlign: "right" as "right" },
  accordionIcon: { height: 24, width: 24 },
  justifyContent: "end",
};
const mobileStyles = {
  list: { maxheight: "158px", overflowY: "auto" as "auto" },
  listItem: { padding: "6px 16px" },
  drawerPaper: {
    width: "100%",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    maxHeight: "223px",
  },
  drawerToolbar: { ...styles.sidebarHeader, display: "flex" },
  drawerText: {
    textAlign: "center" as "center",
    fontSize: "16px",
    fontWeight: "bold" as "bold",
    margin: "auto",
  },
  leftDiv: { padding: "8px 20px", textAlign: "left" as "left", width: "70px" },
  rightDiv: { padding: "8px 20px", textAlign: "right" as "right", width: 70 },
  icon: { height: "30px", width: "30px" },
  searchDiv: { height: "44px", padding: "2px 20px" },
};
// Customizable Area End
