import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePassdField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  ErrorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiLoginCallId: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePassdField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      ErrorMsg:"",
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.companyPortal()
    // Customizable Area End
  }

  // Customizable Area Start



  // Web Event Handling
  handleClickShowPwd = () => {
    this.setState({
      enablePassdField: !this.state.enablePassdField,
    });
  };

  setEmailInput = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPasswordInput = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMeInput = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };




  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.apiLoginCallId) {
          if (responseJson && responseJson.token) {
            localStorage.setItem(
              "userData",
              JSON.stringify(responseJson.user)
            );
            localStorage.setItem("token", responseJson.token);
            this.apiResRedirect(responseJson.user);
            this.setState({ErrorMsg:"Login Successfully"})
           
          } else {
            //Check Error Response
            this.setState({ErrorMsg:configJSON.errorApiRes})
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  apiResRedirect=(user:any)=>{
    if(user?.password_changed===false){
      this.props.navigation.navigate("SuccessFully");
    }else{
     this.redirectPage(user.user_type)
    }
  }
  redirectPage =(page:any)=>{
    if(page ==="technician"){
      this.props.navigation.navigate("TechnicianLandingPage");
    }else{
      this.props.navigation.navigate("CompanyLandingPage");
    }
  }
  doEmailLogIn=()=> {
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !emailRegEx.test(this.state.email)
    ) {
      this.setState({ErrorMsg:configJSON.errorEmailNotValid});
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({ErrorMsg:configJSON.errorPasswordNotValid});
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };


    const httpBody = {
      email: this.state.email,
      password: this.state.password,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginSAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  /*istanbul ignore next */
  companyPortal=()=>{
    let urlParams:any;
    if(typeof window !== "undefined"){
       urlParams = new URLSearchParams(window.location.search);
       const paramValue:any =  urlParams.get('encrypted_params');
    if(paramValue !== null && paramValue !== undefined && paramValue !== ""){
      const decodedPassword = Buffer.from(paramValue, 'base64').toString('utf-8');
    const [email, password] = decodedPassword.split(' ');
    this.setState({email:email, password:password})
    setTimeout(()=>{
      this.doEmailLogIn()
    }, 500)
    }
      }
   
    
  }
 
  // Customizable Area End
}
