import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  allManagerList:any;
  maintainer_Id:any;
  ClientDataObj: {
    clientId:any;
    companyName: string;
    vat:string;
    contactDetails:string;
    contactPreferences:string;
    segmentSelect:any;
    paymentTerms:any;
    paymentDetails:string;
    accountManager:any;
    fiscalId: string;
    assignedTechnician:string;
    comments:string;
  }
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateClientController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  getGroupsApiCallId = "";
  apiViewAllMngCallId: string ="";
  createClientApiCallId: string="";
  updateClientApiCallId: string="";
  apiViewAllClientDataCallId:string="";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allManagerList:[],
      maintainer_Id:null,
      ClientDataObj: {
        clientId:0,
        companyName: "",
        fiscalId: "",
        vat:"",
        contactDetails:"",
        contactPreferences:"",
        paymentDetails:"",
        segmentSelect:"",
        paymentTerms:"",
        accountManager:"",
        assignedTechnician:"",
        comments:"",
        

      },
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAllManagerRes();
    const userDatas:any = localStorage.getItem("userData")
    const user:any = JSON.parse(userDatas);
    const maintainerId:any = user.maintainer_id;
    this.setState({
      maintainer_Id:maintainerId
    })
    this.getParamsIdHandle();
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId != null) { 
        switch (true) {
          case apiRequestCallId === this.apiViewAllMngCallId:
            this.ViewAllManagerData(responseJson)
            break;
            case apiRequestCallId === this.createClientApiCallId:
                this.clientSucces(responseJson)
              break
              case apiRequestCallId === this.apiViewAllClientDataCallId:
                this.AllclientSetState(responseJson)
              break
              case apiRequestCallId === this.updateClientApiCallId:
                this.updateResData(responseJson)
                break
            default:
        }
      }
    }
    // Customizable Area End
  }
  getParamsIdHandle=()=>{
    let urlID:any = this.props.navigation.getParam("id")
    if(urlID){
      const userDataString:any = localStorage.getItem("userData")
    const userResData:any = JSON.parse(userDataString);
    const maintainerId:any = userResData.maintainer_id;
    this.getAllClientDataRes(maintainerId);
    }
  }
  handleSubmit = (values: any, { resetForm }: any) => {
    resetForm({
      values: {
        companyName: this.state.ClientDataObj.companyName,
        vat:this.state.ClientDataObj.vat,
        contactDetails:this.state.ClientDataObj.contactDetails,
        paymentDetails:this.state.ClientDataObj.paymentDetails,
        contactPreferences:this.state.ClientDataObj.contactPreferences,
        segmentSelect:this.state.ClientDataObj.segmentSelect,
        paymentTerms:this.state.ClientDataObj.paymentTerms,
        accountManager:this.state.ClientDataObj.accountManager,
        assignedTechnician:this.state.ClientDataObj.assignedTechnician,
        comments:this.state.ClientDataObj.comments,

        fiscalId: this.state.ClientDataObj.fiscalId,

      },
   }); 
   if(this.state.ClientDataObj.clientId){
    this.handleClientUpdate();
   }else{
    this.handlePostClient()
   }
 
 };
 handlePostClient = () => {

  let headers = {
    "Content-Type": "application/json",
    "Token": localStorage.getItem("token"),
  }
  const httpBody = {
    "client_account": {
      "company_name": this.state.ClientDataObj.companyName,
      "fiscal_id": this.state.ClientDataObj.fiscalId,
      "vat": this.state.ClientDataObj.vat,
      "contact_preferences": this.state.ClientDataObj.contactPreferences,
      "payment_details": this.state.ClientDataObj.paymentDetails,
      "payment_terms": this.state.ClientDataObj.paymentTerms,
      "contact_details": this.state.ClientDataObj.contactDetails,
      "account_manager_id":this.state.ClientDataObj.accountManager,
      "assigned_technician":this.state.ClientDataObj.assignedTechnician,
      "segment": this.state.ClientDataObj.segmentSelect,
      "comments":this.state.ClientDataObj.comments,
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.createClientApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createClientApiEndPoint+this.state.maintainer_Id
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleClientUpdate = () => {

  let headers = {
    "Content-Type": "application/json",
    "Token": localStorage.getItem("token"),
  }
  const httpBody = {
    "client_account": {
      "company_name": this.state.ClientDataObj.companyName,
      "fiscal_id": this.state.ClientDataObj.fiscalId,
      "vat": this.state.ClientDataObj.vat,
      "contact_details": this.state.ClientDataObj.contactDetails,
      "contact_preferences": this.state.ClientDataObj.contactPreferences,
      "payment_details": this.state.ClientDataObj.paymentDetails,
      "payment_terms": this.state.ClientDataObj.paymentTerms,
      "segment": this.state.ClientDataObj.segmentSelect,
      "account_manager_id":this.state.ClientDataObj.accountManager,
      "assigned_technician":this.state.ClientDataObj.assignedTechnician,
      "comments":this.state.ClientDataObj.comments,
      
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.updateClientApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.updateClientApiEndPoint+this.state.ClientDataObj.clientId
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
naviLocation=()=>{
  this.props.navigation.navigate("CreateLocation")
}
getAllManagerRes=()=>{
  const headers = {
    "Token": localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiViewAllMngCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.viewAllManagerApiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}
getAllClientDataRes=(keyId:any)=>{
  const headers = {
    "Token": localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiViewAllClientDataCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.viewAllClientApiEndPoint+keyId
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}
ViewAllManagerData=(responseJson:any)=>{
if(responseJson.data.length>0){
  this.setState({
    allManagerList:responseJson.data
  })
}

}
clientSucces=(responseJson:any)=>{
if(responseJson.meta){
  this.setState({
    ClientDataObj: { ...this.state.ClientDataObj, 
      companyName: "",
      fiscalId: "",
      vat:"",
      contactDetails:"",
      contactPreferences:"",
      paymentDetails:"",
      segmentSelect:"",
      paymentTerms:"",
      accountManager:"",
      assignedTechnician:"",
      comments:"",
    }})
}
}
AllclientSetState=(responseJson:any)=>{
  if (responseJson?.maintainer?.clients) {
    const clientIdToFind = this.props.navigation.getParam("id");
    const foundClient = responseJson.maintainer.clients.find((item:any) => {
      return item.client_account_id === parseInt(clientIdToFind)
    }
    );
    this.setState({
      ClientDataObj: {
        ...this.state.ClientDataObj,
        clientId:foundClient.client_account_id,
        companyName: foundClient.client_company_name,
        fiscalId: foundClient.client_details.data.attributes.fiscal_id,
        vat:foundClient.client_details.data.attributes.vat,
        contactDetails:foundClient.client_details.data.attributes.contact_details,
        contactPreferences:foundClient.client_details.data.attributes.contact_preferences,
        paymentDetails:foundClient.client_details.data.attributes.payment_details,
        segmentSelect:foundClient.client_details.data.attributes.segment,
        paymentTerms:foundClient.client_details.data.attributes.payment_terms,
        accountManager:foundClient.client_details.data.attributes.account_manager_id,
        assignedTechnician:foundClient.client_details.data.attributes.assigned_technician,
        comments:foundClient.client_details.data.attributes.comments,
        

      }
     
    })
    
  }
}
updateResData=(responseJson:any)=>{
  if(responseJson.meta){
    this.setState({
      ClientDataObj: { ...this.state.ClientDataObj, 
        companyName: "",
        clientId:0,
        paymentTerms:"",
        accountManager:"",
        assignedTechnician:"",
        comments:"",
        fiscalId: "",
        vat:"",
        contactDetails:"",
        contactPreferences:"",
        paymentDetails:"",
        segmentSelect:"",
      }})
      this.props.navigation.navigate("ViewClientProfile")
  }
}
backToViewClient=()=>{
  this.props.navigation.navigate("ViewClientProfile")
}
  // Customizable Area End
}
