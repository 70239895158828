import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingAssetState:any;
  singleAssetData:any;
  showModalMsgAsset:any;
  user_typels:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class ViewAssetController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiViewAllAssetFilterCallId:string="";
  apiSingleAssetDeleteCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      user_typels:"",
      loadingAssetState:true,
      showModalMsgAsset:false,
      singleAssetData:{
        assetIds:0,
        asset_id:0,
        asset_type: "",
        serial_no: "",
        location_id:"",
        floor_no:"",
        location_no:"",
        last_maintenance_date: "",
        maintenance_frq:"",
        manufacturing_date:"",
        expiry_date:"",
        last_recharge_date:"",
        asset_qty:0,
        discount_amount:"",
        maintenance_duration:"",
        price:"",
        comments:"",
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
 async componentDidMount() {
    super.componentDidMount();
    const userDataStringData: any = localStorage.getItem("userData")
    const userDataLocal: any = JSON.parse(userDataStringData);
    const userType:any = userDataLocal.user_type;
    this.setState({
      user_typels:userType
    })
    this.getAllAssetFilter()
 }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) { 
        if(apiRequestCallId === this.apiViewAllAssetFilterCallId){
            this.ViewAllAssetFilterData(responseJson)
        }
        if(apiRequestCallId === this.apiSingleAssetDeleteCallId){
            this.deleteSingleAssetData(responseJson)
        }
       
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAllAssetFilter=()=>{
    let clientIds:any = localStorage.getItem("clientId")
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiViewAllAssetFilterCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAssetApiEndPoint+clientIds
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  ViewAllAssetFilterData=(responseJson:any)=>{
   if (responseJson.client_assets) {
    const clientIdToFind = this.props.navigation.getParam("id");
    const foundLocation = responseJson.client_assets.find((item:any) => {
      return item.data.id === clientIdToFind
    }
    );
    this.setState({
        singleAssetData: { ...this.state.singleAssetData, 
            assetIds:foundLocation.data.id,
          asset_type:foundLocation.data.attributes.asset_type,
          asset_id:foundLocation.data.attributes.asset_id,
          serial_no:foundLocation.data.attributes.serial_number,
          location_id:foundLocation.data.attributes.location_id,
          floor_no:foundLocation.data.attributes.floor_number,
          location_no:foundLocation.data.attributes.location_number,
          last_maintenance_date:foundLocation.data.attributes.last_maintenance_date,
          maintenance_frq:foundLocation.data.attributes.maintenance_frequency,
          manufacturing_date:foundLocation.data.attributes.manufacturing_date,
          expiry_date:foundLocation.data.attributes.expiry_date,
          last_recharge_date:foundLocation.data.attributes.last_recharge_date,
          asset_qty:foundLocation.data.attributes.asset_quantity,
          discount_amount:foundLocation.data.attributes.discount_amount_percentage,
          maintenance_duration:foundLocation.data.attributes.duration,
          price:foundLocation.data.attributes.price,
          comments:foundLocation.data.attributes.comments,
         
      }
     
    })
    this.setState({loadingAssetState:false})
  }
  }
  handleRemoveModalAsset=()=>{
    this.setState({showModalMsgAsset:true})
  }
  closeMsgModalAsset=()=>{
    this.setState({showModalMsgAsset:false})
  }
  deleteSingleAsset=()=>{
    let cliId:any = localStorage.getItem("clientId")
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSingleAssetDeleteCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAssetApiEndPoint + `?client_account_id=${cliId}`+`&asset_id=${this.state.singleAssetData.assetIds}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  deleteSingleAssetData=(responseJson:any)=>{
    if(responseJson){
      this.setState({
        showModalMsgAsset:false
      })
      this.props.navigation.navigate("ViewClientProfile")
    }

  }
  editHandleAsset=()=>{
    this.props.navigation.navigate("CreateAssets",{id:this.state.singleAssetData.assetIds})
  }
  // Customizable Area End
}
