import React from "react";

// Customizable Area Start
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";

import { dialogStyles } from "../../landingpage/src/JobDetail.web";
import { InvCertHeader } from "../../cfautomaticallygeneratepdfcertification/src/CertificationDb.web";
import { DeleteOutlinedIcon } from "../../landingpage/src/assets";
import ResponsiveDialog from "../../landingpage/src/ResponsiveDialog.web";
import Layout from "../../landingpage/src/Layout.web";
import InvoiceDbController, {
  IInvCertDetail,
  Props,
} from "./InvoiceDbController.web";

export const InvCertRow = (props: {
  isDelete: boolean;
  detail: IInvCertDetail;
  onDelete: (id: string) => void;
  onDownload: (id: string) => void;
  isInvoice?: boolean;
}) => {
  const { detail, onDownload, onDelete, isInvoice, isDelete } = props;
  return (
    <div style={styles.container}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={3} lg={3}>
          <Typography style={styles.lgText}>{detail.id}</Typography>
          <Typography style={styles.smText}>
            {isInvoice ? "Invoice No." : "Certification ID"}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <Typography style={styles.lgText}>
            {detail.jobOrderId || "-"}
          </Typography>
          <Typography style={styles.smText}>Job Order ID</Typography>
        </Grid>
        <Grid item xs={6} sm={3} lg={4}>
          <Typography style={styles.lgText}>
            {detail.clientCompanyName || "-"}
          </Typography>
          <Typography style={styles.smText}>Client Name</Typography>
        </Grid>
        <Grid item xs={4} sm={2} lg={1}>
          <Button
            data-test-id="downloadBtn"
            onClick={() => onDownload(detail.id)}
            style={{ ...styles.btn, ...styles.fBold }}
          >
            Download
          </Button>
        </Grid>
        <Grid item xs={2} sm={1} lg={1} style={styles.alignRight}>
          {isDelete && (
            <IconButton
              data-test-id="deleteInvCert"
              style={styles.p4}
              onClick={() => onDelete(detail.id)}
            >
              <Avatar
                variant="rounded"
                style={styles.deleteIcon}
                src={DeleteOutlinedIcon}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
// Customizable Area End

export default class InvoiceDb extends InvoiceDbController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout navigation={this.props.navigation}>
        <InvCertHeader
          data-test-id="header"
          title="Invoice Database"
          search={this.state.search}
          onSearch={this.onSearch}
        />
        {this.state.invoices.map((detail: IInvCertDetail, index: number) => (
          <InvCertRow
            isInvoice
            key={index}
            data-test-id={`invRow${index}`}
            detail={detail}
            isDelete={!this.state.isTechnician}
            onDelete={this.onInvDelete}
            onDownload={this.getInvoicePDF}
          />
        ))}
        <ResponsiveDialog
          data-test-id="deleteInvDialog"
          open={this.state.deleteInv.open}
          okText="Delete Invoice"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.handleInvDeleteDialog}
        >
          <Typography style={dialogStyles.fBold}>
            Are you sure you want to delete the Invoice?
          </Typography>
          <Typography style={dialogStyles.f12}>
            This action can't be undone.
          </Typography>
        </ResponsiveDialog>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  container: {
    boxShadow: "0px 4px 4px 0px #0000001A",
    padding: "16px 20px",
    backgroundColor: "#fff",
    border: "1px solid #d4d4d430",
    borderRadius: "16px",
    marginBottom: 16,
  },
  p4: { padding: 4 },
  fBold: { fontWeight: 700 },
  alignRight: { textAlign: "right" as "right" },
  smText: { fontWeight: 400, fontSize: 10, margin: 0, color: "#00000070" },
  lgText: { fontSize: 14, fontWeight: 600, margin: 0 },
  btn: {
    backgroundColor: "#D53535",
    fontSize: 12,
    padding: "8px 14px",
    color: "#ffffff",
    textTransform: "none" as "none",
  },
  deleteIcon: { height: 24, width: 24 },
};
// Customizable Area End
