import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  ButtonGroup,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
//@ts-ignore
import FullCalendar from '@fullcalendar/react';
import CloseIcon from "@material-ui/icons/Close";
import "../assets/style.css"
//@ts-ignore
import Calendar from "react-calendar";

import EventController, {
  Props,
} from "./CreateEventController.web";
import Layout from "../../landingpage/src/Layout.web";
import CustomCalender from "../../../components/src/CustomCalender";


const optionsArray: any = [
  { value: '1:00', label: '1:00' },
  { value: '2:00', label: '2:00' },
  { value: '3:00', label: '3:00' },
  { value: '4:00', label: '4:00' },
  { value: '5:00', label: '5:00' },
  { value: '6:00', label: '6:00' },
  { value: '7:00', label: '7:00' },
  { value: '8:00', label: '8:00' },
  { value: '9:00', label: '9:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
 
];
// Customizable Area End
export default class CreateEvent extends EventController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
   

    return (
      // Required for all blocks
         <Layout
         navigation={this.props.navigation}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box className="boxTopCss"
                sx={webStyles.successbuttonBox}
              >
                <Button
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  color="primary"
                  className="textcpital widthMss"
                  style={webStyles.successResetPwd}
                  onClick={this.setUnavailabilityBtn}
                >
                  Set Unavailability
                </Button>
                <Button
                  data-test-id={"btnJobOrder"}
                  variant="contained"
                  color="primary"
                  className="textcpital2 widthMss"
                  style={webStyles.crezteJobCss}
                  onClick={this.createJobOrder}
                >
                  Create Job Order
                </Button>
              </Box>
            </Grid>
           
            <Grid item xs={12}>
             <Box sx={webStyles.calenderBox}>
             <CustomCalender state={this.state}
                      handleEventClick={this.handleEventClick} />
             </Box>
            </Grid>
          </Grid>

          <Dialog
            className="modalBox"
            open={this.state.showModal}
            onClose={this.closeModal}
            PaperProps={{ style: webStyles.backOfficeModal }}
          >
            <DialogActions className="headingText">
              <Typography variant="h4">Set Unavailability</Typography>
              <Button
                onClick={this.closeModal}
                style={webStyles.btnCloseBackOffice}
              >
                <CloseIcon />
              </Button>
            </DialogActions>
            <form>
            <DialogContent className="textFormControl" style={webStyles.backOfficeModalContent}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <p style={webStyles.createEventinputDate}>
                    Start Date
                  </p>
                  <TextField
                    fullWidth
                    data-test-id="textInputStartDate"
                    variant="outlined"
                    type="text"
                    value={this.state.CalenderObj.startDate ? this.formatDate(this.state.CalenderObj.startDate) : ''} // Display the date in the input
                    onClick={this.startDateIcon}
                    aria-readonly
                    InputProps={{
                      className: "MuiOutlinedInput-input",
                      startAdornment: (
                        <InputAdornment className="iconLeft" position="start">
                          <IconButton onClick={this.startDateIcon}>
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.StartShowCalendar && (
                    <Calendar
                    tileDisabled={({ date }) => this.isDateDisabled(date)}
                      value={this.state.CalenderObj.startDate}
                      onChange={this.handleDateChange}
                    />
                  )}

                </Grid>

                <Grid item xs={5}>
                  <p style={webStyles.createEventinputDate}>
                    Start Time
                  </p>
                  <FormControl fullWidth variant="outlined">
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <InputAdornment className="iconLeft" position="start">
      <IconButton>
        <AccessTimeIcon />
      </IconButton>
    </InputAdornment>
    <Select
      fullWidth
      data-test-id="textInputStartTime"
      value={this.state.CalenderObj.startTime}
      onChange={this.handleTimeChange}
      MenuProps={{
        transformOrigin: {
          horizontal: 'left',
          vertical: 'top',
         
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
          
        },
      }}
      inputProps={{
        className: "MuiOutlinedInput-select",
      }}
    >
      {optionsArray.map((option: any) => (
                                     <MenuItem 
                                      key={option.value} value={option.value}>
                                     {option.label}
                                   </MenuItem>
                                  ))}
    </Select>
  </div>
</FormControl>
{this.state.validationErrors.startTime && <Box className="colorError">{this.state.validationErrors.startTime}</Box>}

                </Grid>
                <Grid item xs={2}>
                  <Box sx={{marginTop:"30px"}} >
                  <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                >
                    <Button
                    
                    onClick={() => this.HandleStartAmPm('AM')}
                    className={this.state.CalenderObj.startAmPm === 'AM' ? 'activeBtnAMPM' : 'defaultBtnAMPM'}
                    type="button"
                    data-test-id="HandleStartAmPm"
                  >
                    AM
                  </Button>
                  <Button
                    type="button"
                    data-test-id="HandleStartAmPma"
                    onClick={() => this.HandleStartAmPm('PM')}
                    className={this.state.CalenderObj.startAmPm === 'PM' ? 'activeBtnAMPM' : 'defaultBtnAMPM'}
                  >
                    PM
                  </Button>
                </ButtonGroup>
                  </Box>
               
                </Grid>

                <Grid item xs={5}>
                  <p style={webStyles.createEventinputDate}>
                    End Date
                  </p>
                  <TextField
                    fullWidth
                    data-test-id ="textInputEndDate"
                    variant="outlined"
                    type="text"
                    value={this.state.CalenderObj.endDate ? this.formatDate(this.state.CalenderObj.endDate) : ''} // Display the date in the input
                    onClick={this.endDateIcon}
                    
                    aria-readonly
                    InputProps={{
                      className: "MuiOutlinedInput-input",
                      startAdornment: (
                        <InputAdornment className="iconLeft" position="start">
                          <IconButton onClick={this.endDateIcon}>
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}

                  />
                  {this.state.EndShowCalendar && (
                    <Calendar
                      value={this.state.CalenderObj.endDate}
                      tileDisabled={({ date }) => this.isDateDisabled2(date)}
                      onChange={this.handleDateEndDateChange}
                    />
                  )}

                </Grid>

                <Grid item xs={5}>
                  <p style={webStyles.createEventinputDate}>
                    End Time
                  </p>
                  
                  <FormControl fullWidth variant="outlined">
  <div style={{ alignItems: 'center',display: 'flex',}}>
    <InputAdornment position="start" className="iconLeft">
      <IconButton>
        <AccessTimeIcon />
      </IconButton>
    </InputAdornment>
    <Select
    title={this.titleFunct("please select start time")}
      fullWidth
      data-test-id="textInputEndTime"
      value={this.state.CalenderObj.endTime}
      onChange={this.handleEndTimeChange}
      disabled={this.state.CalenderObj.startTime?false:true}
      MenuProps={{
        transformOrigin: {
          vertical: 'top', horizontal: 'left',
          
        },
        
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      inputProps={{
        className: "MuiOutlinedInput-select",
      }}
    >
       {optionsArray.map((optionKey: any) => (
       <MenuItem 
          disabled={
            this.disableFunct(optionKey) 
          }
            key={optionKey.value} value={optionKey.value}>
            {optionKey.label}
          </MenuItem>
        ))}
    </Select>
  </div>
</FormControl>
{this.state.validationErrors.endTime && <Box className="colorError">{this.state.validationErrors.endTime}</Box>}

                </Grid>
                <Grid item xs={2}>
                  <Box sx={{marginTop:"30px"}} >
                  <ButtonGroup
                  
                  aria-label="Disabled elevation buttons"
                  disableElevation
                  variant="contained"
                >
                    <Button
                    type="button"
                    data-test-id="HandleEndAmPm"
                    onClick={() => this.HandleEndAmPm('AM')}
                    className={this.state.CalenderObj.endAmPm === 'AM' ? 'activeBtnAMPM' : 'defaultBtnAMPM'}
                  >
                    AM
                  </Button>
                  <Button
                    type="button"
                    data-test-id="HandleEndAmPma"
                    onClick={() => this.HandleEndAmPm('PM')}
                    className={this.state.CalenderObj.endAmPm === 'PM' ? 'activeBtnAMPM' : 'defaultBtnAMPM'}
                  >
                    PM
                  </Button>
                </ButtonGroup>
                  </Box>
               
                </Grid>
                <Grid item xs={12}>
                  <p style={webStyles.createEventinputDate}>
                    Description
                  </p>
                  <FormControl className="des" fullWidth={true} variant="outlined">
                  <Select
                  data-test-id="descriptionInput"
                  inputProps={{className: "MuiOutlinedInput-select" }}
                   fullWidth

                   MenuProps={{
                    getContentAnchorEl: null,
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  value={this.state.CalenderObj.description}
                  onChange={this.handleDesChange}
                >
                  <MenuItem value="1">
              Select Reason For Unavailability
            </MenuItem>
                  <MenuItem value="Vacation">Vacation</MenuItem>
                  <MenuItem value="Sickness">Sickness</MenuItem>
                  <MenuItem value="Parental Leave">Parental Leave</MenuItem>
                  <MenuItem value="Grievement">Grievement</MenuItem>
                  <MenuItem value="Doctor Appointment">Doctor Appointment</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
                </FormControl>
                {this.state.validationErrors.description && <Box className="colorError">{this.state.validationErrors.description}</Box>}
                {
                  this.state.CalenderObj.description === "Others" && 
                  <>
                   <Box className="des">
                   <p style={webStyles.createEventinputDate}>
                    Please Specify
                  </p>
                  <TextField
                    fullWidth
                    data-test-id="txtInputOther"
                    variant="outlined"
                    type="text"
                    value={this.state.CalenderObj.other} // Display the date in the input
                    InputProps={{
                      className: "MuiOutlinedInput-input",
                     
                    }}
                    inputProps={{
                      maxLength: 250, // Set the maximum character length
                    }}
                    onChange={this.handleDesOtherChange}
                  />
                  {this.state.validationErrors.other && <Box className="colorError">{this.state.validationErrors.other}</Box>}
                             
                            </Box>
                  </>
                }
                </Grid>
              </Grid>


            </DialogContent>
            <DialogActions style={webStyles.doneBtnBoxBackOffice}>
            <button
              data-test-id="cancelButton"
                style={webStyles.cancelBtn}
                onClick={this.closeModal}
              >
                Cancel
              </button>
              <button data-test-id="onSubmit" onClick={this.handleSubmit} style={webStyles.doneBtnBackOffice} type="button">
                Done
              </button>
            </DialogActions>
            </form>
          </Dialog>
          </Layout>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyles = {
  createEventinputDate: {
    margin: "5px 0",
    display: "block",
    fontSize: "14px"
  },
  cancelBtn: {
    fontWeight:600,
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "13px 20px",
    border: "none",
    margin: "5px 0",
    boxShadow:"none",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  btnCloseBackOffice: {
    cursor: "pointer",
    padding: "5px",
    background: "fff",
    color: "#000"
  },
  doneBtnBackOffice: {
    fontWeight:600,
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "13px 20px",
    border: "none",
    margin: "0px 16px 0px 12px",
    // textTransform: 'capitalize',
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
  doneBtnBoxBackOffice: {
    borderTop: "1px #0003 solid",
    padding:"8px"
  },
  backOfficeModal: {
    width: "550px",
    borderRadius: "5px 5px 30px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
  },
  backOfficeModalContent: {
    fontSize: "20px",
    fontWeight: 500
  },
  calenderBox:{
    padding:"20px",
    borderRadius:"20px",
    background:"#fff",
    margin:"0 15px 0 0"
  },
  successbuttonBox: {
    padding: "10px 0px",
    display:"flex",
    justifyContent:"space-between"
  },
  crezteJobCss:{
    fontSize: "13px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "10px 10px",
    border: "none",
    background: "rgba(215, 31, 31, 0.84) none repeat scroll 0% 0%",
    color: "#fff",
    margin: "0px 15px 0px 0px"
  },
  successResetPwd: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "10px 30px",
    border: "none",
    background: "rgba(215, 31, 31, 0.84) none repeat scroll 0% 0%",
    color: "#fff",
  },

}
// Customizable Area End