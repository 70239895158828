import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {logo, topImg, bottomImg} from "./assets"
import "../assets/style.css"

import EmailAccountLoginController, {
  Props,
} from "./EmailLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#dbaeae30",
    },
  },
});
// Customizable Area End
export default class Success extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box sx={webStyles.successTopBox} className="boxModalHeight">
        <Container style={{ maxWidth: '400px' }}>
            <Box sx={webStyles.successLogoBox}>
                <img src={logo} style={{width:"200px"}} />
            </Box>
          <Box
            sx={webStyles.successloginBox}
            className="borderRediousCss"
          >
            <Typography style={webStyles.successWeight} variant="h5" component="h2">
              Success
            </Typography>

            <Box sx={webStyles.successinsideBox}>
                <Typography style={webStyles.successPading} variant="body1">
                    You have successfully logged in
                </Typography>
              <Box
                sx={webStyles.successbuttonBox}
              >
                <Button
                  data-test-id={"handleClickSuccess"}
                  variant="contained"
                  color="primary"
                  className="textcpital"
                  fullWidth
                  style={webStyles.successResetPwd}
                  onClick={()=>this.props.navigation.navigate("ResetPassword")}
                >
                  Reset Password
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyles = {
    successbuttonBox:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 0px",
    },
    successinsideBox:{
        width: "100%", paddingTop: "20px"
    },
    successTopBox:{
      height:"100vw",
        background:"rgb(127 107 107 / 16%)",
        position:"relative",
        '&::before': {
          position: 'absolute',
            content: '""',
            backgroundSize: 'cover',
            top: '0',
            right: '0',
            width: '38%',
            height: '400px',
            backgroundImage: `url('${topImg}')`, 
            backgroundRepeat: 'no-repeat',
          },
          '&::after': {
            position: 'absolute',
            bottom: '0',
            backgroundImage: `url('${bottomImg}')`, 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            content: '""', 
            left: '0',
            width:"38%",
            height: '473px',
          },
    },
    successLogoBox:{
        margin:"50px 0",
        display: "flex",
        padding: "20px",
        justifyContent:"space-around"
    },
    successloginBox:{
        textAlign:"center",
        margin:"50px 0",
              background:"#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              padding: "35px",
              borderRadius:"10px 10px 30px 10px"
    },
    successResetPwd: {
        fontSize: "15px",
        cursor: "pointer",
        borderRadius: "5px",
        padding: "10px 20px",
        border: "none",
        background: "rgba(215, 31, 31, 0.84) none repeat scroll 0% 0%",
    color: "#fff",
    },
    successPading:{
        padding:"0 40px"
    },
    successWeight:{
        fontWeight:600
    }
}
// Customizable Area End
