import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface IRoute {
  title: string;
  path: string;
  inclusion: string;
  subRoutes: { title: string; path: string; inclusion: string }[];
}

export interface ILayoutContainerProps {
  openMobileSearch: boolean;
  onMenuToggle: () => void;
  onMobileSearch: () => void;
}

export enum UserType {
  Dispatcher = "dispatcher",
  BackOffice = "back_office",
  Manager = "manager",
  SuperAdmin = "super_admin",
  Technician = "technician",
}

const fieldWorkerRoutes: IRoute[] = [
  {
    title: "Home",
    path: "/Dashboard-Technician",
    inclusion: "Dashboard-Technician",
    subRoutes: [],
  },
  {
    title: "Calender",
    path: "/Calendar",
    inclusion: "Calendar",
    subRoutes: [],
  },
  {
    title: "Databases",
    path: "static",
    inclusion: "Database",
    subRoutes: [
      {
        title: "Job Orders",
        path: "/Database/JobOrders",
        inclusion: "JobOrders",
      },
      { title: "Invoices", path: "/Database/Invoices", inclusion: "Invoices" },
      {
        title: "Certifications",
        path: "/Database/Certifications",
        inclusion: "Certifications",
      },
    ],
  },
];

const officeWorkerRoutes: IRoute[] = [
  {
    title: "Home",
    path: "/Dashboard-Company",
    inclusion: "Dashboard-Company",
    subRoutes: [],
  },
  {
    title: "Account Creation",
    path: "/Account-Creation/Dispatcher",
    inclusion: "Account-Creation",
    subRoutes: [],
  },
  {
    title: "Client Database",
    path: "/ClientDb",
    inclusion: "ClientDb",
    subRoutes: [],
  },
  {
    title: "Employees",
    path: "/Employees",
    inclusion: "Employees",
    subRoutes: [],
  },
  {
    title: "Other Databases",
    path: "static",
    inclusion: "Database",
    subRoutes: [
      ...fieldWorkerRoutes[2].subRoutes,
      { title: "Other Documents", path: "static", inclusion: "Documents" },
    ],
  },
  { title: "Admin Console", path: "static", inclusion: "Admin", subRoutes: [] },
];

const dispatcherBackOfficeRoutes: IRoute[] = [
  { ...officeWorkerRoutes[0] },
  { ...officeWorkerRoutes[2] },
  { ...officeWorkerRoutes[3] },
  { ...officeWorkerRoutes[4] },
];

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  children: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openMobileDrawer: boolean;
  openMobileSearch: boolean;
  routes: IRoute[];
  currentPath: string;
  menuToggle: string[];
  validRoutes: { title: string; path: string }[];
  isValid: boolean;
  token: any;
  isTechnician: boolean;
  searchText: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LayoutController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      openMobileDrawer: false,
      openMobileSearch: false,
      routes: [],
      currentPath: "",
      menuToggle: [],
      validRoutes: [],
      isValid: false,
      token: undefined,
      isTechnician: true,
      searchText: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    const path = window.location.pathname;
    this.isCheck();
    const userDataStr = localStorage.getItem("userData");
    if (userDataStr) {
      const userData = JSON.parse(userDataStr);
      this.setState({
        isTechnician: userData.user_type === UserType.Technician,
      });
      const { routes, validRoutes } = this.getUserRoutes(userData.user_type);
      this.setState({ routes: routes, validRoutes: validRoutes }, () => {
        this.setState({
          currentPath: path,
          isValid: this.isValidPath(path, validRoutes),
          menuToggle: [
            routes.find((x) => x.inclusion === path.split("/")[1])?.title || "",
          ],
        });
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const updatedPath = window.location.pathname as any;
    if (updatedPath !== prevState.currentPath) {
      this.isCheck();
      this.setState({
        currentPath: updatedPath,
        isValid: this.isValidPath(updatedPath),
      });
    }
  }

  onMenuToggle = () => {
    this.setState({
      openMobileDrawer: !this.state.openMobileDrawer,
      openMobileSearch: false,
    });
  };

  onCollapse = (menu: string) => {
    let temp = [...this.state.menuToggle];
    if (temp.includes(menu)) temp = temp.filter((x) => x !== menu);
    else temp.push(menu);
    this.setState({ menuToggle: temp });
  };

  onSearchToggle = () => {
    this.setState({
      openMobileSearch: !this.state.openMobileSearch,
      openMobileDrawer: false,
      searchText: "",
    });
  };

  getUserRoutes = (userType: string) => {
    let routes;
    switch (userType) {
      case UserType.SuperAdmin:
        routes = officeWorkerRoutes;
        break;
      case UserType.Technician:
        routes = fieldWorkerRoutes;
        break;
      case UserType.Manager:
        routes = officeWorkerRoutes;
        break;
      case UserType.BackOffice:
      case UserType.Dispatcher:
        routes = dispatcherBackOfficeRoutes;
        break;
      default:
        routes = [] as IRoute[];
    }

    let validRoutes: { title: string; path: string }[] = [];
    routes.forEach((x) => {
      if (x.subRoutes.length) validRoutes = [...validRoutes, ...x.subRoutes];
      else validRoutes.push({ title: x.title, path: x.path });
    });
    return { routes, validRoutes };
  };

  isValidPath = (path: string, validRoutes = this.state.validRoutes) => {
    return !!validRoutes.find((x) => x.path === path);
  };

  isCheck = () => {
    const token = localStorage.getItem("token");
    if (!token) this.props.navigation.navigate("EmailAccountLogin");
  };

  getBgColor = (inclusion: string) => {
    return this.state.currentPath.split("/")[1] === inclusion
      ? "#f4cccc"
      : "none";
  };

  onPageReload = (path: string) => {
    if (path === this.state.currentPath) window.location.reload();
  };

  // NOTE: Used in global search functionality
  onSearch = (e: any) => this.setState({ searchText: e.target.value.trim() });
  // Customizable Area End
}
