import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { JobTypeList, JobTypeValue } from "./JobOrderController";

export class JobTypeC {
  id: string = "";
  asset_ids: Array<string> = [];
}
interface Task {
  id: string;
  type: string;
  attributes: {
      id: number;
      title: string | null;
      due_date: string | null;
      description: string | null;
      status: string;
      priority: string;
      duration: string;
  };
}

interface TaskListResponse {
  error: number;
  data: Task[];
  errors?: any; // Adjust type according to your API response structure
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filterParam:{
    munciplity:string,
    postcode:string,
    status:string,
    first_name:string,
    vertical:string,
    job_type:string
   
  }
  Munipality:string;
  searchItem:string;
  maintainer_id:string;
  openTaskList:Task[];
  openTaskListAssigned:Task[];
  anchorEl:HTMLElement | null;
  filterArr:string[];
  filterArrJobType:string[];
  filterArrcompletion:string[];
  openMunsipality:boolean;
  openPostCode:boolean;
  openTechnician:boolean;
  openVertical:boolean;
  openJobType:boolean;
  openCompletion:boolean;
  errorMsg:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobOrderListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllTasksApiCallId = "";
  apiAdvanceSearchJoborderId="";
  apiAdvanceMunciJoborderId="";
  apiAdvanceVerticalJoborderId="";
  apiAdvanceJobTypeId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      filterParam:{
        munciplity:"",
        postcode:"",
        status:"",
        first_name:"",
        vertical:"",
        job_type:"",
       
      },
      Munipality:"",
      searchItem:"",
      maintainer_id:"",
      errorMsg:false,
      openTaskList:[],
      filterArr:[],
      filterArrJobType:[],
      filterArrcompletion:[],
      openTaskListAssigned:[],
      anchorEl:null,
      openMunsipality:false,
      openPostCode:false,
      openTechnician:false,
      openVertical:false,
      openJobType:false,
      openCompletion:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {

    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.getAllTasksApiCallId:
            this.handleGetAllTaskList(responseJson);
            break;
            case apiRequestCallId === this.apiAdvanceSearchJoborderId:
              this.handleGetAllTaskList(responseJson);
              break;
              case apiRequestCallId === this.apiAdvanceMunciJoborderId:
                this.handleGetAllTaskList(responseJson);
                break;
              case apiRequestCallId === this.apiAdvanceVerticalJoborderId:
                this.handleGetAllTaskList(responseJson);
              break;
              case apiRequestCallId === this.apiAdvanceJobTypeId:
                this.handleGetAllTaskList(responseJson);
              break;
          default:
      }
      }
    }
  };

  // Customizable Area Start
  async componentDidMount() {
    const userDataString: any = localStorage.getItem("userData");
    const userData: any = JSON.parse(userDataString);
    const maintainer_id: any = userData.maintainer_id;
    this.setState({
      maintainer_id: maintainer_id,
    })
      this.getAllTaskList(maintainer_id);
  }
  getAllTaskList = (maintainer_id:string) => {
    const headers = {
      Token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTasksApiEndPoint + maintainer_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiManageAssestsDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetAllTaskList = (res: TaskListResponse) => {
    if (!res.errors && res.data) {
        const unassignedTasks = res.data.filter((x: Task) => x.attributes.status === "un_assigned");
        const assignedTasks = res.data.filter((x: Task) => x.attributes.status === "assigned");

        
        this.setState({
          openTaskList: unassignedTasks,
          openTaskListAssigned: assignedTasks,
          errorMsg:false
        });
     
    }else{
      this.setState({errorMsg:true})
    }
  };

  getCapitalizedJobs = (jobs: Array<{ id: string; asset_ids: Array<string> }>) => {
    let temp: any[] = [];
    jobs.forEach((x: JobTypeC) => {
      const option = JobTypeList.find((y: JobTypeValue) => y.value === x.id);
      if (option) temp.push({ ...x, id: option.label });
    });

    return temp;
  };

  getJobTypes = (temp: Array<{ id: string; asset_ids: Array<string> }>) => {
    let jobTypes = [];
    const jobList = this.getCapitalizedJobs(temp);
    if (jobList[0]) jobTypes.push(jobList[0].id[0]);

    if (jobList[1]) jobTypes.push(jobList[1].id[0]);

    if (jobList.length > 2) jobTypes.push(`+${jobList.length - 2}`);
    return jobTypes;
  };

  getVerticals = (verticals: string[]): string[] => {
    let list: string[] = [];
    const trim = (x: string): string => {
        const a = x.split(" ");
        return a.length > 2 ? a[0][0] + a[1][0] : a[0][0];
    };
    if (verticals[0]) list.push(trim(verticals[0]));
    if (verticals[1]) list.push(trim(verticals[1]));
    if (verticals.length > 2) list.push(`+${verticals.length - 2}`);

    return list;
};
  onView = (id: string) => {
    this.props.navigation.navigate("JobDetail", { id });
  };

   handleClick = (event:React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorEl:event.currentTarget})
  };

   handleClose = () => {
    this.setState({anchorEl:null})
  };
   handleToggleClick = () => {
   this.setState({
    openMunsipality:!this.state.openMunsipality
   })
  };
  
  handleToggleClickPostCode = () => {
    this.setState({
     openPostCode:!this.state.openPostCode
    })
   };
   handleToggleClickTech = () => {
    this.setState({
      openTechnician:!this.state.openTechnician
    })
   };
   handleToggleClickVertical = () => {
    this.setState({
      openVertical:!this.state.openVertical
    })
   };
   handleToggleClickJobType = () => {
    this.setState({
      openJobType:!this.state.openJobType
    })
   };
   handleToggleClickCompletion= () => {
    this.setState({
      openCompletion:!this.state.openCompletion
    })
   };
  handleNavigateJob=()=>{
    this.props.navigation.navigate("CreateJobOrder")
  }
  
  handleClearFilters=()=>{
    this.setState({ filterArr: [] , filterArrcompletion:[], filterArrJobType:[], filterParam: {
      ...this.state.filterParam, munciplity:"", postcode:"",status:"",first_name:"", vertical:"", job_type:""}});
    this.getAllTaskList(this.state.maintainer_id)
  }

  handleSearchJobOrder = (e:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchItem: e.target.value });
    if (e.target.value != "") {
      const headers = {
        "Token": localStorage.getItem("token"),
      };
      const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.apiAdvanceSearchJoborderId = message.messageId
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.viewAllJobSearchApiEndPoint + `${this.state.maintainer_id}&search_query=${encodeURIComponent(e.target.value)}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(message.id, message);
    } else {
     this.getAllTaskList(this.state.maintainer_id)
      
    }
  };
onMuncipalityChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
const munciplity = e.target.value;
let params = {
  ...this.state.filterParam, munciplity
}
this.setState({filterParam: params, })
this.handleFilterJobOrder(params)
}
onPostalCodeChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
  const postcode = e.target.value;
  let params = {
    ...this.state.filterParam, postcode
  }
  this.setState({filterParam: params, })
  this.handleFilterJobOrder(params)
  }
  onTechnicianChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
    const first_name = e.target.value;
    let params = {
      ...this.state.filterParam, first_name
    }
    this.setState({filterParam: params, })
    this.handleFilterJobOrder(params)
    }
    handleCheckedFilterCompletion =(e:React.ChangeEvent<HTMLInputElement>, key:string) => {
      
      if (e.target.checked) {
        this.setState(prevState => ({
          filterArrcompletion: [...prevState.filterArrcompletion, key]
        }), () => {
          this.onStatusChange(this.state.filterArrcompletion);
        });
      } else {
        this.setState(prevState => ({
          filterArrcompletion: prevState.filterArrcompletion.filter((item:string) => item !== key)
        }), () => {
          this.onStatusChange(this.state.filterArrcompletion);
        });
      }
    }
    onStatusChange = (statuses:any) => {
      const status = statuses.join(', '); 
      let params = {
        ...this.state.filterParam,
        status
      };
      this.setState({ filterParam: params });
      this.handleFilterJobOrder(params)
    }
    handleCheckedFilter = (e:React.ChangeEvent<HTMLInputElement>, key:string) => {
      if (e.target.checked) {
        this.setState(prevState => ({
          filterArr: [...prevState.filterArr, key]
        }), () => {
          this.onVerticalChange(this.state.filterArr);
        });
      } else {
        this.setState(prevState => ({
          filterArr: prevState.filterArr.filter((item:string) => item !== key)
        }), () => {
          this.onVerticalChange(this.state.filterArr);
        });
      }
    }
    onVerticalChange = (statuses:Array<string>) => {
      const vertical = statuses.join(', '); 
      let params = {
        ...this.state.filterParam,
        vertical
      };
      this.setState({ filterParam: params });
      this.handleFilterVerticalJobOrder(params.vertical)
    }
    handleCheckedFilterJobType= (e:React.ChangeEvent<HTMLInputElement>, key:string) => {
      if (e.target.checked) {
        this.setState(prevState => ({
          filterArrJobType: [...prevState.filterArrJobType, key]
        }), () => {
          this.onJobTypeChange(this.state.filterArrJobType);
        });
      } else {
        this.setState(prevState => ({
          filterArrJobType: prevState.filterArrJobType.filter((item:string) => item !== key)
        }), () => {
          this.onJobTypeChange(this.state.filterArrJobType);
        });
      }
    }
    onJobTypeChange = (statuses:Array<string>) => {
      const job_type = statuses.join(', '); 
      let params = {
        ...this.state.filterParam,
        job_type
      };
      this.setState({ filterParam: params });
      this.handleFilterJobTypeJobOrder(params.job_type)
    }
  handleFilterJobOrder = (param:any) => {
      const headers = {
        "Token": localStorage.getItem("token"),
      };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
      ...param
    }).toString();
      const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.apiAdvanceMunciJoborderId = message.messageId
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.viewAllJobFilterApiEndPoint + `?${urlParams}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(message.id, message);
      
    
  };

  handleFilterVerticalJobOrder = (param:any) => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.apiAdvanceVerticalJoborderId = message.messageId
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllJobFilterVerticalApiEndPoint + `?maintainer_id=${this.state.maintainer_id}&vertical=${encodeURIComponent(param)}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(message.id, message);
    
  
};

handleFilterJobTypeJobOrder = (param:any) => {
  const headers = {
    "Token": localStorage.getItem("token"),
  };
  const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
  this.apiAdvanceJobTypeId = message.messageId
  message.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.viewAllJobFilterJobTypeApiEndPoint + `?maintainer_id=${this.state.maintainer_id}&job_type=${encodeURIComponent(param)}`
  );
  message.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  message.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getApiMethod
  );
  runEngine.sendMessage(message.id, message);
  

};
  // Customizable Area End
}
