import React from "react";

// Customizable Area Start
import {
  Container,
  TableCell,
  Table,
  Box,
  Button,
  Select,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  TableBody,
  Grid,
  Typography,
  FormControl,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { imgLogo } from "./assets";
import "../assets/style.css";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import HeaderNavigation from "../../../components/src/HeaderNavigation";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "#eae7e7",
    height: "30px",
    color: "#777",
    borderRadius: "8px",
    maxWidth: '250px'

  },
});

const optionsArrayTech: any = [
  { value: '1:00', label: '1:00' },
  { value: '1:30', label: '1:30' },
  { value: '2:00', label: '2:00' },
  { value: '2:30', label: '2:30' },
  { value: '3:00', label: '3:00' },
  { value: '3:30', label: '3:30' },
  { value: '4:00', label: '4:00' },
  { value: '4:30', label: '4:30' },
  { value: '5:00', label: '5:00' },
  { value: '5:30', label: '5:30' },
  { value: '6:00', label: '6:00' },
  { value: '6:30', label: '6:30' },
  { value: '7:00', label: '7:00' },
  { value: '7:30', label: '7:30' },
  { value: '8:00', label: '8:00' },
  { value: '8:30', label: '8:30' },
  { value: '9:00', label: '9:00' },
  { value: '9:30', label: '9:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' },
  { value: '24:00', label: '24:00' }
];


const optionsArrayJob = [
  { value: 'Install', label: 'Install' },
  { value: 'Repair', label: 'Repair' },
  { value: 'Substitute', label: 'Substitute' },
  { value: 'Commissioning', label: 'Commissioning' },
  { value: 'Call-out', label: 'Call-out' },
  { value: 'Quarterly Maintenance', label: 'Quarterly Maintenance' },
  { value: 'Yearly Maintenance', label: 'Yearly Maintenance' },
  { value: 'Custom Maintenance', label: 'Custom Maintenance' },
  { value: 'Recharge', label: 'Recharge' },
  { value: 'Others', label: 'Others' },
]
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

let validationSchema: any = Yup.object().shape({
  fullName: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  address: Yup.string().required('Address is required'),
  phoneNo: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
  workHoursFromSelect: Yup.string().required('Select At least One Value'),
  workHoursToSelect: Yup.string().required('Select At least One Value'),
  jobTypeSelect: Yup.string().required('Select At least One Value'),

});
// Customizable Area End

import TechnicianController, {
  configJSON,
  Props
} from "./TechnicianController";
import { Formik } from "formik";
import DialogMessage from "../../../components/src/DialogMessage";
import { SearchGrayIcon } from "../../landingpage/src/assets";

export default class Technician extends TechnicianController {
  [x: string]: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderJobType = (setFieldValue: any) => {
    return this.state.showJobAcordianBox ? (
      <>
        <Box sx={webStylesTech.technicianInputBoxBackO}>
          <p
            style={


              webStylesTech.technicianInputBoxBackOLabel
            }
          >
            {configJSON.jobTypes}
          </p>
          <FormControl fullWidth variant="outlined">
            <Select
              fullWidth={true}
              data-test-id="textJobTypes"
              onChange={(event: any) => {
                setFieldValue("breakStartSelect", event.target.value)
                this.setState({
                  techUserInfo: {
                    ...this.state.techUserInfo,
                    breakStartSelect: event.target.value
                  },

                })
              }

              }
              placeholder={configJSON.jobTypes}
              value={this.state.techUserInfo.breakStartSelect}
              id="demo-simple-select"
              labelId="demo-simple-select-label"
              inputProps={{
                sx: {
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  color: "#84989F",
                }
              }}
            >
              {optionsArrayJob
                .filter((optionJob: any) => !this.state.techUserInfo.jobTypeSelect || optionJob.value !== this.state.techUserInfo.jobTypeSelect)
                .map((optionJob: any) => (
                  <option key={optionJob.value} value={optionJob.value}>
                    {optionJob.label}
                  </option>
                ))}
            </Select>
          </FormControl>
        </Box>
      </>
    ) : (
      ""
    )
  }

  renderJobTypeButton = () => {
    return this.state.showJobAcordianBox ? (
      <>
        <RemoveCircleOutlineIcon />{" "}
        <p>Remove Job Type</p>
      </>
    ) : (
      <>
        <ControlPointIcon /> <p>Add Job Type</p>
      </>
    )
  }

  renderFilterOptionJobTypes = () => {
    return optionsArrayJob.map((optionJob: any) => (
      <option key={optionJob.value} value={optionJob.value}>
        {optionJob.label}
      </option>
    ))
  }

  // Customizable Area End

  render() {

    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStylesTech.backGdBoxBackO} maxWidth="lg">
          <Box>
            <Box sx={{ display: "flex", width: "102%" }}>
              <Box sx={webStylesTech.headerSidBarBackO}>
                <Box sx={webStylesTech.width100}>
                  <img src={imgLogo} width="60" />
                </Box>
              </Box>
              {
                this.state.manageAssetsOpen === true ?
                  <>
                    <Container style={{ maxWidth: '100%', padding: '0', backgroundColor: 'rgb(234, 231, 231)', marginLeft: '16vw', overflowX: "hidden" }}>
                      <div
                        style={{
                          width: "102%",
                          height: '64px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          background: '#fff',
                          marginBottom: '15px'
                        }}
                      >
                        <CustomTextField
                          disabled
                          variant="outlined"
                          placeholder="Search"
                          style={{ right: '5rem' }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img
                                  src={SearchGrayIcon}
                                  style={webDialogStyle.searchedIcon}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '12rem', paddingRight: '2rem' }}>
                        <Typography>
                          {this.state.techUserInfo.jobTypeSelect} -<span style={{ fontWeight: 'bold' }}>Select Asset Expertise</span>
                        </Typography>
                        <Button
                          onClick={this.openModalDialogBackBtn}
                          style={{
                            width: '13rem',
                            height: '3.40rem',
                            background: 'rgb(215 31 31 / 84%)',
                            color: '#FFF',
                            display: 'flex',
                            borderRadius: '10px',
                            fontSize: '16px',
                            whiteSpace: 'nowrap',
                            fontWeight: 600 as 600,
                            textTransform: "capitalize"
                          }}
                        >
                          Back To Create User
                        </Button>
                      </div>

                      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <div style={{ position: 'absolute', top: '15px', right: '11.5rem' }}>
                          <Typography
                            onClick={this.handleSelectAll}
                            style={{
                              cursor: 'pointer',
                              color: 'rgb(215 31 31 / 84%)',
                              fontSize: '16px',
                              fontWeight: 'bold',
                            }}
                          >
                            Select All
                          </Typography>

                        </div>

                        {this.state.getAssests &&
                          this.state.getAssests.map((assest: any, index: number) => (
                            <>
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', paddingLeft: '18rem' }}>
                                <Typography>{assest.attributes?.name}</Typography>
                              </div>
                              {assest.attributes?.sub_categories?.map((subcategory: any, index: number) => (
                                <>
                                  <div
                                    style={{
                                      width: '75%',
                                      height: '2.5rem',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      background: '#FFF',
                                      borderRadius: '0px',
                                      marginTop: '1.5px',
                                      padding: '5px',
                                    }}
                                  >

                                    <Typography style={{ background: '#FFF' }}>{subcategory.name}</Typography>
                                    <Checkbox
                                      checked={this.state.checkedList.filter((val: any) => (val.length !== 0)).length !== 0 && this.state.checkedList.some((item: any) => item?.id === subcategory?.id)}
                                      onChange={
                                        (e: any) =>
                                          this.handleCheckboxChange(
                                            e,
                                            subcategory.name,
                                            subcategory.id,
                                            assest.attributes?.name,
                                            assest.attributes?.id
                                          )
                                      }
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                      data-test-id={`textCheckboxes${index}`}
                                    />
                                  </div>
                                </>
                              ))}
                            </>
                          ))}
                      </div>

                    </Container>


                    <Dialog
                      className="modalBox"
                      open={this.state.showModalBackBtn}
                      onClose={this.closeModal}
                      PaperProps={{ style: webDialogStyle.backTechModal }}
                    >
                      <DialogActions>
                        <Button
                          onClick={this.closeModalDialogBackBtn}
                          style={webDialogStyle.btnOffModal}
                        >
                          <CloseIcon />
                        </Button>
                      </DialogActions>
                      <DialogContent style={webDialogStyle.prepTop} >
                        <p>It looks like you made some changes. Would you like to save your selection before going back?</p>

                      </DialogContent>
                      <DialogActions style={webDialogStyle.yesBtnBorder}>
                        <button
                          data-test-id="cancelButton"
                          style={{ ...webDialogStyle.canselBtn, marginRight: '8px' }}  // Adjust the margin as needed
                          onClick={this.closeModalDialogBackBtn}
                        >
                          Cancel
                        </button>
                        <button
                          data-test-id="cancelButton"
                          style={{ ...webDialogStyle.canselBtn, marginRight: '8px' }}  // Adjust the margin as needed
                          onClick={this.closeManageAssetsModalNo}
                        >
                          No
                        </button>
                        <button
                          data-test-id="onSubmit"
                          onClick={this.closeManageAssetsModal}
                          style={webDialogStyle.yesBtnSelection}
                          type="button"
                        >
                          Yes
                        </button>
                      </DialogActions>
                    </Dialog>
                  </>
                  :
                  <Box sx={webStylesTech.technicianBoxBackO}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="h2"
                          style={webStylesTech.technicianTypoBackO}
                        >
                          {" "}
                          <ChevronRightIcon
                            style={webStylesTech.technicianLeftICon}
                          />{" "}
                          &nbsp; Create new user
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3} sm={3} md={3} lg={3} />
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box>
                          <Typography
                            variant="h2"
                            style={{
                              ...webStylesTech.technicianTypoBoxBackO,
                              ...webStylesTech.technicianTypoBackO
                            }}
                          >
                            {" "}
                            Select User Type
                          </Typography>
                        </Box>
                        <HeaderNavigation
                          state={this.state}
                          goToDisPatcher={this.goToDisPatcher}
                          goToBackOffice={this.goToBackOffice}
                          goToTechnician={this.goToTechnician}
                        />
                        <Box
                          className="dispatchFormBox"
                          style={webStylesTech.technicianFormBoxBackO}
                        >
                          <Formik
                            initialValues={{
                              fullName: this.state.techUserInfo.fullName,
                              email: this.state.techUserInfo.email,
                              id: this.state.techUserInfo.tech_id,
                              address: this.state.techUserInfo.address,
                              phoneNo: this.state.techUserInfo.phoneNo,
                              workHoursFromSelect: this.state.techUserInfo.workHoursFromSelect,
                              workHoursToSelect: this.state.techUserInfo.workHoursToSelect,
                              breakStartSelect: this.state.techUserInfo.breakStartSelect,
                              breakEndSelect: this.state.techUserInfo.breakEndSelect,
                              jobTypeSelect: this.state.techUserInfo.jobTypeSelect,
                            }}
                            validationSchema={validationSchema}
                            data-test-id="dummydd"
                            onSubmit={
                              this.handleSubmit
                            }
                            enableReinitialize
                          >
                            {({ values,
                              errors,
                              setFieldTouched,
                              setFieldValue,
                              touched, handleSubmit, setErrors, resetForm }) => (
                              <form
                                autoComplete="off"

                                style={webStylesTech.marginTop15Back}
                                onSubmit={

                                  handleSubmit

                                }

                              >
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.fullName}
                                  </p>
                                  <TextField
                                    fullWidth
                                    name="fullName"
                                    data-test-id={"txtFirstName"}
                                    placeholder={configJSON.fullName}
                                    variant="outlined"
                                    inputProps={{ maxLength: 20 }}
                                    value={this.state.techUserInfo.fullName}
                                    onChange={(event: any) => {
                                      setFieldValue("fullName", event.target.value)
                                      this.setState({
                                        techUserInfo: {
                                          ...this.state.techUserInfo,
                                          fullName: event.target.value,
                                        }
                                      })
                                    }}
                                  />
                                  <FieldError
                                    data-test-id="fieldError"
                                    error={errors.fullName}
                                    touched={touched.fullName}
                                  />
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.email}
                                  </p>
                                  <TextField
                                    fullWidth
                                    placeholder={configJSON.email}
                                    name="email"
                                    data-test-id="textEmail"
                                    variant="outlined"
                                    value={this.state.techUserInfo.email}
                                    onChange={(event: any) => {
                                      setFieldValue("email", event.target.value)
                                      this.setState({
                                        techUserInfo: {
                                          ...this.state.techUserInfo,
                                          email: event.target.value,
                                        }
                                      })
                                    }}
                                  />
                                  <FieldError
                                    error={errors.email}
                                    touched={touched.email}
                                  />
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.id}
                                  </p>
                                  <TextField
                                    fullWidth
                                    id="id"
                                    name="id" // Add this line
                                    data-test-id="textID"
                                    placeholder={configJSON.id}
                                    variant="outlined"
                                    value={this.state.techUserInfo.tech_id}
                                    onChange={(event) => {
                                      setFieldValue("id", event.target.value);
                                      this.setState({
                                        techUserInfo: {
                                          ...this.state.techUserInfo,
                                          tech_id: event.target.value,
                                        },
                                      });
                                    }}
                                  />
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.address}
                                  </p>
                                  <TextField
                                    fullWidth
                                    id="textInput"
                                    placeholder={configJSON.address}
                                    variant="outlined"
                                    data-test-id="textAddress"
                                    value={this.state.techUserInfo.address}
                                    onChange={(event: any) => {
                                      setFieldValue("address", event.target.value)
                                      this.setState({
                                        techUserInfo: {
                                          ...this.state.techUserInfo,
                                          address: event.target.value,
                                        }
                                      })
                                    }}
                                  />
                                  <FieldError
                                    error={errors.address}
                                    touched={touched.address}
                                  />
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.phoneNo}
                                  </p>
                                  <TextField
                                    fullWidth
                                    id="textInput"
                                    name="phoneNo"
                                    placeholder={configJSON.phoneNo}
                                    data-test-id="textPhoneNo"
                                    variant="outlined"
                                    value={this.state.techUserInfo.phoneNo}
                                    onChange={(event: any) => {
                                      setFieldValue("phoneNo", event.target.value)
                                      this.setState({
                                        techUserInfo: {
                                          ...this.state.techUserInfo,
                                          phoneNo: event.target.value,
                                        }
                                      })
                                    }}
                                  />
                                  <FieldError
                                    error={errors.phoneNo}
                                    touched={touched.phoneNo}
                                  />
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.jobType}
                                  </p>
                                  <FormControl fullWidth variant="outlined">
                                    <Select
                                      value={this.state.techUserInfo.jobTypeSelect}
                                      onChange={(event: any, selectedValue: any) => {
                                        setFieldValue("jobTypeSelect", event.target.value)
                                        this.setState({
                                          techUserInfo: {
                                            ...this.state.techUserInfo,
                                            jobTypeSelect: event.target.value
                                          },

                                        })
                                      }}
                                      fullWidth
                                      data-test-id="textJobType"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      placeholder={configJSON.jobType}

                                      inputProps={{
                                        sx: {
                                          color: "#84989F",
                                          borderRadius: "5px",
                                          backgroundColor: "#fff"
                                        }
                                      }}
                                    >

                                      {this.renderFilterOptionJobTypes()}

                                    </Select>
                                    <FieldError
                                      error={errors.jobTypeSelect}
                                      touched={touched.jobTypeSelect}
                                    />
                                  </FormControl>
                                </Box>

                                <Box className="viewSlectionBox">
                                  <Button
                                    data-test-id="textManangeAssest"
                                    id="btnExample"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.manageAssetsHandle}
                                    style={webStylesTech.technicianBtnRedBackO}
                                  >
                                    Manage Assets
                                  </Button>
                                  <Button
                                    id="btnExample"
                                    variant="contained"
                                    color="primary"
                                    onClick={this.manageViewHandle}
                                    className="viewSelectionBtn"
                                  >
                                    View Selection
                                  </Button>
                                </Box>
                                {this.state.manageViewOpen &&
                                  <Box>
                                    <Box>
                                      <Paper
                                        style={{
                                          width: "100%",
                                          position: 'relative',
                                          zIndex: 99999,
                                        }}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            padding: "1rem",
                                            justifyContent: "space-between",

                                          }}
                                        >
                                          <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                            Selected Assets
                                          </Typography>
                                          <CloseIcon onClick={this.handleCloseDialog} />
                                        </Box>
                                        <TableContainer style={{ height: '20rem' }}>
                                          <Table stickyHeader aria-label="sticky table">

                                            <TableHead style={{ position: "sticky" }}>
                                              <TableRow>
                                                <TableCell>Asset Type</TableCell>
                                                <TableCell>Asset ID</TableCell>
                                                <TableCell>Vertical</TableCell>
                                                <TableCell>Location</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody style={{ overflowX: 'hidden', overflowY: 'scroll', }}>
                                              {this.state.checkedList.filter((val: any) => (val !== null)).map((item: any, index: any) => {
                                                return (
                                                  <>
                                                    < TableRow key={item.id} tabIndex={- 1}>
                                                      <TableCell>{item.name}
                                                      </TableCell>
                                                      <TableCell>{item.id}</TableCell>
                                                      <TableCell>{item.parentName}</TableCell>
                                                      <TableCell>{0}</TableCell>
                                                    </TableRow>
                                                  </>
                                                )
                                              })}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Paper>
                                    </Box>
                                  </Box>
                                }


                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  {this.renderJobType(setFieldValue)}
                                  <Typography
                                    onClick={this.clickJobAcordianBtn}
                                    data-test-id="clickJobAcordianBtn"
                                    style={webStylesTech.technicianBackOAccor}
                                    variant="body1"
                                  >
                                    {this.renderJobTypeButton()}
                                  </Typography>
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.workHoursFrom}
                                  </p>
                                  <FormControl fullWidth variant="outlined">
                                    <Select
                                      value={this.state.techUserInfo.workHoursFromSelect}
                                      data-test-id="textWorkHoursFrom"
                                      onChange={(event: any) => {
                                        setFieldValue("workHoursFromSelect", event.target.value)
                                        this.setState({
                                          techUserInfo: {
                                            ...this.state.techUserInfo,
                                            workHoursFromSelect: event.target.value, breakStartSelect: "", breakEndSelect: "", workHoursToSelect: ""
                                          }

                                        })
                                      }

                                      }
                                      fullWidth
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      placeholder={configJSON.workHoursFrom}

                                      inputProps={{
                                        sx: {
                                          color: "#84989F",
                                          borderRadius: "5px",
                                          backgroundColor: "#fff"
                                        }
                                      }}
                                    >

                                      {optionsArrayTech.map((optionTech: any) => (
                                        <option key={optionTech.value} value={optionTech.value}>
                                          {optionTech.label}
                                        </option>
                                      ))}
                                    </Select>
                                    <FieldError
                                      error={errors.workHoursFromSelect}
                                      touched={touched.workHoursFromSelect}
                                    />
                                  </FormControl>
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.workHoursTo}
                                  </p>
                                  <FormControl fullWidth
                                    variant="outlined">
                                    <Select
                                      data-test-id="textWorkHoursTo"
                                      onChange={(event: any) => {
                                        setFieldValue("workHoursToSelect", event.target.value)
                                        this.setState({
                                          techUserInfo: {
                                            ...this.state.techUserInfo,
                                            workHoursToSelect: event.target.value
                                          },

                                        })
                                      }

                                      }
                                      fullWidth={true}
                                      value={this.state.techUserInfo.workHoursToSelect}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      placeholder={configJSON.workHoursTo}
                                      inputProps={{
                                        sx: {
                                          color: "#84989F",
                                          backgroundColor: "#fff",
                                          borderRadius: "5px",
                                        }
                                      }}
                                    >

                                      {optionsArrayTech.map((optionTechItem: any) => (
                                        <option key={optionTechItem.value} value={optionTechItem.value}>
                                          {optionTechItem.label}
                                        </option>
                                      ))}
                                    </Select>
                                    <FieldError
                                      error={errors.workHoursToSelect}
                                      touched={touched.workHoursToSelect}
                                    />
                                  </FormControl>
                                </Box>


                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  {this.state.showAcordianBox ? (
                                    <>
                                      <Box sx={webStylesTech.technicianInputBoxBackO}>
                                        <p
                                          style={


                                            webStylesTech.technicianInputBoxBackOLabel
                                          }
                                        >
                                          {configJSON.breakStart}
                                        </p>
                                        <FormControl fullWidth variant="outlined">
                                          <Select
                                            fullWidth={true}
                                            data-test-id="textBreakStart"
                                            onChange={(event: any) => {
                                              setFieldValue("breakStartSelect", event.target.value)
                                              this.setState({
                                                techUserInfo: {
                                                  ...this.state.techUserInfo,
                                                  breakStartSelect: event.target.value
                                                },

                                              })
                                            }

                                            }
                                            placeholder={configJSON.breakStart}
                                            value={this.state.techUserInfo.breakStartSelect}
                                            id="demo-simple-select"
                                            labelId="demo-simple-select-label"
                                            inputProps={{
                                              sx: {
                                                backgroundColor: "#fff",
                                                borderRadius: "5px",
                                                color: "#84989F",
                                              }
                                            }}
                                          >
                                            {
                                              this.getStartingBreakTimeTech(optionsArrayTech, this.state.techUserInfo.workHoursFromSelect, this.state.techUserInfo.workHoursToSelect).map((option: any) => <option key={option.value} value={option.value}>
                                                {option.label}
                                              </option>)
                                            }
                                          </Select>
                                        </FormControl>
                                      </Box>

                                      <Box sx={webStylesTech.technicianInputBoxBackO}>
                                        <p
                                          style={
                                            webStylesTech.technicianInputBoxBackOLabel
                                          }
                                        >
                                          {configJSON.breakEnd}
                                        </p>
                                        <FormControl variant="outlined" fullWidth>
                                          <Select
                                            data-test-id="textBreakEnd"
                                            onChange={(event: any) => {
                                              setFieldValue("breakEndSelect", event.target.value)
                                              this.setState({
                                                techUserInfo: {
                                                  ...this.state.techUserInfo,
                                                  breakEndSelect: event.target.value
                                                },

                                              })
                                            }

                                            }
                                            fullWidth={true}
                                            value={this.state.techUserInfo.breakEndSelect}
                                            labelId="demo-simple-select-label"
                                            placeholder={configJSON.breakEnd}
                                            id="demo-simple-select"
                                            inputProps={{
                                              sx: {
                                                borderRadius: "5px",
                                                backgroundColor: "#fff",
                                                color: "#84989F",
                                              }
                                            }}
                                          >
                                            {this.getBreakEndTimeTech(optionsArrayTech, this.state.techUserInfo.breakStartSelect, this.state.techUserInfo.workHoursToSelect)
                                              .map((itemKeys: any) => (
                                                <option key={itemKeys.value} value={itemKeys.value}>
                                                  {itemKeys.label}
                                                </option>
                                              ))}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Typography
                                    onClick={this.clickAcordianBtn}
                                    data-test-id="clickAcordianBtn"
                                    style={webStylesTech.technicianBackOAccor}
                                    variant="body1"
                                  >
                                    {this.state.showAcordianBox ? (
                                      <>
                                        <RemoveCircleOutlineIcon />{" "}
                                        <p>Remove Break Timing</p>
                                      </>
                                    ) : (
                                      <>
                                        <ControlPointIcon /> <p>Add Break Timing</p>
                                      </>
                                    )}
                                  </Typography>
                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.salary}
                                  </p>
                                  <TextField
                                    fullWidth
                                    id="textInput"
                                    placeholder={configJSON.salary}
                                    variant="outlined"
                                    data-test-id="textSalary"
                                    value={this.state.techUserInfo.salary}
                                    onChange={(event: any) => {
                                      setFieldValue("salary", event.target.value)
                                      this.setState({
                                        techUserInfo: {
                                          ...this.state.techUserInfo,
                                          salary: event.target.value,
                                        }
                                      })
                                    }}
                                  />

                                </Box>
                                <Box sx={webStylesTech.technicianInputBoxBackO}>
                                  <p style={webStylesTech.technicianInputBoxBackOLabel}>
                                    {configJSON.SocialSuqNo}
                                  </p>
                                  <TextField
                                    fullWidth
                                    data-test-id="textSocialSuqNo"
                                    placeholder={configJSON.SocialSuqNo}
                                    variant="outlined"
                                    value={this.state.techUserInfo.SocialSuqNo}
                                    onChange={(event: any) => {
                                      setFieldValue("SocialSuqNo", event.target.value)
                                      this.setState({
                                        techUserInfo: {
                                          ...this.state.techUserInfo,
                                          SocialSuqNo: event.target.value,
                                        }
                                      })
                                    }}
                                  />

                                </Box>
                                <Typography
                                  style={{ fontSize: '14px', color: 'red', }}
                                  variant="h6"
                                >
                                  {this.state.errorEmailExistTech}
                                </Typography>
                                <Box
                                  sx={{
                                    ...webStylesTech.technicianInputBoxBackO,
                                    ...webStylesTech.technicianBoxButton
                                  }}
                                >
                                  <Button
                                    id="btnExample"
                                    variant="contained"
                                    color="primary"
                                    style={webStylesTech.technicianBtnWhiteBackO}
                                    onClick={this.goToListT}
                                  >
                                    Back To List
                                  </Button>
                                  <Button
                                    id="btnExample"
                                    variant="contained"
                                    color="primary"
                                    style={webStylesTech.technicianBtnRedBackO}
                                    type="submit"
                                    data-test-id="textBtnSubmitback"
                                  >
                                    Save user
                                  </Button>
                                </Box>
                              </form>
                            )}
                          </Formik>
                        </Box>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3} />
                    </Grid>
                  </Box>}
            </Box>
          </Box>
          <DialogMessage showModal={this.state.showModal} closeModal={this.closeModal} modalEmail={this.state.modalEmail} modalPassword={this.state.modalPasswordTech} isEdit={this.state.isEdit} />
        </Container>
      </ThemeProvider >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStylesTech = {
  backGdBoxBackO: {
    background: "rgba(227, 190, 190, 0.28) none repeat scroll 0% 0%",
    overflowY: "auto" as "auto",
    maxWidth: '100%',
    overflowX: "hidden" as "hidden"
  },
  width100: {
    width: "100%"
  },
  headerSidBarBackO: {
    paddingTop: "10px",
    background: "#fff",
    textAlign: "center",
    width: "18vw",
    zIndex: "9",
    position: "fixed",
    bottom: "0px",
    top: "0px",
    left: "0",
    borderRight: "1px solid rgba(0, 0, 0, 0.18)"
  },
  
  technicianBoxBackO: {
    marginLeft: '18vw',
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
  technicianTypoBackO: {
    fontSize: "20px",
    lineHeight: "45px",
    display: "flex"
  },
  technicianTypoBoxBackO: {
    borderRadius: "5px 5px 30px 5px",
    padding: "15px 15px 15px 35px",
    background: "#fff"
  },
  technicianTabButton: {
    display: "flex",
    textAlign: "center",
    padding: "10px",
    margin: "20px 0px",
    borderRadius: "10px",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%",
    justifyContent: "center"
  },
  technicianBackOAccor: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  marginTop15Back: {
    marginTop: "15px"
  },
  technicianFormBoxBackO: {
    borderRadius: "10px",
    padding: "15px",
    background: "#fff",
    marginBottom: "20px"
  },
  technicianLeftICon: {
    marginTop: "10px"
  },
  technicianInputBoxBackO: {
    margin: "10px 0"
  },
  technicianInputBoxBackOLabel: {
    margin: "5px 0",
    display: "block"
  },
  technicianBtnWhiteBackO: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  technicianBtnRedBackO: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },

  technicianBoxButton: {
    textTransform: "capitalize",
    marginTop: "20px",
    borderTop: "1px #bbb8b8 solid"
  }
};



const webDialogStyle = {

  yesBtnBorder: {
    borderTop: "1px #0003 solid",
  },
  searchedIcon: { height: "22px", width: "22px" },

  backTechModal: {
    width: "470px",
    borderRadius: "5px 5px 30px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
  },
  prepTop: {
    fontWeight: 500,
    fontSize: "20px",
    marginTop: "-40px"
  },
  canselBtn: {
    fontWeight: 600,
    borderRadius: "5px",
    fontSize: "15px",
    cursor: "pointer",
    boxShadow: "none",
    padding: "13px 20px",
    color: "rgb(215 31 31 / 84%)",
    border: "none",
    margin: "5px 0",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  btnOffModal: {
    padding: "5px",
    background: "fff",
    cursor: "pointer",
    color: "#000",
    justifyContent: "end !important"
  },
  yesBtnSelection: {
    fontWeight: 600,
    padding: "13px 20px",
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    border: "none",
    margin: "0px 16px 0px 12px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },

};

//@ts-ignore 
function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
// Customizable Area End
