import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, TextField } from "@material-ui/core";
 import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

const HeaderNavigation = (props:any) =>  {
        const { classes,state,
            goToDisPatcher,
            goToBackOffice,
            goToTechnician,
        } = props
        return (

            <Box sx={webStyles.dispatchTabButton}>
                <button
                style={{fontSize:"15px",
                cursor:"pointer",
                borderRadius:"10px",
                padding:"10px",
                border:"none",
                margin:"0 10px",
                color: state.dispatcher ?"#fff"
                :"#d71f1fb3",
                 background: state.dispatcher ?
                  "#d71f1fb3"
                  : "#fff"}}
                onClick={goToDisPatcher}
                >
                    <div>Dispatcher</div>
                </button>
                <button
                style={{fontSize:"15px",
                cursor:"pointer",
                borderRadius:"10px",
                padding:"10px",
                border:"none",
                margin:"0 10px",
                color: state.backOffice ?"#fff"
                :"#d71f1fb3",
                 background: state.backOffice ?
                  "#d71f1fb3"
                  : "#fff"}}
                onClick={goToBackOffice}
                >
                    <div>Back Office</div>
                </button>
                <button
                style={{fontSize:"15px",
                cursor:"pointer",
                borderRadius:"10px",
                padding:"10px",
                border:"none",
                margin:"0 10px",
                color: state.technician ?"#fff"
                :"#d71f1fb3",
                 background: state.technician ?
                  "#d71f1fb3"
                  : "#fff"}}
                    onClick={goToTechnician}
                >
                     <div>Technician </div>
                </button>
                </Box>
            )
}
const webStyles = {
    dispatchTabButton:{
        display:"flex",
        textAlign:"center",
        padding:"10px",
        margin:"20px 0px",
        borderRadius: "10px",
        background:"rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%",
        justifyContent: "center"
      },
}
export default HeaderNavigation;

