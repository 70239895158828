import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { createTheme, withStyles } from "@material-ui/core/styles";
import "../assets/style.css";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import * as Yup from 'yup';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
  },
});
const GreenCheckbox = withStyles({
  root: {
    color: "#4ca433",
    '&$checked': {
      color: "#4ca433",
    },
  },
  checked: { color: "#4ca433",},
  
})(Checkbox);
const optionTimeFormat: any = [

  { value: '1:00', label: '1:00' },
  { value: '1:30', label: '1:30' },
  { value: '2:00', label: '2:00' },
  { value: '2:30', label: '2:30' },
  { value: '3:00', label: '3:00' },
  { value: '3:30', label: '3:30' },
  { value: '4:00', label: '4:00' },
  { value: '4:30', label: '4:30' },
  { value: '5:00', label: '5:00' },
  { value: '5:30', label: '5:30' },
  { value: '6:00', label: '6:00' },
  { value: '6:30', label: '6:30' },
  { value: '7:00', label: '7:00' },
  { value: '7:30', label: '7:30' },
  { value: '8:00', label: '8:00' },
  { value: '8:30', label: '8:30' },
  { value: '9:00', label: '9:00' },
  { value: '9:30', label: '9:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' },
  { value: '24:00', label: '24:00' }
];
let validationSchema: any = Yup.object().shape({
  locationName: Yup.string().required('Name is required'),
  address: Yup.string().required('Address is required'),
  country:Yup.string().required('Country is required'),
  town:Yup.string().required('Town is required'),
  municipality:Yup.string().required('Municipality is required'),
  postCode:Yup.string().required('Postcode is required'),
  contactDetails:Yup.string().required('Contact Details is required'),
  paymentDetails:Yup.string().required('Payment Details is required'),
  assignedTechnician:Yup.string().required('Select At least One Value'),
  workHoursFromSelect: Yup.string().required('Select At least One Value'),
  workHoursToSelect: Yup.string().required('Select At least One Value'),
  breakEndSelect:Yup.string().required('Select At least One Value'),
  breakStartSelect:Yup.string().required('Select At least One Value'),


});
// Customizable Area End

import { Formik } from "formik";

import CreateLocationController, {
  configJSON,
  Props
} from "./CreateLocationController.web";
import Layout from "../../landingpage/src/Layout.web";
export default class LocationCreate extends CreateLocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout
        navigation={this.props.navigation}> 
      <Box sx={webStyles.locationBox}>
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box
                      className="locationFormBox"
                      style={webStyles.locationFormBox}
                    >
                      
                      <Formik
                      data-test-id="formikForm"
                      enableReinitialize={true}
                        initialValues={
                          this.state.locationDataObj
                       
                      }
                        validationSchema={validationSchema}
                        onSubmit={
                          this.handleSubmit
                        }

                      >
                        {({ values,
                          errors,
                          setFieldTouched,
                          setFieldValue,
                          touched, handleSubmit, resetForm, setErrors, setSubmitting }) => (
                          <form
                            autoComplete="off"
                            data-test-id={"btnPost"}
                            onSubmit={
                              handleSubmit
                            }
                            style={webStyles.marginTop15location}
                          >
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.locationName}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth

                                data-test-id={"txtInputLocationName"}
                                name={"locationName"}
                                placeholder={configJSON.locationName}
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                value={this.state.locationDataObj.locationName}
                                onChange={(event: any) => {
                                  setFieldValue("locationName", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      locationName: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                              data-test-id="fieldError"
                                error={errors.locationName}
                                touched={touched.locationName}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.address}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                name={"address"}
                                value={this.state.locationDataObj.address}
                               
                                onChange={(event: any) => {
                                  setFieldValue("address", event.target.value)
                                  
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      address: event.target.value,
                                    }
                                  })
                                }}
                                onBlur={(event)=>this.getLocationApi(event.target.value)}

                                data-test-id="textInputAddress"
                                placeholder={configJSON.address}
                                variant="outlined"
                              />
                              <p style={{color:"rgba(215, 31, 31, 0.96)"}}>{this.state.showLocationMsg}</p>
                              <FieldError
                                error={errors.address}
                                touched={touched.address}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.country}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                              name={"country"}
                                fullWidth
                                data-test-id="textInputCountry"
                                placeholder={configJSON.country}
                                variant="outlined"
                                value={this.state.locationDataObj.country}
                                onChange={(event: any) => {
                                  setFieldValue("country", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      country: event.target.value,
                                    },
                                    showLocationMsg:""
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.country}
                                touched={touched.country}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.town}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                              name={"town"}
                                fullWidth
                                data-test-id="textInputTown"
                                placeholder={configJSON.town}
                                variant="outlined"
                                value={this.state.locationDataObj.town}
                                onChange={(event: any) => {
                                  setFieldValue("town", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      town: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.town}
                                touched={touched.town}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.municipality}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                              name={"municipality"}
                                fullWidth
                                data-test-id="textInputMunicipality"
                                placeholder={configJSON.municipality}
                                variant="outlined"
                                value={this.state.locationDataObj.municipality}
                                onChange={(event: any) => {
                                  setFieldValue("municipality", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      municipality: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.municipality}
                                touched={touched.municipality}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.postCode}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                               name={"postCode"}
                                fullWidth
                                data-test-id="textInputPostCode"
                                placeholder={configJSON.postCode}
                                variant="outlined"
                                value={this.state.locationDataObj.postCode}
                                onChange={(event: any) => {
                                  setFieldValue("postCode", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      postCode: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.postCode}
                                touched={touched.postCode}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.contactDetails}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                               name={"contactDetails"}
                                fullWidth
                                data-test-id="textInputContactDetails"
                                placeholder={configJSON.contactDetails}
                                variant="outlined"
                                value={this.state.locationDataObj.contactDetails}
                                onChange={(event: any) => {
                                  setFieldValue("contactDetails", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      contactDetails: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.contactDetails}
                                touched={touched.contactDetails}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.contactPreferences}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputContactPreferences"
                                placeholder={configJSON.contactPreferences}
                                variant="outlined"
                                value={this.state.locationDataObj.contactPreferences}
                                onChange={(event: any) => {
                                  setFieldValue("contactPreferences", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      contactPreferences: event.target.value,
                                    }
                                  })
                                }}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.paymentDetails}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                name={"paymentDetails"}
                                data-test-id="textInputPaymentDetails"
                                placeholder={configJSON.paymentDetails}
                                variant="outlined"
                                value={this.state.locationDataObj.paymentDetails}
                                onChange={(event: any) => {
                                  setFieldValue("paymentDetails", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      paymentDetails: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.paymentDetails}
                                touched={touched.paymentDetails}
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                      {configJSON.segmentSelect}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.locationDataObj.segmentSelect}
                                        onChange={(event: any) => {
                                          setFieldValue("segmentSelect", event.target.value)
                                          this.setState({
                                            locationDataObj: {
                                              ...this.state.locationDataObj,

                                              segmentSelect: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textSegmentSelect"
                                        
                                        placeholder={configJSON.segmentSelect}
                                        labelId="demo-simple-select-label"

                                        id="demo-simple-select"
                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",

                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                        
                                      >
                                         <option value={"Parking"}> Parking</option>

                                        <option value={"Office Building"}> Office Building</option>
                                        
                                        <option value={"Warehouse"}> Warehouse</option>
                                        <option value={"Hotel"}> Hotel</option>
                                       
                                        <option value={"Private home"}> Private home</option>
                                        
                                        <option value={"Factory/Industrial"}> Factory/Industrial</option>
                                        
                                        <option value={"Entertainment (Casino,Museum,etc.)"}> Entertainment (Casino,Museum,etc.)</option>
                                        <option value={"Subway"}> Subway</option>
                                        <option value={"Storage Facility"}> Storage Facility</option>
                                        
                                        <option value={"Restaurant"}> Restaurant</option>
                                        <option value={"Retail Store"}> Retail Store</option>
                                        <option value={"Residential Building"}> Residential Building</option>
                                       
                                        <option value={"Government/Institution"}> Government/Institution</option>
                                        
                                        <option value={"School/Education"}> School/Education</option>
                                        <option value={"Airport"}> Airport</option>
                                        
                                        <option value={"Terminal (e.g. bus)"}> Terminal (e.g. bus)</option>
                                        <option value={"Others"}> Others</option>
                                      </Select>
                                    </FormControl>
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                      {configJSON.accountManager}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.locationDataObj.accountManager}
                                        onChange={(event: any) => {
                                          setFieldValue("accountManager", event.target.value)
                                          this.setState({
                                            locationDataObj: {
                                              ...this.state.locationDataObj,
                                              accountManager: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textAccountManager"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.accountManager}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                        
                                      >
                                        {
                                         this.state.allManagerListLocation.map((item:any)=>(
                                          <option value={item.id}> {item.attributes.first_name}</option>
                                         ))
                                       }
                                      </Select>
                                     
                                    </FormControl>
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                      {configJSON.workingDays}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.locationDataObj.workingDays}
                                        onChange={(event: any) => {
                                          setFieldValue("workingDays", event.target.value)
                                          this.setState({
                                            locationDataObj: {
                                              ...this.state.locationDataObj,
                                              workingDays: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textWorkingDays"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.workingDays}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                      >
                                        <option value={"Monday - Friday"}> Monday - Friday</option>
                                        <option value={"Others"}>Others</option>
                                      </Select>
                                     
                                    </FormControl>
                            </Box>




                           
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.workHoursFrom}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <FormControl fullWidth variant="outlined">
                                <Select
                                 name={"workHoursFromSelect"}
                                  onChange={(event: any) =>{
                                    setFieldValue("workHoursFromSelect", event.target.value)
                                    this.setState({
                                      locationDataObj: { ...this.state.locationDataObj, workHoursFromSelect: event.target.value, breakStartSelect:"", breakEndSelect: "", workHoursToSelect: ""}
                                     
                                    })
                                  }  
                                  }
                                  data-test-id="textInputWorkHoursFrom"
                                  value={this.state.locationDataObj.workHoursFromSelect}
                                  fullWidth={true}
                                  labelId="demo-simple-select-label"
                                  placeholder={configJSON.workHoursFrom}
                                  id="demo-simple-select"
                                  
                                  
                                  inputProps={{
                                    sx: {
                                      backgroundColor: "#fff",borderRadius: "5px",
                                      color: "#84989F",
                                    }
                                  }}
                                >
                                  {optionTimeFormat.map((option: any) => (
                                    <option key={option.value} value={option.value}> {option.label}</option>
                                  ))}
                                </Select>
                                <FieldError
                                error={errors.workHoursFromSelect}
                                touched={touched.workHoursFromSelect}
                              />
                              </FormControl>
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.workHoursTo}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <FormControl fullWidth variant="outlined">
                                <Select
                                  data-test-id="textInputWorkHoursTo"
                                  name={"workHoursToSelect"}

                                  onChange={(event: any) =>{
                                    setFieldValue("workHoursToSelect", event.target.value)
                                    this.setState({
                                      locationDataObj: { ...this.state.locationDataObj,

                                        workHoursToSelect: event.target.value},
                                     
                                    })
                                  }
                                   
                                  }
                                  value={this.state.locationDataObj.workHoursToSelect}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  placeholder={configJSON.workHoursTo}
                                  fullWidth={true}
                                  
                                  inputProps={{
                                    sx: {
                                     
                                      backgroundColor: "#fff",
                                      color: "#84989F",
                                      borderRadius: "5px",
                                    }
                                  }}
                                >
                                  {optionTimeFormat.map((optionKey: any) => (
                                    <option key={optionKey.value} value={optionKey.value}>
                                      
                                      {optionKey.label}
                                    </option>
                                  ))}
                                </Select>
                                 <FieldError
                                error={errors.workHoursToSelect}
                                touched={touched.workHoursToSelect}
                              />
                              </FormControl>
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              {this.state.showAcordianBox ? ( <>
                                  <Box sx={webStyles.locationInputBox}>
                                    <p style={webStyles.locationInputBoxLabel}> {configJSON.breakStart}
                                    {" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}</p>
                                    <FormControl fullWidth={true} variant="outlined">
                                      <Select
                                        data-test-id="textInputBreakStart"
                                        
                                        fullWidth={true}
                                        value={this.state.locationDataObj.breakStartSelect}
                                        placeholder={configJSON.breakStart}
                                        id="demo-simple-select"
                                        onChange={(event: any) =>{
                                            setFieldValue("breakStartSelect", event.target.value)
                                            this.setState({
                                              locationDataObj: { ...this.state.locationDataObj,
                                                breakStartSelect: event.target.value},
                                             
                                            })
                                          }
                                           
                                          }
                                        inputProps={{
                                          sx: {borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            
                                            color: "#84989F",
                                          }
                                        }}
                                        labelId="demo-simple-select-label"
                                      >
                                        {
                                            this.getStartingBreakTimeL(optionTimeFormat, this.state.locationDataObj.workHoursFromSelect, this.state.locationDataObj.workHoursToSelect).map((option: any) => <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>)
                                        }
                                      </Select>
                                      <FieldError
                                error={errors.breakStartSelect}
                                touched={touched.breakStartSelect}
                              />
                                    </FormControl>
                                  </Box>
                                  <Box sx={webStyles.locationInputBox}>
                                    <p style={webStyles.locationInputBoxLabel}>
                                      {configJSON.breakEnd}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                                    </p>
                                    <FormControl fullWidth={true} variant="outlined">
                                      <Select
                                       
                                        value={this.state.locationDataObj.breakEndSelect}
                                        onChange={(event: any) =>{
                                          setFieldValue("breakEndSelect", event.target.value)
                                          this.setState({
                                            locationDataObj: { ...this.state.locationDataObj,
                                              breakEndSelect: event.target.value},
                                           
                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textInputBreakEnd"
                                        placeholder={configJSON.breakEnd}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          }
                                        }}
                                      >
                                        {this.getBreakEndTimeL(optionTimeFormat, this.state.locationDataObj.breakStartSelect, this.state.locationDataObj.workHoursToSelect)
                                          .map((itemKeys: any) => (
                                            <option key={itemKeys.value} value={itemKeys.value}>
                                              {itemKeys.label}
                                              
                                            </option>
                                          ))}
                                      </Select>

                                      <FieldError
                                error={errors.breakEndSelect}
                                touched={touched.breakEndSelect}
                              />
                                    </FormControl>
                                  </Box>
                                </>
                              ) : (
                                ""
                              )}
                              <Typography
                                style={webStyles.dispatchAccor}
                                data-test-id="clickAcordianBtn"
                                onClick={this.clickAcordianBtn}
                                variant="body1"
                              >
                                {this.state.showAcordianBox ? (
                                  <>
                                    <RemoveCircleOutlineIcon /> <p>Remove Break Timing</p>
                                  </>
                                ) : (
                                  <>
                                    <ControlPointIcon /> <p>Add Break Timing</p>
                                  </>
                                )}
                              </Typography>
                            </Box>

                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                      {configJSON.assignedTechnician}{" "}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.locationDataObj.assignedTechnician}
                                        onChange={(event: any) => {
                                          setFieldValue("assignedTechnician", event.target.value)
                                          this.setState({
                                            locationDataObj: {
                                              ...this.state.locationDataObj,
                                              assignedTechnician: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textAssignedTechnician"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.assignedTechnician}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                      >
                                        <option value={"Dummy"}> Dummy</option>
                                        <option value={"Others"}>Others</option>
                                      </Select>
                                     
                                    </FormControl>
                                    <FieldError
                                error={errors.assignedTechnician}
                                touched={touched.assignedTechnician}
                              />
                              <FormControlLabel
                                control={<GreenCheckbox
                                //@ts-ignore
                                checked={true} name="checkedG" />}
                                label="Mandatory"
                              />
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.propertyAdministrator}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textPropertyAdministrator"
                                placeholder={configJSON.propertyAdministrator}
                                variant="outlined"
                                value={this.state.locationDataObj.propertyAdministrator}
                                onChange={(event: any) => {
                                  setFieldValue("propertyAdministrator", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      propertyAdministrator: event.target.value,
                                    }
                                  })
                                }}
                              />
                             
                            </Box>
                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.accessControl}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputAccessControl"
                                placeholder={configJSON.accessControl}
                                variant="outlined"
                                value={this.state.locationDataObj.accessControl}
                                onChange={(event: any) => {
                                  setFieldValue("accessControl", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      accessControl: event.target.value,
                                    }
                                  })
                                }}
                              />
                             
                            </Box>

                            <Box sx={webStyles.locationInputBox}>
                              <p style={webStyles.locationInputBoxLabel}>
                                {configJSON.comments}
                              </p>
                              <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                data-test-id="textInputComments"
                                placeholder={"Type somethings.."}
                                variant="outlined"
                                value={this.state.locationDataObj.comments}
                                onChange={(event: any) => {
                                  setFieldValue("comments", event.target.value)
                                  this.setState({
                                    locationDataObj: {
                                      ...this.state.locationDataObj,
                                      comments: event.target.value,
                                    }
                                  })
                                }}
                                
                              />
                             
                            </Box>
                            <Box sx={{display:"flex", justifyContent:"end", padding:"20px 0"}}>
                            <Button
                            className="fontText"
                                data-test-id={"textBtnProceed"}
                                color="primary"
                                style={webStyles.dispatchBtnRed}
                                type="button"
                                disabled={!this.state.locationDataObj.locationId}
                                onClick={this.naviAsset}

                              >
                                Proceed To Assets
                              </Button>
                            </Box>
                            <Box
                              sx={{
                                ...webStyles.locationInputBox,
                                ...webStyles.locationBoxButton
                              }}
                            >
                              <Button
                                id="btnExample"
                                className="fontText"
                                onClick={this.backToViewAllClient}
                                variant="contained"
                                color="primary"
                                style={webStyles.dispatchBtnWhite}
                              >
                                Back To List
                              </Button>
                              <Button
                              className="fontText"
                                data-test-id={"textBtnSubmit"}
                                color="primary"
                                style={webStyles.dispatchBtnRed}
                                type="submit"

                              >
                                {this.state.locationDataObj.locationId?"Edit":"Save"}
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} />

                </Grid>
              </Box>
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  backLBox: {
    background: "rgba(227, 190, 190, 0.28) none repeat scroll 0% 0%",
    overflowY: "auto" as "auto",
    maxWidth:'100%'
  },
  dispatchAccor: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  marginTop15location: {
    marginTop: "15px"
  },
  width100: {
    width: "100%"
  },
  headerSidLBar: {
    paddingTop: "10px",
    background: "#fff",
    height: "100vh",
    textAlign: "center",
    width: "18vw",
    zIndex: "9",
    position: "fixed",
    bottom: "0px",
    top: "0px",
    left: "0",
    borderRight: "1px solid rgba(0, 0, 0, 0.18)"
  },
  locationBox: {
    minHeight: "100vh",
    width: "100%",
  },
  locationType: {
    fontSize: "20px",
    lineHeight: "45px",
    display: "flex",
    fontWeight:600
  },
  locationTypeBox: {
    borderRadius: "5px 5px 30px 5px",
    padding: "15px 15px 15px 35px",
    background: "#fff"
  },
  dispatchTabButton: {
    display: "flex",
    textAlign: "center",
    padding: "10px",
    margin: "20px 0px",
    borderRadius: "10px",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%",
    justifyContent: "center"
  },
  locationFormBox: {
    borderRadius: "10px",
    padding: "15px",
    background: "#fff",
    marginBottom: "20px"
  },
  dispatchLeftICon: {
    marginTop: "10px"
  },
  locationInputBox: {
    margin: "10px 0"
  },
  locationInputBoxLabel: {
    margin: "5px 0",
    display: "block",
    fontWeight:600
  },
  dispatchBtnWhite: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  dispatchBtnRed: {

    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
  locationBoxButton: {
    textTransform: "capitalize",
    marginTop: "20px",
    borderTop: "1px #bbb8b8 solid",
    justifyContent:"end",
    display:"flex"
  }
};

// @ts-ignore
export function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
// Customizable Area End
