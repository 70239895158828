import React from "react";
// Customizable Area Start
import Pagination from "@material-ui/lab/Pagination";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  styled,
  withStyles,
} from "@material-ui/core";
import Timeline, {
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
  DateHeader,
  SidebarHeader,
  ReactCalendarItemRendererProps,
  TimelineItemBase,
  TimelineGroupBase,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";

import { LeftChevronIcon, MenuGrayIcon, RightChevronIcon } from "./assets";
import { PageHeaderContainer } from "./TechnicianLandingPage.web";
import Layout from "./Layout.web";
import ResponsiveDialog from "./ResponsiveDialog.web";
import CompanyLandingPageController, {
  Props,
  pageSize,
} from "./CompanyLandingPageController.web";

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#fff",
    boxShadow: "4px 4px 4px 4px #0000001A",
    width: "auto",
    padding: "8px 16px",
    "& .title": {
      color: "#000",
      fontWeight: "bold",
      fontSize: 14,
      marginBottom: 2,
    },
    "& .address": { color: "#000", marginTop: 8, fontSize: 12 },
    "& .vertical": {
      color: "#5183d9",
      backgroundColor: "#d3e3fa",
      width: "max-content",
      padding: "4px 8px",
      marginTop: 6,
      borderRadius: 24,
      fontSize: 12,
      fontWeight: "bold",
    },
    "& .job": {
      color: "#fff",
      backgroundColor: "#5183d9",
      width: "max-content",
      padding: "8px 20px",
      marginTop: 6,
      borderRadius: 24,
      fontSize: 12,
    },
  },
})(Tooltip);

const CustomTimeline = styled(Timeline)((props) => ({
  "& .rct-header-root, & .rct-horizontal-lines .rct-hl-odd, & .rct-horizontal-lines .rct-hl-even": {
    backgroundColor: "#fff !important",
  },
  "& .rct-calendar-header": { border: "none", overflow: "auto", width: "100%" },
  "& .rct-dateHeader": {
    backgroundColor: "#fff",
    border: "none",
    fontSize: 12,
    pointerEvents: "none",
  },
  "& .rct-sidebar .rct-sidebar-row": {
    backgroundColor: "#fff !important",
    fontSize: 12,
    textAlign: "center",
  },
  "& .rct-vertical-lines .rct-vl": { borderWidth: "1px !important" },
  "& .upcoming": { backgroundColor: "#5182de !important" },
  "& .active": { backgroundColor: "#64be7e !important" },
  "& .pending": { backgroundColor: "#e8a1a1 !important" },
  "& .completed": { backgroundColor: "#a2bae8 !important" },
}));

export const TaskRow = (props: {
  task: any;
  getDuration: (duration: any) => string;
  getJobTypes: (value: any[]) => any[];
  getVerticals: (value: any[]) => any[];
  onDrag: (e: any) => void;
  onView: (id: any) => void;
}) => {
  const {
    task,
    getDuration,
    getJobTypes,
    getVerticals,
    onView,
    onDrag,
  } = props;
  return (
    <div
      data-test-id="mainDiv"
      style={tStyles.container}
      id={task.id.toString()}
      draggable
      onDragStart={onDrag}
    >
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4} md={2}>
          <Typography style={tStyles.lgText}>
            {task.location_name || "-"}
          </Typography>
          <CustomTooltip
            placement="bottom-start"
            title={
              <React.Fragment>
                <Typography className="title">Address</Typography>
                <Typography className="address">
                  {task.location_address || "-"}
                </Typography>
              </React.Fragment>
            }
          >
            <Typography
              style={{
                ...tStyles.smText,
                ...tStyles.textOverflow,
                ...tStyles.addressWidth,
              }}
            >
              {task.location_address || "-"}
            </Typography>
          </CustomTooltip>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CustomTooltip
            placement="bottom-start"
            title={
              <React.Fragment>
                <Typography className="title">Job Types</Typography>
                {task.documents_type.map((x: any, index: number) => (
                  <Typography key={index} component="div" className="job">
                    {x.id}
                  </Typography>
                ))}
              </React.Fragment>
            }
          >
            <div style={tStyles.avatarDiv}>
              {getJobTypes(task.documents_type).map((x: any, index: number) => (
                <Avatar key={index} style={tStyles.lgAvatar}>
                  {x}
                </Avatar>
              ))}
            </div>
          </CustomTooltip>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography style={tStyles.lgText}>
            {task.municipality || "-"}
          </Typography>
          <Typography style={tStyles.smText}>Municipality</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CustomTooltip
            title={
              <React.Fragment>
                <Typography className="title">Verticals</Typography>
                {task.asset_ids.map((x: any, index: number) => (
                  <Typography key={index} component="div" className="vertical">
                    {x}
                  </Typography>
                ))}
              </React.Fragment>
            }
          >
            <div style={tStyles.avatarDiv}>
              {getVerticals(task.asset_ids).map((x: any, index: number) => (
                <Avatar key={index} style={tStyles.smAvatar}>
                  {x}
                </Avatar>
              ))}
            </div>
          </CustomTooltip>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography style={tStyles.lgText}>
            {getDuration(task.duration)}
          </Typography>
          <Typography style={tStyles.smText}>Duration</Typography>
        </Grid>
        <Grid item xs={3} sm={2} md={1}>
          <Button
            data-test-id="view"
            style={tStyles.viewBtn}
            onClick={() => onView(task.id)}
          >
            View
          </Button>
        </Grid>
        <Grid item xs={3} sm={2} md={1}>
          <div style={tStyles.iconDiv}>
            <img style={tStyles.icon} src={MenuGrayIcon} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

// Customizable Area End

export default class CompanyLandingPage extends CompanyLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  itemRenderer = (
    props: ReactCalendarItemRendererProps<TimelineItemBase<any>>
  ) => {
    const { item, getItemProps } = props;
    return (
      <div {...getItemProps({ style: { borderRadius: 24, border: "none" } })}>
        <div style={calStyles.itemDiv}>
          <CustomTooltip
            placement="bottom-start"
            title={
              <React.Fragment>
                <Typography className="title">Address</Typography>
                <Typography className="address">
                  {this.getAddress(item.id)}
                </Typography>
              </React.Fragment>
            }
          >
            <Typography
              style={{ ...tStyles.textOverflow, ...calStyles.itemTitle }}
              onClick={() => this.onView(item.id)}
            >
              {item.title}
            </Typography>
          </CustomTooltip>
          {item.className === "upcoming" && (
            <Avatar style={calStyles.menuAvatar} src={MenuGrayIcon} />
          )}
        </div>
      </div>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    const customGroups = this.state.technicianList.map(
      (x: any) =>
        ({
          id: x.id,
          title: (
            <div
              id={x.id.toString()}
              onDragOver={(e: any) => e.preventDefault()}
              onDrop={this.onDrop}
            >
              {`${x.first_name} ${x.last_name}`.trim()}
            </div>
          ),
        } as TimelineGroupBase)
    );
    return (
      <Layout navigation={this.props.navigation}>
        {this.state.showTask && (
          <>
            <PageHeaderContainer>
              <div
                style={styles.titleStyle}
              >{`Open Job Orders (${this.state.openTaskList.all.length})`}</div>
              <div className="btn-wrapper">
                <Button
                  data-test-id="createJobBtn"
                  variant="contained"
                  style={styles.createJobBtn}
                  onClick={this.onCreateJob}
                >
                  Create Job Order
                </Button>
              </div>
            </PageHeaderContainer>
            <div style={styles.taskContainer}>
              <div style={tStyles.listWrapper}>
                {this.state.openTaskList.current.map(
                  (task: any, index: number) => (
                    <TaskRow
                      key={index}
                      task={task}
                      data-test-id={`taskRow${index}`}
                      getJobTypes={this.getJobTypes}
                      getVerticals={this.getVerticals}
                      getDuration={this.getDuration}
                      onView={this.onView}
                      onDrag={this.onDrag}
                    />
                  )
                )}
              </div>
              <div style={styles.paginationDiv}>
                <Pagination
                  data-test-id="pagination"
                  count={Math.ceil(
                    this.state.openTaskList.all.length / pageSize
                  )}
                  siblingCount={0}
                  boundaryCount={1}
                  style={styles.pagination}
                  page={this.state.pagination.current}
                  onChange={this.onPageChange}
                />
                <Typography>{`${this.state.pagination.start}-${this.state.pagination.end} of ${this.state.openTaskList.all.length} results`}</Typography>
              </div>
            </div>
          </>
        )}

        <div style={calStyles.container}>
          <Grid container>
            <Grid item xs={6} sm={5}>
              <div style={calStyles.dateDiv}>
                <IconButton
                  onClick={() => this.onIncDecDate("dec")}
                  data-test-id="decBtn"
                >
                  <Avatar src={LeftChevronIcon} style={calStyles.chevron} />
                </IconButton>
                <Typography style={calStyles.date}>
                  {this.state.date.format("dddd DD MMMM")}
                </Typography>
                <IconButton
                  onClick={() => this.onIncDecDate("inc")}
                  data-test-id="incBtn"
                >
                  <Avatar src={RightChevronIcon} style={calStyles.chevron} />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={6} sm={7} style={calStyles.dFlex}>
              {this.state.showTask && (
                <Button
                  data-test-id="seeAllBtn"
                  style={calStyles.allTechBtn}
                  onClick={this.onSeeAllBtnClick}
                >
                  See All Technician Calendars
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomTimeline
                data-test-id="timeline"
                items={this.state.timeline.items}
                groups={customGroups}
                visibleTimeStart={this.state.timeline.initStart}
                visibleTimeEnd={this.state.timeline.initEnd}
                lineHeight={60}
                buffer={1}
                sidebarWidth={150}
                onTimeChange={this.onTimeChange}
                itemRenderer={this.itemRenderer}
                stackItems
                canMove={false}
                canResize={false}
                canChangeGroup={false}
                minZoom={60 * 60 * 1000 * 24}
                onItemMove={this.onItemMove}
                moveResizeValidator={this.moveValidator}
              >
                <TimelineHeaders className="sticky">
                  <SidebarHeader data-test-id="sidebarHeader">
                    {({ getRootProps }) => (
                      <div
                        {...getRootProps()}
                        style={calStyles.sidebarTitleDiv}
                      >
                        Technician Profiles
                      </div>
                    )}
                  </SidebarHeader>
                  <DateHeader labelFormat="HH:00" />
                </TimelineHeaders>
                <TimelineMarkers>
                  <TodayMarker
                    data-test-id="todayMarker"
                    date={new Date()}
                    interval={60000}
                  >
                    {({ styles }) => (
                      <>
                        <div style={{ ...styles, ...calStyles.todayDot }} />
                        <div style={styles} />
                      </>
                    )}
                  </TodayMarker>
                </TimelineMarkers>
              </CustomTimeline>
            </Grid>
          </Grid>
        </div>
        <ResponsiveDialog
          data-test-id="confirmDialog"
          open={this.state.allocationDetail.open}
          okText="Confirm"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onAllocationDialog}
        >
          <Typography style={styles.fontBold}>
            {`Are you sure you want to Assign "${this.state.allocationDetail.title}" to "${this.state.allocationDetail.technicianName}"?`}
          </Typography>
          <Typography style={styles.font12}>
            Date: {this.state.date.format("DD/MM/YYYY")}
          </Typography>
          <Typography style={styles.font12}>
            {`Time: ${this.getHourFormat(
              Number(this.state.allocationDetail.duration)
            )}`}
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="leaveDialog"
          open={this.state.leaveDetail.open}
          okText="Assign Anyway"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onLeaveDialog}
        >
          <Typography style={styles.fontBold}>
            Oops! The technician is unavailable during that time slot. Please
            select a different time or consider another technician.
          </Typography>
          <Typography style={styles.font12}>
            Technician Name: {this.state.updateTimeline.technicianName}
          </Typography>
          <Typography style={styles.font12}>
            Unavailable From: {this.state.leaveDetail.duration}
          </Typography>
          <Typography style={styles.font12}>
            Reason: {this.state.leaveDetail.reason}
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="scheduleDialog"
          open={this.state.workScheduleDetail.open}
          okText="Assign Anyway"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onScheduleDialog}
        >
          <Typography style={styles.fontBold}>
            Oops! The technician is currently away. Would you like to assign the
            job order to a different time or another technician?
          </Typography>
          <Typography style={styles.font12}>
            Technician Name: {this.state.updateTimeline.technicianName}
          </Typography>
          <Typography style={styles.font12}>
            Working Hours: {this.state.workScheduleDetail.timing}
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="breakDialog"
          open={this.state.breakDetail.open}
          okText="Assign Anyway"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onBreakDialog}
        >
          <Typography style={styles.fontBold}>
            Oops! It seems the technician is on their break during this time.
            Try another Time Slot.
          </Typography>
          <Typography style={styles.font12}>
            Technician Name: {this.state.updateTimeline.technicianName}
          </Typography>
          <Typography style={styles.font12}>
            Break Time: {this.state.breakDetail.duration}
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="anotherJobDialog"
          open={this.state.anotherJobDetail.open}
          okText="Assign Anyway"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onAnotherJobDialog}
        >
          <Typography style={styles.fontBold}>
            There is another job order assigned, are you sure you want to assign
            the job order?
          </Typography>
          <Typography style={styles.font12}>
            Date: {this.state.date.format("DD/MM/YYYY")}
          </Typography>
          <Typography style={styles.font12}>
            {`Time: ${this.getHourFormat(
              Number(this.state.updateTimeline.duration)
            )}`}
          </Typography>
          <Typography style={styles.font12}>
            Existing Job: {this.state.anotherJobDetail.title}
          </Typography>
        </ResponsiveDialog>
      </Layout>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  titleStyle: {
    textAlign: "left" as "left",
    minWidth: "max-content",
    padding: "4px 0px",
    fontSize: "16px",
    fontWeight: "bold" as "bold",
    margin: "auto 0px",
  },
  taskContainer: { marginBottom: 16 },
  fontBold: { fontWeight: "bold" as "bold" },
  font12: { fontSize: 12 },
  createJobBtn: {
    padding: "6px 10px",
    fontSize: 14,
    color: "#fff",
    backgroundColor: "#d23637",
    textTransform: "none" as "none",
    fontWeight: "bold" as "bold",
  },
  paginationDiv: {
    display: "flex",
    marginBottom: 16,
    placeItems: "center",
  },
  pagination: { margin: "auto" },
};

const tStyles = {
  container: {
    padding: "12px 16px",
    boxShadow: "0px 4px 4px 0px #0000001A",
    borderRadius: "16px",
    border: "1px solid #d4d4d430",
    backgroundColor: "#fff",
    marginBottom: 16,
  },
  listWrapper: { minHeight: 316 },
  addressWidth: { width: 100 },
  textOverflow: {
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lgText: { fontSize: 14, fontWeight: 600, margin: 0 },
  smText: { fontWeight: 400, fontSize: 10, margin: 0, color: "#00000070" },
  avatarDiv: { display: "flex", gap: 4, width: "max-content" },
  lgAvatar: { height: 36, width: 36, backgroundColor: "#5183d9", fontSize: 14 },
  smAvatar: {
    height: 32,
    width: 32,
    fontSize: 12,
    fontWeight: 700,
    color: "#5183d9",
    backgroundColor: "#d3e3fa",
  },
  viewBtn: {
    backgroundColor: "#D53535",
    fontSize: 12,
    padding: "8px 14px",
    color: "#ffffff",
    textTransform: "none" as "none",
  },
  iconDiv: { display: "flex", justifyContent: "right", margin: "4px 0px" },
  icon: { height: 28, width: 28, pointerEvents: "none" as "none" },
};

const calStyles = {
  container: { backgroundColor: "#fff", borderRadius: 16, padding: "8px 0px" },
  dFlex: { display: "flex" },
  dateDiv: { display: "flex", placeItems: "center" },
  date: { fontSize: 12, width: 160, textAlign: "center" as "center" },
  allTechBtn: {
    alignSelf: "center",
    padding: "6px 10px",
    fontSize: "12px",
    color: "#fff",
    backgroundColor: "#d23637",
    textTransform: "none" as "none",
    fontWeight: "bold" as "bold",
  },
  chevron: { height: 28, width: 28, border: "0.5px solid #aaa" },
  sidebarTitleDiv: {
    margin: "auto",
    textAlign: "center" as "center",
    fontSize: 12,
    width: "150px !important",
  },
  todayDot: { height: 6, width: 6, borderRadius: "50%", marginLeft: -2 },
  menuAvatar: { height: 20, width: 20 },
  itemTitle: { color: "#fff", fontSize: 12, width: "100%" },
  itemDiv: {
    height: "100%",
    margin: "0px 10px",
    display: "flex",
    placeItems: "center",
  },
};
// Customizable Area End
