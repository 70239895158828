import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  
  maintainer_Id:any;
  allAssetTypeDrop:any;
  showLocationMsg:any;
  assetDataObj: {
    assetId: any;
    assetType:any;
    serialNo:any;
    locationId:any;
    floorNo:any;
    locationNo:any;
    manufacturingDate:any;
    expiryDate:any;
    lastMaintenanceDate:any;
    lastRechargeDate:any;
    assetQty:any;
    maintenanceFrq:any;
    discountAmt:any;
    maintenanceDuration:any;
    price:any;
    comments:any;
    assetsIDs:any;
  }
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateAssetController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  getGroupsApiCallId = "";
  createAssetApiCallId:string="";
  apiViewAllAssetDrpCallId:string="";
  apiViewLocationListCallId:string=""
  updateAssetApiCallId:string="";
  apiViewAssetListCallId:string="";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      maintainer_Id:null,
      showLocationMsg:"",
      allAssetTypeDrop:[],
      assetDataObj: {
        assetId:"",
        assetsIDs:"",
        assetType:"",
        serialNo:"",
        locationId:"",
        floorNo:"",
        locationNo:"",
        manufacturingDate:"",
        expiryDate:"",
        lastMaintenanceDate:"",
        lastRechargeDate:"",
        assetQty:"1",
        maintenanceFrq:"",
        discountAmt:"",
        maintenanceDuration:"",
        price:"",
        comments:"",

      },
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const location_Id:any = localStorage.getItem("location_Id")
    this.setState({
        assetDataObj: { ...this.state.assetDataObj, 
            locationId:location_Id
          }
    })
    this.getAllAssetDrpFunct();
    this.getParamsIdAssetHandle();
    
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId != null) { 
        switch (true) {
            case apiRequestCallId === this.createAssetApiCallId:
                this.assetSuccess(responseJson)
              break;
              case apiRequestCallId === this.apiViewAllAssetDrpCallId:
            this.ViewAllAssetDropData(responseJson)
            break;
           case apiRequestCallId === this.apiViewAssetListCallId:
            this.ViewAllDataAsset(responseJson)
           break;
           case apiRequestCallId === this.updateAssetApiCallId:
            this.updateAssetFunct(responseJson)
            break;
            default:
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
 
  handleAssetUpdate = () => {

    let clienss:any = localStorage.getItem("clientId")
    let headers = {
      "Content-Type": "application/json",
      
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "asset": {
          "location_id":this.state.assetDataObj.locationId,
          "asset_type": this.state.assetDataObj.assetType,
          "serial_number": this.state.assetDataObj.serialNo,
          "floor_number":this.state.assetDataObj.floorNo,
          "asset_id": this.state.assetDataObj.assetsIDs,
          "last_maintenance_date":this.state.assetDataObj.lastMaintenanceDate,
          "maintenance_frequency":this.state.assetDataObj.maintenanceFrq,
          "last_recharge_date":this.state.assetDataObj.lastRechargeDate,
          "location_number":this.state.assetDataObj.locationNo,
          "manufacturing_date":this.state.assetDataObj.manufacturingDate,
          "expiry_date":this.state.assetDataObj.expiryDate,
          "discount_amount_percentage":this.state.assetDataObj.discountAmt,
          "duration":this.state.assetDataObj.maintenanceDuration,
          "price":this.state.assetDataObj.price,
          "asset_quantity":this.state.assetDataObj.assetQty,
         
          "comments": this.state.assetDataObj.comments
        }
  
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAssetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAssetApiEndPoint+ `?client_account_id=${clienss}`+`&asset_id=${this.state.assetDataObj.assetId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlePostAsset = () => {
    let clientID:any = localStorage.getItem("clientId")
    let headers = {
      "Content-Type": "application/json",
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
        "asset": {
            "location_id":this.state.assetDataObj.locationId,
            "asset_type": this.state.assetDataObj.assetType,
            "asset_id": this.state.assetDataObj.assetsIDs,
            "serial_number": this.state.assetDataObj.serialNo,
            "floor_number":this.state.assetDataObj.floorNo,
            "location_number":this.state.assetDataObj.locationNo,
            "manufacturing_date":this.state.assetDataObj.manufacturingDate,
            "expiry_date":this.state.assetDataObj.expiryDate,
            "last_maintenance_date":this.state.assetDataObj.lastMaintenanceDate,
            "maintenance_frequency":this.state.assetDataObj.maintenanceFrq,
            "last_recharge_date":this.state.assetDataObj.lastRechargeDate,
            "asset_quantity":this.state.assetDataObj.assetQty,
            "discount_amount_percentage":this.state.assetDataObj.discountAmt,
            "duration":this.state.assetDataObj.maintenanceDuration,
            "price":this.state.assetDataObj.price,
            "comments": this.state.assetDataObj.comments
          }
    
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAssetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAssetApiEndPoint+clientID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit = (values: any, { resetForm }: any) => {
    resetForm({
      values: {
        assetType: this.state.assetDataObj.assetType,
        serialNo:this.state.assetDataObj.serialNo,
        locationId:this.state.assetDataObj.locationId,
        floorNo:this.state.assetDataObj.floorNo,
        locationNo:this.state.assetDataObj.locationNo,
        manufacturingDate:this.state.assetDataObj.manufacturingDate,
        expiryDate:this.state.assetDataObj.expiryDate,
        lastMaintenanceDate:this.state.assetDataObj.lastMaintenanceDate,
        lastRechargeDate:this.state.assetDataObj.lastRechargeDate,
        assetQty:this.state.assetDataObj.assetQty,
        maintenanceFrq:this.state.assetDataObj.maintenanceFrq,
        discountAmt:this.state.assetDataObj.discountAmt,
        maintenanceDuration:this.state.assetDataObj.maintenanceDuration,
        price:this.state.assetDataObj.price,
        comments:this.state.assetDataObj.comments,
       


      },
   }); 
   if(this.state.assetDataObj.assetId){
    this.handleAssetUpdate();
   }else{
    this.handlePostAsset()
   }
 };
  

  
  assetSuccess=(responseJson:any)=>{
    if(responseJson.meta){
      this.setState({
        assetDataObj: { ...this.state.assetDataObj, 
          assetId:0,
          assetsIDs:"",
          assetType:"",
          serialNo:"",
          locationId:"",
          floorNo:"",
          locationNo:"",
          manufacturingDate:"",
          expiryDate:"",
          lastMaintenanceDate:"",
          lastRechargeDate:"",
          assetQty:"1",
          maintenanceFrq:"",
          discountAmt:"",
          maintenanceDuration:"",
          price:"",
          comments:"",
        }})
    }
  }
  getAllAssetDrpFunct=()=>{
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiViewAllAssetDrpCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAssetDrpApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  ViewAllAssetDropData=(responseJson:any)=>{
    if(responseJson.data.length>0){
      this.setState({
        allAssetTypeDrop:responseJson.data
      })
    }
  }
  backToViewAllClientbtn=()=>{
    this.props.navigation.navigate("ViewClientProfile")
  }
  getParamsIdAssetHandle=()=>{
    let urlIDasset:any = this.props.navigation.getParam("id")
    if(urlIDasset){
      this.getAllAssetDataRes()
    }
  }
  getAllAssetDataRes=()=>{
    let clientIDkeasst:any = localStorage.getItem("clientId")
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewAssetListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAssetApiEndPoint+clientIDkeasst
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  ViewAllDataAsset=(responseJson:any)=>{
    if (responseJson.client_assets) {
      const clientIdToFind = this.props.navigation.getParam("id");
      const foundLocation = responseJson.client_assets.find((item:any) => {
        return item.data.id === clientIdToFind
      }
      );
      const dateObject:any = new Date(foundLocation.data.attributes.last_maintenance_date);
      const year:any = dateObject.getFullYear();
      const month:any = String(dateObject.getMonth() + 1).padStart(2, '0'); 
      const day:any = String(dateObject.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      this.setState({
          assetDataObj: { ...this.state.assetDataObj, 
            assetId:foundLocation.data.id,
            assetType:foundLocation.data.attributes.asset_type,
            assetsIDs:foundLocation.data.attributes.asset_id,
            serialNo:foundLocation.data.attributes.serial_number,
            locationId:foundLocation.data.attributes.location_id,
            floorNo:foundLocation.data.attributes.floor_number,
            locationNo:foundLocation.data.attributes.location_number,
            lastMaintenanceDate:formattedDate,
            maintenanceFrq:foundLocation.data.attributes.maintenance_frequency,
            manufacturingDate:foundLocation.data.attributes.manufacturing_date,
            expiryDate:foundLocation.data.attributes.expiry_date,
            lastRechargeDate:foundLocation.data.attributes.last_recharge_date,
            assetQty:foundLocation.data.attributes.asset_quantity,
            discountAmt:foundLocation.data.attributes.discount_amount_percentage,
            maintenanceDuration:foundLocation.data.attributes.duration,
            price:foundLocation.data.attributes.price,
            comments:foundLocation.data.attributes.comments,
           
        }
       
      })
  }
}
updateAssetFunct=(responseJson:any)=>{
  if(responseJson){
    this.props.navigation.navigate("ViewLocation",{id:this.state.assetDataObj.locationId})
  }
}

  // Customizable Area End
}
