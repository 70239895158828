export const ContactPrimaryIcon = require("../assets/contact-primary-icon.svg");
export const PersonPrimaryIcon = require("../assets/person-primary-icon.svg");
export const MenuGrayIcon = require("../assets/menu-gray-icon.svg");
export const MenuBlackIcon = require("../assets/menu-black-icon.svg");
export const ClearBlackIcon = require("../assets/clear-black-icon.svg");
export const SearchBlackIcon = require("../assets/search-black-icon.svg");
export const BackArrowIcon = require("../assets/back-arrow-icon.svg");
export const SearchGrayIcon = require("../assets/search-gray-icon.svg");
export const UpAccordionIcon = require("../assets/up-accordion-icon.svg");
export const DownAccordionIcon = require("../assets/down-accordion-icon.svg");
export const LeftChevronIcon = require("../assets/chevron-left-icon.svg");
export const RightChevronIcon = require("../assets/chevron-right-icon.svg");
export const PlaceBlackIcon = require("../assets/place-black-icon.svg");
export const WatchLaterIcon = require("../assets/watch-later-icon.svg");
export const EditPenIcon = require("../assets/edit-pen-icon.svg");
export const DeleteOutlinedIcon = require("../assets/delete-outlined-icon.svg");
export const FilterListIcon = require("../assets/filter-list-icon.svg");
export const BackChevronIcon = require("../assets/back-chevron-icon.svg");
