import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface EmpProps {
  client_company_name: string;
    Type: string;
    total_locations: any;
    total_assets:any;
    updated_at: string;
    client_account_id: any;
    openEmpDetails: boolean;
  }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  maintainer_id: any;
  ClientData: any;
  clientFilter: any;
  searchTerm: any;
  selectedFile: any;
  isImportOpen: boolean;
  user_type:any;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ViewProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiLoginCallId: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  apiViewAllClientCallId: string = "";
  apiAdvanceSearchClientId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      searchTerm: "",
      user_type:"",
      email: "",
      password: "",
      ClientData: [],
      clientFilter: [],
      maintainer_id: null,
      selectedFile: null,
      isImportOpen: false


    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const userDataString: any = localStorage.getItem("userData")
    const userData: any = JSON.parse(userDataString);
    const maintainerId: any = userData.maintainer_id;
    this.getClientProfileRes(maintainerId);
    const userType:any = userData.user_type;
    this.setState({
      user_type:userType,
      maintainer_id: maintainerId,
    })
    // Customizable Area End
  }

  // Customizable Area Start





  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiViewAllClientCallId) {
          this.ViewAllClientData(responseJson)
        }
        this.getAdvanceSearchClientHandler(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  getAdvanceSearchClientHandler = (messageId: any, data: any) => {
    if (messageId !== this.apiAdvanceSearchClientId) {
      return
    }
    this.setState({
      ClientData: data.maintainer?.clients ? data.maintainer?.clients : []
    })
  }

  getClientProfileRes = (keyId: any) => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewAllClientCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllClientApiEndPoint + keyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  ViewAllClientData=(responseJson:any)=>{
    if(responseJson?.maintainer?.clients){
      this.setState({
        ClientData: responseJson.maintainer.clients,
        clientFilter: responseJson.maintainer.clients,
      })
    }

  }
  handleOpenEmpDetails = (task: EmpProps) => {

    this.props.navigation.navigate("SingleClient", { id: task.client_account_id })
  };
  handleSearch = (e: any) => {
    this.setState({ searchTerm: e.target.value });
    if (e.target.value != "") {
      const headers = {
        "Token": localStorage.getItem("token"),
      };
      const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.apiAdvanceSearchClientId = message.messageId
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.viewAllClientApiEndPoint + `${this.state.maintainer_id}&search_query=${encodeURIComponent(e.target.value)}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(message.id, message);
    } else {
      this.setState({
        ClientData: this.state.clientFilter
      })
    }
  };


  handleSelectFile = (e: any) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // File is selected, update the state and navigate
      this.setState({ selectedFile }, () => {
       this.setState({isImportOpen: true})
      });
    }
  }

  onImportBack = () => {
    this.setState({ isImportOpen:false, selectedFile: null })
  }


  // Customizable Area End
}
