import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {logo, topImg, bottomImg} from "./assets"

import ResetPasswordController, {
  Props,
} from "./ResetPasswordController";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import "../assets/style.css"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#dbaeae30",
    },
  },
});
// Customizable Area End
export default class EmailAccountLoginBlock extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    const hasCapitalLetter = this.checkForCapitalLetter(this.state.password);
    const hasLowerCase = this.checkLowerCaseLetter(this.state.password)
    const hasNumber = this.checkForNumber(this.state.password);
    const meetsMinLength = this.checkMinLength(this.state.password);
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box className="boxModalHeight" sx={webStylesReset.topBoxReset}>
        <Container maxWidth="sm">
            <Box sx={webStylesReset.logoBoxReset}>
                <img src={logo} style={{width:"200px"}} />
            </Box>
            {
                this.state.ErrorMsg?<>
                <Box className="ErrorMsg" sx={webStylesReset.errorMsgReset}>{this.state.ErrorMsg}</Box>
                </>
                :""
            }
          <Box
            className="borderRediousCss"
            sx={webStylesReset.loginBoxReset}
          >
            <Typography className="textHeading" variant="h4" component="h2">
             Reset Password
            </Typography>

            <Box sx={webStylesReset.insideBoxReset}>
              <Box style={webStylesReset.inputBoxGridReset}>
                <Typography className="textLebel" variant="body1">New Password</Typography>
                <TextField
                  data-test-id="txtInputPassword"
                  type={this.state.enablePasswordFieldReset ? "password" : "text"}
                  placeholder={"Enter New Password"}
                  variant="outlined"
                  fullWidth={true}
                  value={this.state.password}
                  onChange={(e) => this.setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: // Corrected property name: endAdornment endAdornment
                      <InputAdornment position="end">
                        <IconButton
                        data-test-id="txtInputPasswordShow"
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowResetPassword}
                          edge="end"
                        >
                          {this.state.enablePasswordFieldReset ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                }}
                />
              </Box>
              <Box style={webStylesReset.inputBoxGridReset}>
              <Typography className="textLebel" variant="body1">Confirm Password</Typography>
              <TextField
                  data-test-id="txtInputConfirmPassword"
                  type={this.state.enableConfirmPasswordField ? "password" : "text"}
                  placeholder={"Re-Enter New Password"}
                  variant="outlined"
                  fullWidth={true}
                  value={this.state.confirmPassword}
                  onChange={(e) => this.setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: // Corrected property name: endAdornment endAdornment
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {this.state.enableConfirmPasswordField ? (
                           <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                }}
                />
               
              </Box>
              <Box sx={webStylesReset.conditionReset}>
                <Typography style={webStylesReset.conditionPre} variant="body1">
                    <CheckCircleIcon style={{...webStylesReset.fontSize13px,color:hasCapitalLetter?"#32ae3b":"#000"}} /> 
                At least one capital letter</Typography>
                <Typography style={webStylesReset.conditionPre} variant="body1">
                <CheckCircleIcon style={{...webStylesReset.fontSize13px,color:hasLowerCase?"#32ae3b":"#000"}} /> 
                     At least one lowercase letter</Typography>
                    
                     <Typography style={webStylesReset.conditionPre} variant="body1">
                     <CheckCircleIcon style={{...webStylesReset.fontSize13px, color:hasNumber?"#32ae3b":"#000"}} /> 
                     At least one number</Typography>

                     <Typography style={webStylesReset.conditionPre} variant="body1">
                {
                        this.state.password!=""?<>
                            {
                                meetsMinLength?<CheckCircleIcon 
                                style={{...webStylesReset.fontSize13px,color:"#32ae3b"}} /> 
                                :<CancelIcon  style={webStylesReset.cancelIconCss} />
                            }
                        </>
                        :<CheckCircleIcon style={webStylesReset.yesIcon} /> 
                    }
                     Minimum character length is 8 characters</Typography>
              </Box>
              <Box
                sx={webStylesReset.buttonBoxReset}
              >
                <Button
                className="buttonRed textcpital"
                  data-test-id="btnResetPwd"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => this.doResetPwd()}
                  style={webStylesReset.loginBtn}
                >
                  Reset Password 
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStylesReset = {
  cancelIconCss:{
    color:"red",
    fontSize:"15px",
    marginRight:"3px"
  },
  fontSize13px:{
    fontSize:"15px",
    marginRight:"3px"
  },
  yesIcon:{
    color:"#000",
    fontSize:"15px",
    marginRight:"3px"
  },
    conditionPre:{
        display:"flex",
        fontSize:"14px",
        alignItems:"center",
        margin:"10px 0"
    },
    conditionReset:{
        margin:"20px 0"
    },
    errorMsgReset:{
        background:" #ed7e7e3d",
        padding: "20px 15px",
        borderRadius: "5px",
        color: "#c12121",
        fontSize: "18px",
        borderLeft: "6px #c10f0ff0 solid",
    },
    buttonBoxReset:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 0px",
    },
    inputBoxGridReset:{
        padding: "15px 0px" 
    },
    insideBoxReset:{
        width: "100%", paddingTop: "20px"
    },
    topBoxReset:{
       height:"100vw",
        
        position:"relative",
        background:"rgb(127 107 107 / 16%)",
        '&::before': {
          width: '38%',
          height: '400px',
            content: '""',
            position: 'absolute',
            top: '0',
            right: '0',
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url('${topImg}')`, 
          },
          '&::after': {
            content: '""', 
            height: '473px',
            bottom: '0',
            left: '0',
            position: 'absolute',
            width:"38%",
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url('${bottomImg}')`, 
            backgroundSize: 'cover', 
          },
    },
    logoBoxReset:{
        margin:"50px 0",
        display: "flex",
        padding: "20px",
        justifyContent:"space-around"
    },
    loginBoxReset:{
        margin:"50px 0",
              background:"#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              padding: "35px",
              borderRadius:"10px 10px 30px 10px"
    },
    loginBtn: {
        fontSize: "15px",
        cursor: "pointer",
        borderRadius: "5px",
        padding: "10px 20px",
        border: "none",
        background: "#d5d5d575",
    color: "rgb(18 17 17)",
    '&:hover': {
        background: 'rgba(215, 31, 31, 0.84) !important',
        color: '#fff'
      },
    },
      textBlackSpan:{
        color:"000",
        fontWeight:"normal"
      }
}
// Customizable Area End
