Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "tasks/tasks";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";

exports.errorEmailNotValid = "Email not valid.";
exports.hintCountryCode = "Select Country";
exports.assignToYourself = "Assign to yourself"
exports.jobOrderId = "Job Order Id"
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.breakEnd = "Break End *"
exports.jobType = "Job Type *"
exports.customerType = "Customer *"
exports.assetsType = "Assets Type"
exports.locationType = "Location *"
exports.locationAddress = "Location Address"
exports.locationComment ="Location Comments"
exports.assetsIds ="Asset IDs"
exports.contactDetails ="Contact Details"
exports.openingHours = "Opening Hours"
exports.accessControl ="Access Control"
exports.duration = "Duration"
exports.price ="Price"
exports.jobOrderComments="Job Order Comments"

exports.validationApiMethodType = "GET";
exports.apiMethodTypeDispatcherDetail = "POST";
exports.accountsAPiEndPointCreateAccount =
  "account_block/create_account";

exports.accountsAPiEndPointManageAssests =
  "account_block/client_index_assets";

  exports.priceApiEndPoint ="bx_block_tasks/tasks/price_calculation"
  exports.durationEndPoint = "bx_block_tasks/tasks/duration_calculation"
  exports.locationApiEndPoint = "account_block/client_index_locations?client_account_id="
  exports.createTaskAPiEndPoint = "bx_block_tasks/tasks?maintainer_id="
  exports.updateTaskAPiEndPoint="bx_block_tasks/tasks/"
  exports.viewAllCustomerApiEndPoint ="account_block/index_client/?maintainer_id="
exports.viewAllJobApiEndPoint = "/bx_block_tasks/tasks"
exports.getAllTasksApiEndPoint = "bx_block_tasks/tasks?maintainer_id=";
exports.apiManageAssestsDetail = "GET";
exports.viewAllJobSearchApiEndPoint="bx_block_tasks/tasks/search_task?maintainer_id="
exports.viewAllJobFilterApiEndPoint="bx_block_advanced_search/task_list_filter"
exports.apiMethodTypeBOfficeDetail = "POST";
exports.apiMethodTypeTechDetail = "POST";
exports.viewAllJobFilterVerticalApiEndPoint="bx_block_advanced_search/vertical_search"
exports.viewAllJobFilterJobTypeApiEndPoint="bx_block_advanced_search/job_type_search"
// Customizable Area End
