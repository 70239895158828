import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import React, { Component } from "react";
import { ClearBlackIcon } from "./assets";
// Customizable Area Start
interface IResponsiveDialogProps {
  open: boolean;
  isConfirmDisabled?: boolean;
  okText: string;
  cancelText: string;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl";
  handleDialog: (isConfirm: boolean) => void;
}
// Customizable Area End

export default class ResponsiveDialog extends Component<
  IResponsiveDialogProps
> {
  constructor(props: IResponsiveDialogProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      <Dialog
        maxWidth={this.props.maxWidth}
        open={this.props.open}
        disableEnforceFocus
        scroll="paper"
        PaperProps={{ style: styles.dialogWrapper }}
      >
        <DialogTitle style={styles.titleWrapper}>
          <IconButton
            data-test-id="closeIcon"
            size="small"
            onClick={() => this.props.handleDialog(false)}
          >
            <Avatar
              src={ClearBlackIcon}
              alt="Close"
              variant="square"
              style={styles.closeIcon}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>{this.props.children}</DialogContent>
        <Divider />
        <DialogActions style={styles.btnWrapper}>
          <Button
            data-test-id="cancelBtn"
            variant="contained"
            style={styles.cancelBtn}
            onClick={() => this.props.handleDialog(false)}
          >
            {this.props.cancelText}
          </Button>
          <Button
            data-test-id="okBtn"
            variant="contained"
            style={{
              ...styles.okBtn,
              opacity: this.props.isConfirmDisabled ? 0.5 : 1,
            }}
            disabled={this.props.isConfirmDisabled}
            onClick={() => this.props.handleDialog(true)}
          >
            {this.props.okText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

// Customizable Area Start
export const styles = {
  dialogWrapper: { borderBottomRightRadius: 20 },
  titleWrapper: { padding: "8px 10px 0px", textAlign: "right" as "right" },
  closeIcon: { height: 20, width: 20 },
  btnWrapper: { padding: "12px 24px" },
  cancelBtn: {
    fontSize: 12,
    color: "#d23637",
    backgroundColor: "#f4cbcf",
    textTransform: "none" as "none",
    fontWeight: "bold" as "bold",
  },
  okBtn: {
    fontSize: 12,
    color: "#f4cbcf",
    backgroundColor: "#d23637",
    textTransform: "none" as "none",
    fontWeight: "bold" as "bold",
  },
};
// Customizable Area End
