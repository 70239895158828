import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  email:string;
  enableConfirmPasswordField: boolean;
  enablePasswordFieldReset: boolean;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  ErrorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiResetPasswordCallId: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      password: "",
      confirmPassword:"",
      email:"",
      enableConfirmPasswordField: true,
      enablePasswordFieldReset: true,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      ErrorMsg:"",
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let userData:any;
    
      userData = JSON.parse(localStorage.getItem('userData') ?? "{}") || "";
      this.setState({ email: userData.email });
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  

  handleClickShowResetPassword = () => {
    this.setState({
      enablePasswordFieldReset: !this.state.enablePasswordFieldReset,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
        enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };

  setConfirmPassword = (text: string) => {
    this.setState({
      confirmPassword: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };


    checkForCapitalLetter = (str:any) => {
        return /[A-Z]/.test(str);
      }
      checkLowerCaseLetter =(text:any)=>{
        return /[a-z]/.test(text);
      }
      checkForNumber = (num:any) => {
        return /\d/.test(num);
      }
      checkMinLength = (text:any) => {
        const minLength = 8;
        return text.length >= minLength;
      }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiResetPasswordCallId) {
          if (responseJson.message) {
            this.sendLoginSuccessMessage();
            this.openInfoPage();
            this.setState({ErrorMsg:"Password reset successfully"})
            this.props.navigation.navigate("ResetSuccess");
          } else {
            //Check Error Response
            this.sendLoginFailMessage();
            this.setState({ErrorMsg:configJSON.errorPasswordAllCheckValid})
            this.parseApiErrorResponse(responseJson);
           
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
 

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);

    this.send(msg);
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doResetPwd=()=> {
    const hasMinimumLength = this.state.password.length >= 8;
    const hasCapitalLetter = /[A-Z]/.test(this.state.password);
    const hasLowercaseLetter = /[a-z]/.test(this.state.password);
    const hasNumber = /\d/.test(this.state.password);
    if (!hasMinimumLength || !hasCapitalLetter || !hasLowercaseLetter || !hasNumber) {
      this.setState({ ErrorMsg: configJSON.errorPasswordNotValid });
      return false;
    }
  

    if (this.state.confirmPassword!=this.state.password) {
      this.setState({ErrorMsg:configJSON.errorConfirmPasswordNotValid});
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    
    const httpBody = {
      "email": this.state.email,
      "new_password": this.state.password,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPwdAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  RidrectLogin=()=> {
    this.props.navigation.navigate("EmailAccountLogin")
  }

  // Customizable Area End
}
