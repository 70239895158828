Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";

exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextChangePassword = "Change Password";
exports.btnTextSaveChanges = "Save Changes";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.phoneNo = "Phone Number *"
exports.workHoursFrom = "Work Hours-From *"
exports.workHoursTo = "Work Hours-To *"
exports.salary = "Salary"
exports.SocialSuqNo = "Social Security Number"
exports.breakStart = "Break Start *"
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorBlankField = "can't be blank";
exports.labelEmail = "Email";
exports.id = "ID"
exports.address = "Address *"
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.email = "Email *"
exports.errorEmailNotValid = "Email not valid.";
exports.hintCountryCode = "Select Country";
exports.fullName = "Full Name *"
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.breakEnd = "Break End *"
exports.jobType = "Job Type *"
exports.jobTypes = "Job Type *"
exports.assetsType = "Assets Type"

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.apiMethodTypeDispatcherDetail = "POST";
exports.accountsAPiEndPointCreateAccount =
  "account_block/create_account";

exports.accountsAPiEndPointManageAssests =
  "bx_block_categories/index";

exports.apiMethodTypeManageAssestsDetail = "GET";

exports.apiMethodTypeBOfficeDetail = "POST";
exports.apiMethodTypeTechDetail = "POST";
exports.apiMethodGet = "GET";
exports.apiMethodPatch = "PATCH";

exports.accountBlockSearchAccountId = "account_block/search_account?id=";
exports.accountBlockUpdateAccount = "account_block/update_account";

// Customizable Area End
