import React from "react";

// Customizable Area Start
import {
  Container,
  TableCell,
  Table,
  Box,
  Button,
  Select,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  TableBody,
  Grid,
  Typography,
  FormControl,
  TextField,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  InputAdornment,
  IconButton,
  withStyles,
} from "@material-ui/core";
import {CustomerType} from './JobOrderController'
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import "../assets/style.css"


let validationSchema: any = Yup.object().shape({
  jobTypeSelect: Yup.array().of( Yup.object().shape({  id: Yup.string().required("Select At least One Value")})),
  customerSelect:Yup.string().required('Select At least One Value'),
  locationSelect:Yup.string().required('Select At least One Value'),
  locationAddress:Yup.string().required('Location address is required'),
  locationComment:Yup.string().required('Location comment is required'),
  contactDetails:Yup.string().required('Contact details is required'),
  openingHours:Yup.string().required('Opening hours is required'),
  accessControl:Yup.string().required('Access control is required'),
  duration:Yup.string().required('Duration is required'),
  price:Yup.string().required('Price is required')

});
// Customizable Area End

import JobOrderController, {
  configJSON,
  JobType,
  Props
} from "./JobOrderController";
import { Formik } from "formik";
import Layout from "../../landingpage/src/Layout.web";
import { SearchGrayIcon } from "./assets";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth:400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
export default class JobOrder extends JobOrderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


     CustomInput = ({ children, ...other }:any) => (
    <div>
      <InputAdornment position="start">
        <img src={SearchGrayIcon} alt="Search" style={webStyle.searchIcon} />
      </InputAdornment>
      {children}
    </div>
  );
  // Customizable Area End

  render() {

    // Customizable Area Start
    return (
        <Layout
        navigation={this.props.navigation}>
       <Grid container spacing={2}>
          <Container style={webStyleOrder.backGdBoxBackJob} maxWidth="lg">
            <Box>
              <Box sx={{ display: "flex" }}>
               
                {
                  this.state.manageAssets.open ?
                    <>
                     <Container maxWidth="xl">
          <div >
            <Box style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }} >
              <Box style={{ width: "100%", height: "5rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography style={{ width: "100%", display: "flex" }}>Asset Database - [Client Name] [Location Name]</Typography>
                <Box style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                  <Button data-test-id="backBtn" className="btnAssetsClick" onClick={this.onManageAssetsClose} style={{ width: "12rem", background: "#e13f3f none repeat scroll 0% 0%", color: "#FFF", display: "flex", borderRadius: "4px", fontSize: "12px", whiteSpace: "nowrap", textTransform:"capitalize" }} >Back To Job Order Creation</Button>
                </Box>
              </Box>
              <Box sx={{width:"100%"}}>
                <Grid container>
                  <Grid item xs={12} md={3}>
                  <TextField
                    
                                      fullWidth
                                      name="jobOrderId"
                                      disabled
                                      data-test-id={"txtSearch"}
                                      placeholder={"Search"}
                                      variant="outlined"
                                      InputProps={{
                                        style: {
                                          borderRadius:"7px",
                                          background:"#fff",
                                          height:"40px"
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <img
                                              src={SearchGrayIcon}
                                              style={webStyle.searchIcon}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                      
                                    />
                  </Grid>
                </Grid>
              </Box>
              <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableTDhead">Asset Type</TableCell>
            <TableCell className="tableTDhead" align="right">Asset ID</TableCell>
            <TableCell className="tableTDhead" align="right">Floor</TableCell>
            <TableCell className="tableTDhead" align="right">Location number</TableCell>
            <TableCell className="tableTDhead" align="right">Placement information</TableCell>
            <TableCell className="tableTDhead" align="right">
            <Checkbox
              disabled={!this.state.allAssetList.length}
                checked={this.isSelectAll()}
                onChange={this.onSelectAll}
                inputProps={{ 'aria-label': 'Select All checkbox' }}
                data-test-id="selectAll"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!this.state.allAssetList.length && this.state.allAssetList.map((row: any, index:any)=> (
            <TableRow style={{background:"#fff"}} key={row.data.attributes.asset_type}>
              <TableCell component="th" scope="row">
                {row.data.attributes.asset_type}
              </TableCell>
              <TableCell align="right">{row.data.attributes.asset_id}</TableCell>
              <TableCell align="right">{row.data.attributes.floor_number}</TableCell>
              <TableCell align="right">{row.data.attributes.location_number}</TableCell>
              <TableCell align="right">
              
              <HtmlTooltip
        title={
          <>
            <Box sx={{display:"flex"}}>
            <Typography color="inherit">Asset Placement Information</Typography>
            <Button
                style={webStyle.btnCloseModal}
              >
                <CloseIcon />
              </Button>
            </Box>
            <Typography color="inherit">[{row.data.attributes.asset_id}]</Typography>
            <Typography color="inherit">{row.data.attributes.comments}</Typography>
           
            
          </>
        }
      >
        <Button>{row.data.attributes.comments}</Button>
      </HtmlTooltip>
              
              </TableCell>
              <TableCell align="right">
              <Checkbox
                        checked={this.isAssetChecked(row.data.id)}
                        onChange={(e) =>this.onSelectionChange(e, row.data.id)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        data-test-id={`checkBox${index}`}

                      />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Box>
          </div>
        </Container>
        <Dialog
            className="modalBox"
            open={this.state.showModalChild}
            PaperProps={{ style: webStyle.backOfficeModal }}
          >
            <DialogActions>
              <Button
                data-test-id="dialogCloseIcon"
                onClick={() => this.onUpdateAssetConfirm(false, true)}
                style={webStyle.btnCloseModal}
              >
                <CloseIcon />
              </Button>
            </DialogActions>
            <DialogContent style={webStyle.preTop}>
             <p>It looks like you made some changes. Would you like to save your selection before going back?</p>

            </DialogContent>
            <DialogActions style={webStyle.doneBtnBorder}>
            <button
              data-test-id="cancelBtn"
                style={webStyle.cancelBtn}
                onClick={() => this.onUpdateAssetConfirm()}
              >
                Cancel
              </button>
              <button data-test-id="okBtn" onClick={()=>this.onUpdateAssetConfirm(true)} style={webStyle.doneBtnSelection} type="button">
                Save Selection
              </button>
            </DialogActions>
          </Dialog>
                    </>

                    :
                    <Box sx={webStyleOrder.technicianBoxBackO}>
                      <Grid container>
                        <Grid item xs={3} sm={3} md={3} lg={3} />
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                          <Box>
                            <Typography
                              variant="h2"
                              style={{
                                ...webStyleOrder.technicianTypoBoxBackO,
                                ...webStyleOrder.technicianTypoBackO
                              }}
                            >
                              {" "}
                             Create Job Order
                            </Typography>
                          </Box>
                          
                          <Box
                            className="dispatchFormBox"
                            style={webStyleOrder.orderFormBoxBackO}
                          >
                            <Formik
                              initialValues={this.state.createJobInfo}
                              enableReinitialize={true}
                              validationSchema={validationSchema}
                              data-test-id="dummydd"
                              onSubmit={
                                this.handleSubmit
                              }

                            >
                              {({ values,
                                errors,
                                setFieldTouched,
                                setFieldValue,
                                touched, handleSubmit, setErrors, resetForm }) => (
                                <form
                                  // noValidate
                                  autoComplete="off"

                                  style={webStyleOrder.marginTop15Back}
                                  onSubmit={

                                    handleSubmit

                                  }

                                >
                                  {this.state.isTechnician && 
                                    <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                      <p style={webStyleOrder.orderInputInputBoxLabel}>
                                        {configJSON.assignToYourself}
                                      </p>
                                      <TextField
                                        fullWidth
                                        select
                                        name="isSelfAssign"
                                        data-test-id="isSelfAssign"
                                        variant="outlined"
                                        inputProps={{ maxLength: 20 }}
                                        value={this.state.createJobInfo.isSelfAssign}
                                        onChange={(event: any) => {
                                          setFieldValue("isSelfAssign", event.target.value)
                                          this.setState({
                                            createJobInfo: {
                                              ...this.state.createJobInfo,
                                              isSelfAssign: event.target.value,
                                            }
                                          })
                                        }}
                                      >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                        
                                      </TextField>
                                    </Box>
                                  }
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.jobOrderId}
                                    </p>
                                    <TextField
                                      fullWidth
                                      name="jobOrderId"
                                      data-test-id={"txtjobOrderId"}
                                      placeholder={configJSON.jobOrderId}
                                      variant="outlined"
                                      inputProps={{ maxLength: 20 }}
                                      value={this.state.createJobInfo.jobOrderId}
                                      onChange={(event: any) => {
                                        setFieldValue("jobOrderId", event.target.value)
                                        this.setState({
                                          createJobInfo: {
                                            ...this.state.createJobInfo,
                                            jobOrderId: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                  </Box>
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.customerType}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <InputAdornment style={{marginTop:"30px"}} className="iconLeft" position="start">
                                      <IconButton>
                                          <img src={SearchGrayIcon} style={webStyle.searchIcon} />
                                      </IconButton>
                                    </InputAdornment>
                                      <Select
                                        value={this.state.createJobInfo.customerSelect}
                                        onChange={(event: any) => {
                                          setFieldValue("customerSelect", event.target.value)
                                          this.setState({
                                            createJobInfo: {
                                              ...this.state.createJobInfo,
                                              customerSelect: event.target.value
                                            },

                                          })
                                          this.handleLocation(event.target.value)
                                        }}
                                        fullWidth
                                        data-test-id="textCustomerType"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.customerType}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                        
                                      >
                                        {this.state.CustomerData.map((item:CustomerType)=>(
                                          <option value={item.client_account_id}> {item.client_company_name}</option>
                                        ))}
                                      </Select>
                                      <FieldError
                                      data-test-id="fieldError"
                                        error={errors.customerSelect}
                                        touched={touched.customerSelect}
                                      />
                                    </FormControl>
                                  </Box>

                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.locationType}
                                    </p>
                                    <FormControl  fullWidth variant="outlined" className="textFormControl11">
                                    <InputAdornment style={{marginTop:"30px"}} className="iconLeft" position="start">
                                      <IconButton>
                                          <img src={SearchGrayIcon} style={webStyle.searchIcon} />
                                      </IconButton>
                                    </InputAdornment>
                                      <Select
                                        value={this.state.createJobInfo.locationSelect}
                                        onChange={(event: any) => {
                                          setFieldValue("locationSelect", event.target.value)
                                          this.setState({
                                            createJobInfo: {
                                              ...this.state.createJobInfo,
                                              locationSelect: event.target.value
                                            },

                                          })
                                          this.locationRelFunct(event.target.value)
                                        }}
                                        fullWidth
                                        data-test-id="textlocationType"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.locationType}

                                        inputProps={{
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                          className: "MuiOutlinedInput-select",
                                        }}
                                      >
                                        {this.state.createJobInfo.locationArray.map((item: any, index: number) => (
      <option key={index} value={item.location.data.id}>
        {item.location.data.attributes.location_name}
      </option>
    ))}
                                      </Select>
                                      <FieldError
                                        error={errors.locationSelect}
                                        touched={touched.locationSelect}
                                      />
                                    </FormControl>
                                  </Box>

                                  
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.locationAddress}
                                    </p>
                                    <TextField
                                      fullWidth
                                      id="textInputLocationAddress"
                                      placeholder={configJSON.locationAddress}
                                      variant="outlined"
                                      data-test-id="textInputLocationAddress"
                                      value={this.state.createJobInfo.locationAddress}
                                      onChange={(event: any) => {
                                        setFieldValue("locationAddress", event.target.value)
                                        this.setState({
                                            createJobInfo: {
                                            ...this.state.createJobInfo,
                                            locationAddress: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                    <FieldError
                                        error={errors.locationAddress}
                                        touched={touched.locationAddress}
                                      />
                                  </Box>

                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.locationComment}
                                    </p>
                                    <TextField
                                      fullWidth
                                      id="textInputLocationComment"
                                      placeholder={configJSON.locationComment}
                                      variant="outlined"
                                      data-test-id="textInputLocationComment"
                                      value={this.state.createJobInfo.locationComment}
                                      onChange={(event: any) => {
                                        setFieldValue("locationComment", event.target.value)
                                        this.setState({
                                            createJobInfo: {
                                            ...this.state.createJobInfo,
                                            locationComment: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                    <FieldError
                                        error={errors.locationComment}
                                        touched={touched.locationComment}
                                      />
                                  </Box>
                                  {this.state.createJobInfo.jobTypeSelect.map((jobType:JobType, mainIndex:number) => (
                                  <React.Fragment key={mainIndex}>
                                    <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.jobType}
                                    </p>
                                    <FormControl fullWidth variant="outlined">
                                      <Select
                                        value={jobType.id}
                                        onChange={(event: any) => {
                                          setFieldValue("jobTypeSelect", this.handleInputChange(event.target.value, mainIndex))
                                        }}
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        data-test-id={`jobType${mainIndex}`}                                        
                                        placeholder={configJSON.jobType}
                                        inputProps={{
                                          sx: {
                                            borderRadius: "5px",
                                            backgroundColor: "#fff",
                                            color: "#84989F",                                           
                                          }
                                        }}
                                      >
                                        {this.getFilteredJobOptions(mainIndex).map((optionJob: any) => (
                                          <option key={optionJob.value} value={optionJob.value}>
                                            {optionJob.label}
                                          </option>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    </Box>
                                    {this.state.assetError?<span style={{color:"rgb(230, 94, 82)"}}>{this.state.assetError}</span>:""}   
                                  <Box className="viewSlectionBox">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      data-test-id={`manangeBtn${mainIndex}`}
                                      onClick={()=>this.onManageAssets(mainIndex)}
                                      style={webStyleOrder.orderBtnRed}
                                      disabled={!jobType.id}
                                    >
                                      Manage
                                    </Button>
                                    <Button
                                      data-test-id={`viewBtn${mainIndex}`}
                                      color="primary"
                                      onClick={()=>this.onViewAssets(mainIndex)}
                                      variant="contained"
                                      className="viewSelectionBtn"
                                      disabled={!jobType.id}
                                    >
                                      View Selection
                                    </Button>
                                  </Box>
                                  {this.state.manageViewOpen.includes(mainIndex.toString()) &&
                                    <Box>
                                      <Box>
                                        <Paper
                                          style={{
                                            position: 'relative',
                                            zIndex: 99999,
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              padding: "1rem",
                                              

                                            }}
                                          >
                                            <Typography style={{ fontWeight: "bold" }} variant="h6" >
                                              Selected Assets
                                            </Typography>
                                            <CloseIcon data-test-id={`viewCloseBtn${mainIndex}`} onClick={()=>this.onViewAssets(mainIndex)} />
                                          </Box>
                                          <TableContainer style={{ height: '20rem'}}>
                                            <Table aria-label="sticky table" stickyHeader>

                                              <TableHead style={{position:"sticky" }}>
                                                <TableRow>
                                                  <TableCell>Asset Type</TableCell>
                                                  <TableCell>Asset ID</TableCell>
                                                  <TableCell>Vertical</TableCell>
                                                  <TableCell>Location</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody style={{  overflowY: 'scroll',overflowX: 'hidden', }}>
                                                {this.getAssetSelection(jobType.asset_ids).map((item: any, index: any) => {
                                                 
                                                  return (
                                                    < TableRow key={index} tabIndex={- 1}>
                                                      <TableCell>{item.data.attributes.asset_type}</TableCell>
                                                      <TableCell>{item.data.attributes.asset_id}</TableCell>
                                                      <TableCell>{item.data.attributes.vertical}</TableCell>
                                                      <TableCell>{item.data.attributes.location_number}</TableCell>
                                                    </TableRow>
                                                  )
                                                })}


                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Paper>
                                      </Box>
                                    </Box>
                                  }
                                  {!!mainIndex && (
                                    <Box
                                      data-test-id={`removeJobTypeBtn${mainIndex}`}
                                      sx={{ display: "flex", alignItems: "center" }}
                                      onClick={() => this.updateInputBox(mainIndex)}
                                    >
                                      <RemoveCircleOutlineIcon /> <p>Remove Job Type</p>
                                    </Box>
                                  )}
                                </React.Fragment>
                                ))}
                                {this.isAddJobType() &&
                                  (<Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <Typography
                                      data-test-id="addJobTypeBtn"
                                      onClick={()=>{
                                        this.updateInputBox()}}
                                      style={webStyleOrder.jobBackOAccor}
                                      variant="body1"
                                      >
                                      <ControlPointIcon /> <p>Add Job Type</p>{" "}
                                    </Typography>
                                  </Box>)}
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.assetsIds}
                                    </p>
                                    <FormControl fullWidth variant="outlined">
                                      <Select
                                        value={this.state.createJobInfo.assetsIds}
                                        onChange={(event: any) => {
                                          setFieldValue("assetsIds", event.target.value)
                                          this.setState({
                                            createJobInfo: {
                                              ...this.state.createJobInfo,
                                              assetsIds: event.target.value
                                            },

                                          })
                                          
                                        }}
                                        fullWidth
                                        data-test-id="textassetsIds"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.assetsIds}
                                        disabled
                                        inputProps={{
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          }
                                        }}
                                      >
                                        <option value={"dummy"}> dummy</option>
                                      </Select>
                                     
                                    </FormControl>
                                  </Box>
                                  
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.contactDetails}
                                    </p>
                                    <TextField
                                      fullWidth
                                      id="textInput"
                                      placeholder={configJSON.contactDetails}
                                      variant="outlined"
                                      data-test-id="textContactDetails"
                                      value={this.state.createJobInfo.contactDetails}
                                      onChange={(event: any) => {
                                        setFieldValue("contactDetails", event.target.value)
                                        this.setState({
                                          createJobInfo: {
                                            ...this.state.createJobInfo,
                                            contactDetails: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                    <FieldError
                                        error={errors.contactDetails}
                                        touched={touched.contactDetails}
                                      />
                                  </Box>
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.openingHours}
                                    </p>
                                    <TextField
                                      fullWidth
                                      id="textInput"
                                      name="openingHours"
                                      placeholder={configJSON.openingHours}
                                      data-test-id="textOpeningHours"
                                      variant="outlined"
                                      value={this.state.createJobInfo.openingHours}
                                      onChange={(event: any) => {
                                        setFieldValue("openingHours", event.target.value)
                                        this.setState({
                                          createJobInfo: {
                                            ...this.state.createJobInfo,
                                            openingHours: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                     <FieldError
                                        error={errors.openingHours}
                                        touched={touched.openingHours}
                                      />
                                  </Box>
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.accessControl}
                                    </p>
                                    <TextField
                                      fullWidth
                                      id="textInput"
                                      placeholder={configJSON.accessControl}
                                      variant="outlined"
                                      data-test-id="textAccessControl"
                                      value={this.state.createJobInfo.accessControl}
                                      onChange={(event: any) => {
                                        setFieldValue("accessControl", event.target.value)
                                        this.setState({
                                          createJobInfo: {
                                            ...this.state.createJobInfo,
                                            accessControl: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                      <FieldError
                                        error={errors.accessControl}
                                        touched={touched.accessControl}
                                      />
                                  </Box>
                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.duration}
                                    </p>
                                    <TextField
                                      fullWidth
                                      disabled
                                      data-test-id="textDuration"
                                      placeholder={configJSON.duration}
                                      variant="outlined"
                                      value={this.state.createJobInfo.duration}
                                      onChange={(event: any) => {
                                        setFieldValue("duration", event.target.value)
                                        this.setState({
                                          createJobInfo: {
                                            ...this.state.createJobInfo,
                                            duration: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                    <FieldError
                                        error={errors.duration}
                                        touched={touched.duration}
                                      />

                                  </Box>

                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.price}
                                    </p>
                                    <TextField
                                      fullWidth
                                      data-test-id="textPrice"
                                      placeholder={configJSON.price}
                                      variant="outlined"
                                      value={this.state.createJobInfo.price}
                                      onChange={(event: any) => {
                                        setFieldValue("price", event.target.value)
                                        this.setState({
                                          createJobInfo: {
                                            ...this.state.createJobInfo,
                                            price: event.target.value,
                                          }
                                        })
                                      }}
                                    />
                                     <FieldError
                                        error={errors.price}
                                        touched={touched.price}
                                      />
                                  </Box>

                                  <Box sx={webStyleOrder.ordererInputBoxBackO}>
                                    <p style={webStyleOrder.orderInputInputBoxLabel}>
                                      {configJSON.jobOrderComments}
                                    </p>
                                    <TextField
                                      fullWidth
                                      data-test-id="textJobOrderComments"
                                      placeholder={configJSON.jobOrderComments}
                                      variant="outlined"
                                      value={this.state.createJobInfo.jobOrderComments}
                                      onChange={(event: any) => {
                                        setFieldValue("jobOrderComments", event.target.value)
                                        this.setState({
                                          createJobInfo: {
                                            ...this.state.createJobInfo,
                                            jobOrderComments: event.target.value,
                                          }
                                        })
                                      }}
                                    />

                                  </Box>
                                  <Typography
                                    style={{ fontSize: '14px', color: 'red', }}
                                    variant="h6"
                                  >
                                    {this.state.errorEmailExistTech}
                                  </Typography>
                                  <Box
                                    sx={{
                                      ...webStyleOrder.ordererInputBoxBackO,
                                      ...webStyleOrder.orderBoxButton
                                    }}
                                  >
                                    <Button
                                      id="btnExample"
                                      variant="contained"
                                      color="primary"
                                      style={webStyleOrder.orderBtnWhite}
                                    >
                                      Discard
                                    </Button>
                                    <Button
                                      id="btnExample"
                                      variant="contained"
                                      color="primary"
                                      style={webStyleOrder.orderBtnRed}
                                      type="submit"
                                      data-test-id="textBtnSubmitback"
                                    >
                                      {this.state.createJobInfo.jobId?"Edit":"Create"}
                                    </Button>
                                  </Box>
                                </form>
                              )}
                            </Formik>
                          </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} />
                      </Grid>
                    </Box>}
              </Box>
            </Box>
          </Container>
      </Grid>
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyleOrder = {
  backGdBoxBackJob: {
    background: "rgba(176, 175, 175, 0) none repeat scroll 0% 0%",
    overflowY: "auto" as "auto"

  },
  width100: {
    width: "100%"
  },
  headerSidBarBackO: {
    paddingTop: "10px",
    background: "#fff",
    height: "100vh",
    textAlign: "center",
    width: "18vw",
    zIndex: "9",
    position: "fixed",
    bottom: "0px",
    top: "0px",
    left: "0",
    borderRight: "1px solid rgba(0, 0, 0, 0.18)"
  },
  technicianBoxBackO: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
  },
  technicianTypoBackO: {
    fontSize: "20px",
    lineHeight: "45px",
    display: "flex"
  },
  technicianTypoBoxBackO: {
    fontWeight:600,
    borderRadius: "5px 5px 30px 5px",
    padding: "15px 15px 15px 35px",
    background: "#fff",
    margin:"20px 0"
  },

  marginTop15Back: {
    marginTop: "15px"
  },
  orderFormBoxBackO: {
    borderRadius: "10px",
    padding: "15px",
    background: "#fff",
    marginBottom: "20px"
  },
  ordererInputBoxBackO: {
    margin: "10px 0"
  },
  orderInputInputBoxLabel: {
    margin: "5px 0",
    display: "block"
  },
  orderBtnWhite: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  orderBtnRed: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },

  orderBoxButton: {
    textTransform: "capitalize",
    marginTop: "20px",
    borderTop: "1px #bbb8b8 solid",
    display:"flex",
    justifyContent:"end"

  },
  jobBackOAccor:{
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  }
};
const webStyle = {
 
  searchIcon: { height: "22px", width: "22px" },

  backOfficeModal: {
    width: "470px",
    borderRadius: "5px 5px 30px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
  },
  preTop: {
    fontSize: "20px",
    fontWeight: 500,
    marginTop:"-40px"
  },
  cancelBtn: {
    fontWeight:600,
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "13px 20px",
    border: "none",
    margin: "5px 0",
    boxShadow:"none",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  btnCloseModal: {
    cursor: "pointer",
    padding: "5px",
    background: "fff",
    color: "#000",
    justifyContent:"end !important"
  },
  doneBtnSelection: {
    fontWeight:600,
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "13px 20px",
    border: "none",
    margin: "0px 16px 0px 12px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
  doneBtnBorder: {
    borderTop: "1px #0003 solid"
  },
};
function FieldError({ error, touched }:any) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}


// Customizable Area End
