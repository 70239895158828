import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  type: string;
  file: any;
  goBack: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  csvFields: { id: string; value: string }[];
  dataOptions: { label: string; value: string }[];
  openDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataKeysApiId: string = "";
  importDataApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      csvFields: [],
      dataOptions: [],
      openDialog: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiResponseCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponseCallId === this.getDataKeysApiId)
        this.handleDataKeyRes(responseJson);
      else if (apiResponseCallId === this.importDataApiId)
        this.handleImportCsvRes(responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getDataKeys();
  }

  getDataKeys = async () => {
    const headers = { token: localStorage.getItem("token") };
    const formData = new FormData();
    formData.append("file", this.props.file);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataKeysApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewImportKeyApiEndPoint}${this.props.type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  importCSV = async () => {
    const headers = { token: localStorage.getItem("token") };
    const userDataString: any = localStorage.getItem("userData");
    const userData: any = JSON.parse(userDataString);
    const maintainerId: any = userData.maintainer_id;

    let mapping: any = {};
    this.state.csvFields
      .filter((x: any) => x.value !== "static")
      .forEach((x: any) => {
        mapping[x.value] = x.id;
      });
    const formData = new FormData();
    formData.append("file", this.props.file);
    formData.append("key_mappings", JSON.stringify(mapping));

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.importDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewImportDataApiEndPoint}${this.props.type}&maintainer_id=${maintainerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDataKeyRes = (res: any) => {
    if (res.keys && res.csv_keys) {
      this.setState({
        dataOptions: res.keys.map((x: any) => ({ label: x, value: x })),
        csvFields: res.csv_keys.map((x: any) => ({ id: x, value: "static" })),
      });
    }
  };

  handleImportCsvRes = (res: any) => {
    if (res.meta) window.location.reload();
    else this.setState({ openDialog: true });
  };

  isImportDisabled = () => {
    return !this.state.csvFields.filter((x: any) => x.value !== "static").length;
  };

  onIconClick = () => {
    this.setState({ openDialog: false });
  };

  getOptions = (optIndex: number) => {
    let options = this.state.dataOptions.filter(
      (x: any) =>
        !this.state.csvFields.find(
          (y: any, index: number) => optIndex !== index && y.value === x.value
        )
    );
    return options;
  };

  handleFieldChange = (e: any, index: number) => {
    let list = [...this.state.csvFields];
    list[index].value = e.target.value;
    this.setState({ csvFields: list });
  };
  // Customizable Area End
}
