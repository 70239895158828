import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  empList: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  getUserList: any[];
  getUserListFilter: any[];
  userTypeFilter: any[];
  maintainer_id: string;
  userType: string;
  currentUserType: string;
  openEmpDetails: boolean;
  detailsUser: any;
  openFilter: boolean;
  anchorEl: any;
  placement: any;
  filterArr: any[];
  openAssets: boolean;
  empId: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdvanceSearchUserApiCallId = "";
  getUserListApiCallId: string = "";
  apiEmployeeDeleteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      openFilter: false,
      anchorEl: null,
      placement: "",
      userTypeFilter: [],
      openAssets: false,
      // Customizable Area Start
      getUserList: [],
      getUserListFilter: [],
      userType: "",
      maintainer_id: "",
      currentUserType: "",
      filterArr: [],
      openEmpDetails: false,
      detailsUser: {
        name: '',
        employeeType: '',
        email: '',
        duration: '',
        id: '',
        work_hours_from: '',
        work_hours_to: '',
        salary: '',
        social_security_number: '',
        address: '',
        job_type: ''
      },
      empId: '',

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const userDataStr = localStorage.getItem("userData");
    if (userDataStr) {
      const userData = JSON.parse(userDataStr);
      this.setState({ userType: userData.user_type, maintainer_id: userData.maintainer_id, currentUserType: userData.user_type })
      this.handleUserList(userData.maintainer_id)
    }

    
    // Customizable Area End
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.getUserListApiCallId) {
          this.handleGetEmplistResponseSuccess(responseJson)
        } else if (apiRequestCallId === this.getAdvanceSearchUserApiCallId) {
          this.handleAdvanceSearchUserResult(responseJson)
        }
      }
    }

  };

  // Customizable Area Start

  handleGetEmplistResponseSuccess = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        getUserList: responseJson.data,
        getUserListFilter: responseJson.data,
      });
    }
  }


  handleClearFilters = () => {
    this.setState({ filterArr: [] });
    this.handleCheckedUser([]);
  };

  handleCheckedFilter = (e: any, userType: any) => {

    let temp = [...this.state.filterArr];
    if (temp.includes(userType)) temp = temp?.filter((x) => x !== userType);
    else temp.push(userType);
    this.setState({ filterArr: temp, userType: userType });
    this.handleCheckedUser(temp);
  };

  handleCheckedUser = (userTypes: any[]) => {
    let url = `?maintainer_id=${this.state.maintainer_id}&`
    userTypes.forEach((val) => {
      url = url + `user_type[]=${val}&`
    })
    const headers = {
      'Content-Type': 'application/json',
      "Token": localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeuserList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListAPiEndPoint + url
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  handleOpenFilter = (newPlacement: any) => (event: any) => {
    const { currentTarget } = event;
    this.setState((prevState: any) => ({
      anchorEl: currentTarget,
      openFilter: prevState.placement !== newPlacement || !prevState.openFilter,
      placement: newPlacement,
    }));
  };

  handleOpenEmpDetails = (item: any) => {
    this.setState({
      detailsUser: {
        name: item.first_name,
        employeeType: item.user_type,
        email: item.email,
        duration: item.full_phone_number,
        id: item.id,
        salary: item.salary,
        social_security_number: item.social_security_number,
        role_id: item.role_id,
        work_hours_from: item.work_hours_from,
        work_hours_to: item.work_hours_to,
        address: item.address,
        job_type: item.job_type
      },
      openEmpDetails: true,
      empId: item.id
    });
    const msg = new Message(
      getName(MessageEnum.NavigationNavigate)
    )
    msg.addData(
      getName(MessageEnum.NavigationNavigateData),
      {
        route: "EmployeeDetail",
        params: {
          openAssets: this.state.openAssets,
          userType: this.state.currentUserType,
          empId: item.id,
          empList: {
            name: item.first_name +" "+ item.last_name,
            employeeType: item.user_type,
            email: item.email,
            duration: item.full_phone_number,
            id: item.id,
            salary: item.salary,
            social_security_number: item.social_security_number,
            role_id: item.role_id,
            work_hours_from: item.work_hours_from,
            work_hours_to: item.work_hours_to,
            address: item.address,
            job_type: item.job_type
          },
        }
      }
    )
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg)
  };

  handleSearchUser = (e: any) => {
    if(e.target.value!=""){
      const headers = {
        'Content-Type': configJSON.validationApiContentType,
        "Token": localStorage.getItem("token"),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAdvanceSearchUserApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userSearchAPiEndPoint+`?maintainer_id=${this.state.maintainer_id}&search_query=${encodeURIComponent(e.target.value)}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeuserList
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else{
      this.setState({
        getUserList: this.state.getUserListFilter
      })
    }
  }

  handleAdvanceSearchUserResult = (response: any) => {
    if (response.data) {
      this.setState({
        getUserList: response.data,
      });
    }
  }

  handleUserList = (maintainer_id:string) => {
    const headers = {
      'Content-Type': 'application/json',
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListAPiEndPoint + `?maintainer_id=${maintainer_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeuserList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleNavigate = () => {
    this.props.navigation?.navigate("DispatchUser")
  }

  extractTimeFromDateString = (dateString: string): string => {
    const inputDate = new Date(dateString);
    const hours = inputDate.getUTCHours();
    const minutes = inputDate.getUTCMinutes();
    const result = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return result;
  }
  // Customizable Area End
}
