import React from "react";

// Customizable Area Start
import {
  Grid,
  Typography,
  Hidden,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { CustomTooltip } from "./CompanyLandingPage.web";

import { RightChevronIcon } from "./assets";
import {
  AssetContainer,
  CustomIconBtn,
  DownloadBtnWrapper,
} from "./JobDetail.web";
import Layout from "../../landingpage/src/Layout.web";
import JobSummaryController, { Props } from "./JobSummaryController.web";

// Customizable Area End

export default class InvoiceDb extends JobSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout navigation={this.props.navigation}>
        <div style={styles.container}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={8}>
              <Typography variant="h6" style={styles.title}>
                Job Summary
              </Typography>
              <Typography style={styles.subTitle}>
                {this.getFieldValue(this.state.task.job_order_id)}
              </Typography>
            </Grid>
            <Grid item style={styles.alignRight} xs={4}>
              <Hidden smDown>
                <DownloadBtnWrapper
                  data-test-id="downloadBtnWrapperWeb"
                  visibleClass="mr-4"
                  onDownload={this.onDownload}
                />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden mdUp>
            <div style={styles.resBtnDiv}>
              <DownloadBtnWrapper
                data-test-id="doenloadBtnWrapper"
                visibleClass=""
                onDownload={this.onDownload}
              />
            </div>
          </Hidden>
        </div>

        <div style={{ ...styles.container, ...styles.assetContainer }}>
          <AssetContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Asset Type</TableCell>
                  <TableCell align="center">Asset ID</TableCell>
                  <TableCell align="center">Mfg. Date</TableCell>
                  <TableCell align="center">Location No.</TableCell>
                  <TableCell align="center">Job Type</TableCell>
                  <TableCell />
                  <TableCell className="red">See All Assets</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.floor.map((a: number, index: number) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell align="left">Floor {a}</TableCell>
                      <TableCell colSpan={8} />
                    </TableRow>
                    {this.getFilteredList(a).map((x: any, subIndex: number) => (
                      <TableRow key={subIndex}>
                        <TableCell align="left">
                          {this.getFieldValue(x.asset.asset_type)}
                        </TableCell>
                        <TableCell align="center">
                          {this.getFieldValue(x.asset.asset_id)}
                        </TableCell>
                        <TableCell align="center">
                          {this.getFieldValue(x.asset.manufacturing_date)}
                        </TableCell>
                        <TableCell align="center">
                          {this.getFieldValue(x.asset.location_number)}
                        </TableCell>
                        <TableCell align="center">{x.job_type}</TableCell>
                        <TableCell>
                          <CustomTooltip
                            placement="top-start"
                            title={
                              <React.Fragment>
                                <Typography className="title">Notes</Typography>
                                <Typography className="address">
                                  {this.getFieldValue(x.notes)}
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <Typography
                              className={this.getNoteClass(x.has_notes)}
                            >
                              Notes
                            </Typography>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell className={`${this.getResult(x).class}`}>
                          {this.getResult(x).status}
                        </TableCell>
                        <TableCell>
                          <CustomIconBtn
                            data-test-id={`rightIcon${index}${subIndex}`}
                            onClick={() => this.toChecklist(x.checklistId)}
                          >
                            <Avatar className="asset" src={RightChevronIcon} />
                          </CustomIconBtn>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </AssetContainer>
        </div>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  container: {
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: "16px 12px 16px 16px",
  },
  alignRight: { textAlign: "right" as "right" },
  title: { fontWeight: "bold" as "bold" },
  subTitle: { fontSize: 14 },
  btnDiv: { display: "inline-grid", gap: 8 },
  resBtnDiv: { display: "grid", gap: 8, marginTop: 8 },
  assetContainer: { marginTop: 12 },
};
// Customizable Area End
