Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";

exports.locationName ="Location Name"
exports.address ="Address"
exports.town ="Town"
exports.country="Country"
exports.municipality="Municipality"
exports.postCode="Postcode"
exports.contactDetails="Contact Details"
exports.contactPreferences="Contact Preferences"
exports.paymentDetails="Payment Details"
exports.segmentSelect="Segments"
exports.accountManager="Account Manager"
exports.workingDays="Working Days"
exports.workHoursFrom="Work Hours From"
exports.workHoursTo="Work Hours To"
exports.breakStart="Break Start"
exports.breakEnd="Break End"
exports.assignedTechnician="Assigned Technician"
exports.propertyAdministrator="property Administrator"
exports.accessControl="Access Control"
exports.comments="Comments"

exports.companyName ="Company Name"
exports.fiscalId = "Fiscal ID"
exports.vat ="VAT"
exports.paymentTerms ="Payment Terms"
exports.assetType ="Asset Type"
exports.serialNo ="Serial No"
exports.floorNo ="Floor No"
exports.locationId="Location ID"
exports.locationNo="Location Number"
exports.manufacturingDate ="Manufacturing Date"
exports.expiryDate ="Expiry Date"
exports.lastMaintenanceDate ="Last Maintenance Date"
exports.lastRechargeDate ="Last Recharge Date"
exports.assetQty ="Asset Quantity"
exports.maintenanceFrq ="Maintenance Frequency"
exports.discountAmt ="Discount Amount"
exports.maintenanceDuration ="Maintenance Duration"
exports.price ="Price"
exports.assetsIDs="Asset ID"
exports.viewAllClientApiEndPoint ="account_block/index_client?maintainer_id="
exports.viewAllManagerApiEndPoint ="account_block/index_manager"
exports.createClientApiEndPoint = "account_block/create_client_accounts?maintainer_id="
exports.updateClientApiEndPoint ="account_block/update_client_accounts?client_account_id="
exports.updateLocationApiEndPoint="account_block/update_client_location"
exports.deleteClientApiEndPoint="account_block/destroy_client"
exports.deleteLocationApiEndPoint="account_block/destroy_client_location"
exports.createLocationApiEndPoint="account_block/create_client_location?client_account_id="
exports.getAllLocationApiEndPoint="account_block/index_client_locations?client_account_id="
exports.locationApiEndPoint="bx_block_location/locations/location_by_geocode"
exports.viewAssetDrpApiEndPoint="/bx_block_categories/sub_categories"
exports.createAssetApiEndPoint="account_block/create_client_asset?client_account_id="
exports.getAllAssetApiEndPoint="account_block/index_client_assets?client_account_id="
exports.getAllAssetssApiEndPoint="account_block/client_index_assets"
exports.getSearchAssetApiEndPoint="account_block/index_client_assets_searching"
exports.deleteAssetApiEndPoint="account_block/destroy_client_asset"
exports.updateAssetApiEndPoint="account_block/update_client_asset"
// Customizable Area End
