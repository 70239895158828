import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  Select
} from "@material-ui/core";
import { imgLogo } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "../assets/style.css";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import * as Yup from 'yup';
import HeaderNavigation from "../../../components/src/HeaderNavigation";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
  },
});
const optionsArray: any = [
  { value: '1:00', label: '1:00' },
  { value: '1:30', label: '1:30' },
  { value: '2:00', label: '2:00' },
  { value: '2:30', label: '2:30' },
  { value: '3:00', label: '3:00' },
  { value: '3:30', label: '3:30' },
  { value: '4:00', label: '4:00' },
  { value: '4:30', label: '4:30' },
  { value: '5:00', label: '5:00' },
  { value: '5:30', label: '5:30' },
  { value: '6:00', label: '6:00' },
  { value: '6:30', label: '6:30' },
  { value: '7:00', label: '7:00' },
  { value: '7:30', label: '7:30' },
  { value: '8:00', label: '8:00' },
  { value: '8:30', label: '8:30' },
  { value: '9:00', label: '9:00' },
  { value: '9:30', label: '9:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' },
  { value: '24:00', label: '24:00' }
];
let validationSchema: any = Yup.object().shape({
  fullName: Yup.string().required('Name is required'),
  phoneNo: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  address: Yup.string().required('Address is required'),
  workHoursFromSelect: Yup.string().required('Select At least One Value'),
  workHoursToSelect: Yup.string().required('Select At least One Value'),


});
// Customizable Area End

import { Formik } from "formik";

import DisPatcherController, {
  configJSON,
  Props
} from "./DisPatcherController.web";
import DialogMessage from "../../../components/src/DialogMessage";
export default class Dispatcher extends DisPatcherController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyles.backGdBox}>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={webStyles.headerSidBar}>
                <Box sx={webStyles.width100}>
                  <img width="60" src={imgLogo} />
                </Box>
              </Box>
              <Box sx={webStyles.dispatchBox}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h2" style={webStyles.dispatchTypo}>
                      {" "}
                      <ChevronRightIcon
                        style={webStyles.dispatchLeftICon}
                      />{" "}
                      &nbsp; Create new user
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box>
                      <Typography
                        variant="h2"
                        style={{
                          ...webStyles.dispatchTypoBox,
                          ...webStyles.dispatchTypo
                        }}
                      >
                        {" "}
                        Select User Type
                      </Typography>
                    </Box>
                    <HeaderNavigation
                      state={this.state}
                      goToDisPatcher={this.goToDisPatcher}
                      goToBackOffice={this.goToBackOffice}
                      goToTechnician={this.goToTechnician}
                    />
                    <Box
                      className="dispatchFormBox"
                      style={webStyles.dispatchFormBox}
                    >
                      <Formik
                      data-test-id="formikForm"
                        initialValues={{fullName: this.state.userInfo.fullName,
                          email: this.state.userInfo.email,
                          back_id: this.state.userInfo.dis_id, 
                          address: this.state.userInfo.address,
                          phoneNo: this.state.userInfo.phoneNo,
                          workHoursFromSelect: this.state.userInfo.workHoursFromSelect,
                          workHoursToSelect: this.state.userInfo.workHoursToSelect,
                          breakEndSelect: this.state.userInfo.breakEndSelect,
                          breakStartSelect: this.state.userInfo.breakStartSelect,

                        }}
                        validationSchema={validationSchema}
                        onSubmit={
                          this.handleSubmit
                        }
                        enableReinitialize
                      >
                        {({ values,
                          errors,
                          setFieldTouched,
                          setFieldValue,
                          touched, handleSubmit, resetForm, setErrors, setSubmitting }) => (
                          <form
                            autoComplete="off"
                            data-test-id={"btnPost"}
                            onSubmit={
                              handleSubmit
                            }
                            style={webStyles.marginTop15Dispatch}
                          >
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.fullName}
                              </p>
                              <TextField
                                fullWidth

                                data-test-id={"txtInputFirstName"}
                                name={"fullName"}
                                placeholder={configJSON.fullName}
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                value={this.state.userInfo.fullName}
                                onChange={(event: any) => {
                                  setFieldValue("fullName", event.target.value)
                                  this.setState({
                                    userInfo: {
                                      ...this.state.userInfo,
                                      fullName: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.fullName}
                                touched={touched.fullName}
                              />
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.email}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputEmail"
                                name="email"
                                placeholder={configJSON.email}
                                value={this.state.userInfo.email}
                                variant="outlined"
                                onChange={(event: any) => {
                                  setFieldValue("email", event.target.value)
                                  this.setState({
                                    userInfo: {
                                      ...this.state.userInfo,
                                      email: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.email}
                                touched={touched.email}
                              />
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.id}
                              </p>
                              <TextField
                                data-test-id="textID"
                                fullWidth
                                id="id"
                                name="id" // Add this line
                                placeholder={configJSON.id}
                                variant="outlined"
                                value={this.state.userInfo.dis_id}
                                onChange={(event) => {
                                  setFieldValue("id", event.target.value);
                                  this.setState({
                                    userInfo: {
                                      ...this.state.userInfo,
                                      dis_id: event.target.value,
                                    },
                                  });
                                }}
                              />
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.address}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputAddress"
                                placeholder={configJSON.address}
                                variant="outlined"
                                value={this.state.userInfo.address}
                                onChange={(event: any) => {
                                  setFieldValue("address", event.target.value)
                                  this.setState({
                                    userInfo: {
                                      ...this.state.userInfo,
                                      address: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.address}
                                touched={touched.address}
                              />
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.phoneNo}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputPhoneNo"
                                placeholder={configJSON.phoneNo}
                                name="phoneNo"
                                variant="outlined"
                                value={this.state.userInfo.phoneNo}
                                onChange={(event: any) => {
                                  setFieldValue("phoneNo", event.target.value)
                                  this.setState({
                                    userInfo: {
                                      ...this.state.userInfo,
                                      phoneNo: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.phoneNo}
                                touched={touched.phoneNo}
                              />
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.workHoursFrom}
                              </p>
                              <FormControl fullWidth variant="outlined">
                                <Select
                                  data-test-id="textInputWorkHoursFrom"
                                  onChange={(event: any) =>{
                                    setFieldValue("workHoursFromSelect", event.target.value)
                                    this.setState({
                                      userInfo: { ...this.state.userInfo,
                                        workHoursFromSelect: event.target.value, breakStartSelect:"", breakEndSelect: "", workHoursToSelect: ""}
                                     
                                    })
                                  }  
                                  }
                                  value={this.state.userInfo.workHoursFromSelect}
                                  fullWidth={true}
                                  id="demo-simple-select"
                                  labelId="demo-simple-select-label"
                                  placeholder={configJSON.workHoursFrom}
                                  
                                  inputProps={{
                                    sx: {
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                      color: "#84989F",
                                    }
                                  }}
                                >
                                  {optionsArray.map((option: any) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </Select>
                                <FieldError
                                error={errors.workHoursFromSelect}
                                touched={touched.workHoursFromSelect}
                              />
                              </FormControl>
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.workHoursTo}
                              </p>
                              <FormControl fullWidth variant="outlined">
                                <Select
                                  data-test-id="textInputWorkHoursTo"
                                  value={this.state.userInfo.workHoursToSelect}
                                  labelId="demo-simple-select-label"
                                  onChange={(event: any) =>{
                                    setFieldValue("workHoursToSelect", event.target.value)
                                    this.setState({
                                      userInfo: { ...this.state.userInfo,
                                        workHoursToSelect: event.target.value},
                                     
                                    })
                                  }
                                   
                                  }
                                  id="demo-simple-select"
                                  placeholder={configJSON.workHoursTo}
                                  fullWidth={true}
                                  
                                  inputProps={{
                                    sx: {
                                      borderRadius: "5px",
                                      backgroundColor: "#fff",
                                      color: "#84989F",
                                    }
                                  }}
                                >
                                  {optionsArray.map((optionKey: any) => (
                                    <option key={optionKey.value} value={optionKey.value}>
                                      {optionKey.label}
                                    </option>
                                  ))}
                                </Select>
                                 <FieldError
                                error={errors.workHoursToSelect}
                                touched={touched.workHoursToSelect}
                              />
                              </FormControl>
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              {this.state.showAcordianBox ? (
                                <>
                                  <Box sx={webStyles.dispatcherInputBox}>
                                    <p style={webStyles.dispatcherInputBoxLabel}>
                                      {configJSON.breakStart}
                                    </p>
                                    <FormControl fullWidth={true} variant="outlined">
                                      <Select
                                        data-test-id="textInputBreakStart"
                                        onChange={(event: any) =>{
                                          setFieldValue("breakStartSelect", event.target.value)
                                          this.setState({
                                            userInfo: { ...this.state.userInfo,
                                              breakStartSelect: event.target.value},
                                           
                                          })
                                        }
                                         
                                        }
                                        fullWidth={true}
                                        value={this.state.userInfo.breakStartSelect}
                                        placeholder={configJSON.breakStart}
                                        id="demo-simple-select"
                                        inputProps={{
                                          sx: {
                                            backgroundColor: "#fff",
                                            borderRadius: "5px",
                                            color: "#84989F",
                                          }
                                        }}
                                        labelId="demo-simple-select-label"
                                      >
                                        {
                                          this.getStartingBreakTime(optionsArray, this.state.userInfo.workHoursFromSelect, this.state.userInfo.workHoursToSelect).map((option: any) => <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>)
                                        }
                                      </Select>
                                      <FieldError
                                error={errors.breakStartSelect}
                                touched={touched.breakStartSelect}
                              />
                                    </FormControl>
                                  </Box>
                                  <Box sx={webStyles.dispatcherInputBox}>
                                    <p style={webStyles.dispatcherInputBoxLabel}>
                                      {configJSON.breakEnd}
                                    </p>
                                    <FormControl fullWidth={true} variant="outlined">
                                      <Select
                                        fullWidth
                                        data-test-id="textInputBreakEnd"
                                        value={this.state.userInfo.breakEndSelect}
                                        onChange={(event: any) =>{
                                          setFieldValue("breakEndSelect", event.target.value)
                                          this.setState({
                                            userInfo: { ...this.state.userInfo,
                                              breakEndSelect: event.target.value},
                                           
                                          })
                                        }}
                                        placeholder={configJSON.breakEnd}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          }
                                        }}
                                      >
                                        {this.getBreakEndTime(optionsArray, this.state.userInfo.breakStartSelect, this.state.userInfo.workHoursToSelect)
                                          .map((itemKeys: any) => (
                                            <option key={itemKeys.value} value={itemKeys.value}>
                                              {itemKeys.label}
                                            </option>
                                          ))}
                                      </Select>
                                      <FieldError
                                error={errors.breakEndSelect}
                                touched={touched.breakEndSelect}
                              />
                                    </FormControl>
                                  </Box>
                                </>
                              ) : (
                                ""
                              )}
                              <Typography
                                style={webStyles.dispatchAccor}
                                data-test-id="clickAcordianBtn"
                                onClick={this.clickAcordianBtn}
                                variant="body1"
                              >
                                {this.state.showAcordianBox ? (
                                  <>
                                    <RemoveCircleOutlineIcon /> <p>Remove Break Timing</p>
                                  </>
                                ) : (
                                  <>
                                    <ControlPointIcon /> <p>Add Break Timing</p>
                                  </>
                                )}
                              </Typography>
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.salary}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputSalary"
                                placeholder={configJSON.salary}
                                variant="outlined"
                                value={this.state.userInfo.salary}
                                onChange={(event: any) => {
                                  setFieldValue("salary", event.target.value)
                                  this.setState({
                                    userInfo: {
                                      ...this.state.userInfo,
                                      salary: event.target.value,
                                    }
                                  })
                                }}
                              />
                             
                            </Box>
                            <Box sx={webStyles.dispatcherInputBox}>
                              <p style={webStyles.dispatcherInputBoxLabel}>
                                {configJSON.SocialSuqNo}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputSocialSuqNo"
                                placeholder={configJSON.SocialSuqNo}
                                variant="outlined"
                                value={this.state.userInfo.SocialSuqNo}
                                onChange={(event: any) => {
                                  setFieldValue("SocialSuqNo", event.target.value)
                                  this.setState({
                                    userInfo: {
                                      ...this.state.userInfo,
                                      SocialSuqNo: event.target.value,
                                    }
                                  })
                                }}
                              />
                             
                            </Box>
                            <Typography variant="h6" style={{color:'red', fontSize: '14px'}}>
                                {this.state.errorEmailExist}
                            </Typography>
                            <Box
                              sx={{
                                ...webStyles.dispatcherInputBox,
                                ...webStyles.dispatchBoxButton
                              }}
                            >
                              <Button
                                id="btnExample"
                                variant="contained"
                                color="primary"
                                style={webStyles.dispatchBtnWhite}
                                onClick={this.goToListD}
                              >
                                Back To List
                              </Button>
                              <Button
                                data-test-id={"textBtnSubmit"}
                                color="primary"
                                style={webStyles.dispatchBtnRed}
                                type="submit"

                              >
                                Save user
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} />
                </Grid>
              </Box>
            </Box>
          </Box>
          <DialogMessage showModal={this.state.showModal} closeModal={this.closeModal} modalEmail={this.state.modalEmail} modalPassword={this.state.modalPassword} isEdit={this.state.isEdit} />
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  backGdBox: {
    background: "rgba(227, 190, 190, 0.28) none repeat scroll 0% 0%",
    overflowY: "auto" as "auto",
    maxWidth:'100%'
  },
  dispatchAccor: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  marginTop15Dispatch: {
    marginTop: "15px"
  },
  width100: {
    width: "100%"
  },
  headerSidBar: {
    paddingTop: "10px",
    background: "#fff",
    height: "100vh",
    textAlign: "center",
    width: "18vw",
    zIndex: "9",
    position: "fixed",
    bottom: "0px",
    top: "0px",
    left: "0",
    borderRight: "1px solid rgba(0, 0, 0, 0.18)"
  },
  dispatchBox: {
    height: "100%",
    width: "83vw",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    marginLeft: "18vw"
  },
  dispatchTypo: {
    fontSize: "20px",
    lineHeight: "45px",
    display: "flex"
  },
  dispatchTypoBox: {
    borderRadius: "5px 5px 30px 5px",
    padding: "15px 15px 15px 35px",
    background: "#fff"
  },
  dispatchTabButton: {
    display: "flex",
    textAlign: "center",
    padding: "10px",
    margin: "20px 0px",
    borderRadius: "10px",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%",
    justifyContent: "center"
  },
  dispatchFormBox: {
    borderRadius: "10px",
    padding: "15px",
    background: "#fff",
    marginBottom: "20px"
  },
  dispatchLeftICon: {
    marginTop: "10px"
  },
  dispatcherInputBox: {
    margin: "10px 0"
  },
  dispatcherInputBoxLabel: {
    margin: "5px 0",
    display: "block"
  },
  dispatchBtnWhite: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  dispatchBtnRed: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
  dispatchBoxButton: {
    textTransform: "capitalize",
    marginTop: "20px",
    borderTop: "1px #bbb8b8 solid"
  }
};

// @ts-ignore
function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
// Customizable Area End
