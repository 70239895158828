import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {logo, topImg, bottomImg} from "./assets"
import "../assets/style.css"

import EmailLoginController, {
  Props,
} from "./EmailLoginController";
const AntSwitch = withStyles((theme:any) => ({
  root: {
    width: 28,
    height: 15,
    padding: 0,
    display: 'flex',
    marginBottom:"4px",
    overflow:"inherit"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: "rgba(193, 15, 15, 0.94)",
        borderColor: "rgba(193, 15, 15, 0.94)",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
    borderRadius:"25%"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "4px",
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#dbaeae30",
    },
  },
});
// Customizable Area End
export default class EmailAccountLoginBlock extends EmailLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box sx={webStyles.topBox} className="boxModalHeight">
        <Container maxWidth="sm">
            <Box sx={webStyles.logoBox}>
                <img src={logo} style={{width:"200px"}} />
            </Box>
            {
                this.state.ErrorMsg?<>
                <Box className="ErrorMsg" sx={webStyles.errorMsg}>{this.state.ErrorMsg}</Box>
                </>
                :""
            }
          <Box
            sx={webStyles.loginBox} className="borderRediousCss"
          >
            <Typography className="textHeading" variant="h4" component="h2">
              {this.labelTitle}
            </Typography>

            <Box sx={webStyles.insideBox}>
              <Box style={webStyles.inputBoxGrid}>
                <Typography className="textLebel" variant="body1">Email</Typography>
              <TextField
                  data-test-id="txtInputEmail"
                  placeholder={"Your email"}
                  variant="outlined"
                  value={this.state.email}
                  onChange={(e) => this.setEmailInput(e.target.value)}
                  fullWidth={true}
                />
              </Box>
              <Box style={webStyles.inputBoxGrid}>
              <Typography className="textLebel" variant="body1">Password</Typography>
              <TextField
                  data-test-id="txtInputPassword"
                  type={this.state.enablePassdField ? "password" : "text"}
                  placeholder={"Your password"}
                  variant="outlined"
                  fullWidth={true}
                  value={this.state.password}
                  onChange={(e) => this.setPasswordInput(e.target.value)}
                  InputProps={{
                    endAdornment: // Corrected property name: endAdornment endAdornment
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPwd}
                          edge="end"
                        >
                          {this.state.enablePassdField ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                }}
                />
               
              </Box>
              <Box
                className="textRightBox"
                sx={webStyles.checkboxGrid}
              >
                
                <AntSwitch checked={this.state.checkedRememberMe}
                data-test-id={"btnRememberMelogin"}
                onClick={() =>
                  this.setRememberMeInput(!this.state.checkedRememberMe)
                }
                 name="checkedC" /> &nbsp;&nbsp;
                <Box sx={webStyles.textBlackSpan}> <Typography variant="body1">Remember Me</Typography></Box>
              </Box>
              <Box
                sx={webStyles.buttonBox}
              >
                <Button
                  className={this.state.checkedRememberMe?"buttonRedlogin textcpital":"textcpital"}
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => this.doEmailLogIn()}
                  style={webStyles.loginBtn}
                >
                  Log In 
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyles = {
    errorMsg:{
        background:" #ed7e7e3d",
        padding: "20px 15px",
        borderRadius: "5px",
        color: "#c12121",
        fontSize: "18px",
        borderLeft: "6px #c10f0ff0 solid",
    },
    buttonBox:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 0px",
    },
    checkboxGrid:{
        fontSize: "18px",
        color: "#6200EE",
        fontWeight: "bold",
        marginTop: "5px",
        textAlign:"center",
        display:"flex",
        justifyContent:"center",
        alignItems: "center"
    },
    inputBoxGrid:{
        padding: "15px 0px" 
    },
    insideBox:{
        width: "100%", paddingTop: "20px"
    },
    topBox:{
        background:"rgb(127 107 107 / 16%)", 
        position:"relative",
        height:"100vw",
        '&::before': {
            width: '38%',
            height: '400px',
            backgroundImage: `url('${topImg}')`, 
            content: '""',
            position: 'absolute',
            top: '0',
            right: '0',
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
          },
          '&::after': {
            left: '0',
            width:"38%",
            content: '""', 
            position: 'absolute',
            bottom: '0',
            height: '473px',
            backgroundImage: `url('${bottomImg}')`, 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
          },
    },
    logoBox:{
        margin:"50px 0",
        display: "flex",
        padding: "20px",
        justifyContent:"space-around"
    },
    loginBox:{
        margin:"50px 0",
              background:"#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              padding: "35px",
              borderRadius:"10px 10px 30px 10px"
    },
    loginBtn: {
        fontSize: "15px",
        cursor: "pointer",
        borderRadius: "5px",
        padding: "10px 20px",
        border: "none",
        background: "#d5d5d575",
    color: "rgb(18 17 17)",
    '&:hover': {
        background: 'rgba(215, 31, 31, 0.84) !important',
        color: '#fff'
      },
    },
      textBlackSpan:{
        color:"000",
        fontWeight:"normal"
      }
}
// Customizable Area End
