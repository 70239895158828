Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.contentTypeApiAddDetail = "application/json";
exports.userListAPiEndPoint = "account_block/index";
exports.userSearchAPiEndPoint = "account_block/search_index";
exports.apiMethodTypeuserList = "GET";
exports.deleteApiMethod = "DELETE";
exports.deleteEmployeeApiEndPoint="account_block/destroy_account"
// Customizable Area End