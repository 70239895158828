import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
interface IChecklist {
  title: string;
  question: string;
  isChecked: boolean;
  note: any;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ids: { task: string; checklist: string };
  maintainer_id: string;
  task: any;
  detail: any;
  showAssetDetail: boolean;
  checklist: IChecklist[];
  answers: { checklist: IChecklist[]; compliance: any; note: any };
  checklistNotes: number[];
  assetNote: { open: boolean; value: any };
  compliance: { value: number; open: boolean; note: any };
  open: { failure: boolean; allComplete: boolean; unsaved: boolean };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChecklistProcessController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTaskDetailApiId = "";
  updateChecklistDetailApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      ids: { task: "", checklist: "" },
      maintainer_id: "",
      task: {},
      detail: {},
      showAssetDetail: false,
      checklist: [],
      checklistNotes: [],
      answers: { checklist: [], compliance: { value: 1, note: "" }, note: "" },
      assetNote: { open: false, value: "" },
      compliance: { value: 1, open: false, note: "" },
      open: { failure: false, allComplete: false, unsaved: false },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getTaskDetailApiId) {
        this.handleTaskDetail(responseJson);
      } else if (
        apiRequestCallId === this.updateChecklistDetailApiId &&
        responseJson.data
      ) {
        this.getTeskDetail();
      }
    }
  };

  async componentDidMount() {
    const userDataStr = localStorage.getItem("userData");
    const task = this.props.navigation.getParam("taskId");
    const checklist = this.props.navigation.getParam("id");
    if (userDataStr && task && checklist) {
      const userData = JSON.parse(userDataStr);
      this.setState(
        { maintainer_id: userData.maintainer_id, ids: { task, checklist } },
        () => this.getTeskDetail()
      );
    }
  }

  getTeskDetail = () => {
    const headers = { Token: localStorage.getItem("token") };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskBlock}/${this.state.ids.task}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateChecklistDetail = () => {
    const { checklist, compliance } = this.state;
    const detail = {
      checklist,
      compliance: { value: compliance.value, note: compliance.note },
    };
    const isSuccess = this.state.checklist.length
      ? !this.state.checklist.some((x: IChecklist) => x.isChecked)
      : !!this.state.compliance.value;
    const formData = new FormData();
    formData.append("checklist[asset_status]", "Completed");
    formData.append("checklist[checklist_answers]", JSON.stringify(detail));
    formData.append("checklist[success]", JSON.stringify(isSuccess));
    formData.append("checklist[notes]", this.state.assetNote.value);
    formData.append(
      "checklist[has_notes]",
      JSON.stringify(!!this.state.assetNote.value)
    );

    const headers = { Token: localStorage.getItem("token") };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateChecklistDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checklistBlock}/${this.state.ids.checklist}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChecklistDetail = (res: any) => {
    if (res) {
      const assetDetail = {
        checklistId: Number(res.id),
        asset_status: res.attributes.asset_status,
        job_type: res.attributes.job_type,
        assetId: Number(res.attributes.asset.data.id),
        ...res.attributes.asset.data.attributes,
      };
      this.setState({ detail: assetDetail });
      const detail = { ...res.attributes };
      if (typeof detail.success === "boolean" && detail.checklist_answers) {
        const answers = JSON.parse(detail.checklist_answers);
        this.setState({
          checklist: answers.checklist,
          compliance: {
            ...this.state.compliance,
            value: answers.compliance.value,
            note: answers.compliance.note,
          },
          assetNote: { ...this.state.assetNote, value: detail.notes },
          answers: { ...answers, note: detail.notes },
        });
      } else if (Array.isArray(detail.checklist_questions)) {
        const checklist = detail.checklist_questions
          .filter((x: any) => x.type === "check")
          .map(
            (x: any) =>
              ({
                title: x.short_question,
                question: x.long_question,
                isChecked: true,
                note: "",
              } as IChecklist)
          );
        this.setState({ checklist });
        this.setState({ answers: { ...this.state.answers, checklist } });
      }
    }
  };

  handleTaskDetail = (res: any) => {
    if (res.data && res.data.attributes) {
      const task = { ...res.data.attributes };
      const checklist = task.checklists.data.find(
        (x: any) => x.id == this.state.ids.checklist
      );
      if (checklist) {
        this.handleChecklistDetail(checklist);
        this.setState({
          task: {
            isEdit: task.status != "complete",
            status: task.status,
            location_name: task.location_name,
            checklists: task.checklists.data.map((x: any) => ({
              checklistId: Number(x.id),
              ...x.attributes,
              asset: {
                ...x.attributes.asset.data.attributes,
                assetId: Number(x.attributes.asset.data.id),
              },
            })),
          },
        });
      }
    }
  };

  getFieldValue = (x: any) => x || "-";

  isUpdated = () => {
    const { checklist, compliance, note } = this.state.answers;
    return (
      this.state.assetNote.value != note ||
      this.state.compliance.value != compliance.value ||
      this.state.compliance.note != compliance.note ||
      this.state.checklist.some(
        (x: IChecklist, index: number) =>
          x.note != checklist[index].note ||
          x.isChecked != checklist[index].isChecked
      )
    );
  };

  goBack = () => this.props.navigation.goBack();

  toJobDetail = () => {
    this.props.navigation.navigate("JobDetail", { id: this.state.ids.task });
  };

  toggleAssetDetail = () => {
    this.setState({ showAssetDetail: !this.state.showAssetDetail });
  };

  toggleNotes = (index: number) => {
    let tempNotes = [...this.state.checklistNotes];
    if (tempNotes.includes(index))
      tempNotes = tempNotes.filter((x) => x !== index);
    else tempNotes.push(index);
    this.setState({ checklistNotes: tempNotes });
  };

  toggleAssetNote = () => {
    this.setState({
      assetNote: { ...this.state.assetNote, open: !this.state.assetNote.open },
    });
  };

  toggleComplianceNote = () => {
    this.setState({
      compliance: {
        ...this.state.compliance,
        open: !this.state.compliance.open,
      },
    });
  };

  getDisplay = () => (this.state.showAssetDetail ? "block" : "none");

  onCheckboxChange = (index: number) => {
    const temp = [...this.state.checklist];
    temp[index].isChecked = !temp[index].isChecked;
    this.setState({ checklist: temp });
  };

  onNoteChange = (e: any, index: number) => {
    const temp = [...this.state.checklist];
    temp[index].note = e.target.value;
    this.setState({ checklist: temp });
  };

  onAssetNoteChange = (e: any) => {
    this.setState({
      assetNote: { ...this.state.assetNote, value: e.target.value },
    });
  };

  onComplianceNoteChange = (e: any) => {
    this.setState({
      compliance: { ...this.state.compliance, note: e.target.value },
    });
  };

  onComplianceChange = (e: any) => {
    const value = Number(e.target.value);
    this.setState({ compliance: { ...this.state.compliance, value } });
  };

  onSubmit = () => {
    const isSuccess = this.state.checklist.length
      ? !this.state.checklist.some((x: IChecklist) => !x.isChecked)
      : !!this.state.compliance.value;
    const isAllAssetPassed = !this.state.task.checklists
      .filter((x: any) => x.checklistId != this.state.ids.checklist)
      .some((x: any) => x.asset_status != "Completed");
    if (!isSuccess) {
      this.setState({ open: { ...this.state.open, failure: true } });
    } else if (isAllAssetPassed) {
      this.setState({ open: { ...this.state.open, allComplete: true } });
    } else this.updateChecklistDetail();
  };

  onLeave = () => {
    if (!this.state.task.isEdit) this.toJobDetail();
    else if (this.state.detail.asset_status != "Completed" || this.isUpdated())
      this.setState({ open: { ...this.state.open, unsaved: true } });
    else this.toJobDetail();
  };

  onFailureDialog = (isConfirm: boolean) => {
    if (isConfirm) this.updateChecklistDetail();
    this.setState({ open: { ...this.state.open, failure: false } });
  };

  onAllCompleteDialog = (isConfirm: boolean) => {
    if (isConfirm) this.updateChecklistDetail();
    this.setState({ open: { ...this.state.open, allComplete: false } });
  };

  onUnsavedDialog = (isConfirm: boolean) => {
    if (isConfirm) this.goBack();
    this.setState({ open: { ...this.state.open, unsaved: false } });
  };
  // Customizable Area End
}
