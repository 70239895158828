import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  Select
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "../assets/style.css";
import * as Yup from 'yup';


let validationSchema: any = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
  fiscalId: Yup.string().required('Fiscal Id is required'),
  vat:Yup.string().required('VAT is required'),
  contactDetails:Yup.string().required('Contact Details is required'),
  paymentDetails:Yup.string().required('Payment Details is required'),


});
// Customizable Area End

import { Formik } from "formik";

import CreateClientController, {
  configJSON,
  Props
} from "./CreateClientController.web";
import Layout from "../../landingpage/src/Layout.web";
export default class ClientCreate extends CreateClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout
        navigation={this.props.navigation}> 
      <Box sx={webStyles.clientBox}>
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box
                      className="locationFormBox"
                      style={webStyles.clientFormBox}
                    >
                      
                      <Formik
                      data-test-id="formikForm"
                      enableReinitialize={true}
                        initialValues={
                          this.state.ClientDataObj
                       
                      }
                        validationSchema={validationSchema}
                        onSubmit={
                          this.handleSubmit
                        }

                      >
                        
                        {({ values,
                          errors,
                          setFieldTouched,

                          setFieldValue,
                          touched, handleSubmit,
                           resetForm, setErrors,
                            setSubmitting }) => (
                              
                          <form
                            autoComplete="off"
                            data-test-id={"btnPost"}
                            onSubmit={
                              handleSubmit
                            }
                            style={webStyles.clientTop15}
                          >
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                {configJSON.companyName}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth

                                data-test-id={"txtInputClientName"}
                                name={"companyName"}
                                placeholder={configJSON.companyName}
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                 value={this.state.ClientDataObj.companyName}
                                onChange={(event: any) => {
                                  setFieldValue("companyName", event.target.value)
                                  this.setState({
                                    ClientDataObj: {
                                      ...this.state.ClientDataObj,
                                      companyName: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                              data-test-id="fieldError"
                                error={errors.companyName}
                                touched={touched.companyName}
                              />
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                {configJSON.fiscalId}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                               
                                value={this.state.ClientDataObj.fiscalId}
                                onChange={(event: any) => {
                                  setFieldValue("fiscalId", event.target.value)
                                  this.setState({
                                    ClientDataObj: {
                                      ...this.state.ClientDataObj,
                                      fiscalId: event.target.value,
                                    }
                                  })
                                }}

                                data-test-id="textInputfiscalId"
                                placeholder={configJSON.fiscalId}
                                variant="outlined"
                              />
                              <FieldError
                                error={errors.fiscalId}
                                touched={touched.fiscalId}
                              />
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                {configJSON.vat}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputVat"
                                placeholder={configJSON.vat}
                                variant="outlined"
                                name={"vat"}
                                value={this.state.ClientDataObj.vat}
                                onChange={(event: any) => {
                                  setFieldValue("vat", event.target.value)
                                  this.setState({
                                    ClientDataObj: {
                                      ...this.state.ClientDataObj,
                                      vat: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.vat}
                                touched={touched.vat}
                              />
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                {configJSON.contactDetails}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                name={"contactDetails"}
                                data-test-id="textInputContactDetails"
                                placeholder={configJSON.contactDetails}
                                variant="outlined"
                                value={this.state.ClientDataObj.contactDetails}
                                onChange={(event: any) => {
                                  setFieldValue("contactDetails", event.target.value)
                                  this.setState({
                                    ClientDataObj: {
                                      ...this.state.ClientDataObj,
                                      contactDetails: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.contactDetails}
                                touched={touched.contactDetails}
                              />
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                {configJSON.contactPreferences}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputContactPreferences"
                                placeholder={configJSON.contactPreferences}
                                variant="outlined"
                                value={this.state.ClientDataObj.contactPreferences}
                                onChange={(event: any) => {
                                  setFieldValue("contactPreferences", event.target.value)
                                  this.setState({
                                    ClientDataObj: {
                                      ...this.state.ClientDataObj,
                                      contactPreferences: event.target.value,
                                    }
                                  })
                                }}
                              />
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                {configJSON.paymentDetails}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                name={"paymentDetails"}
                                data-test-id="textInputPaymentDetails"
                                placeholder={configJSON.paymentDetails}
                                variant="outlined"
                                value={this.state.ClientDataObj.paymentDetails}
                                onChange={(event: any) => {
                                  setFieldValue("paymentDetails", event.target.value)
                                  this.setState({
                                    ClientDataObj: {
                                      ...this.state.ClientDataObj,
                                      paymentDetails: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.paymentDetails}
                                touched={touched.paymentDetails}
                              />
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                      {configJSON.paymentTerms}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.ClientDataObj.paymentTerms}
                                        onChange={(event: any) => {
                                          setFieldValue("paymentTerms", event.target.value)
                                          this.setState({
                                            ClientDataObj: {
                                              ...this.state.ClientDataObj,
                                              paymentTerms: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textpaymentTermsSelect"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.paymentTerms}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                        
                                      >
                                        <option value={"Dummy"}> Dummy</option>

                                        <option value={"Others"}>Others</option>
                                      </Select>
                                    </FormControl>
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                      {configJSON.segmentSelect}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.ClientDataObj.segmentSelect}
                                        onChange={(event: any) => {
                                          setFieldValue("segmentSelect", event.target.value)
                                          this.setState({
                                            ClientDataObj: {
                                              ...this.state.ClientDataObj,
                                              segmentSelect: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textSegmentSelect"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.segmentSelect}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                        
                                      >
                                        <option value={"Parking"}> Parking</option>
                                        <option value={"Office Building"}> Office Building</option>
                                        <option value={"Warehouse"}> Warehouse</option>
                                        <option value={"Hotel"}> Hotel</option>
                                        <option value={"Storage Facility"}> Storage Facility</option>
                                        <option value={"Restaurant"}> Restaurant</option>
                                        <option value={"Retail Store"}> Retail Store</option>
                                        <option value={"Residential Building"}> Residential Building</option>
                                        <option value={"Government/Institution"}> Government/Institution</option>
                                        <option value={"School/Education"}> School/Education</option>
                                        <option value={"Private home"}> Private home</option>
                                        <option value={"Factory/Industrial"}> Factory/Industrial</option>
                                        <option value={"Entertainment (Casino,Museum,etc.)"}> Entertainment (Casino,Museum,etc.)</option>
                                        <option value={"Subway"}> Subway</option>
                                        <option value={"Airport"}> Airport</option>
                                        <option value={"Terminal (e.g. bus)"}> Terminal (e.g. bus)</option>
                                        <option value={"Others"}> Others</option>
                                      </Select>
                                    </FormControl>
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                      {configJSON.accountManager}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select

                                        value={this.state.ClientDataObj.accountManager}
                                        onChange={(event: any) => {

                                          setFieldValue("accountManager", event.target.value)
                                          this.setState({

                                            ClientDataObj: {
                                              ...this.state.ClientDataObj,
                                              accountManager: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textAccountManager"
                                        labelId="demo-simple-select-label"

                                        id="demo-simple-select"

                                        placeholder={configJSON.accountManager}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",

                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                        
                                      >
                                       {
                                         this.state.allManagerList.map((item:any)=>(
                                          <option value={item.id}> {item.attributes.first_name}</option>
                                         ))
                                       }
                                      </Select>
                                     
                                    </FormControl>
                            </Box>
                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                      {configJSON.assignedTechnician}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.ClientDataObj.assignedTechnician}

                                        onChange={(event: any) => {

                                          setFieldValue("assignedTechnician", event.target.value)

                                          this.setState({
                                            ClientDataObj: {
                                              ...this.state.ClientDataObj,

                                              assignedTechnician: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textAssignedTechnician"

                                        labelId="demo-simple-select-label"

                                        id="demo-simple-select"
                                        placeholder={configJSON.assignedTechnician}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                      >
                                        <option value={"Assigned"}> assigned</option>


                                        <option value={"Others"}>Others</option>
                                      </Select>
                                     
                                    </FormControl>
                            </Box>

                            <Box sx={webStyles.clientInputbox}>
                              <p style={webStyles.clientInputboxLabel}>
                                {configJSON.comments}
                              </p>
                              <TextField
                                fullWidth
                                multiline

                                minRows={4}
                                data-test-id="textInputComments"

                                placeholder={"Type somethings.."}

                                variant="outlined"
                                value={this.state.ClientDataObj.comments}

                                onChange={(event: any) => {
                                  setFieldValue("comments", event.target.value)
                                  this.setState({
                                    ClientDataObj: {
                                      ...this.state.ClientDataObj,

                                      comments: event.target.value,
                                    }
                                  })
                                }}
                                
                              />
                             
                            </Box>
                            <Box sx={{display:"flex", justifyContent:"end", padding:"20px 0"}}>
                            <Button
                            className="fontText"
                                data-test-id={"textBtnProceed"}
                                disabled={this.state.ClientDataObj.clientId?false:true}

                                color="primary"
                                style={webStyles.clientBtnRed}
                                type="button"
                                onClick={this.naviLocation}

                              >
                                Proceed To Locations
                              </Button>
                            </Box>
                            <Box
                              sx={{
                                ...webStyles.clientInputbox,

                                ...webStyles.clientBoxButton
                              }}
                            >
                              <Button
                                id="btnExample"
                                type="button"
                                onClick={this.backToViewClient}
                                className="fontText"

                                variant="contained"
                                color="primary"

                                style={webStyles.clientBtnWhite}
                              >
                                Back To List
                              </Button>
                              <Button
                              className="fontText"

                                data-test-id={"textBtnSubmit"}

                                color="primary"
                                style={webStyles.clientBtnRed}
                                type="submit"

                              >{this.state.ClientDataObj.clientId?"Edit":"Save"}
                                
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} />

                </Grid>
              </Box>
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {


  clientTop15: {
    marginTop: "15px"
  },
  clientBox: {
    minHeight: "100vh",
    width: "100%",
  },
  clientTypee: {
    fontSize: "20px",
    lineHeight: "45px",
    display: "flex",
    fontWeight:600
  },
  clientFormBox: {
    borderRadius: "10px",
    padding: "15px",
    background: "#fff",
    marginBottom: "20px"
  },
  clientLeftIcon: {
    marginTop: "10px"
  },
  clientInputbox: {
    margin: "10px 0"
  },
  clientInputboxLabel: {
    margin: "5px 0",
    display: "block",
    fontWeight:600
  },
  clientBtnWhite: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  clientBtnRed: {

    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
  clientBoxButton: {
    textTransform: "capitalize",
    marginTop: "20px",
    borderTop: "1px #bbb8b8 solid",
    justifyContent:"end",
    display:"flex"
  }
};

function FieldError({ error, touched }:any) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
// Customizable Area End