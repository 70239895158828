import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  showModal:any;
  StartShowCalendar:any;
  EndShowCalendar:any;
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  CalenderObj:any;
  events:any;
  validationErrors:any;
  maintainerId:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EventController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  apiEventCallId: string = "";
  getEventApiCallId: string = "";
  serviceProviderDetailApiId:any;
  serviceProviderScheduleApiId:any;
  getTechApiCallId:any;
  // Customizable Area End
  calendarRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.calendarRef = React.createRef<any>();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      selectedTime: 0,
      events:[],
      maintainerId:"",
      StartShowCalendar:false,
      EndShowCalendar:false,
      CalenderObj:{
        startDate: new Date(),
        endDate:new Date(),
        startTime:"",
        endTime:"",
        startAmPm:"AM",
        endAmPm:"PM",
        description:"1",
        other:""
      },

      validationErrors:{},
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      showModal:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
   
   const userDataString:any = JSON.parse(localStorage.getItem("userData")?? "{}")
   const maintainerId:any = userDataString.maintainer_id;
      this.setState({ maintainerId:maintainerId})
      this.getEventRes(maintainerId);
   this.getTechRes(maintainerId)

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.apiEventCallId:
            this.eventPost(responseJson, errorReponse)
            break;
            case apiRequestCallId === this.getEventApiCallId:
                this.eventGet(responseJson)
              break
            case apiRequestCallId === this.getTechApiCallId:
              this.getTechGet(responseJson);
              break
            default:
        }
      }
    }
    // Customizable Area End
  }
  eventGet=(responseJson:any)=>{
    if(!responseJson.message){
      const ModifiedArray = responseJson.map((item:any) => {
        // Change the key names as needed
        return {
          id:item.id,
          start: item.start_date,
          end: item.end_date,
          title: item.title,
          // color: 'rgba(193, 15, 15, 0.94)'
          className: "bg-Red-default",
        };
      });
      this.setState({events:ModifiedArray})
    }
  }
  getTechGet=(responseJson:any)=>{
    if(!responseJson.message){
      
      const ModifiedArray = responseJson.data.map((item:any) => {
        let className = '#000000';
        switch (true) {
          case item.attributes.status === "assigned":
            className = 'bgAssigned';
            break;
            case item.attributes.status === "complete":
              className = 'bgComplete';
              break
            case item.attributes.status === "in_progress":
              className = 'bgInprogress';
              break
            default:
        }
        return {
          id:item.attributes.id,
          start: item.attributes.start_time,
          end: item.attributes.end_time,
          title: item.attributes.location_details.data.attributes.location_name,
          className: className
        };
      });
      this.setState({
        events: [...this.state.events,...ModifiedArray]
      });
    }
  }
  eventPost=(responseJson:any, errorReponse:any)=>{
    if (responseJson) {
      this.setState({ CalenderObj:{
       startDate: new Date(),
       endDate:new Date(),
       startTime:"",
       endTime:"",
       startAmPm:"AM",
       endAmPm:"PM",
       description:"1",
       other:""
     },});
     this.getEventRes(this.state.maintainerId);
      
     } 

     this.parseApiCatchErrorResponse(errorReponse);
  }
  dayHeaderContent = () => {
    const today:any = new Date();
    const options:any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(today);
  };
  handleEventClick = (selected:any) => {
      const updatedItems = this.state.events.filter((item:any) => item.title !== selected);
    this.setState({ events: updatedItems });
  };
  validateFunct =()=>{
    const errors:any = {};
switch (true) {
  
  case !this.state.CalenderObj.startTime:
    errors.startTime = 'Start time is required';
    break;
    case !this.state.CalenderObj.endTime:
    errors.endTime = 'End time is required';
    break;
  case this.state.CalenderObj.description==="1":
    errors.description = 'Description is required';
    break;
    case this.state.CalenderObj.description=== "Others" && !this.state.CalenderObj.other:
    errors.other = 'Specify is required';
    break;
  default:
    break;
}
if (Object.keys(errors).length > 0) {
  this.setState({ validationErrors: errors });
  return true;
}
return false; // No validation errors

  }
  handleSubmit =(e:any)=>{
    e.preventDefault();
    if (this.validateFunct()) {
      return;
    }
    const startDates = new Date(`${this.formatDate(this.state.CalenderObj.startDate)} ${this.state.CalenderObj.startTime} ${this.state.CalenderObj.startAmPm}`);
    const endDates = new Date(`${this.formatDate(this.state.CalenderObj.endDate)} ${this.state.CalenderObj.endTime} ${this.state.CalenderObj.endAmPm}`);
    this.setState({ showModal: !this.state.showModal });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "Token": localStorage.getItem("token"),
    };

   
    const httpBody = {
      title: this.state.CalenderObj.description === "Others"?this.state.CalenderObj.other:this.state.CalenderObj.description,
      start_date: startDates,  
      end_date: endDates, 



    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEventCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventCalenderAPiEndPoint + this.state.maintainerId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  createJobOrder=()=>{
    this.props.navigation.navigate("CreateJobOrder") 
  }
  getEventRes = (maintainerId:string) => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEventApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventCalenderGetAPiEndPoint + maintainerId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getTechRes = (maintainerId:any) => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTechApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventTechGetAPiEndPoint + `${maintainerId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  setUnavailabilityBtn=()=>{
    this.setState({showModal:true})
  }
  closeModal=()=>{
    this.setState({showModal:false, EndShowCalendar:false, StartShowCalendar:false})
  }
  isDateDisabled = (date:any) => {
    // Calculate the current date
    const today = new Date();
    // Check if the date is in the past
    return date < today && !this.isSameDay(date, today);
  };
  isDateDisabled2 = (date:any) => {
    // Calculate the current date
    const today = this.state.CalenderObj.startDate;
    // Check if the date is in the past
    return date < today && !this.isSameDay(date, today);
  };
isSameDay = (date1:any, date2:any) => {
    return (
      date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
    );
  };
  handleDateChange = (date:any) => {
    this.setState({
      CalenderObj:{
        ...this.state.CalenderObj,
        startDate: date,
        endDate:date,
      },
      StartShowCalendar:false,
     
    })
   
  };
  handleDateEndDateChange = (date:any) => {
    this.setState({
      CalenderObj:{
        ...this.state.CalenderObj,
        endDate: date,
      },
      EndShowCalendar:false,
     
    })
  };
  handleTimeChange = (event:any) => {
    this.setState({
      CalenderObj:{
        ...this.state.CalenderObj,
        startTime: event.target.value,
        endTime:""
      },
      validationErrors:{
        ...this.state.validationErrors,
        startTime:"",
       }
    })
  };
  handleEndTimeChange = (event:any) => {
    this.setState({
      CalenderObj:{
        ...this.state.CalenderObj,
        endTime: event.target.value,
      },
      validationErrors:{
        ...this.state.validationErrors,
        endTime:"",
       }
    })
  };
  handleDesChange = (event:any) => {
    this.setState({
      CalenderObj:{
        ...this.state.CalenderObj,
        description: event.target.value,
        other:"",
      },
      validationErrors:{
        ...this.state.validationErrors,
        description:"",
       }
    })
  };
  handleDesOtherChange=(event:any)=>{
    this.setState({
      CalenderObj:{
        ...this.state.CalenderObj, other: event.target.value,
      },
      validationErrors:{
        ...this.state.validationErrors,
        other:"",
       }
    })
  }
  HandleStartAmPm =(value:any)=>{
    this.setState((prevState) => {
      let updatedState1 = {
        CalenderObj: {
          ...prevState.CalenderObj,startAmPm: value,
        },
      };
  
      if (prevState.CalenderObj.endTime && prevState.CalenderObj.endAmPm === value) {
        updatedState1.CalenderObj.startTime = ""; // Clear startTime if AM/PM doesn't match
      }
  
      return updatedState1;
    });
  }
  HandleEndAmPm =(value:any)=>{
    this.setState((prevState) => {
      let updatedState = {
        CalenderObj: {
          ...prevState.CalenderObj,
          endAmPm: value,
        },
      };
  
      if (prevState.CalenderObj.startTime && prevState.CalenderObj.startAmPm === value) {
       
        updatedState.CalenderObj.endTime = ""; // Clear endTime if AM/PM doesn't match
      }
  
      return updatedState;
    });
  }
  
   formatDate = (date:any) => {
    if (!date) return '';
    const dat = new Date(date)
    const year = dat.getFullYear();
    const month = `0${dat.getMonth() + 1}`.slice(-2);
    const day = `0${dat.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  endDateIcon = () =>{
    this.setState({ EndShowCalendar: !this.state.EndShowCalendar })
  }
  startDateIcon = () =>{
    
    this.setState({ StartShowCalendar: !this.state.StartShowCalendar })
  }

 getIntTime=(option:any)=>{
    const formatString = 'YYYY-MM-DD HH:mm:ss';
    const currentDate = new Date();
     let startDate = moment(`${this.formatDate(currentDate)} ${this.state.CalenderObj.startTime}:00`, formatString);
     let endDate = moment(`${this.formatDate(currentDate)} ${option}:00`, formatString);
    return (endDate <= startDate)
    }

    disableFunct=(optionKey:any)=>{
     return ( optionKey.value==="12:00"?true:this.getIntTime(optionKey.value)) && 
            this.state.CalenderObj.startAmPm === this.state.CalenderObj.endAmPm 
    }
    titleFunct=(ele:any)=>{
     return this.state.CalenderObj.startTime?"":ele
    }
  // Customizable Area End
}