import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Button,
  Grid,
  FormControl,
  Select,
} from "@material-ui/core";
import "../assets/style.css";
import * as Yup from 'yup';

let validationSchema: any = Yup.object().shape({
  assetType:Yup.string().required('Select At least One Value'),
  assetsIDs:Yup.string().required('Asset Id is required'),
  serialNo: Yup.string().required('Serial No is required'),
  locationId: Yup.string().required('Location Id is required'),
  manufacturingDate:Yup.string().required('Manufacturing Date is required'),
  expiryDate:Yup.string().required('Expiry Date is required'),
  lastMaintenanceDate:Yup.string().required('Last Maintenance Date is required'),
  lastRechargeDate:Yup.string().required('Last Recharge Date is required'),
  maintenanceDuration:Yup.string().required('Maintenance Duration is required'),
  maintenanceFrq:Yup.string().required('Select At least One Value'),
  price:Yup.string().required('Price is required'),

});
// Customizable Area End

import { Formik } from "formik";

import CreateAssetController, {
  configJSON,
  Props
} from "./CreateAssetController.web";
import Layout from "../../landingpage/src/Layout.web";
export default class CreateAsset extends CreateAssetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout
        navigation={this.props.navigation}> 
      <Box sx={webStyles.assetBox}>
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Box
                      className="locationFormBox"
                      style={webStyles.assetFormBox}
                    >
                      
                      <Formik
                      data-test-id="formikForm"
                      enableReinitialize={true}
                        initialValues={
                          this.state.assetDataObj
                       
                      }
                        validationSchema={validationSchema}
                        onSubmit={
                          this.handleSubmit
                        }

                      >
                        {({ values,
                          errors,
                          setFieldTouched,
                          setFieldValue,
                          touched, handleSubmit, resetForm, setErrors, setSubmitting }) => (
                          <form
                            autoComplete="off"
                            data-test-id={"btnPost"}
                            onSubmit={
                              handleSubmit
                            }
                            style={webStyles.marginTop15asset}
                          >
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                      {configJSON.assetType}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.assetDataObj.assetType}
                                        onChange={(event: any) => {
                                          setFieldValue("assetType", event.target.value)
                                          this.setState({
                                            assetDataObj: {
                                              ...this.state.assetDataObj,
                                              assetType: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="textAsset"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.assetType}

                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                        
                                      >
                                        {
                                         this.state.allAssetTypeDrop.map((item:any)=>(
                                          <option value={item.attributes.name}> {item.attributes.name}</option>
                                         ))
                                       }
                                      </Select>
                                     
                                    </FormControl>
                                    <FieldError
                                error={errors.assetType}
                                touched={touched.assetType}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.assetsIDs}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth

                                data-test-id={"txtInputassetsIDs"}
                                name={"assetsIDs"}
                                placeholder={configJSON.assetsIDs}
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                value={this.state.assetDataObj.assetsIDs}
                                onChange={(event: any) => {
                                  setFieldValue("assetsIDs", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      assetsIDs: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                              data-test-id="fieldError"
                                error={errors.assetsIDs}
                                touched={touched.assetsIDs}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.serialNo}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth

                                data-test-id={"txtInputSerial"}
                                name={"serialNo"}
                                placeholder={configJSON.serialNo}
                                variant="outlined"
                                inputProps={{ maxLength: 20 }}
                                value={this.state.assetDataObj.serialNo}
                                onChange={(event: any) => {
                                  setFieldValue("serialNo", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      serialNo: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.serialNo}
                                touched={touched.serialNo}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.locationId}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                name={"locationId"}
                                value={this.state.assetDataObj.locationId}
                               
                                onChange={(event: any) => {
                                  setFieldValue("locationId", event.target.value)
                                  
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      locationId: event.target.value,
                                    }
                                  })
                                }}

                                data-test-id="textlocationId"
                                placeholder={configJSON.locationId}
                                variant="outlined"
                              />
                              <FieldError
                                error={errors.locationId}
                                touched={touched.locationId}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.floorNo}
                              </p>
                              <TextField
                              name={"floorNo"}
                                fullWidth
                                data-test-id="textInputFloor"
                                placeholder={configJSON.floorNo}
                                variant="outlined"
                                value={this.state.assetDataObj.floorNo}
                                onChange={(event: any) => {
                                  setFieldValue("floorNo", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      floorNo: event.target.value,
                                    },
                                  })
                                }}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.locationNo}
                              </p>
                              <TextField
                              name={"locationNo"}
                                fullWidth
                                data-test-id="textLocationNo"
                                placeholder={configJSON.locationNo}
                                variant="outlined"
                                value={this.state.assetDataObj.locationNo}
                                onChange={(event: any) => {
                                  setFieldValue("locationNo", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      locationNo: event.target.value, 
                                    }
                                  })
                                }}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.manufacturingDate}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                              name={"manufacturingDate"}
                              type="date"
                                fullWidth
                                data-test-id="textInputPManufacturing"
                                placeholder={configJSON.manufacturingDate}
                                variant="outlined"
                                value={this.state.assetDataObj.manufacturingDate}
                                onChange={(event: any) => {
                                  setFieldValue("manufacturingDate", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      manufacturingDate: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.manufacturingDate}
                                touched={touched.manufacturingDate}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.expiryDate}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                               name={"expiryDate"}
                                fullWidth
                                type="date"
                                data-test-id="textInputExpiry"
                                placeholder={configJSON.expiryDate}
                                variant="outlined"
                                value={this.state.assetDataObj.expiryDate}
                                onChange={(event: any) => {
                                  setFieldValue("expiryDate", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      expiryDate: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.expiryDate}
                                touched={touched.expiryDate}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.lastMaintenanceDate}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                               name={"lastMaintenanceDate"}
                                fullWidth
                                type="date"
                                data-test-id="textInputLastMaintenance"
                                placeholder={configJSON.lastMaintenanceDate}
                                variant="outlined"
                                value={this.state.assetDataObj.lastMaintenanceDate}
                                onChange={(event: any) => {
                                  setFieldValue("lastMaintenanceDate", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      lastMaintenanceDate: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.lastMaintenanceDate}
                                touched={touched.lastMaintenanceDate}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                      {configJSON.maintenanceFrq}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                                    </p>
                                    <FormControl className="textFormControl11" fullWidth variant="outlined">
                                    <Select
                                        value={this.state.assetDataObj.maintenanceFrq}
                                        onChange={(event: any) => {
                                          setFieldValue("maintenanceFrq", event.target.value)
                                          this.setState({
                                            assetDataObj: {
                                              ...this.state.assetDataObj,
                                              maintenanceFrq: event.target.value
                                            },

                                          })
                                        }}
                                        fullWidth
                                        data-test-id="maintenanceFrq"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={configJSON.maintenanceFrq}
 
                                        inputProps={{
                                          className: "MuiOutlinedInput-select",
                                          sx: {
                                            color: "#84989F",
                                            borderRadius: "5px",
                                            backgroundColor: "#fff"
                                          },
                                        }}
                                      >
                                        <option value={"Monthly"}> Monthly</option>
                                        <option value={"Quarterly"}>Quarterly</option>
                                        <option value={"Twice a year"}>Twice a year</option>
                                        <option value={"Yearly"}>Yearly</option>
                                        <option value={"Every 2 years"}>Every 2 years</option>
                                        <option value={"Every 3 years"}>Every 3 years</option>
                                        <option value={"Every 5 years"}>Every 5 years</option>
                                      </Select>
                                     
                                    </FormControl>
                                    <FieldError
                                error={errors.maintenanceFrq}
                                touched={touched.maintenanceFrq}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.lastRechargeDate}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                type="date"
                                data-test-id="textLastRecharge"
                                placeholder={configJSON.lastRechargeDate}
                                variant="outlined"
                                value={this.state.assetDataObj.lastRechargeDate}
                                onChange={(event: any) => {
                                  setFieldValue("lastRechargeDate", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      lastRechargeDate: event.target.value,
                                    }
                                  })
                                }}
                              />
                               <FieldError
                                error={errors.lastRechargeDate}
                                touched={touched.lastRechargeDate}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.assetQty}
                              </p>
                              <TextField
                                fullWidth
                                name={"assetQty"}
                                type="number"
                                data-test-id="textAssetQty"
                                placeholder={configJSON.assetQty}
                                variant="outlined"
                                value={this.state.assetDataObj.assetQty}
                                onChange={(event: any) => {
                                  setFieldValue("assetQty", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      assetQty: event.target.value,
                                    }
                                  })
                                }}
                              />
                             
                            </Box>

                           
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.discountAmt}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textdiscountAmt"
                                placeholder={configJSON.discountAmt}
                                variant="outlined"
                                value={this.state.assetDataObj.discountAmt}
                                onChange={(event: any) => {
                                  setFieldValue("discountAmt", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      discountAmt: event.target.value,
                                    }
                                  })
                                }}
                              />
                            
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.maintenanceDuration}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                type="number"
                                data-test-id="textInputDuration"
                                placeholder={configJSON.maintenanceDuration}
                                variant="outlined"
                                value={this.state.assetDataObj.maintenanceDuration}
                                onChange={(event: any) => {
                                  setFieldValue("maintenanceDuration", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      maintenanceDuration: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.maintenanceDuration}
                                touched={touched.maintenanceDuration}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.price}{" "}{<span style={{color:"rgb(215 31 31 / 96%)"}}>*</span>}
                              </p>
                              <TextField
                                fullWidth
                                data-test-id="textInputPrice"
                                placeholder={configJSON.price}
                                variant="outlined"
                                value={this.state.assetDataObj.price}
                                onChange={(event: any) => {
                                  setFieldValue("price", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      price: event.target.value,
                                    }
                                  })
                                }}
                              />
                              <FieldError
                                error={errors.price}
                                touched={touched.price}
                              />
                            </Box>
                            <Box sx={webStyles.assetInputBox}>
                              <p style={webStyles.assetInputBoxLabel}>
                                {configJSON.comments}
                              </p>
                              <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                data-test-id="textInputComments"
                                placeholder={"Type somethings.."}
                                variant="outlined"
                                value={this.state.assetDataObj.comments}
                                onChange={(event: any) => {
                                  setFieldValue("comments", event.target.value)
                                  this.setState({
                                    assetDataObj: {
                                      ...this.state.assetDataObj,
                                      comments: event.target.value,
                                    }
                                  })
                                }}
                                
                              />
                             
                            </Box>
                            <Box
                              sx={{
                                ...webStyles.assetInputBox,
                                ...webStyles.assetBoxButton
                              }}
                            >
                              <Button
                                id="btnExample"
                                className="fontText"
                                onClick={this.backToViewAllClientbtn}
                                variant="contained"
                                color="primary"
                                style={webStyles.assetBtnWhite}
                              >
                                Back To List
                              </Button>
                              <Button
                              className="fontText"
                                data-test-id={"textBtnSubmit"}
                                color="primary"
                                style={webStyles.assetBtnRed}
                                type="submit"

                              >
                                {this.state.assetDataObj.assetId?"Edit":"Save"}
                              </Button>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3} />

                </Grid>
              </Box>
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {

  marginTop15asset: {
    marginTop: "15px"
  },
  assetBox: {
    minHeight: "100vh",
    width: "100%",
  },


  assetFormBox: {
    borderRadius: "10px",
    padding: "15px",
    background: "#fff",
    marginBottom: "20px"
  },

  assetInputBox: {
    margin: "10px 0"
  },
  assetInputBoxLabel: {
    margin: "5px 0",
    display: "block",
    fontWeight:600
  },
  assetBtnWhite: {
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  assetBtnRed: {

    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "10px 20px",
    border: "none",
    margin: "15px 10px 10px 0px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
  assetBoxButton: {
    textTransform: "capitalize",
    marginTop: "20px",
    borderTop: "1px #bbb8b8 solid",
    justifyContent:"end",
    display:"flex"
  }
};

// @ts-ignore
export function FieldError({ error, touched }) {
  return error && touched ? (
    <div style={{ color: "#e65e52" }}>{error}</div>
  ) : null;
}
// Customizable Area End
