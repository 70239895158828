import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import "../assets/style.css"
//@ts-ignore
import Printer, { print } from "react-pdf-print";
//@ts-ignore
import Pdf from "react-to-pdf";

const ref = React.createRef();

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SaveAsPdfController, { Props, configJSON } from "./SaveAsPdfController";

export default class SaveAsPdf extends SaveAsPdfController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <Container ref={ref} maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>
            <Pdf targetRef={ref} filename="code-example.pdf">
              {/* @ts-ignore */}
              {({ toPdf }) => (
                <Box
                  data-test-id="btnAddExample"
                  onClick={toPdf}
                  component="button"
                  sx={webStyle.buttonStyle}
                >
                  <Button color={"primary"}>
                    {configJSON.btnExampleTitle}
                  </Button>
                </Box>
              )}
            </Pdf>
            <Box>
            <table   >
                        <tbody>
                            <tr>
                                <td>
                                    <table   className="width100 mb121">
                                        <tbody>
                                            <tr>
                                                <td  className="widthTD40">
                                                <img src="https://assets-global.website-files.com/650abf08a23a0f8ea5cca8c3/650ac0d2ed3778abead76c6a_logoblack.svg" /><br />
                                                </td>
                                                <td  className="widthTD40">
                                                   <div className="textRight displayBlock">
                                                    <img src="https://i.imgur.com/1q5LdTt.png" />
                                                    <span className="invoceTemp upperText">INVOICE</span>
                                                   </div>
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                    <table   className="width100 mb121">
                                        <tbody>
                                            <tr>
                                                <td  className="widthTD40">
                                                    <div className="displayBlock">
                                                        <span className="upperText">BILL TO</span><br/>
                                                        <span className="spanBoldSize">Synergy Pvt. Ltd</span><br/>
                                                        <span>#323, Los Ave, Los Luna, P.O Box-1392, New Mexico</span><br/>
                                                        <span>Ph No.: <span className="spanBoldSize">567654-333</span></span><br />
                                                        <span>Fiscal ID: <span>567654</span></span>
                                                    </div>
                                                   
                                                </td>
                                                <td  className="widthTD40">

                                                </td>
                                                <td  className="widthTD40">
                                                   <div className="displayBlock">
                                                    
                                                    <span>Invoice No:<span className="redText">#OD039</span></span><br />
                                                    <span>Invoice Date: 23/12/2023</span>
                                                    
                                                   </div>
                                                   <div className="displayBlock">
                                                    <h3>Invoice Contact Details</h3>
                                                    <ul className="ullistTemp">
                                                        <li> <img className="imgIconWidth" src="https://i.imgur.com/70de8r4.png"/> <span>+123-455-678</span></li>
                                                        <li> <img className="imgIconWidth" src="https://i.imgur.com/4koQatj.png"/> <span>info@cofrai.com</span></li>
                                                      </ul>
                                                   </div>
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                    <table    className="width100 mb121">
                                        <tbody>
                                            <tr className="bgRedop">
                                                <th className=" padding20"><span>JO ID</span></th>
                                                <th className=" padding20"><span>Description</span></th>
                                                <th className=" padding20"><span>Units</span></th>
                                                <th className=" padding20"><span>Price</span></th>
                                                <th className=" padding20"><span>Discounts</span></th>
                                                <th className=" padding20"><span>Total</span></th>
                                            </tr>
                                            <tr>
                                                <td className="padding10"><span>01</span></td>
                                                <td className="padding10"><span>lorem lipsom</span></td>
                                                <td className="padding10"><span>01</span></td>
                                                <td className="padding10"><span>280.80</span></td>
                                                <td className="padding10"><span>5.00</span></td>
                                                <td className="padding10"><span>280.80</span></td>
                                            </tr>
                                            <tr>
                                                <td className="padding10"><span>01</span></td>
                                                <td className="padding10"><span>lorem lipsom</span></td>
                                                <td className="padding10"><span>01</span></td>
                                                <td className="padding10"><span>280.80</span></td>
                                                <td className="padding10"><span>5.00</span></td>
                                                <td className="padding10"><span>280.80</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table    className="width100 marginTop20px">
                                        <tbody>
                                            <tr>
                                                <td >
                                                    <p className="borderCssbottom1">
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                  </table>
                                    <table className="width100 marginTop20px">
                                          <tbody>
                                              <tr>
                                                  <td className="borderCornerTd">
                                                      <table    className="mb121">
                                        <tbody>
                                            <tr className="">
                                                <th className="textLeftCss padding10"><span>PAYMENT INFO</span></th>
                                            </tr>
                                            <tr>
                                                <td className="padding10"><span>Bank Name:</span></td>

                                                <td className="padding10"><span>Bank of spain</span></td>
                                            </tr>
                                            <tr>
                                                <td className="padding10"><span>Acc No:</span></td>
                                                <td className="padding10"><span>54564345</span></td>
                                            </tr>
                                            <tr>
                                                <td className="padding10"><span>Total Amt:</span></td>
                                                <td className="padding10"><span>567</span></td>
                                            </tr>
                                            <tr>
                                                <td className="padding10"><span>Paid Date:</span></td>
                                                <td className="padding10"><span>12/12/2023</span></td>
                                            </tr>
                                            <tr>
                                                <td className="padding10"><span>Payment Terms:<br/>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                                </span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   
                                                  </td>
                                                  <td className="dflexTD">
                                                    <table    className="width100 mb121">
                                                        <tbody>
                                                            <tr className="textBlak bgRedop">
                                                                <th className=" padding20"><span>Total Net</span></th>
                                                                <th className=" padding20"><span>VAT in %</span></th>
                                                                <th className=" padding20"><span>VAT in �</span><br /><span className="tewxtMin">
                                                                    Total Net x VAt in %
                                                                </span></th>
                                                                <th className=" padding20"><span>Total</span><br /><span className="tewxtMin">
                                                                    Total Net + VAt in %
                                                                </span></th>
                                                            </tr>
                                                            
                                                            <tr>
                                                                <td className="padding10"><span>01</span></td>
                                                                <td className="padding10"><span>34</span></td>
                                                                <td className="padding10"><span>34</span></td>
                                                                <td className="padding10"><span>34</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="padding10"><span>01</span></td>
                                                                <td className="padding10"><span>34</span></td>
                                                                <td className="padding10"><span>34</span></td>
                                                                <td className="padding10"><span>34</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                  </td>
                                              </tr>
                                          </tbody>
                                    </table>
                                    <table    className="width100 mb121">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="widthTD40per">
                                                        <h4 className="iexttdcolor">JOB ORDER NOTES</h4>
                                                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</span>
                                                    </div>
                                                   </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                        <table    className="width100 mb121">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="marginTopBottom50">
                                                           </div>
                                                       </td>
                                                
                                                </tr>
                                                    </tbody>
                                            </table>
                                    
                                    <table    className="width100      marginTop20px">
                                          <tbody>
                                              <tr>
                                                  <td >
                                                      <p className="borderCssbottom">
                                                      </p>


                                                  </td>
                                              </tr>

                                          </tbody>

                                    </table>
                               
                                </td>
                            
                            </tr>
                                         </tbody>
                    </table>
            </Box>
          </Box>
          
        </Container>
        <div className="parentDiv">
      <div className="div850">
          <div>
              <table  >
                  <tbody>
                      <tr>
                          <td>
                              <table  className="width100 mb121">
                                  <tbody>
                                      <tr>
                                          <td  className="widthTD40">
                                          <img src="https://assets-global.website-files.com/650abf08a23a0f8ea5cca8c3/650ac0d2ed3778abead76c6a_logoblack.svg" /><br />
                                          </td>
                                          <td  className="widthTD40">
                                             <div className="textRight displayBlock">
                                              
                                              <span className="spanBoldSize">CERTIFICATE:<span className="redText">#OD00000039</span></span><br />
                                              <span>"Preventive Maintenance" + Vertical</span>
                                             </div>
                                          </td>
                                      </tr>
                                     
                                  </tbody>
                              </table>
                              <table className="width100">
                                  <tbody>
                                      <tr>
                                          <td  className="bgRedop">
                                              <span className="spanBoldSize">CERTIFICATE</span>
                                          </td>
                                          <td >
                                            <table   className="width100">
                                                <tbody>
                                                    <tr>
                                                        <td  className="widthTD40">
                                                            <span className="textBoldSpan">Certificate No:</span>
                                                            <br/>
                                                            <span className="textNormalSpan fontSize14">Date of Certification:</span>
                                                            <br/>
                                                            <span className="textNormalSpan fontSize14">Job Order Type</span>
                                                            <br/>
                                                            </td>
                                                        <td  className="widthTD40">
                                                        <span className="textBoldSpan">#000000</span>
                                                        <br />
                                                        <span className="textNormalSpan fontSize14">23\12\2023</span>
                                                        <br />
                                                        <span className="textNormalSpan fontSize14">Lorem Sum</span>
                                                        <br />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td  className="bgRedop">
                                              <span className="spanBoldSize">MAINTENANCE COMPANY</span>
                                          </td>
                                          <td >
                                            <table   className="width100">
                                                <tbody>
                                                    <tr>
                                                        <td  className="widthTD40">
                                                            <span className="textBoldSpan">COFRAI Pvt Ltd</span>
                                                            <br/>
                                                            <span className="textNormalSpan fontSize14">#323, Los Ave, Los Luna,
P.O Box-1392, New Mexico</span>
                                                            <br/>
                                                            </td>
                                                        <td  className="widthTD40">
                                                        <span className="textNormalSpan fontSize14">Phone No:</span>
                                                        <br />
                                                        <span className="textNormalSpan fontSize14">Mail ID:</span>
                                                        <br />
                                                        <span className="textNormalSpan fontSize14">Website:</span>
                                                        <br />
                                                        </td>
                                                        <td  className="widthTD40">
                                                        <span className="textBoldSpan">7767676543</span>
                                                        <br />
                                                        <span className="textBoldSpan">dummy@gmail.com</span>
                                                        <br />
                                                        <span className="textBoldSpan">cofrai.com</span>
                                                        <br />
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr>
                                                    <td >
                                            <span className="fontSize14">Empresa Instaladore Maintenance slestams PCI: <span>NR444/58104</span></span>
                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td  className="bgRedop">
                                              <span className="spanBoldSize">TECHNICIAN</span>
                                          </td>
                                          <td >
                                            <span className="textBoldSpan">Nitish Arora</span><br/>
                                            <span className="textNormalSpan fontSize14">Lorem Sum</span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td  className="bgRedop">
                                              <span className="spanBoldSize">CUSTOMER DETAILS</span>
                                          </td>
                                          <td >
                                            <span className="textBoldSpan">Cameron Roin</span><br/>
                                            <span className="textNormalSpan fontSize14">#323, Los Ave, Los Luna,
P.O Box-1392, New Mexico</span>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td  className="bgRedop">
                                              <span className="spanBoldSize">LOCATION DETAILS</span>
                                          </td>
                                          <td >
                                            <span className="textBoldSpan">John Haroid</span><br/>
                                            <span className="textNormalSpan fontSize14">Valencia</span><br />
                                            <span className="textNormalSpan fontSize14">#323, Los Ave, Los Luna,
P.O Box-1392, New Mexico</span>
                                          </td>
                                      </tr>
                                      
                                  </tbody>
                              </table>
                              <table  className="width100">
                                  <tbody>
                                      <tr className="fontSize14">
                                          <td  className="bgRedop textBoldSpan">ASSET TYPE</td>
                                          <td  >
                                              <table  className="width100">
                                                    <tbody>
                                                        <tr>
                                                            <td  className="padding10 textCenter"> <span className="textBoldSpan">ASSETS</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="padding10 fontSize12px"> <span className="textBoldSpan fontSize12pxt">Total no. of Asset Reviewed</span></td>
                                                            <td className="padding10 fontSize12px"> <span className="textBoldSpan fontSize12pxt">Assets MainTained with No Anamolies</span></td>
                                                            <td className="padding10 fontSize12px"> <span className="textBoldSpan fontSize12pxt">Incorrect</span></td>
                                                            <td className="padding10 fontSize12px"> <span className="textBoldSpan fontSize12pxt">Incorrectos Sciuclnodes</span></td>
                                                            <td className="padding10 fontSize12px"> <span className="textBoldSpan fontSize12pxt">Incorrectos Sciuclnodes</span></td>
                                                        </tr>
                                                    </tbody>
                                              </table>
                                          </td>
                                          <td  className="bgRedop textBoldSpan">PENDING ANAMOLIES</td>
                                      </tr>
                                      <tr>
                                      <td  className="textBoldSpan textCenter">Fire Protection</td>
                                      <td className="bgRedop textBoldSpan">0</td>
                                      <td className="bgRedop textBoldSpan">0</td>
                                      <td className="bgRedop textBoldSpan">0</td>
                                      <td className="bgRedop textBoldSpan">0</td>
                                      <td className="bgRedop textBoldSpan">0</td>
                                      <td  className="bgRedop textBoldSpan">0</td>
                                      </tr>
                                  </tbody>
                              </table>
                              <table   className="width100">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h3 className="marginTop20px">COFRAI CERTIFICATE</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                     Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                      when an unknown printer took a galley of type and scrambled it to make a type 
                                                      specimen book. It has survived not only five centuries, but also the leap 
                                                      into electronic typesetting, remaining essentially unchanged.
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                              <table   className="width100 marginTop20px">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </p>
                                            </td>
                                            <td >
                                                <p className="textBoldSpan textRight">21st sept 2023
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                              <table   className="width100 marginTop20px">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <p className="borderCssbottom">
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                              <table   className="width100 marginTop20px">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <h3>
                                                    User Name <br />
                                                    Cofrai Pvt Ltd
                                                </h3>
                                            </td>
                                            <td >
                                            <h3 className="textRight">
                                                   Customer Name
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>

                              <table   className="width100 marginTop20px">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </td>
                                            <td >
                                            <h3 className="textRight">
                                                   Date: ____________
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                              <table   className="width100 marginTop20px">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <p className="borderCssbottom">
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                              <table   className="width100 marginTop20px">
                                    <tbody>
                                        <tr>
                                            <td  className="textCenter">
                                               <img src="https://i.imgur.com/8TMoQF1.png"/>
                                            </td>
                                            <td >
                                              <ul className="ullistTemp">
                                                <li> <img className="imgIconWidth" src="https://i.imgur.com/70de8r4.png"/> <span>+123-455-678</span></li>
                                                <li> <img className="imgIconWidth" src="https://i.imgur.com/4koQatj.png"/> <span>info@cofrai.com</span></li>
                                                <li> <img className="imgIconWidth" src="https://i.imgur.com/3YuBMgt.png"/> <span>www.cofrai.com</span></li>
                                              </ul>
                                            </td>
                                            <td >
                                             <p>#323, Los Ave, Los Luna, P.O Box-1392, New Mexico</p>
                                             <p>Fiscal ID: <span>#FS123</span></p>
                                            </td>
                                            <td >
                                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                              <table   className="width100 marginTop20px">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <p className="borderCssbottom">
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <table>
                
                        <tbody>
                            <tr>
                                <td>
                                    <table  className="width100  mb121">
                                        <tbody>
                                            <tr>
                                                <td    className="widthTD40">
                                                <img src="https://assets-global.website-files.com/650abf08a23a0f8ea5cca8c3/650ac0d2ed3778abead76c6a_logoblack.svg" /><br />
                                                </td>
                                                <td    className="widthTD40 ">
                                                   <div className="textRight displayBlock">
                                                    <img src="https://i.imgur.com/1q5LdTt.png" />
                                                    <span className="invoceTemp">INVOICE</span>
                                                   </div>
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
              </table>
          </div>
      </div>
  </div>
      </ThemeProvider>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
