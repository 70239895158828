import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  InputAdornment,
  Grid,
  Tooltip,
  TextField,
  Menu,
  Avatar,
  MenuItem,
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemText,
  Collapse,
  List,
} from "@material-ui/core";
import {MenuGrayIcon } from "./assets";
import { styled, withStyles } from "@material-ui/core/styles";
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
const ref = React.createRef();
const vertical = [
  {"name": "Fire Protection", "key": "Fire Protection"},
  {"name": "Security & Alarms", "key": "Security & Alarms"},
  {"name": "Climate Control (HVAC)", "key": "HVAC"},
  {"name": "Medium/High Voltage electricity", "key": "Medium/High Voltage electricity"},
  {"name": "Lifts & Elevators", "key": "Lifts & Elevators"},
  {"name": "Pest Control", "key": "Pest Control"},
  {"name": "Defibrillators", "key": "Defibrillators"},
  {"name": "Shipping Companies", "key": "Shipping Companies"},
  {"name": "Industrial Waste", "key": "Industrial Waste"},
  {"name": "Water Pumps", "key": "Water Pumps"},
  {"name": "Glasswork", "key": "Glasswork"},
  {"name": "Medical equipment", "key": "Medical equipment"},
  {"name": "EV chargers", "key": "EV chargers"},
  {"name": "Solar panels", "key": "Solar panels"},
  {"name": "Landscaping/gardening", "key": "Landscaping/gardening"},
  {"name": "Building Multiservices", "key": "Building Multiservices"},
  {"name": "Low voltage electricity", "key": "Low voltage electricity"},
  {"name": "Swimming pools", "key": "Swimming pools"},
  {"name": "Locksmithing", "key": "Locksmithing"},
  {"name": "Plumbing", "key": "Plumbing"},
  {"name": "Office water & sundry distribution", "key": "Office water & sundry distribution"},
  {"name": "Roofing", "key": "Roofing"},
  {"name": "Garage Doors", "key": "Garage Doors"},
  {"name": "Chimney sweeps", "key": "Chimney sweeps"},
  {"name": "Sewage/septic tanks", "key": "Sewage/septic tanks"},
  {"name": "Home care", "key": "Home care"},
  {"name": "Vending machines", "key": "Vending machines"},
  {"name": "Catering delivery", "key": "Catering delivery"},
  {"name": "Carpet cleaning", "key": "Carpet cleaning"},
  {"name": "Document destruction", "key": "Document destruction"},
  {"name": "Medical waste disposal", "key": "Medical waste disposal"},
  {"name": "Pressure washing", "key": "Pressure washing"},
  {"name": "Oil & Gas", "key": "Oil & Gas"},
  {"name": "Energy & Utilities", "key": "Energy & Utilities"}
]

export const CustomTooltip = withStyles({
    tooltip: {
      backgroundColor: "#fff",
      "& .title": {
        color: "#000",
        fontWeight: "bold",
        fontSize: 14,
        marginBottom: 2,
      },
      boxShadow: "4px 4px 4px 4px #0000001A",
      width: "auto",
      padding: "8px 16px",
      "& .vertical": {
        color: "#5183d9",
        backgroundColor: "#d3e3fa",
        borderRadius: 24,
        fontSize: 12,
        fontWeight: "bold",
        width: "max-content",
        padding: "4px 8px",
        marginTop: 6,
      },
      "& .address": { color: "#000", marginTop: 8, fontSize: 12 },
     
      "& .job": {
        marginTop: 6,
        borderRadius: 24,
        fontSize: 12,
        color: "#fff",
        backgroundColor: "#5183d9",
        width: "max-content",
        padding: "8px 20px",
      },
    },
  })(Tooltip);
  
  
  
  const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
      color: "#777",
      borderRadius: "8px",
      width: 'auto',
      height: '2.45rem'
    },
  });
  import JobOrderListController, { Props } from "./JobOrderListController";
  import Layout from "../../landingpage/src/Layout.web";
// Customizable Area End



export default class JobOrderList extends JobOrderListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout navigation={""}>
          <div style={styles.jobContainer}>
            <div style={employeeStyles.jobContainerMain}>
              <Grid
                container direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >

                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={4} md={7}>
                    <Typography style={employeeStyles.avatarDivTitleJob}>Job Orders</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} style={{textAlign:"right"}}>
                        <Button style={employeeStyles.cnempBtnJob} data-test-id="handleNavigateJob"
                        onClick={this.handleNavigateJob}
                        >Create Job Order</Button>
                      
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <div
                      style={{
                        ...styles.searchbarHeaderJob,
                        ...styles.containerSearchJob,
                      }}
                    >
                      <div style={styles.searchEmpDivJob}>
                        <CustomTextField
                          fullWidth
                          data-test-id="searchData"
                          variant="outlined"
                          placeholder="Search"
                          onChange={this.handleSearchJobOrder}
                          value={this.state.searchItem}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={4} md={1} style={{ maxWidth: '7.3%' }}>
                  <Button
                        data-test-id="btnClickEnable"
                      type="button"
                        onClick={this.handleClick}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                      >
                        <FilterListIcon />
                      </Button>
                      <Menu
                       marginThreshold={72}
                       transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left'
                       }}
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        
                        open={Boolean(this.state.anchorEl)}
                        data-test-id="handleClose"
                        onClose={this.handleClose}
                        keepMounted
                       
                      >
                              <ListItem style={{ width: '350px' }} data-test-id="MunipalityToggle" onClick={this.handleToggleClick}>
                              <ListItemText primary="Munipality" />
                              {this.state.openMunsipality ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.openMunsipality} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                <ListItem>
                                <div style={styles.searchEmpDivJob}>
                                              <CustomTextField
                                                fullWidth
                                                data-test-id="Munipality"
                                                variant="outlined"
                                                placeholder="Munipality"
                                                value={this.state.filterParam.munciplity}
                                                onChange={this.onMuncipalityChange}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <SearchIcon />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            </div>
                                </ListItem>
                              </List>
                            </Collapse>
                            <ListItem style={{ width: '350px' }} data-test-id="postalCodeToggle"  onClick={this.handleToggleClickPostCode}>
                              <ListItemText primary="Postal code" />
                              {this.state.openPostCode ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.openPostCode} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                <ListItem>
                                <div style={styles.searchEmpDivJob}>
                                              <CustomTextField
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Postal code"
                                                data-test-id="postalCode"
                                                value={this.state.filterParam.postcode}
                                                onChange={this.onPostalCodeChange}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <SearchIcon />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            </div>
                                </ListItem>
                              </List>
                            </Collapse>
                            <ListItem style={{ width: '350px' }} data-test-id="technicianToggle"  onClick={this.handleToggleClickTech}>
                              <ListItemText primary="Technician" />
                              {this.state.openTechnician ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.openTechnician} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                <ListItem>
                                <div style={styles.searchEmpDivJob}>
                                              <CustomTextField
                                                fullWidth
                                                data-test-id="technician"
                                                variant="outlined"
                                                placeholder="Technician"
                                                value={this.state.filterParam.first_name}
                                                onChange={this.onTechnicianChange}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <SearchIcon />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            </div>
                                </ListItem>
                              </List>
                            </Collapse>

                            <ListItem style={{ width: '350px' }} data-test-id="verticalToggle" onClick={this.handleToggleClickVertical}>
                              <ListItemText primary="Vertical" />
                              {this.state.openVertical ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.openVertical} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                              {vertical.map(({name, key}, idx) => (
                                              <MenuItem key={key+idx}>
                                                <FormControlLabel
                                                 data-test-id={`handleCheckedFilter${idx}`}
                                                  control={
                                                    <Checkbox
                                                    data-test-id="vertical"
                                                      checked={this.state.filterArr.includes(key)}
                                                      onChange={(e) => {
                                                        this.handleCheckedFilter(e, key)
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    <Typography style={{ padding: '8px', width: "15rem" }}>
                                                      {name}
                                                    </Typography>
                                                  }
                                                />
                                              </MenuItem>
                                            ))}
                              </List>
                            </Collapse>
                            <ListItem style={{ width: '350px' }}  data-test-id="jobTypeToggle" onClick={this.handleToggleClickJobType}>
                              <ListItemText primary="Job Type" />
                              {this.state.openJobType ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.openJobType} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                              {[
                                    { name: 'Install', key: 'Install' },
                                    { name: 'Repair', key: 'Repair' },
                                    { name: 'Substitute', key: 'Substitute' },
                                    { name: 'Commissioning', key: 'Commissioning' },
                                    { name: 'Call-out', key: 'Call-out' },
                                    { name: 'Quarterly Maintenance', key: 'Quarterly_Maintenance' },
                                    { name: 'Yearly Maintenance', key: 'Yearly_Maintenance' },
                                    { name: 'Custom Maintenance', key: 'Custom_Maintenance' },
                                    { name: 'Recharge', key: 'Recharge' },
                                    { name: 'Others', key: 'Others' }
                                  ].map(({name, key}, idx) => (
                                              <MenuItem key={key+idx}>
                                                <FormControlLabel
                                                data-test-id={`handleCheckedFilterJobType${idx}`}
                                                  control={
                                                    <Checkbox
                                                      checked={this.state.filterArrJobType.includes(key)}
                                                      onChange={(e) => {
                                                        this.handleCheckedFilterJobType(e, key)
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    <Typography style={{ padding: '8px', width: "15rem" }}>
                                                      {name}
                                                    </Typography>
                                                  }
                                                />
                                              </MenuItem>
                                            ))}
                              </List>
                            </Collapse>

                            <ListItem style={{ width: '350px' }} data-test-id="completionToggle" onClick={this.handleToggleClickCompletion}>
                              <ListItemText primary="Completion Status" />
                              {this.state.openCompletion ? <ExpandLess data-test-id="openCompletion" /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.openCompletion}  timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                              {[
                                              {name: "Complete", key: "assigned"},
                                              {name: "Not Complete", key: "un_assigned"},
                                            ].map(({name, key}, idx) => (
                                              <MenuItem key={key+idx}  >
                                                <FormControlLabel
                                                 data-test-id={`handleCheckedFilterCompletion${idx}`}
                                                  control={
                                                    <Checkbox
                                                   
                                                      checked={this.state.filterArrcompletion.includes(key)}
                                                      onChange={(e) => {
                                                        this.handleCheckedFilterCompletion(e, key)
                                                      }}
                                                    />
                                                  }
                                                  label={
                                                    <Typography style={{ padding: '8px', width: "15rem" }}>
                                                      {name}
                                                    </Typography>
                                                  }
                                                />
                                              </MenuItem>
                                            ))}
                              </List>
                            </Collapse>
                            <ListItem data-test-id="handleClearFilters"
                                              onClick={ this.handleClearFilters}
                                            >
                                              <Typography
                                                style={{
                                                  padding: '8px', alignItems: 'center',
                                                  justifyContent: 'center', display: 'flex',
                                                  cursor: 'pointer', width:"100%", whiteSpace: 'pre-line', fontWeight: 'bold', color: "rgb(213, 53, 53)",
                                                }}
                                              >Clear Filters</Typography>
                                            </ListItem>
                      </Menu>

                  </Grid>

                </Grid>


              </Grid>

            </div>
            {this.state.errorMsg?<>
              <div className="containerSub">
  <div className="contentSub">
    <h2>Your Job Order History is Empty</h2>
    <p>It looks like you haven't received any job orders yet. Once you do, they'll appear here for you to track and manage.</p>
  </div>
</div>
            </>:<>
            <div style={styles.jobContainer}>
              <div
                style={styles.titleStyleJob}
              >{`Assigned Job Orders `}</div>
              
            <div style={styles.taskContainerJob}>
              <div>
                {this.state.openTaskListAssigned.map(
                  (task: any, index: number) => (
                    <JobListRow
                      key={index}
                      task={task}
                      data-test-id={`taskRow${index}`}
                      getCapitalizedJobs={this.getCapitalizedJobs}
                      getJobTypes={this.getJobTypes}
                      getVerticals={this.getVerticals}
                      onView={this.onView}
                    />
                  )
                )}
              </div>
              </div>

              <div
                style={styles.titleStyleJob}
              >{`Future Job Orders `}</div>
              
            <div style={styles.taskContainerJob}>
              <div>
                {this.state.openTaskList.map(
                  (task: any, index: number) => (
                    <JobListRow
                      key={index}
                      task={task}
                      data-test-id={`taskRow${index}`}
                      getCapitalizedJobs={this.getCapitalizedJobs}
                      getJobTypes={this.getJobTypes}
                      getVerticals={this.getVerticals}
                      onView={this.onView}
                    />
                  )
                )}
              </div>
              </div>
            </div>
            </>}
          </div>

      </Layout>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
interface JobTypeCr {
  id: string;
  asset_ids: Array<string>;
}
interface Task {
  id: string;
  type: string;
  attributes: {
      id: number;
      title: string | null;
      due_date: string | null;
      location_address:string;
      description: string | null;
      status: string;
      priority: string;
      duration: string;
      location_name: string;
      documents_type: Array<JobTypeCr>;
      municipality:string;
      asset_ids: Array<string>
  };
}

export const JobListRow = (props: {
    task: Task;
    getCapitalizedJobs: (jobs: Array<{ id: string; asset_ids: Array<string>}>) => string[];
    getJobTypes: (value:Array<{ id: string; asset_ids: Array<string>}>) => string[];
    getVerticals: (value: string[]) => string[];
    onView: (id: string) => void;
  }) => {
    const {
      task,
      getCapitalizedJobs,
      getJobTypes,
      getVerticals,
      onView,
    } = props;
    return (
      <div
        data-test-id="mainDiv"
        style={tStyles.containerSec}
        id={task.attributes.id.toString()}
      >
        <Grid container spacing={1}>
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={tStyles.lgTextJob}>
              {task.attributes.location_name || "-"}
            </Typography>
            <CustomTooltip
              placement="bottom-start"
              title={
                <React.Fragment>
                  <Typography className="title">Address</Typography>
                  <Typography className="address">
                    {task.attributes.location_address || "-"}
                  </Typography>
                </React.Fragment>
              }
            >
              <Typography
                style={{
                  ...tStyles.smTextJob,
                  ...tStyles.textOverflowJob,
                  ...tStyles.addressWidthJob,
                }}
              >
                {task.attributes.location_address || "-"}
              </Typography>
            </CustomTooltip>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <CustomTooltip
              placement="bottom-start"
              title={
                <React.Fragment>
                  <Typography className="title">Job Types</Typography>
                  {getCapitalizedJobs(task.attributes.documents_type).map(
                    (x: any, index: number) => (
                      <Typography key={index} component="div" className="job">
                        {x.id}
                      </Typography>
                    )
                  )}
                </React.Fragment>
              }
            >
              <div style={tStyles.avatarDivTitleJob}>
                {getJobTypes(task.attributes.documents_type).map((x: any, index: number) => (
                  task.attributes.documents_type.length === 1 ? (
                    <Avatar key={index} style={tStyles.lgAvatarJob1}>
                      {task.attributes.documents_type[0].id} 
                    </Avatar>
                ) : (
                    <Avatar key={index} style={tStyles.lgAvatarJob}>
                      {x}
                    </Avatar>
                )
              
                ))}
              </div>
            </CustomTooltip>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={tStyles.lgTextJob}>
              {task.attributes.municipality || "-"}
            </Typography>
            <Typography style={tStyles.smTextJob}>Municipality</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <CustomTooltip
              title={
                <React.Fragment>
                  <Typography className="title">Verticals</Typography>
                  {task.attributes.asset_ids.map((x: any, index: number) => (
                    <Typography key={index} component="div" className="vertical">
                      {x}
                    </Typography>
                  ))}
                </React.Fragment>
              }
            >
              <div style={tStyles.avatarDivTitleJob}>
                {getVerticals(task.attributes.asset_ids).map((x: any, index: number) => (
                  <Avatar key={index} style={tStyles.smAvatar}>
                    {x}
                  </Avatar>
                ))}
              </div>
            </CustomTooltip>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={tStyles.lgTextJob}>{task.attributes.duration || "-"}</Typography>
            <Typography style={tStyles.smTextJob}>Duration</Typography>
          </Grid>
          <Grid item xs={3} sm={2} md={1}>
            <Button
              data-test-id="view"
              style={tStyles.viewBtnJob}
              onClick={() => onView(task.id)}
            >
              View
            </Button>
          </Grid>
          <Grid item xs={3} sm={2} md={1}>
            <div style={tStyles.iconDiv}>
              <img style={tStyles.icons} src={MenuGrayIcon} />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
const employeeStyles = {
    jobContainerMain: {
      padding: "7px 20px",
      boxShadow: "0px 4px 4px 0px #0000001A",
      border: "1px solid #d4d4d430",
      background: "#ffffff",
      marginBottom: 16,
      borderRadius: "8px",
      marginLeft: "15px",
      marginTop: "23px",
      marginRight: "16px"
    },
    addressWidthJob: { width: 100 },
    textOverflowJob: {
      whiteSpace: "nowrap" as "nowrap",
      overflow: "hidden",
      textOverflowJob: "ellipsis",
    },
    lgTextJob: { fontSize: 16, fontWeight: 600, margin: 0,textTransform: "capitalize" as "capitalize" },
    smTextJob: { fontWeight: 400, fontSize: 10, margin: 0, color: "#00000070", textTransform: "capitalize" as "capitalize" },
    avatarDivTitleJob: { display: "flex", gap: 8, width: "max-content", fontWeight: 'bold' as 'bold' },
    lgAvatarJob: { height: 36, width: 36, backgroundColor: "#5183d9", fontSize: 14 },
  
    cnempBtnJob: {
      backgroundColor: "rgb(213, 53, 53)",
      fontSize: 12,
      padding: "8px 14px",
      color: "white",
      textTransform: "none" as "none",
    }
  };
  
  const styles = {
    titleStyleJob: {
        textAlign: "left" as "left",
        minWidth: "max-content",
        padding: "4px 0px",
        fontSize: "16px",
        fontWeight: "bold" as "bold",
        margin: "auto 0px",
        marginBottom:"10px",
      },
      taskContainerJob: { marginBottom: 16 },
   
    jobContainer: { marginBottom: 16, paddingRight: 14, paddingLeft:14 },
    
    searchbarHeaderJob: {
      height: "64px",
      background: "#fff",
      padding: "10px 0px",
      textAlign: "center" as "center",
      width: "100%",
    },
    containerSearchJob: { display: "flex" },
    searchEmpDivJob: {
      padding: "2px",
      width:"100%",
    }
  };
  const tStyles = {
    containerSec: {
      padding: "12px 16px",
      boxShadow: "0px 4px 4px 0px #0000001A",
      borderRadius: "16px",
      border: "1px solid #d4d4d430",
      backgroundColor: "#fff",
      marginBottom: 16,
    },
    addressWidthJob: { width: 100 },
    textOverflowJob: {
      whiteSpace: "nowrap" as "nowrap",
      overflow: "hidden",
      textOverflowJob: "ellipsis",
    },
    lgTextJob: { fontSize: 14, fontWeight: 600, margin: 0 },
    smTextJob: { fontWeight: 400, fontSize: 10, margin: 0, color: "#00000070" },
    avatarDivTitleJob: { display: "flex", gap: 4, width: "max-content" },
    lgAvatarJob: { height: 36, width: 36, backgroundColor: "#5183d9", fontSize: 14 },
    lgAvatarJob1:{ height: 36, width: "auto", backgroundColor: "#5183d9", fontSize: 14,
    borderRadius:"20px", padding:"0 20px"},
    smAvatar: {
      fontWeight: 700,
      color: "#5183d9",
      height: 32,
      width: 32,
      fontSize: 12,
      backgroundColor: "#d3e3fa",
    },
    viewBtnJob: {
      backgroundColor: "#D53535",
      fontSize: 12,
      padding: "8px 14px",
      color: "#ffffff",
      textTransform: "none" as "none",
    },
    iconDiv: { display: "flex", justifyContent: "right", margin: "4px 0px" },
    icons: { height: 28, width: 28, pointerEvents: "none" as "none" },
  };
// Customizable Area End
