import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isEdit: boolean;
  accountId: string;
  backOffice: boolean;
  dispatcher: boolean;
  technician: boolean;

  showAcordianBox: boolean;
  showJobAcordianBox: boolean;
  assetsTypeSelect: any;
  manageViewOpen: boolean;
  manageAssetsOpen: boolean;
  isOpenDialogBox: boolean,
  showModalBackBtn: any;
  showModal: any;
  openAssest: any,
  errorEmailExistTech: string;
  techUserInfo: {
    fullName: string;
    email: any;
    address: string;
    phoneNo: any;
    salary: any;
    SocialSuqNo: any;
    tech_id: any;
    jobTypeSelect: any;
    workHoursFromSelect: any;
    workHoursToSelect: any;
    breakEndSelect: any;
    breakStartSelect: any;
    assetsType: any[];
  }
  modalEmail: string,
  modalPasswordTech: string,
  getAssests: any[];
  checkedList: any[];
  checkedListArr: any[];
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TechnicianController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserApiCallId = "";
  patchUserApiCallId = "";
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  createTechApiCallId: string = "";
  getManageAssestApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isEdit: false,
      accountId: "",
      assetsTypeSelect: "",
      showModalBackBtn: false,
      showModal: false,
      manageAssetsOpen: false,
      manageViewOpen: false,
      isOpenDialogBox: false,
      openAssest: false,
      backOffice: false,
      dispatcher: false,
      technician: true,
      showAcordianBox: false,
      showJobAcordianBox: false,
      errorEmailExistTech: '',
      techUserInfo: {
        fullName: "",
        email: "",
        address: "",
        phoneNo: "",
        salary: "",
        SocialSuqNo: "",
        tech_id: "",
        workHoursFromSelect: "",
        workHoursToSelect: "",
        breakEndSelect: "",
        breakStartSelect: "",
        jobTypeSelect: "",
        assetsType: []
      },
      modalEmail: '',
      modalPasswordTech: '',
      getAssests: [],
      checkedList: [],
      checkedListArr: [],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    this.handleManageAssests()

  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.

    if (this.createTechApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.message) {
        this.setState({
          showModal: true,
          modalEmail: responseJson.data.data.attributes.email,
          modalPasswordTech: responseJson.data.data.attributes.user_password,
          techUserInfo: {
            address: '', email: '', fullName: '', phoneNo: '', salary: '', SocialSuqNo: '', tech_id: '', workHoursFromSelect: '', workHoursToSelect: '', breakEndSelect: '', breakStartSelect: '',
            jobTypeSelect: "", assetsType: []
          },
          errorEmailExistTech: "",

        });        
      }
      else if (responseJson.error) {
        this.setState({ errorEmailExistTech: responseJson.error })
      }
    }

    if (this.getManageAssestApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState({
          ...this.state,
          getAssests: responseJson.data,
        });
        this.checkAllFn(responseJson.data)
      }

    }

    switch(message.id) {
      case getName(MessageEnum.RestAPIResponceMessage):
        const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const successData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        const errorData = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
        this.handleGetUserApiCall(messageId, successData, errorData)
        this.handlePatchUserApiCall(messageId, successData, errorData)
        break;
      case getName(MessageEnum.NavigationPayLoadMessage):
        const data = message.getData(
          getName(MessageEnum.NavigationNavigateData)
          )
        this.handleReceiveNavigationTechnician(data)
        break;
    }
  };

  handleReceiveNavigationTechnician = (data: any) => {
    if (data?.id) {
      let getUserApiCallTechnician = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.getUserApiCallId = getUserApiCallTechnician.messageId

      getUserApiCallTechnician.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountBlockSearchAccountId+data.id
      )
      getUserApiCallTechnician.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodGet,
      )
      getUserApiCallTechnician.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Token": localStorage.getItem("token")
        })
      )

      runEngine.sendMessage(getUserApiCallTechnician.id, getUserApiCallTechnician)

      this.setState({
        accountId: data.id
      })
    }
  }

  handleGetUserApiCall = (messageId: string, successData: any, _: any) => {
    if (this.getUserApiCallId !== messageId) {
      return
    }
    const { data: { attributes: {
      address,
      email,
      first_name,
      last_name,
      full_phone_number,
      salary,
      social_security_number: SocialSuqNo,
      technician_id: tech_id,
      work_hours_to: wht,
      work_hours_from: whf,
      break_end: be,
      break_start: bs,
      asset_type: assetsType,
      job_type: jobTypeSelect,
    }}} = successData
    let workHoursToSelect = wht === "" || !wht ? "" : this.extractTimeFromDateStringTech(wht, false)
    let workHoursFromSelect = whf === "" || !whf ? "" : this.extractTimeFromDateStringTech(whf, false)
    let breakEndSelect = be === "" || !be ? "" : this.extractTimeFromDateStringTech(be, false)
    let breakStartSelect = bs === "" || !bs ? "" : this.extractTimeFromDateStringTech(bs, false)
    this.setState({
      techUserInfo: {
        address,
        email,
        fullName: `${first_name}${last_name.length > 0 ? " " : ""}${last_name}`,
        phoneNo: full_phone_number,
        salary,
        SocialSuqNo,
        tech_id,
        workHoursFromSelect,
        workHoursToSelect,
        breakEndSelect,
        breakStartSelect,
        jobTypeSelect,
        assetsType
      },
      checkedList: assetsType,
      showAcordianBox: breakEndSelect !== "" && breakStartSelect !== "",
      isEdit: true
    });
  };

  handlePatchUserApiCall = (messageId: string, successData: any, _: any) => {
    if (this.patchUserApiCallId !== messageId) {
      return
    }
    if (!successData.errors) {
      this.setState({ showModal: true })
      return
    }
    this.setState({ errorEmailExistTech: successData?.error ?? successData?.errors?.[0] })
  }

  goToDisPatcher = () => {
    this.props.navigation.navigate("DispatchUser");
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  handleOpenDialog = () => {
    this.setState({ openAssest: true });
  };

  handleCloseDialog = () => {
    this.setState({ manageViewOpen: false });
  };

  goToBackOffice = () => {
    this.props.navigation.navigate("BackOfficeUser");
  };
  goToTechnician = () => {
    this.props.navigation.navigate("TechnicianUser");
  };
  goToListT = () => {
    const msgT = new Message(
      getName(MessageEnum.NavigationNavigate)
    )
    msgT.addData(
      getName(MessageEnum.NavigationNavigateData),
      {
        route: "Employees"
      }
    )
    msgT.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msgT)
  }
  clickAcordianBtn = () => {
    this.setState({
      showAcordianBox: !this.state.showAcordianBox
    });
  };
  clickJobAcordianBtn = () => {
    this.setState({
      showJobAcordianBox: !this.state.showJobAcordianBox
    })
  };
  manageViewHandle = () => {
    this.setState({ manageViewOpen: true });
  };

  manageAssetsHandle = () => {
    this.setState({ manageAssetsOpen: true, showModalBackBtn: false });
  };

  closeManageAssetsModal = () => {
    this.setState({ manageAssetsOpen: !this.state.manageAssetsOpen });
  }

  closeManageAssetsModalNo = () => {
    this.setState({ manageAssetsOpen: false });
    this.handleSelectAll()
  }


  openModalDialog = () => {
    this.setState({ isOpenDialogBox: true })
  }

  closeModalDialog = () => {
    this.setState({ isOpenDialogBox: false })
  }

  openModalDialogBackBtn = () => {
    this.setState({ showModalBackBtn: true })
  }

  closeModalDialogBackBtn = () => {
    this.setState({ showModalBackBtn: false })
  }


  checkAllFn = (responseJson: any[]) => {
    this.setState({ getAssests: responseJson })
    const allAssets = responseJson.flatMap(category =>

      category.attributes.sub_categories.map((subCategory: any) => ({
        id: subCategory.id,
        name: subCategory.name,
        parentName: category.attributes.name
      }))
    );
    if (!this.state.isEdit) {
      this.setState({ checkedList: allAssets })
    }
  }

  handleCheckboxChange = (e: any, subCategoryName: string, subcategoryId: number, parentName: string, parentId: number) => {
    const isChecked = e.target.checked;
    this.setState((prevState) => {
      if (isChecked === true) {
        const updatedCheckedList = [
          ...prevState.checkedList.map((val: any) => (val)),
          {
            id: subcategoryId,
            name: subCategoryName,
            parentName: parentName,
            parentId: parentId,
          },
        ];
        this.setState({
          checkedList: updatedCheckedList
        })
      } else {
        const updatedCheckedList:any[] = prevState.checkedList.filter((item: any) => (item.id !== subcategoryId));
        this.state.techUserInfo.assetsType= updatedCheckedList
        this.setState({
          checkedList: updatedCheckedList
        })
      }
    });
  };


  handleSelectAll = () => {
    const allAssets = this.state.getAssests.reduce(
      (accumulator: any, asset: any) => [
        ...accumulator,
        ...asset.attributes.sub_categories.map((subcategory: any) => ({
          id: subcategory.id,
          name: subcategory.name,
          parentName: asset.attributes.name,
          parentId: asset.attributes.id,
        })),
      ],
      []
    );

    this.setState({ checkedList: allAssets }, () => {
      localStorage.setItem("checkedList", JSON.stringify(allAssets));
    });
  };


  handleSubmit = (values: any, { resetForm }: any) => {
    resetForm({
      values: {
        fullName: this.state.techUserInfo.fullName,
        email: values.email, // Keep the email value
        id: this.state.techUserInfo.tech_id,
        address: this.state.techUserInfo.address,
        phoneNo: this.state.techUserInfo.phoneNo,
        workHoursFromSelect: this.state.techUserInfo.workHoursFromSelect,
        jobTypeSelect: this.state.techUserInfo.jobTypeSelect,
        workHoursToSelect: this.state.techUserInfo.workHoursToSelect,
        breakStartSelect: this.state.techUserInfo.breakStartSelect,
        breakEndSelect: this.state.techUserInfo.breakEndSelect,
        salary: this.state.techUserInfo.salary,
        SocialSuqNo: this.state.techUserInfo.SocialSuqNo,
        assetsType: this.state.techUserInfo.assetsType
      },
    });
    if (!this.state.isEdit) {
      this.handlePostTech()
    } else {
      this.handlePatchTech()
    }
  };

  handlePostTech = () => {

    let headers = {
      "Content-Type": "application/json",
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "user_type": "technician",
      "account": {
        "first_name": this.state.techUserInfo.fullName,
        "technician_id": this.state.techUserInfo.tech_id,
        "last_name": "",
        "email": this.state.techUserInfo.email,
        "address": this.state.techUserInfo.address,
        "full_phone_number": this.state.techUserInfo.phoneNo,
        "job_type": this.state.techUserInfo.jobTypeSelect,
        "work_hours_from": this.state.techUserInfo.workHoursFromSelect,
        "work_hours_to": this.state.techUserInfo.workHoursToSelect,
        "break_start": this.state.techUserInfo.breakStartSelect,
        "break_end": this.state.techUserInfo.breakEndSelect,
        "salary": this.state.techUserInfo.salary,
        "social_security_number": this.state.techUserInfo.SocialSuqNo,
        "asset_type": this.state.techUserInfo.assetsType
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTechApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointCreateAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeTechDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePatchTech = () => {
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "account_id": this.state.accountId,
      "first_name": this.state.techUserInfo.fullName,
      "technician_id": this.state.techUserInfo.tech_id,
      "last_name": "",
      "email": this.state.techUserInfo.email,
      "address": this.state.techUserInfo.address,
      "full_phone_number": this.state.techUserInfo.phoneNo,
      "job_type": this.state.techUserInfo.jobTypeSelect,
      "salary": this.state.techUserInfo.salary,
      "social_security_number": this.state.techUserInfo.SocialSuqNo,
      "asset_type": this.state.techUserInfo.assetsType,
      "work_hours_from": this.state.techUserInfo.workHoursFromSelect,
      "work_hours_to": this.state.techUserInfo.workHoursToSelect,
      "break_start": this.state.techUserInfo.breakStartSelect,
      "break_end": this.state.techUserInfo.breakEndSelect,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountBlockUpdateAccount
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodPatch
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleManageAssests = () => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getManageAssestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointManageAssests
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeManageAssestsDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getStartingBreakTimeTech = (timelist: any, startWork: any, endWork: any) => {
    let timeOptions = []
    timeOptions =
      timelist.slice(timelist.findIndex((item: any) => item.value === startWork), timelist.findIndex((item: any) => item.value === endWork)).slice(1)

    return timeOptions;
  }

  getBreakEndTimeTech = (timelist: any, startBreakTime: any, endWorkTime: any) => {
    let timeOptions = []
    timeOptions =
      timelist.slice((timelist.findIndex((item: any) => item.value === startBreakTime) + 1), timelist.findIndex((item: any) => item.value === endWorkTime))

    return timeOptions;
  }

  extractTimeFromDateStringTech = (dateString: string, isPaddedHour: boolean): string => {
    const inputDate = new Date(dateString);
    const hour = inputDate.getUTCHours();
    const minute = inputDate.getUTCMinutes();
    let result = ""
    if (isPaddedHour) {
      result = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    } else {
      result = `${hour.toString()}:${minute.toString().padStart(2, '0')}`;
    }
    return result;
  }
  // Customizable Area End
}
