import React from "react";

// Customizable Area Start
import {
  Avatar,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";

import { SearchGrayIcon } from "../../landingpage/src/assets";
import { dialogStyles } from "../../landingpage/src/JobDetail.web";
import { InvCertRow } from "../../invoicebilling/src/InvoiceDb.web";
import ResponsiveDialog from "../../landingpage/src/ResponsiveDialog.web";
import Layout from "../../landingpage/src/Layout.web";
import CertificationDbController, {
  Props,
} from "./CertificationDbController.web";
import { IInvCertDetail } from "../../invoicebilling/src/InvoiceDbController.web";

export const InvCertHeader = (props: {
  title: string;
  search: string;
  onSearch: (e: any) => void;
}) => {
  const { title, search, onSearch } = props;
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      style={certStyles.mb8}
    >
      <Grid item xs={6}>
        <Typography gutterBottom style={certStyles.fBold}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={3} style={certStyles.alignRight}>
        <TextField
          fullWidth
          data-test-id="search"
          variant="outlined"
          placeholder="Search"
          color="secondary"
          InputProps={{
            style: certStyles.search,
            startAdornment: (
              <InputAdornment position="start">
                <Avatar
                  variant="square"
                  style={certStyles.searchIcon}
                  src={SearchGrayIcon}
                />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={onSearch}
        />
      </Grid>
    </Grid>
  );
};
// Customizable Area End

export default class CertificationDb extends CertificationDbController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout navigation={this.props.navigation}>
        <InvCertHeader
          data-test-id="header"
          title="Certification Database"
          search={this.state.search}
          onSearch={this.onSearch}
        />
        {this.state.certificates.map(
          (detail: IInvCertDetail, index: number) => (
            <InvCertRow
              key={index}
              data-test-id={`certRow${index}`}
              isDelete={!this.state.isTechnician}
              detail={detail}
              onDelete={this.onCertDelete}
              onDownload={this.getCertificatePDF}
            />
          )
        )}
        <ResponsiveDialog
          data-test-id="deleteCertDialog"
          open={this.state.deleteCert.open}
          okText="Delete Certificate"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.handleCertDeleteDialog}
        >
          <Typography style={dialogStyles.fBold}>
            Are you sure you want to delete the Certificate?
          </Typography>
          <Typography style={dialogStyles.f12}>
            This action can't be undone.
          </Typography>
        </ResponsiveDialog>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const certStyles = {
  mb8: { marginBottom: 8 },
  fBold: { fontWeight: "bold" as "bold" },
  alignRight: { textAlign: "right" as "right" },
  search: {
    height: 36,
    borderRadius: 6,
    color: "#777",
    backgroundColor: "#fff",
  },
  searchIcon: { height: 24, width: 24 },
};
// Customizable Area End
