import React from "react";
// Customizable Area Start
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";

import GlobalSearchController, { Props } from "./GlobalSearchCotroller.web";
import { DeleteOutlinedIcon } from "../../landingpage/src/assets";

const OrderRow = () => {
  return (
    <div style={styles.container}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4} md={2}>
          <Typography style={styles.lgText}>Nahia Colung</Typography>
          <Typography style={{ ...tStyles.address }}>
            326 E.KK. Apollo lane 3828 Pierremont Drive, Albuquerque, NM
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div style={tStyles.avatarDiv}>
            <Avatar style={tStyles.lgAvatar}>S</Avatar>
            <Avatar style={tStyles.lgAvatar}>M</Avatar>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography style={styles.lgText}>Cordoba</Typography>
          <Typography style={styles.smText}>Municipality</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <div style={tStyles.avatarDiv}>
            <Avatar style={tStyles.smAvatar}>FP</Avatar>
            <Avatar style={tStyles.smAvatar}>H</Avatar>
            <Avatar style={tStyles.smAvatar}>P</Avatar>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Typography style={styles.lgText}>1h 40m</Typography>
          <Typography style={styles.smText}>Duration</Typography>
        </Grid>
        <Grid item xs={3} sm={2} md={1}>
          <Button style={styles.btn}>View</Button>
        </Grid>
        <Grid item xs={3} sm={2} md={1} />
      </Grid>
    </div>
  );
};

const InvCertRow = (props: {
  onDelete: () => void;
  isInvoice?: boolean;
  isDelete?: boolean;
}) => {
  const { onDelete, isInvoice, isDelete } = props;
  return (
    <div style={styles.container}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={3} lg={3}>
          <Typography style={styles.lgText}>
            {isInvoice ? "[Invoice Number]" : "[Certification ID]"}
          </Typography>
          <Typography style={styles.smText}>
            {isInvoice ? "Invoice No." : "Certification ID"}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <Typography style={styles.lgText}>MER001-PIC-001</Typography>
          <Typography style={styles.smText}>Job Order ID</Typography>
        </Grid>
        <Grid item xs={6} sm={3} lg={4}>
          <Typography style={styles.lgText}>John Smith</Typography>
          <Typography style={styles.smText}>Client Name</Typography>
        </Grid>
        <Grid item xs={4} sm={2} lg={1}>
          <Button style={{ ...styles.btn, ...styles.fBold }}>Download</Button>
        </Grid>
        <Grid item xs={2} sm={1} lg={1} style={styles.alignRight}>
          {isDelete && (
            <IconButton
              data-test-id="deleteInvCert"
              style={styles.p4}
              onClick={onDelete}
            >
              <Avatar
                variant="rounded"
                style={icStyles.deleteIcon}
                src={DeleteOutlinedIcon}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const EmployeeRow = (props: { isTechnician?: boolean }) => {
  const { isTechnician } = props;
  return (
    <div style={styles.container}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4} sm={3}>
          <Typography style={styles.lgText}>Ciryl Gane</Typography>
          <Typography style={styles.smText}>
            {isTechnician ? "Technician" : "Back Office"}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={3}>
          {isTechnician ? (
            <>
              <Typography style={styles.lgText}>
                09:00-12:00 | 13:00-17:00
              </Typography>
              <Typography style={styles.smText}>Working Hours</Typography>
            </>
          ) : (
            <>
              <Typography style={styles.lgText}>ciryl@company.com</Typography>
              <Typography style={styles.smText}>Email</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={6} sm={3} lg={4}>
          {isTechnician && (
            <>
              <Typography style={styles.lgText}>999 999 999</Typography>
              <Typography style={styles.smText}>Phone Number</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <Button style={{ ...styles.btn, ...styles.fBold }}>
            View Profile
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const ClientRow = () => {
  return (
    <div style={styles.container}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={3}>
          <Typography style={styles.lgText}>Cameron Rain</Typography>
          <Typography style={styles.smText}>Client</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Typography style={styles.lgText}>2</Typography>
          <Typography style={styles.smText}>Locations</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          <Typography style={styles.lgText}>24</Typography>
          <Typography style={styles.smText}>Assets</Typography>
        </Grid>
        <Grid item xs={6} sm={2} lg={3} />
        <Grid item xs={6} sm={3} lg={2}>
          <Button style={{ ...styles.btn, ...styles.fBold }}>
            View Profile
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const Header = (props: { title: string }) => {
  const { title } = props;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={8}>
        <Typography gutterBottom style={{ ...styles.fBold, width: "60%" }}>
          {title} (5)
        </Typography>
      </Grid>
      <Grid item xs={4} style={styles.alignRight}>
        <Typography style={{ ...styles.viewAll, ...styles.lgText }}>
          View All
        </Typography>
      </Grid>
    </Grid>
  );
};
// Customizable Area End

export default class GlobalSearch extends GlobalSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <>
        <Typography gutterBottom style={styles.fBold}>
          Showing 5 Results for "[input]"
        </Typography>
        <Header data-test-id="header" title="Job Orders" />
        {[1, 2].map((x: number, index: number) => (
          <OrderRow key={index} data-test-id={`orderRow${index}`} />
        ))}
        <Header title="Invoices" />
        {[1, 2].map((x: number, index: number) => (
          <InvCertRow
            data-test-id={`invRow${index}`}
            isInvoice
            key={index}
            isDelete={!this.props.isTechnician}
            onDelete={() => {}}
          />
        ))}
        <Header title="Certificates" />
        {[1, 2].map((x: number, index: number) => (
          <InvCertRow
            data-test-id={`certRow${index}`}
            key={index}
            isDelete={!this.props.isTechnician}
            onDelete={() => {}}
          />
        ))}
        <Header title="Employees" />
        {[1, 2].map((x: number, index: number) => (
          <EmployeeRow
            data-test-id={`empRow${index}`}
            key={index}
            isTechnician={!!index}
          />
        ))}
        <Header title="Client Database" />
        {[1, 2].map((x: number, index: number) => (
          <ClientRow key={index} data-test-id={`clientRow${index}`} />
        ))}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    boxShadow: "0px 4px 4px 0px #0000001A",
    padding: "16px 20px",
    backgroundColor: "#fff",
    border: "1px solid #d4d4d430",
    borderRadius: "16px",
    marginBottom: 16,
  },
  textOverflow: {
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  p4: { padding: 4 },
  mb6: { marginBottom: 6 },
  fBold: { fontWeight: 700 },
  alignRight: { textAlign: "right" as "right" },
  lgText: { fontSize: 14, fontWeight: 600, margin: 0 },
  smText: { fontWeight: 400, fontSize: 10, margin: 0, color: "#00000070" },
  btn: {
    backgroundColor: "#D53535",
    fontSize: 12,
    padding: "8px 14px",
    color: "#ffffff",
    textTransform: "none" as "none",
  },
  viewAll: { color: "#D53535", display: "inline-grid", cursor: "pointer" },
};

const tStyles = {
  address: { width: 100, ...styles.textOverflow, ...styles.smText },
  avatarDiv: { display: "flex", gap: 8, width: "max-content" },
  lgAvatar: { height: 36, width: 36, backgroundColor: "#5183d9", fontSize: 14 },
  smAvatar: {
    height: 32,
    width: 32,
    fontSize: 14,
    fontWeight: 700,
    color: "#5183d9",
    backgroundColor: "#d3e3fa",
  },
};

const icStyles = { deleteIcon: { height: 24, width: 24 } };
// Customizable Area End
