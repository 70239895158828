import React from "react";

import {
  Button,
  Typography,
  Grid,
  Box,
  // Customizable Area Start
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CustomTooltip from "../../../components/src/CustomTooltip";
import EmployeeDetailController, { Props } from "./EmployeeDetailController";
import Layout from "../../landingpage/src/Layout.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

export default class EmployeeDetail extends EmployeeDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  customTooltipTitle = (close: () => void) => {
    return (
      <Grid container justifyContent="space-between">
        <Grid item xs={11}>
          <Typography className="address">
            To update your profile, please connect with Cofrai
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={close} size="small">
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  customTooltipContent = (open: () => void) => {
    return (
      <Button style={{ background: "rgb(213, 53, 53)", color: "#FFF", borderRadius: "8px" }} onClick={open}>
      <InfoOutlinedIcon />
      </Button>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout navigation={""}>
        <div style={{ width: "100%", height: "100%" }}>
          {this.state.openAssets === true ? <div style={styles.empDetailsContainer} >



            <div style={employeeStyles.detailsContainerMain}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={4} md={2}>
                    <Typography style={employeeStyles.nameDiv}>[Technician Name] [Asset Type]</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <Button style={employeeStyles.editBtn}>Edit Asset Types</Button>
                  </Grid>
                </Grid>
              </Grid>

            </div>
            <div style={styles.empDetailsContainer}>

            </div>
          </div>
            : <Grid container style={{ margin: "auto", padding: "20px", borderRadius: "8px", backgroundColor: "white" }}>
              <Grid md={12} xs={12} style={{ display: "flex", justifyContent: "space-between", }}>
                <Typography style={{ fontWeight: "bold", fontSize: "26px",textTransform: "capitalize" as "capitalize" }}>{this.state.empList.name}</Typography>
                {this.state.userType === "manager" && this.state.empList.employeeType !== 'manager' && (<Button data-test-id="handleEditedData" style={{ color: "rgb(213, 53, 53)", background: "rgb(232, 161, 161)", fontWeight:600, borderRadius: "8px", textTransform: "none" }} onClick={this.handleEditedData}><EditOutlinedIcon /> Edit User Profile</Button>)}
                {this.state.userType === "manager" && this.state.empList.employeeType === 'manager' && (<CustomTooltip title={this.customTooltipTitle}>{this.customTooltipContent}</CustomTooltip>)}
              </Grid>
              <Grid md={12} xs={12} style={{ display: "flex", justifyContent: "space-between", }}>
                <Box>
                  <Box style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
                    <AssignmentIndRoundedIcon />
                    <Typography>{this.state.empList.employeeType}</Typography>
                  </Box>
                  <Box style={{ display: "flex" }}>
                    <CallIcon />
                    <Typography>{this.state.empList.duration}</Typography>
                  </Box>
                  <Box style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
                    <MailIcon />
                    <Typography>{this.state.empList.email}</Typography>
                  </Box>
                  {this.state.empList.employeeType !== 'manager' &&
                    <Box style={{ display: "flex" }}>
                      <ScheduleIcon />
                      <Typography>{this.state.empList.work_hours_from} | {this.state.empList.work_hours_to}</Typography>
                    </Box>}
                </Box>
                {this.state.userType === "manager" && this.state.empList.employeeType !== 'manager' && (<Button  style={{ height: "42px", background: "rgb(213, 53, 53)", color: "#FFF", alignSelf: "center", fontWeight:600, borderRadius: "8px", textTransform: "none" }} data-test-id="modalClick" onClick={this.openDeleteModal}><DeleteOutlineOutlinedIcon />Delete User Profile</Button>)}
              </Grid>
              {this.state.empList.employeeType !== 'manager' && <>
                <Grid md={12} xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Typography style={{ fontWeight: "bold" }}>ID</Typography>
                  <Typography>{this.state.empList.id}</Typography>
                </Grid>
                <Grid md={12} xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Typography style={{ fontWeight: "bold" }}>ADDRESS</Typography>
                  <Typography>{this.state.empList.address}</Typography>
                </Grid>

                {this.state.empList.employeeType === 'technician' && <Grid md={12} xs={12} style={{ marginTop: "5px", marginBottom: "5px" }}>
                  <Typography style={{ fontWeight: "bold" }}>JOB TYPE(S)</Typography>
                  <Box style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
                    <Typography style={{ alignSelf: "end" }}>{this.state.empList.job_type}</Typography>
                    <span data-test-id="asset-view" style={{ color: "red", textDecoration: "none", alignSelf: "end", cursor: 'pointer' }} onClick={this.handleAssetView}><strong>view asset type</strong></span>
                  </Box>
                  <hr style={{ backgroundColor: "#000", height: "1px" }} />
                </Grid>}

                <Grid md={12} xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Typography style={{ fontWeight: "bold" }}>SALARY</Typography>
                  <Typography>{this.state.empList.salary}</Typography>
                </Grid>
                <Grid md={12} xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Typography style={{ fontWeight: "bold" }}>SOCIAL SECURITY NUMBER</Typography>
                  <Typography>{this.state.empList.social_security_number}</Typography>
                </Grid>
              </>}
            </Grid>}
        </div>
        <Dialog
           className="modalBox"
           open={this.state.showModalMsg}
           PaperProps={{ style: stleEmp.singleBoxModalqemp }}
         >
           <DialogActions>
             <Button
               data-test-id="dialogCloseIcon"
               onClick={ this.closeMsgModal}
               style={stleEmp.btnClseMoalEmp}
             >
               <CloseIcon />
             </Button>
           </DialogActions>
           <DialogContent style={stleEmp.preTopEmp}>
             <Typography className="widthHeadingPre" style={{fontWeight:600}} variant="h6">Are you sure you want to Delete Employee?</Typography>
             <Typography variant="body1" style={{margin:"10px 0"}}>This will be deleted Permanently. You can't undo this Action</Typography>

           </DialogContent>
           <DialogActions style={stleEmp.doneModalBtnEmp}>
           <button
             data-test-id="cancelBtn"
               style={stleEmp.cancelBtnTagEmp}
               onClick={ this.closeMsgModal}
             >
               Cancel
             </button>
             <button data-test-id="deleteEmployee"
              onClick={this.deleteEmployee}
              style={stleEmp.doneModalBtnSelecEmp}
                  type="button"> Confirm
             </button>
           </DialogActions>
         </Dialog>
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const stleEmp={
  singleBoxModalqemp: {
    width: "470px",
    borderRadius: "5px 5px 30px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
  },
  btnClseMoalEmp: {
    cursor: "pointer",
    padding: "5px",
    background: "fff",
    color: "#000",
    justifyContent:"end !important"
  },
  preTopEmp: {
    fontSize: "20px",
    fontWeight: 500,
    marginTop:"-40px"
  },
  doneModalBtnEmp: {
    borderTop: "1px #0003 solid"
  },
  cancelBtnTagEmp: {
    fontWeight:600,
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "13px 20px",
    border: "none",
    margin: "5px 0",
    boxShadow:"none",
    color: "rgb(215 31 31 / 84%)",
    background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
  },
  doneModalBtnSelecEmp: {
    fontWeight:600,
    fontSize: "15px",
    cursor: "pointer",
    borderRadius: "5px",
    padding: "13px 20px",
    border: "none",
    margin: "0px 16px 0px 12px",
    background: "rgb(215 31 31 / 84%)",
    color: "#fff"
  },
}
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const styles = {
  titleStyle: {
    textAlign: "left" as "left",
    minWidth: "max-content",
    padding: "4px 0px",
    fontSize: "16px",
    fontWeight: "bold" as "bold",
    margin: "auto 0px",
  },
  empDetailsContainer: { marginBottom: 16, paddingRight: 14 },
  fontBold: { fontWeight: "bold" as "bold" },
  font12: { fontSize: 12 },
  searchbarHeader: {
    height: "64px",
    background: "#fff",
    padding: "10px 0px",
    textAlign: "center" as "center",
    width: "100%",
  },
};

const employeeStyles = {
  container: {
    padding: "16px 20px",
    boxShadow: "0px 4px 4px 0px #0000001A",
    border: "1px solid #d4d4d430",
    background: "#ffffff",
    marginBottom: 16,
    borderRadius: "8px",
    marginTop: "8px",
    marginLeft: "15px"
  },
  detailsContainerMain: {
    padding: "7px 20px",
    boxShadow: "0px 4px 4px 0px #0000001A",
    border: "1px solid #d4d4d430",
    background: "#ffffff",
    marginBottom: 16,
    borderRadius: "8px",
    marginLeft: "15px",
    marginTop: "23px",
    marginRight: "16px"
  },
  nameDiv: { display: "flex", gap: 8, width: "max-content", fontWeight: 'bold' as 'bold' },
  editBtn: {
    backgroundColor: "rgb(213, 53, 53)",
    fontSize: 12,
    padding: "8px 14px",
    color: "white",
    textTransform: "none" as "none",
    width: "10rem",
  },

};

// Customizable Area End
