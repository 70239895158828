import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
 
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import "../assets/style.css";


// Customizable Area End

import ViewAssetController, {
  Props
} from "./ViewAssetController.web";
import Layout from "../../landingpage/src/Layout.web";
export default class ViewAsset extends ViewAssetController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout
        navigation={this.props.navigation}> 
        {this.state.loadingAssetState?<>Loading...</>:
        <>
         <Box sx={webStyles.clientBoxAsset}>
               
               <Box sx={webStyles.bgSectionAsset}>
                   <Grid container spacing={2}>
                       <Grid item xs={12} sm={12} md={12} lg={10}>
                           <Typography style={webStyles.textBoldAsset} variant="h4">{this.state.singleAssetData.asset_type}</Typography>
                           <Typography style={webStyles.dflexAlignCenterAsset} variant="body1">Asset ID: #{this.state.singleAssetData.asset_id}</Typography>
                           <Box>
                               <Grid container spacing={2}>
                                   <Grid item xs={12} sm={12} md={12} lg={6}>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Serial No</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{"#"+this.state.singleAssetData.serial_no}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Location Id</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.location_id}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Floor No.</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.floor_no}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Location Number</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.location_no}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Last Maintenance date</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.last_maintenance_date}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Maintenance Frequency</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.maintenance_frq}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Manufacturing Date</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.manufacturing_date}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Expiry Date</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.expiry_date}</Typography>
                                       </Box>

                                   </Grid>
                                   <Grid item xs={12} sm={12} md={12} lg={6}>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Last Recharge Date</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.last_recharge_date}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Asset Quantity</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.asset_qty}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">discount Amount</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.discount_amount +"%"}</Typography>
                                       </Box>
                                       
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Maintenance Duration</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.maintenance_duration}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Price</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.price}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxAsset}>
                                       <Typography style={{...webStyles.textBoldAsset, ...webStyles.fontTextUpperAsset}} variant="body1">Comments</Typography>
                                       <Typography style={webStyles.dflexMrginTopAsset} variant="body1">{this.state.singleAssetData.comments}</Typography>
                                       </Box>
                                   </Grid>
                               </Grid>
                           </Box>
                       </Grid>
                       <Grid item xs={12} sm={12} md={12} lg={2}>
                       {this.state.user_typels==="manager" || this.state.user_typels==="back_office" || this.state.user_typels==="technician"?<>
                       <Box

                                  >
                                      <Button

                                    className="fontText"

                                      data-test-id={"textBtnSubmit"}
                                      onClick={this.editHandleAsset}
                                      color="primary"
                                      style={webStyles.viewBtnRedAsset}
                                      type="button"

                                    ><EditIcon />&nbsp;
                                      Edit
                                    </Button>
                                    {this.state.user_typels==="manager" &&
                                    <Button
                                      id="btnExample"
                                      onClick={this.handleRemoveModalAsset}
                                      className="fontText"
                                      color="primary"

                                      style={webStyles.viewBtnwhiteAsset}
                                    >
                                      <DeleteIcon/> &nbsp;
                                      Delete
                                    </Button>
  }
                                  </Box>
                       </>:null}
                      
                       </Grid>
                   </Grid>
               </Box>
           </Box>
           
           <Dialog
           className="modalBox"
           open={this.state.showModalMsgAsset}
           PaperProps={{ style: webStyles.singleBoxModalAsset }}
         >
           <DialogActions>
             <Button
               data-test-id="dialogCloseIcon"
               onClick={ this.closeMsgModalAsset}
               style={webStyles.btnClseMoalAsset}
             >
               <CloseIcon />
             </Button>
           </DialogActions>
           <DialogContent style={webStyles.preTopAsset}>
             <Typography className="widthHeadingPre" style={{fontWeight:600}} variant="h6">Are you sure you want to Delete "{this.state.singleAssetData.asset_type}" Asset?</Typography>
             <Typography variant="body1" style={{margin:"10px 0"}}>This will be deleted Permanently. You can't undo this Action</Typography>

           </DialogContent>
           <DialogActions style={webStyles.doneModalBtnsAsset}>
           <button
             data-test-id="cancelBtn"
               style={webStyles.cancelBtnTagAsset}
               onClick={ this.closeMsgModalAsset}
             >
               Cancel
             </button>
             <button data-test-id="okBtn" 
              onClick={this.deleteSingleAsset}
              style={webStyles.doneModalBtnSelecAsset}
                  type="button"> Confirm
             </button>
           </DialogActions>
         </Dialog>
        </>
        }
        
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
   
    clientBoxAsset: {
        minHeight: "100vh",
        width: "100%",
        
      },
      bgSectionAsset:{
        background:"#fff",
        padding:"15px",
        borderRadius: "8px",
      },
      dflexAlignCenterAsset:{
        marginTop:"15px",
        display:"flex",
        alignItems:"center"
      },
      dflexMrginTopAsset:{
        display:"flex",
        alignItems:"center"
      },
      textBoldAsset:{
        fontWeight:600
      },
      fontTextUpperAsset:{
        textTransform:"uppercase" as "uppercase"
      },
      marginTop10pxAsset:{
        marginTop:"15px",
        marginLeft:"5px"
      },
      viewBtnwhiteAsset: {
        fontSize: "15px",
        fontWeight:600,
        cursor: "pointer",
        borderRadius: "10px",
        padding: "10px 20px",
        display:"flex",
        width:"70%",
        border: "none",
        margin: "15px 10px 10px 35px",
        color: "rgb(215 31 31 / 84%)",
        background: "rgba(208, 51, 51, 0.24) none repeat scroll 0% 0%"
      },
      viewBtnRedAsset: {
        display:"flex",
        fontWeight:600,
        width:"70%",
        fontSize: "15px",
        cursor: "pointer",
        borderRadius: "10px",
        padding: "10px 20px",
        border: "none",
        margin: "15px 10px 10px 35px",
        background: "rgb(215 31 31 / 84%)",
        color: "#fff"
      },
      viewBoxButtonAsset: {
        textTransform: "capitalize",
        marginTop: "20px",
        borderTop: "1px #bbb8b8 solid",
        justifyContent:"end",
        display:"flex"
      },

          cancelBtnTagAsset: {
            fontWeight:600,
            fontSize: "15px",
            cursor: "pointer",
            borderRadius: "5px",
            padding: "13px 20px",
            border: "none",
            margin: "5px 0",
            boxShadow:"none",
            color: "rgb(215 31 31 / 84%)",
            background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
          },
          btnClseMoalAsset: {
            cursor: "pointer",
            padding: "5px",
            background: "fff",
            color: "#000",
            justifyContent:"end !important"
          },
          doneModalBtnSelecAsset: {
            fontWeight:600,
            fontSize: "15px",
            cursor: "pointer",
            borderRadius: "5px",
            padding: "13px 20px",
            border: "none",
            margin: "0px 16px 0px 12px",
            background: "rgb(215 31 31 / 84%)",
            color: "#fff"
          },
          doneModalBtnsAsset: {
            borderTop: "1px #0003 solid"
          },
          singleBoxModalAsset: {
            width: "470px",
            borderRadius: "5px 5px 30px",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
          },
          preTopAsset: {
            fontSize: "20px",
            fontWeight: 500,
            marginTop:"-40px"
          },


         
             
             
    
};

// Customizable Area End