import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
export interface ICardProps {
  task: any;
  dragHandleProps: any;
  getJobTypes: (x: any) => any[];
  getVerticals: (x: any) => any[];
  onTaskClick: (id: number) => void;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cardList: any[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TechnicianLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTechnicnsnTasksApiCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      cardList: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getTechnicnsnTasksApiCallId) {
        this.handleGetTechnicianTasks(responseJson);
      }
    }
  };
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    this.getTechnicianTaskList();
  }

  getTechnicianTaskList = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    const userDataString: any = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    let urlParams = new URLSearchParams({
      maintainer_id: userData.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTechnicnsnTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTechnicianTasksApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetTechnicianTasks = (res: any) => {
    if (res.data) {
      const tasks = res.data.flatMap((x: any) => x.attributes);
      this.setState({ cardList: tasks });
    }
  };

  getJobTypes = (jobList: any[]) => {
    let jobTypes: any[] = [];
    if (jobList[0]) jobTypes.push(jobList[0].id[0]);
    if (jobList[1]) jobTypes.push(jobList[1].id[0]);
    if (jobList.length > 2) jobTypes.push(`+${jobList.length - 2}`);
    return jobTypes;
  };

  getVerticals = (assets: any[]) => {
    let tempList = [];
    const trim = (x: any) => {
      const a = x.split(" ");
      return a.length > 2 ? a[0][0] + a[1][0] : a[0][0];
    };
    if (assets[0]) tempList.push(trim(assets[0]));
    if (assets[1]) tempList.push(trim(assets[1]));
    if (assets.length > 2) tempList.push(`+${assets.length - 2}`);
    return tempList;
  };

  handleDrop = (e: any) => {
    const dragId = Number(e.source.index);
    const dropId = Number(e.destination.droppableId);
    if (typeof dragId === "number" && typeof dropId === "number") {
      const tempList = [...this.state.cardList];
      const [dragData] = tempList.splice(dragId, 1);
      tempList.splice(dropId, 0, dragData);
      this.setState({ cardList: tempList });
    }
  };

  onTaskClick = (id: number) => {
    this.props.navigation.navigate("JobDetail", { id });
  };

  onCalendarClick = () => {
    this.props.navigation.navigate("Calendar");
  };
  // Customizable Area End
}
