import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isEdit: boolean;
  accountId: string;
  backOffice: boolean;
  dispatcher: boolean;
  technician: boolean;
  showAcordianBox: boolean;
  showModal: any;
  backOfficeInfo: {
    fullName: string;
    email: any;
    address: string;
    phoneNo: any;
    salary: any;
    SocialSuqNo: any;
    back_id: any;
  workHoursFromSelect: any;
  workHoursToSelect: any;
  breakEndSelect: any;
  breakStartSelect: any;
  },
  errorEmailExistBo: string,
  modalEmail: string,
  modalPasswordBackOffice: string,
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BackOfficeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserApiCallId = "";
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  createBOfficeApiCallId: string = "";
  patchBOfficeApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isEdit: false,
      accountId: "",
      showModal: false,
      backOffice: true,
      dispatcher: false,
      technician: false,
      showAcordianBox: false,
      backOfficeInfo: {
        fullName: "",
        email: "",
        address: "",
        phoneNo: "",
        salary: "",
        SocialSuqNo: "",
        back_id: "",
        workHoursToSelect: "",
        workHoursFromSelect: "",
        breakEndSelect: "",
        breakStartSelect: "",
      },
      modalEmail: '',
      modalPasswordBackOffice: '',
      errorEmailExistBo: ''
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.

    if (this.createBOfficeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.setState({
          backOfficeInfo: { address: '', email: '', fullName: '', phoneNo: '', salary: '', SocialSuqNo: '', back_id: '',  workHoursToSelect: '', workHoursFromSelect: '', breakEndSelect: '', breakStartSelect: '', },
          showModal: true,
          modalEmail: responseJson.data.data.attributes.email,
          modalPasswordBackOffice: responseJson.data.data.attributes.user_password,
          errorEmailExistBo: "",

        });
      }
      else if(responseJson.error){
        this.setState({errorEmailExistBo: responseJson?.error})
      }
    } else if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const successData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      const errorData = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
      if (this.getUserApiCallId === messageId) {
        this.handleGetUserApiCall(successData,errorData)
      } else if (this.patchBOfficeApiCallId === messageId) {
        if (!successData.errors) {
          this.setState({ showModal: true })
        } else {
          this.setState({ errorEmailExistBo: successData?.error ?? successData?.errors?.[0] })
        }
      }
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.NavigationNavigateData)
      )
      this.handleReceiveNavigation(data)
    }
  };

  handleReceiveNavigation = (data: any) => {
    if (data?.id) {
      let getUserApiCall = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.getUserApiCallId = getUserApiCall.messageId

      getUserApiCall.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountBlockSearchAccountId+data.id
      )
      getUserApiCall.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodGet,
      )
      getUserApiCall.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Token": localStorage.getItem("token")
        })
      )

      runEngine.sendMessage(getUserApiCall.id, getUserApiCall)

      this.setState({
        accountId: data.id
      })
    }
  }

  handleGetUserApiCall = (successData: any, _: any) => {
    const { data: { attributes: {
      address,
      email,
      first_name,
      last_name,
      full_phone_number,
      salary,
      social_security_number: SocialSuqNo,
      back_office_id,
      work_hours_to: wht,
      work_hours_from: whf,
      break_end: be,
      break_start: bs,
    }}} = successData
    let workHoursToSelect = wht === "" || !wht ? "" : this.extractTimeFromDateString(wht, false)
    let workHoursFromSelect = whf === "" || !whf ? "" : this.extractTimeFromDateString(whf, false)
    let breakEndSelect = be === "" || !be ? "" : this.extractTimeFromDateString(be, false)
    let breakStartSelect = bs === "" || !bs ? "" : this.extractTimeFromDateString(bs, false)
    this.setState({
      backOfficeInfo: {
        address,
        email,
        fullName: `${first_name}${last_name.length > 0 ? " " : ""}${last_name}`,
        phoneNo: full_phone_number ?? '',
        salary,
        SocialSuqNo,
        back_id: back_office_id ?? '',
        workHoursToSelect,
        workHoursFromSelect,
        breakEndSelect,
        breakStartSelect,
      },
      showAcordianBox: breakEndSelect !== "" && breakStartSelect !== "",
      isEdit: true
    });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };
  goToDisPatcher = () => {
    this.props.navigation.navigate("DispatchUser");
  };
  goToBackOffice = () => {
    this.props.navigation.navigate("BackOfficeUser");
  };
  goToTechnician = () => {
    this.props.navigation.navigate("TechnicianUser");
  };
  goToListBO = () => {
    const msgBO = new Message(
      getName(MessageEnum.NavigationNavigate)
    )
    msgBO.addData(
      getName(MessageEnum.NavigationNavigateData),
      {
        route: "Employees"
      }
    )
    msgBO.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msgBO)
  }
  clickAcordianBtn = () => {
    this.setState({
      showAcordianBox: !this.state.showAcordianBox
    });
  };

  handleSubmit = (values: any, { resetForm }: any) => {
    resetForm({

    values: {
      fullName: this.state.backOfficeInfo.fullName,
      email: values.email, // Keep the email value
      back_id: this.state.backOfficeInfo.back_id,
      address: this.state.backOfficeInfo.address,
      phoneNo: this.state.backOfficeInfo.phoneNo,
      workHoursFromSelect: this.state.backOfficeInfo.workHoursFromSelect,
      workHoursToSelect: this.state.backOfficeInfo.workHoursToSelect,
      breakStartSelect: this.state.backOfficeInfo.breakStartSelect,
      breakEndSelect: this.state.backOfficeInfo.breakEndSelect,
      salary: this.state.backOfficeInfo.salary,
        SocialSuqNo: this.state.backOfficeInfo.SocialSuqNo,

    },
   }); 
   if (!this.state.isEdit) {
     this.handlePostBOffice()
   } else {
    this.handlePatchBOffice()
   }
 };

  handlePostBOffice = () => {

    let headers = {
      "Content-Type": "application/json",
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {

      "user_type": "back_office",
      "account": {
        "first_name": this.state.backOfficeInfo.fullName,
        "last_name":'',
        "back_office_id": this.state.backOfficeInfo.back_id,
        "email": this.state.backOfficeInfo.email,
        "address": this.state.backOfficeInfo.address,
        "full_phone_number": this.state.backOfficeInfo.phoneNo,
        "salary": this.state.backOfficeInfo.salary,
        "social_security_number": this.state.backOfficeInfo.SocialSuqNo,
        "work_hours_from": this.state.backOfficeInfo.workHoursFromSelect,
        "work_hours_to": this.state.backOfficeInfo.workHoursToSelect,
        "break_start": this.state.backOfficeInfo.breakStartSelect,
        "break_end": this.state.backOfficeInfo.breakEndSelect,
      }

    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createBOfficeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointCreateAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeBOfficeDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePatchBOffice = () => {
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "account_id": this.state.accountId,
      "first_name": this.state.backOfficeInfo.fullName,
      "last_name": "",
      "back_office_id": this.state.backOfficeInfo.back_id,
      "email": this.state.backOfficeInfo.email,
      "address": this.state.backOfficeInfo.address,
      "full_phone_number": this.state.backOfficeInfo.phoneNo,
      "work_hours_from": this.state.backOfficeInfo.workHoursFromSelect,
      "work_hours_to": this.state.backOfficeInfo.workHoursToSelect,
      "break_start": this.state.backOfficeInfo.breakStartSelect,
      "break_end": this.state.backOfficeInfo.breakEndSelect,
      "salary": this.state.backOfficeInfo.salary,
      "social_security_number": this.state.backOfficeInfo.SocialSuqNo,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchBOfficeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountBlockUpdateAccount
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodPatch
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStartingBreakTimeBo = (timelist: any, startWork: any, endWork: any) => {
    let timeOptions = []
    timeOptions =
      timelist.slice(timelist.findIndex((item: any) => item.value === startWork), timelist.findIndex((item: any) => item.value === endWork)).slice(1)

    return timeOptions;
  }

  getBreakEndTimeBo = (timelist: any, startBreakTime: any, endWorkTime: any) => {
    let timeOptions = []
    timeOptions =
      timelist.slice((timelist.findIndex((item: any) => item.value === startBreakTime) + 1), timelist.findIndex((item: any) => item.value === endWorkTime))

    return timeOptions;
  }

  extractTimeFromDateString = (dateString: string, isPaddedHour: boolean): string => {
    const inputDate = new Date(dateString);
    const hours = inputDate.getUTCHours();
    const minutes = inputDate.getUTCMinutes();
    let result = ""
    if (isPaddedHour) {
      result = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    } else {
      result = `${hours.toString()}:${minutes.toString().padStart(2, '0')}`;
    }
    return result;
  }
  // Customizable Area End
}
