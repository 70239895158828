import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
type EmployeeDetails = {
    name:                   string;
    employeeType:           string;
    email:                  string;
    duration:               string;
    id:                     string;
    salary:                 string;
    social_security_number: string;
    work_hours_from:        string;
    work_hours_to:          string;
    address:                string;
    job_type:               string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openAssets: boolean;
  userType: string;
  empId: string;
  empList: EmployeeDetails;
  showModalMsg:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmployeeDeleteCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showModalMsg:false,
        openAssets: false,
        userType: "",
        empId: "",
        empList: {
            name:                   "",
            employeeType:           "",
            email:                  "",
            duration:               "",
            id:                     "",
            salary:                 "",
            social_security_number: "",
            work_hours_from:        "",
            work_hours_to:          "",
            address:                "",
            job_type:               "",
        },
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    const result = await getStorageData("employee-detail-data", true)
    if (result) {
        this.setState(result)
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apiEmployeeDeleteCallId) {
        this.deleteRes(responseJson)
       }
    }
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
              const data = message.getData(
            getName(MessageEnum.NavigationNavigateData)
        )
        await this.handleReceiveNavigation(data)
    }
     
    // Customizable Area End
  }

  // Customizable Area Start
  handleReceiveNavigation = async (data: S) => {
    if (data) {
        this.setState(data)
        await setStorageData("employee-detail-data", JSON.stringify(data))
    }
  }

  handleAssetView = () => {
    this.setState({ openAssets: true })
  }

  handleEditedData = () => {
    const msg = new Message(
      getName(MessageEnum.NavigationNavigate)
    )
    let data = {}
    // Determine the appropriate screen name based on user type
    switch (this.state.empList.employeeType) {
      case 'dispatcher':
        data = {
          route: "DispatchUser",
          params: { id: this.state.empId }
        }
        break;
      case 'back_office':
        data = {
          route: "BackOfficeUser",
          params: { id: this.state.empId }
        }
        break;
      case 'technician':
        data = {
          route: "TechnicianUser",
          params: { id: this.state.empId }
        }
        break;
    }
    msg.addData(
      getName(MessageEnum.NavigationNavigateData),
      data
    )
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg)
  };
  openDeleteModal=()=>{
    this.setState({showModalMsg:true})
  }
  closeMsgModal=()=>{
    this.setState({showModalMsg:!this.state.showModalMsg})
  }
  deleteRes=(responseJson:any)=>{
    if(responseJson.message){
      this.props.navigation.navigate("Employees")
    }
  }
  deleteEmployee = () => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmployeeDeleteCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteEmployeeApiEndPoint + `?account_id=${this.state.empId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
