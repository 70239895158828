import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import "../assets/style.css";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
      color: "#777", borderRadius: "8px",width: '300px',height: '3.25rem'
    },
  });

// Customizable Area End

import ViewLocationController, {
  Props
} from "./ViewLocationController.web";
import Layout from "../../landingpage/src/Layout.web";
import ImportExportData from "../../importexportdata/src/ImportExportData.web";
export default class ViewLocationPage extends ViewLocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
complexExport=()=>{
  return(
    <>
    {this.state.user_typel==="manager" || this.state.user_typel==="back_office" || this.state.user_typel==="technician"?<>
                  <Grid item xs={6} sm={6} md={2}>
                      <input
                        id="uploadBtn"
                        type="file"
                        hidden
                        onChange={this.onFileChange}
                        accept=".csv"
                      />
                      <Button
                        style={webStyles.importCsvBtn}
                        startIcon={
                          <label htmlFor="uploadBtn">
                            <GetAppSharpIcon />
                          </label>
                        }
                      >
                        <label htmlFor="uploadBtn">Import CSV</label>
                      </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3}>
                    <Link
                      onClick={this.handleAssetkey}
                      to="/ClientDb/Asset">
                    <Button type="button" style={webStyles.addAssetsBtn}><AddIcon /> Add New Assets</Button>
                    </Link>
                  </Grid>
                  </>:null
                  }
    </>
  )
}

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout
        navigation={this.props.navigation}> 
        {this.state.loadingState?<>Loading...</>:
        <>
        {this.state.isAssetImportOpen && this.state.selectedAssetCsv ?  
          <ImportExportData data-test-id="importFile" file={this.state.selectedAssetCsv} goBack={this.onAssetImportBack} type="assets" navigation={""} id={""} />
        : 
        <>
         <Box sx={webStyles.clientBoxLoc}>
               
               <Box sx={webStyles.bgSectionLoc}>
                   <Grid container spacing={2}>
                       <Grid item xs={12} sm={12} md={12} lg={10}>
                           <Typography style={webStyles.textBoldLoc} variant="h4">{this.state.singleLocationData.location_name}</Typography>
                           <Typography style={webStyles.dflexAlignCenterLoc} variant="body1"><RoomIcon/>&nbsp;{this.state.singleLocationData.address +", "+ 
                           this.state.singleLocationData.postcode}</Typography>
                           <Typography style={webStyles.dflexAlignCenterLoc} variant="body1"><CalendarTodayIcon/>&nbsp;{this.state.singleLocationData.working_days}</Typography>

                           <Box>
                               <Grid container spacing={2}>
                                   <Grid item xs={12} sm={12} md={12} lg={6}>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Location Id</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{"#"+this.state.singleLocationData.location_Id}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Working Hours</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.customFunct(this.state.singleLocationData.work_hours_from,this.state.singleLocationData.work_hours_to)}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Break hours</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.customFunct(this.state.singleLocationData.break_hours_from,this.state.singleLocationData.break_hours_to)}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">contact Details</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.contact_details}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Contact Preferences</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.contact_preferences}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Payment Details</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.payment_details}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Segments</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.segments}</Typography>
                                       </Box>

                                   </Grid>
                                   <Grid item xs={12} sm={12} md={12} lg={6}>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Account manager</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.account_manager_name}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Assigned Technician</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.assigned_technician}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">property Administrator</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.property_admin}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">access control</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.access_control?"Yes":"No"}</Typography>
                                       </Box>
                                       <Box sx={webStyles.marginTop10pxLoc}>
                                       <Typography style={{...webStyles.textBoldLoc, ...webStyles.fontTextUpperLoc}} variant="body1">Comments</Typography>
                                       <Typography style={webStyles.dflexMrginTopLoc} variant="body1">{this.state.singleLocationData.comments}</Typography>
                                       </Box>
                                   </Grid>
                               </Grid>
                           </Box>
                       </Grid>
                       <Grid item xs={12} sm={12} md={12} lg={2}>
                       {this.state.user_typel==="manager" || this.state.user_typel==="back_office" || this.state.user_typel==="technician"?<>
                       <Box>
                                      <Button

                                    className="fontText"

                                      data-test-id={"textBtnSubmit"}
                                      onClick={this.editHandleLocation}
                                      color="primary"
                                      style={webStyles.viewBtnRedLoc}
                                      type="button"

                                    ><EditIcon />&nbsp;
                                      Edit
                                    </Button>
                                    {this.state.user_typel==="manager" && 
                                    <Button
                                      id="btnExample"
                                      onClick={this.handleRemoveModalLocation}
                                      className="fontText"
                                      color="primary"

                                      style={webStyles.viewBtnwhiteLoc}
                                    >
                                      <DeleteIcon/> &nbsp;
                                      Delete
                                    </Button>
  }
                                  </Box>
                       </>
                       :null
        }
                      
                       </Grid>
                   </Grid>
               </Box>
           </Box>
           
           <Dialog
           className="modalBox"
           open={this.state.showModalMsgLocation}
           PaperProps={{ style: webStyles.singleBoxModalLoc }}
         >
           <DialogActions>
             <Button
               data-test-id="dialogCloseIcon"
               onClick={ this.closeMsgModalLocation}
               style={webStyles.btnClseMoalLoc}
             >
               <CloseIcon />
             </Button>
           </DialogActions>
           <DialogContent style={webStyles.preTopLoc}>
             <Typography className="widthHeadingPre" style={{fontWeight:600}} variant="h6">Are you sure you want to Delete "{this.state.singleLocationData.location_name}" Location?</Typography>
             <Typography variant="body1" style={{margin:"10px 0"}}>This will be deleted Permanently. You can't undo this Action</Typography>

           </DialogContent>
           <DialogActions style={webStyles.doneModalBtns}>
           <button
             data-test-id="cancelBtn"
               style={webStyles.cancelBtnTagLoc}
               onClick={ this.closeMsgModalLocation}
             >
               Cancel
             </button>
             <button data-test-id="okBtn" 
              onClick={this.deleteSingleLocation}
              style={webStyles.doneModalBtnSelecLoc}
                  type="button"> Confirm
             </button>
           </DialogActions>
         </Dialog>

         <Box style={webStyles.TopBoxAssets}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography style={webStyles.headingPaddingAssets} variant="body1">Assets</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div
                      style={{
                        ...webStyles.searchbarHeaderCss,
                        ...webStyles.containerSearchcss,
                      }}
                    >
                      <div style={webStyles.searchForAssetsCss}>
                        <CustomTextField
                          fullWidth
                          className="custom-text-field"
                          data-test-id="searchInput"
                          onChange={this.handleSearchAsset}
                          variant="outlined"
                          placeholder="Search"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                 {this.complexExport()}
                  
                </Grid>
                </Box>
                <Box style={webStyles.TopBoxAssets} className="tableDataBox" >
                <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={webStyles.tableTdColorAssets}>Assets Type</TableCell>
            <TableCell style={webStyles.tableTdColorAssets} align="right">Assets ID</TableCell>
            <TableCell style={webStyles.tableTdColorAssets} align="right">Floor</TableCell>
            <TableCell style={webStyles.tableTdColorAssets} align="right">Last Maintenance Date</TableCell>
            <TableCell style={webStyles.tableTdColorAssets} align="right">Maintenance Frequency</TableCell>
            <TableCell style={webStyles.tableTdColorAssets} align="right">{""}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.AssetData.length>0 && this.state.AssetData.map((item:any, index:any)=>(
                 <TableRow key={index}>
              
                 <TableCell component="th" scope="row">
                   {item.data.attributes.asset_type}
                 </TableCell>
                 <TableCell align="right"> {item.data.attributes.asset_id}</TableCell>
                 <TableCell align="right">{item.data.attributes.floor_number}</TableCell>
                 <TableCell align="right">{item.data.attributes.last_maintenance_date}</TableCell>
                 <TableCell align="right">{item.data.attributes.maintenance_frequency}</TableCell>
                 <TableCell align="right" data-test-id="clickTableRow" onClick={()=>this.singlAssetClick(item.data.id)}><ArrowForwardIosIcon /></TableCell>
               </TableRow>
                ))}
        </TableBody>
      </Table>
                </Box>
        </>}
        </>
        }
        
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
   
    clientBoxLoc: {
        width: "100%",
        
      },
      bgSectionLoc:{
        background:"#fff",
        padding:"15px",
        borderRadius: "8px",
      },
      dflexAlignCenterLoc:{
        marginTop:"15px",
        display:"flex",
        alignItems:"center"
      },
      dflexMrginTopLoc:{
        display:"flex",
        alignItems:"center"
      },
      textBoldLoc:{
        fontWeight:600
      },
      fontTextUpperLoc:{
        textTransform:"uppercase" as "uppercase"
      },
      marginTop10pxLoc:{
        marginTop:"15px",
        marginLeft:"5px"
      },
      viewBtnwhiteLoc: {
        fontSize: "15px",
        fontWeight:600,
        cursor: "pointer",
        borderRadius: "10px",
        padding: "10px 20px",
        display:"flex",
        width:"70%",
        border: "none",
        margin: "15px 10px 10px 35px",
        color: "rgb(215 31 31 / 84%)",
        background: "rgba(208, 51, 51, 0.24) none repeat scroll 0% 0%"
      },
      viewBtnRedLoc: {
        display:"flex",
        fontWeight:600,
        width:"70%",
        fontSize: "15px",
        cursor: "pointer",
        borderRadius: "10px",
        padding: "10px 20px",
        border: "none",
        margin: "15px 10px 10px 35px",
        background: "rgb(215 31 31 / 84%)",
        color: "#fff"
      },
      viewBoxButtonLocLoc: {
        textTransform: "capitalize",
        marginTop: "20px",
        borderTop: "1px #bbb8b8 solid",
        justifyContent:"end",
        display:"flex"
      },

          cancelBtnTagLoc: {
            fontWeight:600,
            fontSize: "15px",
            cursor: "pointer",
            borderRadius: "5px",
            padding: "13px 20px",
            border: "none",
            margin: "5px 0",
            boxShadow:"none",
            color: "rgb(215 31 31 / 84%)",
            background: "rgba(208, 51, 51, 0.1) none repeat scroll 0% 0%"
          },
          btnClseMoalLoc: {
            cursor: "pointer",
            padding: "5px",
            background: "fff",
            color: "#000",
            justifyContent:"end !important"
          },
          doneModalBtnSelecLoc: {
            fontWeight:600,
            fontSize: "15px",
            cursor: "pointer",
            borderRadius: "5px",
            padding: "13px 20px",
            border: "none",
            margin: "0px 16px 0px 12px",
            background: "rgb(215 31 31 / 84%)",
            color: "#fff"
          },
          doneModalBtns: {
            borderTop: "1px #0003 solid"
          },
          singleBoxModalLoc: {
            width: "470px",
            borderRadius: "5px 5px 30px",
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
          },
          preTopLoc: {
            fontSize: "20px",
            fontWeight: 500,
            marginTop:"-40px"
          },


          headingPaddingAssets:{
            fontWeight:600,
            padding:"15px 0",
            fontSize:"20px"
              },
              TopBoxAssets:{
                background:"#fff",
                padding:"15px",
                marginTop:"15px",
                marginBottom:"15px",
                borderRadius: "8px",
              },
              searchbarHeaderCss: {
                height: "64px",
                background: "#fff",
                textAlign: "center" as "center",
                width: "100%",
              },
              containerSearchcss: { display: "flex" },
              searchForAssetsCss: {
                padding: "8px 20px",
                width: "33%",
              },
              importCsvBtn: {
                color: "#ffffff",
                textTransform: "none" as "none",
                borderRadius:"7px",
                backgroundColor: "#D53535",
                fontSize:" 16px",
                fontWeight: 600,
                width: '100%',
                
              },
              addAssetsBtn:{
               
                padding: "11px 14px",
                color: "#D53535",
                textTransform: "none" as "none",
                borderRadius:"7px",
                backgroundColor: "#fff",
                fontSize:" 16px",
                fontWeight: 600,
                width: '100%',
                marginTop:"7px"
              },
              tableTdColorAssets:{
                fontWeight:600,
                color:"rgba(21, 86, 157, 0.73)"
              },
    
};

// Customizable Area End