import React from "react";

import {
  Button,
  Typography,
  Grid,
  Tooltip,
  InputAdornment,
  TextField,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  Divider,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, withStyles } from "@material-ui/core/styles";
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: "#fff",
    boxShadow: "4px 4px 4px 4px #0000001A",
    width: "auto",
    padding: "8px 16px",
  },
})(Tooltip);



const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#777",
    borderRadius: "8px",
    width: 'auto',
    height: '2.45rem'
  },
});

// Customizable Area End

import EmployeeController, {
  Props,
} from "./EmployeeController";
import Layout from "../../landingpage/src/Layout.web";

export default class Employee extends EmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  employeeDetailsRow = ({ attributes: { full_phone_number, email, user_type, first_name, last_name, salary, social_security_number, work_hours_from, work_hours_to, address, job_type, break_start, break_end }, id }: any) => {
    work_hours_from = `${this.extractTimeFromDateString(work_hours_from)}-${this.extractTimeFromDateString(break_start)}`
    work_hours_to = `${this.extractTimeFromDateString(break_end)}-${this.extractTimeFromDateString(work_hours_to)}`

    return (
      <div style={employeeStyles.container}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={6} sm={4} md={2}>
            <Typography style={employeeStyles.lgText}>{first_name} {last_name}</Typography>
            <CustomTooltip
              placement="bottom-start"
              title={
                <React.Fragment>
                  <Typography style={employeeStyles.tooltipHeading}>Employee-Type</Typography>
                  <Typography style={employeeStyles.tooltipType}>
                    {user_type}
                  </Typography>
                </React.Fragment>
              }
            >
              <Typography
                style={{
                  ...employeeStyles.smText,
                  ...employeeStyles.textOverflow,
                  ...employeeStyles.addressWidth,
                }}
              >
                {user_type}
              </Typography>
            </CustomTooltip>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            {user_type === "technician" ? (
              <>
                <Typography style={employeeStyles.lgText}>{work_hours_from} | {work_hours_to}</Typography>
                <Typography style={employeeStyles.smText}>Working Hours</Typography>
              </>
            ) : (
              <>
                <Typography style={{...employeeStyles.lgText, ...employeeStyles.textEmail}}>{email}</Typography>
                <Typography style={employeeStyles.smText}>email</Typography>
              </>
            )}
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            {user_type === 'technician' && (<><Typography style={employeeStyles.lgText}>{full_phone_number}</Typography>
              <Typography style={employeeStyles.smText}>Phone Number</Typography></>)}
          </Grid>
          <Grid item xs={3} sm={2} md={2} style={{textAlign:"right"}}>
            <Button data-testid="handleOpenEmpDetails" style={employeeStyles.viewBtnProfile} onClick={this.handleOpenEmpDetails.bind(this, { first_name,last_name, user_type, email, full_phone_number, salary, social_security_number, work_hours_to, work_hours_from, address, job_type, openEmpDetails: this.state.openEmpDetails, id: id })}>View Profile</Button>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderEmployeeRow = () => {

    return this.state.getUserList?.map((task: any, index: number) => {
      return this.employeeDetailsRow(task)
    })
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout navigation={""}>
          <div style={styles.empContainer}>
            <div style={employeeStyles.containerMain}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} sm={4} md={7}>
                    <Typography style={employeeStyles.avatarDiv}>Employee List</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    {
                      this.state.currentUserType === "manager" ?
                        <Button style={employeeStyles.cnempBtn} onClick={this.handleNavigate}>Create New Employee</Button>
                      :
                        <></>
                    }
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <div
                      style={{
                        ...styles.searchbarHeader,
                        ...styles.containerSearch,
                      }}
                    >
                      <div style={styles.searchEmpDiv}>
                        <CustomTextField
                          fullWidth
                          variant="outlined"
                          placeholder="Search"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={this.handleSearchUser}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={4} md={1} style={{ maxWidth: '7.3%' }}>
                    <Button data-test-id="btnClickEnable" style={this.state.filterArr.includes(this.state.userType)
                      ? employeeStyles.viewfBtnChecked
                      : employeeStyles.viewfBtn} onClick={this.handleOpenFilter('left-start')}>
                      <FilterListIcon />
                    </Button>
                    <Menu
                      data-test-id="filterMenu"
                      anchorEl={this.state.anchorEl}
                      open={this.state.openFilter}
                      onClose={this.handleOpenFilter('left-start')}
                      marginThreshold={72}
                    >
                      {[
                        {name: "Dispatcher", key: "dispatcher"},
                        {name: "Back Office Worker", key: "back_office"},
                        {name: "Technician", key: "technician"},
                        {name: "Manager", key: "manager"},
                      ].map(({name, key}, idx) => (
                        <MenuItem key={key+idx}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.filterArr.includes(key)}
                                onChange={(e) => {
                                  this.handleCheckedFilter(e, key)
                                }}
                              />
                            }
                            label={
                              <Typography style={{ padding: '8px', width: "15rem" }}>
                                {name}
                              </Typography>
                            }
                          />
                        </MenuItem>
                      ))}
                      <Divider />
                      <MenuItem
                        onClick={(e) => {
                          this.handleClearFilters(); this.handleOpenFilter('left-start')(e);
                        }}
                      >
                        <Typography
                          style={{
                            padding: '8px', whiteSpace: 'pre-line', fontWeight: 'bold', color: "rgb(213, 53, 53)", alignItems: 'center',
                            justifyContent: 'center', display: 'flex',
                            cursor: 'pointer'
                          }}
                        >Clear Filters</Typography>
                      </MenuItem>
                    </Menu>
                  </Grid>

                </Grid>


              </Grid>

            </div>
            <div style={styles.empContainer}>
              {this.renderEmployeeRow()}
            </div>
          </div>

      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const employeeStyles = {
  container: {
    padding: "16px 20px",
    boxShadow: "0px 4px 4px 0px #0000001A",
    border: "1px solid #d4d4d430",
    background: "#ffffff",
    marginBottom: 16,
    borderRadius: "8px",
    marginTop: "8px",
    marginLeft: "15px"
  },
  containerMain: {
    padding: "7px 20px",
    boxShadow: "0px 4px 4px 0px #0000001A",
    border: "1px solid #d4d4d430",
    background: "#ffffff",
    marginBottom: 16,
    borderRadius: "8px",
    marginLeft: "15px",
    marginTop: "23px",
    marginRight: "16px"
  },
  addressWidth: { width: 100 },
  textOverflow: {
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textEmail:{textTransform: "lowercase" as "lowercase" },
  lgText: { fontSize: 16, fontWeight: 600, margin: 0,textTransform: "capitalize" as "capitalize" },
  smText: { fontWeight: 400, fontSize: 10, margin: 0, color: "#00000070", textTransform: "capitalize" as "capitalize" },
  avatarDiv: { display: "flex", gap: 8, width: "max-content", fontWeight: 'bold' as 'bold' },
  lgAvatar: { height: 36, width: 36, backgroundColor: "#5183d9", fontSize: 14 },
  smAvatar: {
    height: 32,
    width: 32,
    fontSize: 14,
    fontWeight: 700,
    color: "#5183d9",
    backgroundColor: "#d3e3fa",
  },
  createBtn: {
    backgroundColor: "#D53535",
    fontSize: 12,
    fontWeight: 'bold',
    width: '169px',
    padding: "8px 14px",
    color: "#ffffff",
    textTransform: "none" as "none",
  },

  viewfBtn: {
    backgroundColor: "rgb(232, 161, 161)",
    fontSize: 12,
    padding: "8px 14px",
    color: "rgb(213, 53, 53)",
    textTransform: "none" as "none",
  },

  viewfBtnChecked:{
    backgroundColor: "rgb(213, 53, 53)",
    color:"white"
  },

  cnempBtn: {
    backgroundColor: "rgb(213, 53, 53)",
    fontSize: 12,
    padding: "8px 14px",
    color: "white",
    textTransform: "none" as "none",
  },
  viewBtnProfile: {
    fontSize: 12,
    padding: "8px 14px",
    backgroundColor: "#D53535",
    fontWeight:600,
    color: "#ffffff",
    textTransform: "none" as "none",
  },
  iconDiv: { display: "flex", justifyContent: "right", margin: "4px 0px" },
  icon: { height: 28, width: 28 },
  tooltipHeading: {
    fontWeight: "bold" as "bold",
    color: "#000",
    marginBottom: 2,
    fontSize: 14,
  },
  tooltipType: { color: "#000", marginTop: 8, fontSize: 12 },
};

const styles = {
  titleStyle: {
    textAlign: "left" as "left",
    minWidth: "max-content",
    padding: "4px 0px",
    fontSize: "16px",
    fontWeight: "bold" as "bold",
    margin: "auto 0px",
  },
  empContainer: { marginBottom: 16, paddingRight: 14 },
  fontBold: { fontWeight: "bold" as "bold" },
  font12: { fontSize: 12 },
  searchbarHeader: {
    height: "64px",
    background: "#fff",
    padding: "10px 0px",
    textAlign: "center" as "center",
    width: "100%",
  },
  containerSearch: { display: "flex" },
  searchEmpDiv: {
    padding: "2px",
  },
  pagination: { margin: "auto" },
};
// Customizable Area End
