import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  FoundClient:any;
  singleClintData:any;
  showModalMsg:any;
  maintainer_id:any;
  client_account_id: string;
  LocationData:any;
  LocationFilter:any;
  searchTermLocation:any;
  selectedLocationCsv: any;
  isLocationImportOpen: boolean;
  user_types:any;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewSingleClientController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  getGroupsApiCallId = "";
  apiViewAllClientSubCallId: string ="";
  apiSingleClientDeleteCallId: string="";
  apiViewAllMngerCallId:string="";
  apiViewAllLocationCallId:string="";
  apiAdvanceSearchLocationId = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      user_types:"",
      isLocationImportOpen: false,
      selectedLocationCsv: null,
      LocationData:[],
      LocationFilter:[],
      showModalMsg:false,
      maintainer_id:null,
      client_account_id: "",
      searchTermLocation:"",
      FoundClient:{},
      singleClintData:{
        clientId: "",
        company_name: "",
        fiscal_id: "",
        vat:"",
        contact_details:"",
        contact_preferences:"",
        payment_details: "",
        segment:"",
        account_manager_name:"",
        account_manager_id:"",
        comments:"",
        assigned_technician:"",
        payment_terms:""
      }
      
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
     let clientIDkey:any=this.props.navigation.getParam("id")
    const userDataString:any = localStorage.getItem("userData")
    const userData:any = JSON.parse(userDataString);
    const maintainerId:any = userData.maintainer_id;
    this.setState({
      maintainer_id:maintainerId,
      client_account_id: clientIDkey,
    })
    const userTypes:any = userData.user_type;
    this.setState({
      user_types:userTypes
    })
    this.getAllClientProfileRes(maintainerId);
    this.getAllLocation(clientIDkey)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) { 
        switch (true) {
          case apiRequestCallId === this.apiViewAllClientSubCallId:
            this.ViewAllClientsData(responseJson)
            break;
            case apiRequestCallId === this.apiSingleClientDeleteCallId:
              this.deleteSingleClientsData(responseJson)
              break
              case apiRequestCallId === this.apiViewAllMngerCallId:
                this.ViewAllManagerData(responseJson)
              break
              case apiRequestCallId === this.apiViewAllLocationCallId:
                this.LocationResData(responseJson)
                break
          case apiRequestCallId === this.apiAdvanceSearchLocationId:
            this.getAdvanceSearchLocationHandler(responseJson)
            break;
            default:
        }
      }
    }
    // Customizable Area End
  }

   // Customizable Area Start

  getAdvanceSearchLocationHandler = (data: any) => {
    this.setState({
      LocationData: data.client_locations ? data.client_locations : []
    })
  }

   getAllClientProfileRes=(keyId:any)=>{
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewAllClientSubCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllClientApiEndPoint+keyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  ViewAllClientsData = (responseJson:any) => {
    if (responseJson?.maintainer?.clients) {
      const clientIdToFind = this.props.navigation.getParam("id");
      const foundClient = responseJson.maintainer.clients.find((item:any) => {
        return item.client_account_id === parseInt(clientIdToFind)
      }
      );
      this.setState({
        singleClintData: { ...this.state.singleClintData, 
        clientId:foundClient.client_account_id,
        company_name: foundClient.client_company_name,
        fiscal_id: foundClient.client_details.data.attributes.fiscal_id,
        vat:foundClient.client_details.data.attributes.vat,
        contact_details:foundClient.client_details.data.attributes.contact_details,
        contact_preferences:foundClient.client_details.data.attributes.contact_preferences,
        payment_details:foundClient.client_details.data.attributes.payment_details,
        segment:foundClient.client_details.data.attributes.segment,
        account_manager_id:foundClient.client_details.data.attributes.account_manager_id,
        comments:foundClient.client_details.data.attributes.comments,
        assigned_technician:foundClient.client_details.data.attributes.assigned_technician,
        payment_terms:foundClient.client_details.data.attributes.payment_terms
        }
       
      })
      this.getAllManagerResponse()
    }
  };
  editHandle=()=>{
    this.locationHandle()
    this.props.navigation.navigate("CreateClients",{id:this.state.singleClintData.clientId})
  }

  deleteSingleClient=()=>{
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSingleClientDeleteCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteClientApiEndPoint + `?client_account_id=${this.state.singleClintData.clientId}`+"/"+
          `&maintainer_id=${this.state.maintainer_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handleRemoveModal=()=>{
    this.setState({showModalMsg:true})
  }
  closeMsgModal=()=>{
    this.setState({showModalMsg:false})
  }
  deleteSingleClientsData=(responseJson:any)=>{
    if(responseJson){
      this.setState({
        showModalMsg:false
      })
      this.props.navigation.navigate("ViewClientProfile")
    }

  }
  getAllManagerResponse=()=>{
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiViewAllMngerCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllManagerApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }

  ViewAllManagerData=(responseJson:any)=>{
    if(responseJson.data.length>0){
      const foundClient = responseJson.data.find((item:any) => {
        return parseInt(item.id) === this.state.singleClintData.account_manager_id
      })
      this.setState({
        singleClintData: { ...this.state.singleClintData, 
          account_manager_name:foundClient?.attributes.first_name,
        }
      })
    }
  }
  locationHandle=()=>{
    let clientIds:any = this.state.singleClintData.clientId;
    localStorage.setItem("clientId", clientIds)
  }
  getAllLocation=(clientId:any)=>{
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiViewAllLocationCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllLocationApiEndPoint+clientId
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  LocationResData=(responseJson:any)=>{
    if(responseJson?.client_locations){
      this.setState({
        LocationData:responseJson.client_locations,
        LocationFilter:responseJson.client_locations,
      })
      this.locationHandle();
    }
  }
  singlLocationClick=(pram:any)=>{
    
    this.props.navigation.navigate("ViewLocation",{id:pram})
  }

  handleSearchLocation = (e:any) => {
    this.setState({ searchTermLocation: e.target.value });
    if(e.target.value!=""){
      const headers = {
        "Token": localStorage.getItem("token"),
      };
      const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.apiAdvanceSearchLocationId = message.messageId
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllLocationApiEndPoint + `${this.state.client_account_id}&search_query=${encodeURIComponent(e.target.value)}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(message.id, message);
    }else{
      this.setState({
        LocationData:this.state.LocationFilter
      })
    }
  };

  onSelectFile = (e: any) => {
    const selected = e.target.files[0];
    if (selected) {
      // File is selected, update the state and navigate
      this.setState({ selectedLocationCsv: selected }, () => {
       this.setState({isLocationImportOpen: true})
      });
    }
  }

  onLocationImportBack = () => {
    this.setState({ isLocationImportOpen:false, selectedLocationCsv: null })
  }
  // Customizable Area End
}
