import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  FoundClient:any;
  singleLocationData:any;
  AssetData:any;
  AssetDataFilter:any;
  showModalMsgLocation:any;
  maintainer_id:any;
  loadingState:any;
  selectedAssetCsv: any;
  isAssetImportOpen: boolean;
  user_typel:any;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewLocationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  getGroupsApiCallId = "";
  apiViewSingleLocationSubCallId: string ="";
  apiSingleLocationDeleteCallId: string="";
  apiViewAllMngerWithLCallId:string="";
  apiViewAllAssetCallId:string="";
  apiAdvanceSearchAssetId = "";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      user_typel:"",
      isAssetImportOpen: false,
      selectedAssetCsv: null,
      loadingState:true,
      showModalMsgLocation:false,
      AssetData:[],
      AssetDataFilter:[],
      maintainer_id:null,
      FoundClient:{},
      singleLocationData:{
        location_Id:0,
        location_name: "",
        address: "",
        country:"",
        town:"",
        municipality:"",
        postcode: "",
        contact_details:"",
        contact_preferences:"",
        payment_details:"",
        segments:"",
        manager_account_id:0,
        account_manager_name:"",
        working_days:"",
        work_hours_from:"",

        work_hours_to: "",
        break_hours_from:"",
        break_hours_to:"",
        assigned_technician:"",
        property_admin:"",
        access_control:0,
        comments:"",
      }
      
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const userDataString:any = localStorage.getItem("userData")
    const userDataloKey:any = JSON.parse(userDataString);
    const maintainerId:any = userDataloKey.maintainer_id;
    const userTypel:any = userDataloKey.user_type;
    this.setState({maintainer_id:maintainerId ,user_typel:userTypel})
    this.getAlllocationProRes();
    
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) { 
        switch (true) {
          case apiRequestCallId === this.apiViewSingleLocationSubCallId:
            this.ViewAllLocationData(responseJson)
            break;
            case apiRequestCallId === this.apiSingleLocationDeleteCallId:
              this.deleteSingleLocationData(responseJson)
              break
              case apiRequestCallId === this.apiViewAllMngerWithLCallId:
                this.ViewAllManagerDataWithLocation(responseJson)
              break
              case apiRequestCallId === this.apiViewAllAssetCallId:
                this.ViewAllAssetData(responseJson)
                break
          case apiRequestCallId === this.apiAdvanceSearchAssetId:
            this.getAdvanceSearchAssetHandler(responseJson)
            break;
            default:
        }
      }
    }
    // Customizable Area End
  }

   // Customizable Area Start
   getAlllocationProRes=()=>{
    let clientIDkeys:any = localStorage.getItem("clientId")
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewSingleLocationSubCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllLocationApiEndPoint+clientIDkeys
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  ViewAllLocationData = (responseJson:any) => {
  
    if (responseJson.client_locations) {
      const clientIdToFind:any = this.props.navigation.getParam("id");
      const foundLocation:any = responseJson.client_locations.find((item:any) => {
        return item.location.data.id === clientIdToFind
      }
      );
      this.setState({
        singleLocationData: { ...this.state.singleLocationData, 
            location_Id:foundLocation.location.data.id,
            location_name:foundLocation.location.data.attributes.location_name,
            address: foundLocation.location.data.attributes.address,
            country:foundLocation.location.data.attributes.country,
            town:foundLocation.location.data.attributes.town,
            municipality:foundLocation.location.data.attributes.municipality,
            postcode:foundLocation.location.data.attributes.postcode,
            contact_details:foundLocation.location.data.attributes.contact_details,
            contact_preferences:foundLocation.location.data.attributes.contact_preferences,
            payment_details:foundLocation.location.data.attributes.payment_details,
            segments:foundLocation.location.data.attributes.segments,
            manager_account_id:foundLocation.location.data.attributes.manager_account_id,
            working_days:foundLocation.location.data.attributes.working_days,
            work_hours_from:foundLocation.location.data.attributes.work_hours_from,
    
            work_hours_to:foundLocation.location.data.attributes.work_hours_to,
            break_hours_from:foundLocation.location.data.attributes.break_hours_from,
            break_hours_to:foundLocation.location.data.attributes.break_hours_to,
            assigned_technician:foundLocation.location.data.attributes.assigned_technician,
            property_admin:foundLocation.location.data.attributes.property_admin,
            access_control:foundLocation.location.data.attributes.access_control,
            comments:foundLocation.location.data.attributes.comments
        }
       
      })
      this.setState({loadingState:false})
      this.getAllManagerLocationResponse();
      this.getAllAsset(clientIdToFind);
    }
  };
  editHandleLocation=()=>{
    this.handleAssetkey()
    this.props.navigation.navigate("CreateLocations",{id:this.state.singleLocationData.location_Id})
  }

  deleteSingleLocation=()=>{
    let clientIDskeys:any = localStorage.getItem("clientId")
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSingleLocationDeleteCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteLocationApiEndPoint + `?client_account_id=${clientIDskeys}`+`&location_id=${this.state.singleLocationData.location_Id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handleRemoveModalLocation=()=>{
    this.setState({showModalMsgLocation:true})
  }
  handleSearchAsset = (e:any) => {
    if(e.target.value!=""){
      const clientLocationId = this.props.navigation.getParam("id");
      const headers = {
        "Token": localStorage.getItem("token"),
      };
      const message = new Message(getName(MessageEnum.RestAPIRequestMessage))
      this.apiAdvanceSearchAssetId = message.messageId
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getSearchAssetApiEndPoint + `?location_id=${clientLocationId}&search_query=${encodeURIComponent(e.target.value)}`
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      runEngine.sendMessage(message.id, message);
    }else{
      this.setState({
        AssetData: this.state.AssetDataFilter
      })
    }
  };
  getAdvanceSearchAssetHandler = (data: any) => {
    this.setState({
      AssetData: data.client_assets ? data.client_assets : []
    })
  }
  closeMsgModalLocation=()=>{
    this.setState({showModalMsgLocation:false})
  }
  deleteSingleLocationData=(responseJson:any)=>{
    if(responseJson){
      this.setState({
        showModalMsgLocation:false
      })
      this.props.navigation.navigate("ViewClientProfile")
    }

  }
  getAllManagerLocationResponse=()=>{
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiViewAllMngerWithLCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllManagerApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  ViewAllManagerDataWithLocation=(responseJson:any)=>{
    if(responseJson.data.length>0){
      const foundLocation = responseJson.data.find((item:any) => {
        return parseInt(item.id) === this.state.singleLocationData.manager_account_id
      })
      this.setState({
        singleLocationData: { ...this.state.singleLocationData, 
          account_manager_name:foundLocation?.attributes.first_name,
        }
      })
    }
  }
  handleAssetkey=()=>{
    localStorage.setItem("location_Id", this.state.singleLocationData.location_Id)
  }
  getAllAsset=(locationIDs:string)=>{
    let clientIds:any = localStorage.getItem("clientId")
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiViewAllAssetCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAssetssApiEndPoint+ `?client_account_id=${clientIds}`+`&location_id=${locationIDs}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  ViewAllAssetData=(responseJson:any)=>{
   if(responseJson.client_assets){
    this.setState({
      AssetData:responseJson.client_assets,
      AssetDataFilter:responseJson.client_assets
    })
   }
  }
  singlAssetClick=(prams:any)=>{
    this.props.navigation.navigate("ViewAsset",{id:prams})
  }

  onFileChange = (e: any) => {
    const selected = e.target.files[0];
    if (selected) {
      // File is selected, update the state and navigate
      this.setState({ selectedAssetCsv: selected }, () => {
       this.setState({isAssetImportOpen: true})
      });
    }
  }

  onAssetImportBack = () => {
    this.setState({ isAssetImportOpen:false, selectedAssetCsv: null })
  }
  customFunct = (x: string, y: string) => {
    const first: string[] = x.split(':');
    const second: string[] = y.split(':');
    const from: number = parseInt(first[0], 10);
    const to: number = parseInt(second[0], 10);

    const formattedX: string = from >= 12 ? x + 'PM' : x + 'AM';
    const formattedY: string = to >= 12 ? y + 'PM' : y + 'AM';

    return formattedX + ' - ' + formattedY;
};
  // Customizable Area End
}
