import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  
  showAcordianBox: boolean;
  maintainer_Id:any;
  allManagerListLocation:any;
  showLocationMsg:any;
  locationDataObj: {
    locationId: any;
    locationName: string;
    country:string;
    town:string;
    municipality:string;
    postCode:string;
    contactDetails:string;
    contactPreferences:string;
    segmentSelect:any;
    paymentDetails:string;
    accountManager:any;
    workingDays:any;
    address: string;
    assignedTechnician:string;
    propertyAdministrator:string;
    accessControl:any;
    comments:string;

  workHoursFromSelect: any;
  workHoursToSelect: any;
  breakEndSelect: any;
  breakStartSelect: any;
  }
  modalEmail: string,
  modalPassword: string,
  errorEmailExist: string
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateLocationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  getGroupsApiCallId = "";
  createLocationApiCallId:string="";
  apiViewAllMngLCallId:string="";
  apiViewLocationListCallId:string=""
  updateLocationApiCallId:string=""
  apiLocationCallId:string=""
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      maintainer_Id:null,
      showLocationMsg:"",
      showAcordianBox: false,
      allManagerListLocation:[],
      locationDataObj: {
        locationId:0,
        locationName: "",
        address: "",
        country:"",
        town:"",
        municipality:"",
        postCode:"",
        contactDetails:"",
        contactPreferences:"",
        paymentDetails:"",
        segmentSelect:"",
        accountManager:0,
        workingDays:"",
        assignedTechnician:"",
        propertyAdministrator:"",
        accessControl:"",
        comments:"",
        workHoursFromSelect: "",
        workHoursToSelect: "",
        breakEndSelect: "",
        breakStartSelect: "",

      },
      modalEmail: "",
      modalPassword: "",
      errorEmailExist: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const userDatal:any = localStorage.getItem("userData")
    const user:any = JSON.parse(userDatal);
    const maintainerIds:any = user.maintainer_id;
    this.setState({
      maintainer_Id:maintainerIds
    })
    this.getAllManagerResloc()
    this.getParamsIdLoHandle();
    
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId != null) { 
        switch (true) {
            case apiRequestCallId === this.createLocationApiCallId:
                this.locationSucces(responseJson)
              break;
              case apiRequestCallId === this.apiViewAllMngLCallId:
            this.ViewAllManagerLData(responseJson)
            break;
            case apiRequestCallId === this.apiViewLocationListCallId:
            this.ViewAllLData(responseJson)
            break;
            case apiRequestCallId === this.updateLocationApiCallId:
              this.SuccessUpdateForm(responseJson);
              break;
            case apiRequestCallId === this.apiLocationCallId:
              this.locationResponse(responseJson)
              break;
            default:
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getParamsIdLoHandle=()=>{
    let urlID:any = this.props.navigation.getParam("id")
    if(urlID){
      this.getAllLocationDataRes()
    }
  }
  getAllLocationDataRes=()=>{
    let clientIDkeys:any = localStorage.getItem("clientId")
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewLocationListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllLocationApiEndPoint+clientIDkeys
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  handlePostlocation = () => {
    let clientID:any = localStorage.getItem("clientId")
    let headers = {
      "Content-Type": "application/json",
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "location": {
        "location_name": this.state.locationDataObj.locationName,
        "address": this.state.locationDataObj.address,
        "country": this.state.locationDataObj.country,
        "town": this.state.locationDataObj.town,
        "municipality": this.state.locationDataObj.municipality,
        "postcode": this.state.locationDataObj.postCode,
        "contact_details": this.state.locationDataObj.contactDetails,
        "contact_preferences":this.state.locationDataObj.contactPreferences,
        "payment_details":this.state.locationDataObj.paymentDetails,
        "segments": this.state.locationDataObj.segmentSelect,
        "manager_account_id":this.state.locationDataObj.accountManager,

        "working_days": this.state.locationDataObj.workingDays,
        "work_hours_from": this.state.locationDataObj.workHoursFromSelect,
        "work_hours_to":this.state.locationDataObj.workHoursToSelect,
        "break_hours_from":this.state.locationDataObj.breakStartSelect,
        "break_hours_to": this.state.locationDataObj.breakEndSelect,
        "assigned_technician":this.state.locationDataObj.assignedTechnician,

        "property_admin":this.state.locationDataObj.propertyAdministrator,
        "access_control": this.state.locationDataObj.accessControl!="Yes"?false:true,
        "comments":this.state.locationDataObj.comments,
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createLocationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createLocationApiEndPoint+clientID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  clickAcordianBtn = () => {

    this.setState({
       showAcordianBox: !this.state.showAcordianBox
    });
  };

  
 
  handleLocationUpdate = () => {

    let clientIDss:any = localStorage.getItem("clientId")
    let headers = {
      "Content-Type": "application/json",
      
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "location": {
        "location_id":this.state.locationDataObj.locationId,
        "location_name": this.state.locationDataObj.locationName,
        "municipality": this.state.locationDataObj.municipality,
        "postcode": this.state.locationDataObj.postCode,
        "contact_details": this.state.locationDataObj.contactDetails,
        "contact_preferences":this.state.locationDataObj.contactPreferences,
        "address": this.state.locationDataObj.address,
        "country": this.state.locationDataObj.country,
        "town": this.state.locationDataObj.town,
        
        "payment_details":this.state.locationDataObj.paymentDetails,
        "segments": this.state.locationDataObj.segmentSelect,
        "working_days": this.state.locationDataObj.workingDays,

        "work_hours_from": this.state.locationDataObj.workHoursFromSelect,
        "work_hours_to":this.state.locationDataObj.workHoursToSelect,

        "manager_account_id":this.state.locationDataObj.accountManager,

        
        "break_hours_from":this.state.locationDataObj.breakStartSelect,
        "break_hours_to": this.state.locationDataObj.breakEndSelect,

        "assigned_technician":this.state.locationDataObj.assignedTechnician,

        "property_admin":this.state.locationDataObj.propertyAdministrator,
        "access_control": this.state.locationDataObj.accessControl!="Yes"?false:true,
        
        "comments":this.state.locationDataObj.comments,
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateLocationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateLocationApiEndPoint+ `?client_account_id=${clientIDss}`+`&location_id=${this.state.locationDataObj.locationId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSubmit = (values: any, { resetForm }: any) => {
    resetForm({
      values: {
        locationName: this.state.locationDataObj.locationName,
        country:this.state.locationDataObj.country,
        town:this.state.locationDataObj.town,
        municipality:this.state.locationDataObj.municipality,
        postCode:this.state.locationDataObj.postCode,
        contactDetails:this.state.locationDataObj.contactDetails,
        paymentDetails:this.state.locationDataObj.paymentDetails,
        contactPreferences:this.state.locationDataObj.contactPreferences,
        segmentSelect:this.state.locationDataObj.segmentSelect,
        accountManager:this.state.locationDataObj.accountManager,
        workingDays:this.state.locationDataObj.workingDays,
        assignedTechnician:this.state.locationDataObj.assignedTechnician,
        propertyAdministrator:this.state.locationDataObj.propertyAdministrator,
        accessControl:this.state.locationDataObj.accessControl?"Yes":"No",
        comments:this.state.locationDataObj.comments,

        address: this.state.locationDataObj.address,
        workHoursFromSelect: this.state.locationDataObj.workHoursFromSelect,
        workHoursToSelect: this.state.locationDataObj.workHoursToSelect,
        breakStartSelect: this.state.locationDataObj.breakStartSelect,
        breakEndSelect: this.state.locationDataObj.breakEndSelect,

      },
   }); 
   
   if(this.state.locationDataObj.locationId){
    this.handleLocationUpdate();
   }else{
    this.handlePostlocation()
   }
 };
  

  getStartingBreakTimeL = (timelist: any, startWork: any, endWork: any) => {
    let timeOptions = []

    timeOptions =
      timelist.slice(timelist.findIndex((item: any) => item.value === startWork), timelist.findIndex((item: any) => item.value === endWork)).slice(1)
      return timeOptions;
  }

  getBreakEndTimeL = (timelist: any, startBreakTime: any, endWorkTime: any) => {
    let timeOptions = []

    timeOptions =
      timelist.slice((timelist.findIndex((item: any) => item.value === startBreakTime) + 1), timelist.findIndex((item: any) => item.value === endWorkTime))
      return timeOptions;
  }
  locationSucces=(responseJson:any)=>{
    if(responseJson.meta){
      this.setState({
        locationDataObj: { ...this.state.locationDataObj, 
          locationId:0,
        locationName: "",
        address: "",
        country:"",
        town:"",
        municipality:"",
        postCode:"",
        contactDetails:"",
        contactPreferences:"",
        paymentDetails:"",
        segmentSelect:"",
        accountManager:0,
        workingDays:"",
        assignedTechnician:"",
        propertyAdministrator:"",
        accessControl:"",
        comments:"",
        workHoursFromSelect: "",
        workHoursToSelect: "",
        breakEndSelect: "",
        breakStartSelect: "",
        }})
    }
  }
  getAllManagerResloc=()=>{
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiViewAllMngLCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllManagerApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  }
  ViewAllManagerLData=(responseJson:any)=>{
    if(responseJson.data.length>0){
      this.setState({
        allManagerListLocation:responseJson.data
      })
    }
  }
  backToViewAllClient=()=>{
    this.props.navigation.navigate("ViewClientProfile")
  }
  SuccessUpdateForm=(responseJson:any)=>{
    if(responseJson){
      let clienssd:any = localStorage.getItem("clientId")
      this.props.navigation.navigate("SingleClient",{id:clienssd})
    }
  }
  ViewAllLData=(responseJson:any)=>{
    if (responseJson.client_locations) {
      const clientIdToFind:any = this.props.navigation.getParam("id");
      const foundLocation:any = responseJson.client_locations.find((item:any) => {
        return item.location.data.id === clientIdToFind
      }
      );
      this.setState({
        locationDataObj: { ...this.state.locationDataObj, 
            locationId:foundLocation.location.data.id,
            locationName:foundLocation.location.data.attributes.location_name,
            address: foundLocation.location.data.attributes.address,
            country:foundLocation.location.data.attributes.country,
            town:foundLocation.location.data.attributes.town,
            municipality:foundLocation.location.data.attributes.municipality,
            postCode:foundLocation.location.data.attributes.postcode,
            contactDetails:foundLocation.location.data.attributes.contact_details,
            contactPreferences:foundLocation.location.data.attributes.contact_preferences,
            paymentDetails:foundLocation.location.data.attributes.payment_details,
            segmentSelect:foundLocation.location.data.attributes.segments,
            accountManager:foundLocation.location.data.attributes.manager_account_id,
            workingDays:foundLocation.location.data.attributes.working_days,
            workHoursFromSelect:foundLocation.location.data.attributes.work_hours_from,
    
            workHoursToSelect:foundLocation.location.data.attributes.work_hours_to,
            breakEndSelect:foundLocation.location.data.attributes.break_hours_to,
            breakStartSelect:foundLocation.location.data.attributes.break_hours_from,
            assignedTechnician:foundLocation.location.data.attributes.assigned_technician,
            propertyAdministrator:foundLocation.location.data.attributes.property_admin,
            accessControl:foundLocation.location.data.attributes.access_control?"Yes":"No",
            comments:foundLocation.location.data.attributes.comments
        }
       
      })
  }
}
getLocationApi=(value:any)=>{
  const headers = {
    "Token": localStorage.getItem("token"),
    "content-type":"application/json"
  };
  const httpBody = {
    "address": value
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiLocationCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.locationApiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}
locationResponse=(responseJson:any)=>{
if(!responseJson.error){
  this.setState({
    locationDataObj: { ...this.state.locationDataObj, 
    address: responseJson.data.attributes.address,
    country:responseJson.data.attributes.country,
    town:responseJson.data.attributes.town,
    municipality:responseJson.data.attributes.municipality,
    postCode:responseJson.data.attributes.postcode,
    },
    showLocationMsg:""
  })
}else{
  this.setState({
    locationDataObj: { ...this.state.locationDataObj, 
      country:"",
    town:"",
    municipality:"",
    postCode:"",
    },
    showLocationMsg:"Internal Server Error"
  })
}
}
naviAsset=()=>{
  this.props.navigation.navigate("CreateAsset")
}
  // Customizable Area End
}
