import React from "react";
import PropTypes from 'prop-types';
import { Tooltip, withStyles } from "@material-ui/core";

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: "#fff",
    boxShadow: "4px 4px 4px 4px #0000001A",
    width: "auto",
    padding: "8px 16px",
    "& .title": {
      color: "#000",
      fontWeight: "bold",
      fontSize: 14,
      marginBottom: 2,
    },
    "& .address": { color: "#000", marginTop: 8, fontSize: 12 },
    "& .vertical": {
      color: "#5183d9",
      backgroundColor: "#d3e3fa",
      width: "max-content",
      padding: "4px 8px",
      marginTop: 6,
      borderRadius: 24,
      fontSize: 12,
      fontWeight: "bold",
    },
    "& .job": {
      color: "#fff",
      backgroundColor: "#5183d9",
      width: "max-content",
      padding: "8px 20px",
      marginTop: 6,
      borderRadius: 24,
      fontSize: 12,
    },
  },
})(Tooltip);


type MyProps = {
  title: JSX.Element | ((close: () => void) => JSX.Element);
  children: (open: () => void) => JSX.Element;
};
type MyState = {
  isTooltipOpen: boolean
};

export default class CustomTooltip extends React.Component<MyProps, MyState> {
  static propTypes = {
    title: PropTypes.element.isRequired,
  };

  constructor(props: MyProps) {
    super(props);

    this.state = {
      isTooltipOpen: false
    };
  }

  close = () => {
    this.setState(prev => ({ ...prev, isTooltipOpen: false }))
  }

  open = () => {
    this.setState(prev => ({ ...prev, isTooltipOpen: true }))
  }

  render() {
    let title = this.props.title instanceof Function ?
      this.props.title(this.close)
      :
      this.props.title;
    return (
      <StyledTooltip
        open={this.state.isTooltipOpen}
        placement="left"
        title={title}
        interactive
      >
        {this.props.children(this.open)}
      </StyledTooltip>
    )
  }
}
