import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment, { Moment } from "moment";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start

interface ICalendar {
  initStart: number;
  initEnd: number;
  items: any[];
}

class AllocationDetail {
  open: boolean = false;
  taskId: string = "";
  title: string = "";
  duration: string = "";
  technicianId: string = "";
  technicianName: string = "";
  workHoursFrom: string = "";
}

class UpdateTimeline {
  taskId: number = -1;
  duration: number = 0;
  technicianName: string = "";
  start_time: Date = new Date();
  end_time: Date = new Date();
  account_id: number = -1;
  assign_any: boolean = false;
}

class LeaveDetail {
  open: boolean = false;
  duration: string = "";
  reason: string = "";
}

class BreakDetail {
  open: boolean = false;
  duration: string = "";
}

class WorkScheduleDetail {
  open: boolean = false;
  timing: string = "";
}

class AnotherJobDetail {
  open: boolean = false;
  title: string = "";
}

export const pageSize = 4;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allocationDetail: AllocationDetail;
  updateTimeline: UpdateTimeline;
  leaveDetail: LeaveDetail;
  workScheduleDetail: WorkScheduleDetail;
  breakDetail: BreakDetail;
  anotherJobDetail: AnotherJobDetail;
  date: Moment;
  timeline: ICalendar;
  showTask: boolean;
  pagination: { current: number; start: number; end: number };
  openTaskList: { all: any[]; current: any[] };
  technicianList: any[];
  maintainer_id: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CompanyLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllTasksApiCallId = "";
  getTechniciansApiCallId = "";
  getAllocatedTasksApiCallId = "";
  patchAssignTaskApiCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      updateTimeline: new UpdateTimeline(),
      allocationDetail: new AllocationDetail(),
      leaveDetail: new LeaveDetail(),
      workScheduleDetail: new WorkScheduleDetail(),
      breakDetail: new BreakDetail(),
      anotherJobDetail: new AnotherJobDetail(),
      date: moment().utc(),
      timeline: {
        initStart: moment()
          .utc()
          .startOf("day")
          .valueOf(),
        initEnd: moment()
          .utc()
          .startOf("day")
          .add(8, "hours")
          .valueOf(),
        items: [],
      },
      showTask: true,
      pagination: { current: 0, start: 0, end: 0 },
      openTaskList: { all: [], current: [] },
      technicianList: [],
      maintainer_id: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (true) {
        case apiRequestCallId === this.getAllTasksApiCallId:
          this.handleGetAllTaskList(responseJson);
          break;
        case apiRequestCallId === this.getTechniciansApiCallId:
          this.handleGetTechnicianList(responseJson);
          break;
        case apiRequestCallId === this.getAllocatedTasksApiCallId:
          this.handleGetAllocatedTaskList(responseJson);
          break;
        case apiRequestCallId === this.patchAssignTaskApiCallId:
          this.handleAssignTasks(responseJson);
          break;
      }
    }
  };
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    const userDataString: any = localStorage.getItem("userData");
    const userData: any = JSON.parse(userDataString);
    const maintainer_id: any = userData.maintainer_id;
    this.setState({ maintainer_id }, () => {
      this.getAllTaskList();
      this.getTechnicianList();
      this.getAllocatedTaskList();
    });
  }

  getAllTaskList = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllTasksApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTechnicianList = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      user_type: "technician",
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTechniciansApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTechniciansApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAllocatedTaskList = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllocatedTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllocatedTasksApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  assignTask = (updatedTimeline: UpdateTimeline) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchAssignTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllTasksApiEndPoint}/${updatedTimeline.taskId}/${configJSON.assignTaskApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updatedTimeline)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetAllTaskList = (res: any) => {
    if (res.data) {
      const allList = res.data
        .flatMap((x: any) => x.attributes)
        .filter((x: any) => !x.assigned_to_id);
      console.log(allList, "allList");
      this.setState(
        {
          openTaskList: { ...this.state.openTaskList, all: allList },
        },
        () => this.onPageChange("", 1)
      );
    }
  };

  handleGetTechnicianList = (res: any) => {
    console.log(res);
    if (res.data) {
      const technicianList = res.data.flatMap((x: any) => ({
        ...x.attributes,
        id: x.id,
      }));
      this.setState({ technicianList });
    }
  };

  getItemClassName = (status: any, startTime: any, endTime: any) => {
    if (status === "complete") return "completed";
    else if (status === "in_progress") return "active";
    else if (status === "assigned" && moment().utc() > moment(endTime).utc())
      return "pending";
    else if (status === "assigned" && moment().utc() < moment(endTime).utc())
      return "upcoming";
    else return "";
  };

  handleGetAllocatedTaskList = (res: any) => {
    if (res.data) {
      const list = res.data
        .flatMap((x: any) => x.attributes)
        .filter((x: any) => x.assigned_to_id && x.start_time && x.end_time);
      let items: any = [];
      list.forEach((x: any) => {
        const className = this.getItemClassName(
          x.status,
          x.start_time,
          x.end_time
        );
        items.push({
          id: x.id,
          group: x.assigned_to_id,
          title: x.location_name || "-",
          className: className,
          start_time: moment(x.start_time).utc(),
          end_time: moment(x.end_time).utc(),
          canMove: className === "upcoming",
          canChangeGroup: className === "upcoming",
          address: x.location_address || "-",
        });
      });
      this.setState({
        timeline: { ...this.state.timeline, items },
      });
    }
  };

  handleAssignTasks = (res: any) => {
    if (res.message === "unavailable") {
      const leaveDetail: LeaveDetail = {
        open: true,
        reason: res.reason,
        duration: `${moment(res.start_date)
          .utc()
          .format("DD/MM/YYYY - HH:mm")} | ${moment(res.end_date)
          .utc()
          .format("DD/MM/YY - HH:mm")}`,
      };
      this.setState({ leaveDetail });
    } else if (res.message === "notWorking") {
      const workScheduleDetail: WorkScheduleDetail = {
        open: true,
        timing: `${String(res.work_hours_from).substring(0, 5)} - ${String(
          res.break_start
        ).substring(0, 5)} | ${String(res.break_end).substring(
          0,
          5
        )} - ${String(res.work_hours_to).substring(0, 5)}`,
      };
      this.setState({ workScheduleDetail });
    } else if (res.message === "onBreak") {
      const breakDetail: BreakDetail = {
        open: true,
        duration: `${String(res.break_start).substring(0, 5)} - ${String(
          res.break_end
        ).substring(0, 5)}`,
      };
      this.setState({ breakDetail });
    } else if (res.message === "anotherjob") {
      const anotherJobDetail = { open: true, title: res.title };
      this.setState({ anotherJobDetail });
    } else {
      this.getAllTaskList();
      this.getAllocatedTaskList();
    }
  };

  getAddress = (id: number | string) => {
    const item = this.state.timeline.items.find((x: any) => x.id == id);
    return item ? item.address : "-";
  };

  getDuration = (x: any) => {
    if (isNaN(x)) return "-";
    const format = moment
      .utc(moment.duration(x, "minutes").asMilliseconds())
      .format("HH:mm");
    let duration = `${format.substring(3)}m`;
    if (Number(format.substring(0, 2)) > 0)
      duration = `${format.substring(0, 2)}h ${duration}`;
    return duration;
  };

  getHourFormat = (x: number) => {
    return moment
      .utc(moment.duration(x, "minutes").asMilliseconds())
      .format("HH:mm");
  };

  getJobTypes = (temp: any[]) => {
    let jobTypes: any[] = [];
    if (temp[0]) jobTypes.push(temp[0].id[0]);
    if (temp[1]) jobTypes.push(temp[1].id[0]);
    if (temp.length > 2) jobTypes.push(`+${temp.length - 2}`);
    return jobTypes;
  };

  getVerticals = (verticals: any[]) => {
    let list = [];
    const trim = (x: any) => {
      const a = x.split(" ");
      return a.length > 2 ? a[0][0] + a[1][0] : a[0][0];
    };
    if (verticals[0]) list.push(trim(verticals[0]));
    if (verticals[1]) list.push(trim(verticals[1]));
    if (verticals.length > 2) list.push(`+${verticals.length - 2}`);
    return list;
  };

  onIncDecDate = (type: string) => {
    const updateDay = this.state.date
      .add(type === "inc" ? 1 : -1, "day")
      .startOf("day");
    this.setState({ date: updateDay }, () =>
      this.setState({
        timeline: {
          ...this.state.timeline,
          initStart: updateDay.valueOf(),
          initEnd: updateDay.add(8, "hours").valueOf(),
        },
      })
    );
  };

  onTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void
  ) => {
    const minTime = this.state.date.startOf("day").valueOf();
    const maxTime = this.state.date.endOf("day").valueOf();
    const diff = visibleTimeEnd - visibleTimeStart;
    let initStart = visibleTimeStart;
    let initEnd = visibleTimeEnd;
    if (visibleTimeStart < minTime) {
      initStart = minTime;
      initEnd = minTime + diff;
    } else if (visibleTimeEnd > maxTime) {
      initStart = maxTime - diff;
      initEnd = maxTime;
    }
    updateScrollCanvas(initStart, initEnd);
    this.setState({ timeline: { ...this.state.timeline, initStart, initEnd } });
  };

  onSeeAllBtnClick = () => this.setState({ showTask: !this.state.showTask });

  onView = (id: any) => {
    this.props.navigation.navigate("JobDetail", { id });
  };

  // NOTE: To implement pagination fumctionality
  onPageChange = (e: any, current: number) => {
    let allList = [...this.state.openTaskList.all];
    let start = current * pageSize - 3;
    let end = current * pageSize;
    if (this.state.openTaskList.all.length < end) {
      end = this.state.openTaskList.all.length;
    }
    const currentList = allList.splice(start - 1, 4);
    this.setState({
      openTaskList: { ...this.state.openTaskList, current: [...currentList] },
      pagination: { current, start: start, end: end },
    });
  };

  // NOTE: To collect drag id
  onDrag = (e: any) => {
    const task = this.state.openTaskList.current.find(
      (x: any) => x.id === Number(e.target.id)
    );
    if (task) {
      this.setState({
        allocationDetail: {
          ...new AllocationDetail(),
          taskId: task.id,
          title: task.location_name,
          duration: task.duration,
        },
      });
    }
  };

  // NOTE: To implement drop functionality
  onDrop = (e: any) => {
    const technician = this.state.technicianList.find(
      (x: any) => x.id === e.target.id
    );
    if (technician && !this.state.date.isSameOrBefore(moment().utc())) {
      this.setState({
        allocationDetail: {
          ...this.state.allocationDetail,
          open: true,
          technicianId: technician.id,
          technicianName: `${technician.first_name} ${technician.last_name}`,
          workHoursFrom: technician.work_hours_from,
        },
      });
    }
  };

  onAllocationDialog = (isConfirm: boolean) => {
    const detail = this.state.allocationDetail;
    if (isConfirm && detail.duration) {
      let startTime = this.state.date.set(
        "hours",
        moment(detail.workHoursFrom)
          .utc()
          .get("hours")
      );
      const updateTimeline: UpdateTimeline = {
        taskId: Number(detail.taskId),
        duration: Number(detail.duration),
        technicianName: detail.technicianName,
        start_time: startTime.toDate(),
        end_time: startTime.add(Number(detail.duration), "minutes").toDate(),
        account_id: Number(detail.technicianId),
        assign_any: false,
      };
      this.setState({ updateTimeline }, () => this.assignTask(updateTimeline));
    }
    this.setState({ allocationDetail: new AllocationDetail() });
  };

  onAssignAnyway = () => {
    this.assignTask({ ...this.state.updateTimeline, assign_any: true });
  };

  onLeaveDialog = (isConfirm: boolean) => {
    if (isConfirm) this.onAssignAnyway();
    this.setState({ leaveDetail: new LeaveDetail() });
  };

  onScheduleDialog = (isConfirm: boolean) => {
    if (isConfirm) this.onAssignAnyway();
    this.setState({ workScheduleDetail: new WorkScheduleDetail() });
  };

  onBreakDialog = (isConfirm: boolean) => {
    if (isConfirm) this.onAssignAnyway();
    this.setState({ breakDetail: new BreakDetail() });
  };

  onAnotherJobDialog = (isConfirm: boolean) => {
    if (isConfirm) this.onAssignAnyway();
    this.setState({ anotherJobDetail: new AnotherJobDetail() });
  };

  moveValidator = (action: any, itemId: any, time: number, resizeEdge: any) => {
    if (time < new Date().getTime()) {
      let newTime =
        Math.ceil(new Date().getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000);
      return newTime;
    }
    return time;
  };

  onItemMove = (itemId: any, dragStartTime: number, newGroupOrder: number) => {
    const technician = this.state.technicianList[newGroupOrder];
    const selectedItem = this.state.timeline.items.find(
      (x: any) => x.id === itemId
    );
    if (selectedItem && selectedItem.id && technician && technician.id) {
      const duration = moment(selectedItem.end_time)
        .utc()
        .diff(moment(selectedItem.start_time).utc(), "minutes");
      const updateTimeline: UpdateTimeline = {
        taskId: Number(selectedItem.id),
        duration: Number(duration),
        technicianName: `${technician.first_name} ${technician.last_name}`,
        start_time: moment(dragStartTime)
          .utc()
          .toDate(),
        end_time: moment(dragStartTime)
          .utc()
          .add(duration, "minutes")
          .toDate(),
        account_id: Number(technician.id),
        assign_any: false,
      };
      this.setState({ updateTimeline }, () => this.assignTask(updateTimeline));
    }
  };

  onCreateJob = () => {
    this.props.navigation.navigate("CreateJobOrder");
  };
  // Customizable Area End
}
