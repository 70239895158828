Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.getMethodType = "GET";
exports.patchMethodType = "PATCH";
exports.deleteMethodType = "DELETE";
exports.postMethodType = "POST";
exports.putMethodType = "PUT";

exports.getAllTasksApiEndPoint = "bx_block_tasks/tasks";
exports.getTechniciansApiEndPoint = "account_block/index";
exports.getAllocatedTasksApiEndPoint = "bx_block_tasks/tasks/fetch_allocated_jobs";
exports.assignTaskApiEndPoint = "assign_task_manually";

exports.getTechnicianTasksApiEndPoint = "bx_block_tasks/tasks/assigned_and_inprogess_task";

exports.taskBlock = "bx_block_tasks/tasks";
exports.rejectTaskEndPoint = "reject_job";
exports.startTaskEndPoint = "start_job";
exports.completeTaskEndPoint = "complete_job";

exports.checklistBlock = "bx_block_custom_form/checklists";
exports.getChecklistSearchEndPoint = "bx_block_advanced_search/checklist_search";

exports.getPdfApiEndPoint = "bx_block_invoice/certification_and_invoice"
// Customizable Area End