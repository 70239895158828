// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfautomaticallygeneratepdfcertification from "../../blocks/cfautomaticallygeneratepdfcertification/src/Cfautomaticallygeneratepdfcertification";
import Contexttagging from "../../blocks/contexttagging/src/Contexttagging";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Repeatingtasks2 from "../../blocks/repeatingtasks2/src/Repeatingtasks2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web";
import ResetPassword from "../../blocks/email-account-login/src/ResetPassword.web"
import ResetSuccess from "../../blocks/email-account-login/src/ResetSuccess.web"
import SuccessFully from "../../blocks/email-account-login/src/Success.web"
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Customform4 from "../../blocks/customform4/src/Customform4";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import DispatchUser from "../../blocks/email-account-registration/src/DispatchUser.web"
import BackOfficeUser from "../../blocks/email-account-registration/src/BackOffice.web"
import TechnicianUser from "../../blocks/email-account-registration/src/Technician.web"
import LandingHome from "../../blocks/email-account-registration/src/DashBoardHome.web"
import TechnicianLandingPage from "../../blocks/landingpage/src/TechnicianLandingPage.web";
import Calendar from "../../blocks/scheduling/src/CreateEvent.web";
import CompanyLandingPage from "../../blocks/landingpage/src/CompanyLandingPage.web";
import JobOrder from "../../blocks/tasks/src/JobOrder.web"
import JobOrderList from "../../blocks/tasks/src/JobOrderList.web"
import Employees from "../../blocks/settings2/src/Employees.web"
import JobDetail from "../../blocks/landingpage/src/JobDetail.web";
import CreateLocation from "../../blocks/accountgroups/src/CreateLocation.web"
import ViewClientProfile from "../../blocks/accountgroups/src/ViewClientProfile.web"
import CreateClient from "../../blocks/accountgroups/src/CreateClient.web"
import SingleClient from "../../blocks/accountgroups/src/ViewSingleClient.web"
import ViewLocation from "../../blocks/accountgroups/src/ViewLocation.web"
import CreateAsset from "../../blocks/accountgroups/src/CreateAsset.web"
import ViewAsset from "../../blocks/accountgroups/src/ViewAsset.web"
import CertificationDb from "../../blocks/cfautomaticallygeneratepdfcertification/src/CertificationDb.web";
import InvoiceDb from "../../blocks/invoicebilling/src/InvoiceDb.web";
import ChecklistProcess from "../../blocks/customform4/src/ChecklistProcess.web";
import EmployeeDetail from "../../blocks/settings2/src/EmployeeDetail.web";
import JobSummary from "../../blocks/landingpage/src/JobSummary.web";


const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfautomaticallygeneratepdfcertification:{
 component:Cfautomaticallygeneratepdfcertification,
path:"/Cfautomaticallygeneratepdfcertification"},
Contexttagging:{
 component:Contexttagging,
path:"/Contexttagging"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Calendar:{
  component:Calendar,
 path:"/Calendar"},
Repeatingtasks2:{
 component:Repeatingtasks2,
path:"/Repeatingtasks2"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
EmailAccountLogin:{
  component:EmailAccountLogin,
  path:"/Login"},
ResetSuccess:{
  component:ResetSuccess,
  path:"/ResetSuccess"},
ResetPassword:{
  component:ResetPassword,
  path:"/ResetPassword"},
  SuccessFully:{
    component:SuccessFully,
    path:"/Success"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Customform4:{
 component:Customform4,
path:"/Customform4"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
LandingHome:{
  component:LandingHome,
  path:"/LandingHome"
},
DispatchUser:{
  component:DispatchUser,
  path:"/Account-Creation/Dispatcher"
},
BackOfficeUser:{
  component:BackOfficeUser,
  path:"/Account-Creation/BackOffice"
},
TechnicianUser:{
  component:TechnicianUser,
  path:"/Account-Creation/Technician"
},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Employees:{
  component:Employees,
  path:"/Employees"
},
EmployeeDetail: {
  component: EmployeeDetail,
  path:"/EmployeeDetail"
},
// component:UserProfileBasicBlock,
  Home: {
component:EmailAccountLogin,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  TechnicianLandingPage: {
    component: TechnicianLandingPage,
    path: "/Dashboard-Technician",
  },
  CompanyLandingPage: {
    component: CompanyLandingPage,
    path: "/Dashboard-Company",
    exact: true,
  },
  JobDetail: {
    component: JobDetail,
    path: "/Database/JobOrders/JobDetail/:id",
    exact: true
  },
  CreateLocation:{
    component:CreateLocation,
    path:"/ClientDb/Location",
    exact:true
  },
  CreateLocations:{
    component:CreateLocation,
    path:"/ClientDb/Location/:id",
    exact:true
  },
  CreateAsset:{
    component:CreateAsset,
    path:"/ClientDb/Asset",
    exact:true
  },
  CreateAssets:{
    component:CreateAsset,
    path:"/ClientDb/Asset/:id",
    exact:true
  },
  ViewClientProfile:{
    component:ViewClientProfile,
    path:"/ClientDb",
    exact:true
  },
 
  CreateClient:{
    component:CreateClient,
    path:"/ClientDb/Client",
    exact:true
  },
  CreateClients:{
    component:CreateClient,
    path:"/ClientDb/Client/:id",
    exact:true
  },
  ViewLocation:{
    component:ViewLocation,
    path:"/ClientDb/ViewLocation/:id",
    exact:true
  },
  SingleClient:{
    component:SingleClient,
    path:"/ClientDb/ViewClient/:id",
    exact:true
  },
 ViewAsset:{
    component:ViewAsset,
    path:"/ClientDb/ViewAsset/:id",
    exact:true
  },
  JobOrder:{
    component:JobOrderList,
    path: "/Database/JobOrders",
    exact:true
  },
  CreateJobOrder:{
    component: JobOrder,
    path:"/Database/JobOrders/Create",
    exact:true
  },
  UpdateJobOrder:{
    component:JobOrder,
    path:"/Database/JobOrders/Update/:id",
    exact:true
  },
  CertificationDb:{
    component: CertificationDb,
    path: "/Database/Certifications",
    exact:true
  },
  InvoiceDb:{
    component: InvoiceDb,
    path: "/Database/Invoices",
    exact:true
  },
  ChecklistProcess:{
    component: ChecklistProcess,
    path: "/Database/JobOrders/Checklist/:taskId/:id",
    exact:true
  },
  JobSummary:{
    component: JobSummary,
    path: "/Database/JobOrders/JobSummary/:id",
    exact:true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw', overflowY: "hidden" }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
