import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { imgLogo } from "./assets";
import { Link  as NavLink} from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "../assets/style.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
  },
});
// Customizable Area End


import DisPatcherController, {
  Props
} from "./DisPatcherController.web";
export default class LandingHome extends DisPatcherController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container style={webStyles.backGdBoxHome} maxWidth="lg">
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={webStyles.headerSidBarHome}>
                <Box sx={webStyles.width100}>
                  <img src={imgLogo} width="60" />
                </Box>
                
                <Box>
                  <NavLink to="/Account-Creation/Dispatcher" style={{marginTop:"20px", display:"block", color:"#000", textDecoration:"none"}}>Account Creation</NavLink>
                </Box>
              </Box>
              <Box sx={webStyles.dispatchBoxHome}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h2" style={webStyles.dispatchTypoHome}>
                      {" "}
                      Landing page coming soon
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  backGdBoxHome: {
    background: "rgba(227, 190, 190, 0.28) none repeat scroll 0% 0%"

  },
  width100: {
    width: "100%"
  },
  headerSidBarHome: {
    paddingTop: "10px",
    background: "#fff",
    height: "100vh",
    textAlign: "center",
    width: "18vw",
    zIndex: "9",
    position: "fixed",
    bottom: "0px",
    top: "0px",
    left: "0",
    borderRight: "1px solid rgba(0, 0, 0, 0.18)"
  },
  dispatchBoxHome: {
    height: "100%",
    width: "83vw",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    marginLeft: "18vw"
  },
  dispatchTypoHome: {
    fontSize: "20px",
    lineHeight: "45px",
    display: "flex"
  },

};

// Customizable Area End
