import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isEdit: boolean;
  accountId: string;
  backOffice: boolean;
  dispatcher: boolean;
  technician: boolean;
  showAcordianBox: boolean;
  
  showModal: any;
  userInfo: {
    dis_id: any;
    fullName: string;
    email: any;
    address: string;
    phoneNo: any;
    salary: any;
    SocialSuqNo: any;
  workHoursFromSelect: any;
  workHoursToSelect: any;
  breakEndSelect: any;
  breakStartSelect: any;
  }
  modalEmail: string,
  modalPassword: string,
  errorEmailExist: string
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class DisPatcherController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserApiCallId = "";
  patchUserApiCallId = "";
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  createDispatchApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isEdit: false,
      accountId: "",
      backOffice: false,
      dispatcher: true,
      technician: false,
      showAcordianBox: false,
      showModal: false,
      userInfo: {
        dis_id: "",
        fullName: "",
        email: "",
        address: "",
        phoneNo: "",
        salary: "",
        SocialSuqNo: "",
      workHoursFromSelect: "",
      workHoursToSelect: "",
      breakEndSelect: "",
      breakStartSelect: "",

      },
      modalEmail: "",
      modalPassword: "",
      errorEmailExist: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.

    if (this.createDispatchApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message) {
        this.setState({
          modalEmail: responseJson.data.data.attributes.email,
          modalPassword: responseJson.data.data.attributes.user_password,
          showModal: true,
          errorEmailExist: "",
          userInfo: { address: '', email: '', fullName: '', dis_id: '', phoneNo: '', salary: '', SocialSuqNo: '', workHoursFromSelect: '', workHoursToSelect: '', breakStartSelect: '', 
          breakEndSelect: '', },
                   
        });
      }
      else if(responseJson.error){
        this.setState( {errorEmailExist: responseJson?.error})
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const successData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      const errorData = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
      if (this.getUserApiCallId === messageId) {
        this.handleGetUserApiCall(successData,errorData)
      } else if (this.patchUserApiCallId === messageId) {
        if (!successData.errors) {
          this.setState({ showModal: true })
        } else {
          this.setState({ errorEmailExist: successData?.error ?? successData?.errors?.[0] })
        }
      }
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.NavigationNavigateData)
      )
      this.handleReceiveNavigationDispatcher(data)
    }

    // Condition for removing account to the group
  };

  handleReceiveNavigationDispatcher = (data: any) => {
    if (data?.id) {
      let getUserApiCallDispatcher = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.getUserApiCallId = getUserApiCallDispatcher.messageId

      getUserApiCallDispatcher.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.accountBlockSearchAccountId+data.id
      )
      getUserApiCallDispatcher.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodGet,
      )
      getUserApiCallDispatcher.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Token": localStorage.getItem("token")
        })
      )

      runEngine.sendMessage(getUserApiCallDispatcher.id, getUserApiCallDispatcher)

      this.setState({
        accountId: data.id
      })
    }
  };

  handleGetUserApiCall = (successData: any, _: any) => {
    const { data: { attributes: {
      address,
      email,
      first_name,
      last_name,
      full_phone_number,
      salary,
      social_security_number: SocialSuqNo,
      dispatcher_id: dis_id,
      work_hours_to: wht,
      work_hours_from: whf,
      break_end: be,
      break_start: bs,
    }}} = successData
    let breakEndSelect = be === "" || !be ? "" : this.extractTimeFromDateStringDispatcher(be, false)
    let breakStartSelect = bs === "" || !bs ? "" : this.extractTimeFromDateStringDispatcher(bs, false)
    let workHoursToSelect = wht === "" || !wht ? "" : this.extractTimeFromDateStringDispatcher(wht, false)
    let workHoursFromSelect = whf === "" || !whf ? "" : this.extractTimeFromDateStringDispatcher(whf, false)
    this.setState({
      userInfo: {
        address,
        email,
        fullName: `${first_name}${last_name.length > 0 ? " " : ""}${last_name}`,
        phoneNo: full_phone_number,
        dis_id,
        salary,
        SocialSuqNo,
        workHoursFromSelect,
        workHoursToSelect,
        breakStartSelect,
        breakEndSelect,
      },
      showAcordianBox: breakEndSelect !== "" && breakStartSelect !== "",
      isEdit: true
    });
  };

  goToBackOffice = () => {
    this.props.navigation.navigate("BackOfficeUser");
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };
  clickAcordianBtn = () => {

    this.setState({
       showAcordianBox: !this.state.showAcordianBox
    });
  };
  goToTechnician = () => {
    this.props.navigation.navigate("TechnicianUser");
  };
  goToDisPatcher = () => {
    this.props.navigation.navigate("DispatchUser");
  };
  goToListD = () => {
    const msgD = new Message(
      getName(MessageEnum.NavigationNavigate)
    )
    msgD.addData(
      getName(MessageEnum.NavigationNavigateData),
      {
        route: "Employees"
      }
    )
    msgD.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msgD)
  }
 
  handleSubmit = (values: any, { resetForm }: any) => {
    resetForm({
      values: {
        fullName: this.state.userInfo.fullName,
        email: values.email, // Keep the email value
        dis_id: this.state.userInfo.dis_id,
        address: this.state.userInfo.address,
        phoneNo: this.state.userInfo.phoneNo,
        workHoursFromSelect: this.state.userInfo.phoneNo,
        workHoursToSelect: this.state.userInfo.workHoursToSelect,
        breakStartSelect: this.state.userInfo.breakStartSelect,
        breakEndSelect: this.state.userInfo.breakEndSelect,
        salary: this.state.userInfo.salary,
        SocialSuqNo: this.state.userInfo.SocialSuqNo,

      },
   }); 
   if (!this.state.isEdit) {
     this.handlePostDispatch()
   } else {
    this.handlePatchDispatch()
   }
 };
  
  handlePostDispatch = () => {

    let headers = {
      "Content-Type": "application/json",
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "user_type": "dispatcher",
      "account": {
        "first_name": this.state.userInfo.fullName,
        "last_name": "",
        "email": this.state.userInfo.email,
        "address": this.state.userInfo.address,
        "full_phone_number": this.state.userInfo.phoneNo,
        "work_hours_from": this.state.userInfo.workHoursFromSelect,
        "work_hours_to": this.state.userInfo.workHoursToSelect,
        "break_start": this.state.userInfo.breakStartSelect,
        "break_end": this.state.userInfo.breakEndSelect,
        "salary": this.state.userInfo.salary,
        "social_security_number": this.state.userInfo.SocialSuqNo,
        "dispatcher_id": this.state.userInfo.dis_id
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDispatchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointCreateAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDispatcherDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePatchDispatch = () => {
    let headers = {
      "Content-Type": configJSON.validationApiContentType,
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
      "account_id": this.state.accountId,
      "first_name": this.state.userInfo.fullName,
      "last_name": "",
      "email": this.state.userInfo.email,
      "address": this.state.userInfo.address,
      "full_phone_number": this.state.userInfo.phoneNo,
      "salary": this.state.userInfo.salary,
      "social_security_number": this.state.userInfo.SocialSuqNo,
      "dispatcher_id": this.state.userInfo.dis_id,
      "work_hours_from": this.state.userInfo.workHoursFromSelect,
      "work_hours_to": this.state.userInfo.workHoursToSelect,
      "break_start": this.state.userInfo.breakStartSelect,
      "break_end": this.state.userInfo.breakEndSelect,
    }

    const requestMessageDispatcher = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchUserApiCallId = requestMessageDispatcher.messageId;
    requestMessageDispatcher.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountBlockUpdateAccount
    );
    requestMessageDispatcher.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessageDispatcher.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessageDispatcher.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodPatch
    );

    runEngine.sendMessage(requestMessageDispatcher.id, requestMessageDispatcher);
  }

  getStartingBreakTime = (timelist: any, startWork: any, endWork: any) => {
    let timeOptions = []
    timeOptions =
      timelist.slice(timelist.findIndex((item: any) => item.value === startWork), timelist.findIndex((item: any) => item.value === endWork)).slice(1)

    return timeOptions;
  }

  getBreakEndTime = (timelist: any, startBreakTime: any, endWorkTime: any) => {
    let timeOptions = []
    timeOptions =
      timelist.slice((timelist.findIndex((item: any) => item.value === startBreakTime) + 1), timelist.findIndex((item: any) => item.value === endWorkTime))

    return timeOptions;
  }

  extractTimeFromDateStringDispatcher = (dateString: string, isPaddedHour: boolean): string => {
    const inputDate = new Date(dateString);
    const hrs = inputDate.getUTCHours();
    const min = inputDate.getUTCMinutes();
    let result = ""
    if (isPaddedHour) {
      result = `${hrs.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
    } else {
      result = `${hrs.toString()}:${min.toString().padStart(2, '0')}`;
    }
    return result;
  }
  // Customizable Area End
}
