import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  maintainer_id: string;
  floor: number[];
  task: any;
  checklist: any[];
  isInvPdf: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobSummaryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskDetailApiId = "";
  getPdfApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      // Customizable Area Start
      maintainer_id: "",
      task: {},
      checklist: [],
      floor: [],
      isInvPdf: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getTaskDetailApiId) {
        this.handleTaskDetailRes(responseJson);
      } else if (apiRequestCallId === this.getPdfApiId) {
        this.handleDownloadPdf(responseJson);
      }
    }
  };
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    const userDataStr = localStorage.getItem("userData");
    const id = this.props.navigation.getParam("id");
    if (userDataStr && id) {
      const userData = JSON.parse(userDataStr);
      this.setState({ maintainer_id: userData.maintainer_id }, () =>
        this.getTaskDetail(id)
      );
    }
  }

  getTaskDetail = (id: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTaskDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.taskBlock}/${id}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPdf = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let urlParams = new URLSearchParams({
      task_id: this.state.task.id,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    this.getPdfApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPdfApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleTaskDetailRes = (res: any) => {
    if (res.data.attributes) {
      const checklist = res.data.attributes.checklists.data.map((x: any) => ({
        checklistId: Number(x.id),
        ...x.attributes,
        asset: {
          ...x.attributes.asset.data.attributes,
          assetId: Number(x.attributes.asset.data.id),
        },
      }));
      let floor: any[] = [];
      checklist.forEach((x: any) => {
        let { floor_number } = x.asset;
        floor_number = floor_number || 0;
        if (!floor.includes(floor_number)) floor.push(floor_number);
      });
      this.setState({ task: res.data.attributes, checklist, floor });
    }
  };

  handleDownloadPdf = (res: any) => {
    if (this.state.isInvPdf && res.invoice) window.open(res.invoice, "_blank");
    else if (res.certificate) window.open(res.certificate, "_blank");
  };

  toChecklist = (id: any) => {
    this.props.navigation.navigate("ChecklistProcess", {
      taskId: this.state.task.id,
      id,
    });
  };

  getFieldValue = (x: any) => x || "-";

  getFilteredList = (floor: number) => {
    return this.state.checklist.filter(
      (x: any) => x.asset.floor_number == floor
    );
  };

  getNoteClass = (x: any) => (x ? "blue" : "light-blue");

  getResult = (x: any) => {
    let value = { class: "yellow", status: "Success" };
    if (typeof x.success !== "boolean")
      value = { class: "gray", status: "Pending" };
    else if (!x.success) value = { class: "red", status: "Failure" };
    else if (!x.has_notes) value = { class: "green", status: "Success" };
    return value;
  };

  onDownload = (isInvPdf: boolean) => {
    this.setState({ isInvPdf }, this.getPdf);
  };
  // Customizable Area End
}
