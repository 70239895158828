import React, { useEffect } from "react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
//@ts-ignore
import FullCalendar from '@fullcalendar/react';



const CustomCalender = (props:any) =>  {
  const fixTimeLabel = () => {
    const timeLabels = document.querySelectorAll('.fc-timegrid-slot-label-cushion');
   timeLabels.forEach((label) => {
      if (label.textContent === '24:00' || label.textContent === '00:0024:00') {
       return label.textContent = '00:00';
      }
    });
  };
  useEffect(() => {
    // Call fixTimeLabel initially
    fixTimeLabel();
    const intervalId = setInterval(() => {
      fixTimeLabel();
    }, 50); 
    return () => clearInterval(intervalId);
  }, []);

        const {state,
            handleEventClick
        } = props
        return (

           <>
            <FullCalendar
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
  initialView="timeGridDay"
  headerToolbar={{
    right: 'prev,next,timeGridDay,timeGridWeek,dayGridMonth',
  }}
  allDaySlot={false}
  eventTimeFormat={{
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Display in 24-hour format
  }}
  slotLabelFormat={{
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Display in 24-hour format
  }}
  events={state.events}
  eventContent={(arg: any) => {
    const closeIcon = ``;
    const eventTitle = closeIcon + arg.event.title;

    return { html: eventTitle };
  }}
  eventClick={(arg: any) => {
    if (arg.jsEvent.target.classList.contains('close-icon')) {
      // If the click target is the close icon, remove the event
      const eventId = arg.event.title;
      handleEventClick(eventId);
    }
  }}
  
  // eventColor="rgba(193, 15, 15, 0.94)"
  themeSystem="bootstrap"
  nowIndicator
  slotLabelInterval={{ hours: 1 }}
/>

           </>
            )
}

export default CustomCalender;

