import React from "react";
// Customizable Area Start
import {
  Avatar,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  Checkbox,
  Divider,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";

import Layout from "./Layout.web";
import JobDetailController, {
  IOption,
  Props,
  rejectReasonOptions,
} from "./JobDetailController.web";
import {
  BackChevronIcon,
  ContactPrimaryIcon,
  DeleteOutlinedIcon,
  DownAccordionIcon,
  EditPenIcon,
  PlaceBlackIcon,
  RightChevronIcon,
  SearchGrayIcon,
  UpAccordionIcon,
  WatchLaterIcon,
} from "./assets";
import ResponsiveDialog from "./ResponsiveDialog.web";
import "../assets/scroll.css";
import "../assets/JobDetail.css";
import { CustomTooltip } from "./CompanyLandingPage.web";

export const CustomBtn = styled(Button)({
  textTransform: "none" as "none",
  fontWeight: "bold" as "bold",
  fontSize: 12,
  "&.mr-4": { marginRight: 4 },
  "&.primary": { color: "#ffffff", backgroundColor: "#d23637" },
  "&.secondary": { color: "#d23637", backgroundColor: "#f4cccc" },
  "&.d-none": { display: "none" },
  "& .MuiButton-startIcon": { margin: 0 },
  "& .MuiAvatar-root": { height: 20, width: 20, marginRight: 4 },
});
const CustomSearch = styled(TextField)({
  border: "none !important",
  "& .MuiInputBase-root": {
    backgroundColor: "#ffffff",
    color: "#777",
    height: 32,
    borderRadius: 16,
  },
  "& .Mui-focused": { borderColor: "#000" },
  "& .MuiAvatar-root": { height: 24, width: 24 },
});
export const CustomIconBtn = styled(IconButton)({
  padding: 0,
  "& .MuiAvatar-root": { height: 32, width: 32 },
  "& .MuiAvatar-root.asset": { height: 24, width: 24 },
  "&.filter .MuiAvatar-img": { height: "64%", width: "64%" },
  "& .bg-pink": { backgroundColor: "#f4cccc", color: "#d23637" },
  "& .bg-red": { backgroundColor: "#d23637", color: "#ffffff" },
  "&.filter .MuiSvgIcon-root": { height: "72%", width: "72%" },
});
export const AssetContainer = styled(TableContainer)((props) => ({
  maxHeight: "80%",
  marginTop: 8,
  [props.theme.breakpoints.up("md")]: { maxHeight: "88%" },
  "& .MuiTableCell-head": { fontWeight: 700, backgroundColor: "#fff" },
  "& .MuiTableCell-root": { fontSize: "12px !important" },
  "& .red": { color: "#d23637", fontWeight: 600, fontSize: 12, padding: 0 },
  "& .green": { color: "#128963", fontWeight: 600, fontSize: 12, padding: 0 },
  "& .yellow": { color: "#d2a65e", fontWeight: 600, fontSize: 12, padding: 0 },
  "& .gray": { color: "#999999", fontWeight: 600, fontSize: 12, padding: 0 },
  "& .blue": { color: "#5c82c0", fontWeight: 600, fontSize: 12, padding: 0 },
  "& .light-blue": {
    color: "#aebcd4",
    fontWeight: 600,
    fontSize: 12,
    padding: 0,
  },
  "& .d-none": { display: "none" },
}));

const CustomHeader = (props: { title: string; onBack: () => void }) => {
  const { title, onBack } = props;
  const styles = {
    paddingLeft: 8,
    marginBottom: 8,
    borderRadius: 8,
    backgroundColor: "#fff",
  };
  return (
    <Typography component="div" style={styles}>
      <CustomIconBtn data-test-id="back" onClick={onBack}>
        <Avatar variant="square" src={BackChevronIcon} />
      </CustomIconBtn>
      {title}
    </Typography>
  );
};

const EditDeleteBtnWrapper = (props: {
  isDisabled: boolean;
  visibleclass: string;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  const { isDisabled, visibleclass, onEdit, onDelete } = props;
  return (
    <>
      <CustomBtn
        data-test-id="editJob"
        className={`primary ${visibleclass}`}
        startIcon={<Avatar variant="square" src={EditPenIcon} />}
        disabled={isDisabled}
        onClick={onEdit}
      >
        Edit Job Order
      </CustomBtn>
      <CustomBtn
        data-test-id="deleteJob"
        className={`secondary ${visibleclass}`}
        startIcon={<Avatar variant="square" src={DeleteOutlinedIcon} />}
        disabled={isDisabled}
        onClick={onDelete}
      >
        Delete Job Order
      </CustomBtn>
    </>
  );
};

export const DownloadBtnWrapper = (props: {
  visibleClass: string;
  onDownload: (x: boolean) => void;
}) => {
  const { visibleClass, onDownload } = props;
  return (
    <>
      <CustomBtn
        data-test-id="downloadInvoice"
        className={`primary ${visibleClass}`}
        onClick={() => onDownload(true)}
      >
        Download Invoice
      </CustomBtn>
      <CustomBtn
        data-test-id="downloadCertificate"
        className={`secondary ${visibleClass}`}
        onClick={() => onDownload(false)}
      >
        Download Certificate
      </CustomBtn>
    </>
  );
};

const FilterWrapper = (props: {
  filter: { anchorEl: any; menus: string[] };
  floor: { current: number[]; filtered: number[]; all: number[] };
  type: { current: string[]; filtered: string[]; all: string[] };
  onOpenClose: (e: any) => void;
  onCategory: (menu: string) => void;
  onType: (x: string) => void;
  onFloor: (x: number) => void;
  onApply: () => void;
}) => {
  const {
    floor,
    type,
    onOpenClose,
    filter,
    onCategory,
    onType,
    onFloor,
    onApply,
  } = props;
  const icon = (isUp: boolean) => (isUp ? UpAccordionIcon : DownAccordionIcon);
  return (
    <>
      <CustomIconBtn
        data-test-id="filterIcon"
        className="filter"
        onClick={(e: any) => onOpenClose(e.currentTarget)}
      >
        <Avatar
          variant="rounded"
          className={Boolean(filter.anchorEl) ? "bg-red" : "bg-pink"}
        >
          <FilterListIcon />
        </Avatar>
      </CustomIconBtn>
      <Menu
        data-test-id="filterMenu"
        anchorEl={filter.anchorEl}
        open={Boolean(filter.anchorEl)}
        onClose={() => onOpenClose(null)}
        marginThreshold={72}
      >
        <MenuItem
          data-test-id={`menuItemType`}
          disableGutters
          style={fStyles.option}
          onClick={() => onCategory("type")}
        >
          <Typography style={fStyles.title}>Asset Type</Typography>
          <Avatar
            style={fStyles.avatar}
            src={icon(filter.menus.includes("type"))}
          />
        </MenuItem>
        <Divider />
        <Collapse in={filter.menus.includes("type")}>
          {type.all.map((x: string, optIndex: number) => (
            <MenuItem key={optIndex} style={fStyles.option}>
              <FormControlLabel
                data-test-id={`type${optIndex}`}
                control={
                  <Checkbox
                    style={fStyles.checkbox}
                    checked={type.current.includes(x)}
                    color="secondary"
                    onChange={() => onType(x)}
                  />
                }
                label={<Typography style={fStyles.title}>{x}</Typography>}
              />
            </MenuItem>
          ))}
          <Divider />
        </Collapse>
        <MenuItem
          data-test-id={`menuItemFloor`}
          disableGutters
          style={fStyles.option}
          onClick={() => onCategory("floor")}
        >
          <Typography style={fStyles.title}>Floor</Typography>
          <Avatar
            style={fStyles.avatar}
            src={icon(filter.menus.includes("floor"))}
          />
        </MenuItem>
        <Divider />
        <Collapse in={filter.menus.includes("floor")}>
          {floor.all.map((x: number, optIndex: number) => (
            <MenuItem key={optIndex} style={fStyles.option}>
              <FormControlLabel
                data-test-id={`floor${optIndex}`}
                control={
                  <Checkbox
                    style={fStyles.checkbox}
                    checked={floor.current.includes(x)}
                    color="secondary"
                    onChange={() => onFloor(x)}
                  />
                }
                label={<Typography style={fStyles.title}>Floor {x}</Typography>}
              />
            </MenuItem>
          ))}
          <Divider />
        </Collapse>
        <MenuItem style={fStyles.option}>
          <Typography
            data-test-id="applyFilter"
            style={fStyles.applyBtn}
            onClick={onApply}
          >
            Apply filters
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
// Customizable Area End

export default class JobDetail extends JobDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { task } = this.state;
    return (
      <Layout navigation={this.props.navigation}>
        <CustomHeader data-test-id="header" title="Back" onBack={this.onBack} />
        <div style={styles.container}>
          <Grid container spacing={1} style={styles.mh108}>
            <Grid item xs={8}>
              <Typography
                data-test-id="locationName"
                variant="h6"
                style={styles.title}
                onClick={this.toLocation}
              >
                {this.getFieldValue(task.location_name)}
              </Typography>
              <div style={styles.dFlex}>
                <Avatar style={styles.subIcon} src={PlaceBlackIcon} />
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(task.location_address)}
                </Typography>
              </div>
              <div style={styles.dFlex}>
                <Avatar style={styles.subIcon} src={ContactPrimaryIcon} />
                <Typography style={styles.subtxt}>
                  {task.contact_details}
                </Typography>
              </div>
              <div style={styles.dFlex}>
                <Avatar style={styles.subIcon} src={WatchLaterIcon} />
                <Typography style={styles.subtxt}>
                  {" "}
                  {task.opening_hours}
                </Typography>
              </div>
            </Grid>
            <Grid item style={styles.alignRight} xs={4}>
              <div style={styles.btnDiv}>
                <Hidden smDown>
                  <EditDeleteBtnWrapper
                    data-test-id="EditDeleteWrapperWeb"
                    isDisabled={this.isActionDisabled(task.status)}
                    onEdit={this.onEdit}
                    onDelete={this.onDelete}
                    visibleclass={this.getActionClass(!this.state.isTechnician)}
                  />
                </Hidden>
                <Hidden smDown>
                  <CustomBtn
                    data-test-id="startJobWeb"
                    className={`primary ${this.getActionClass()}`}
                    disabled={this.isActionDisabled(task.status)}
                    onClick={this.startTask}
                  >
                    Start Job
                  </CustomBtn>
                  <DownloadBtnWrapper
                    data-test-id="downloadBtnWrapperWeb"
                    visibleClass={this.getCompletionClass()}
                    onDownload={this.onDownload}
                  />
                </Hidden>
                <CustomBtn
                  data-test-id="editJob"
                  className={`secondary ${this.getActionClass()}`}
                  disabled={this.isActionDisabled(task.status)}
                  onClick={this.onEdit}
                >
                  Edit Job
                </CustomBtn>
                <CustomBtn
                  data-test-id="rejectJob"
                  className={`secondary ${this.getActionClass()}`}
                  disabled={this.isActionDisabled(task.status)}
                  onClick={this.onReject}
                >
                  Reject Job
                </CustomBtn>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6} md={4}>
              <Typography style={styles.subTitle}>JOB ORDER ID</Typography>
              <Typography style={styles.subtxt}>
                {this.getFieldValue(task.job_order_id)}
              </Typography>
              <Typography style={styles.subTitle}>CUSTOMER ID</Typography>
              <Typography style={styles.subtxt}>
                {this.getFieldValue(task.customer_id)}
              </Typography>
              <Typography style={styles.subTitle}>LOCATION ID</Typography>
              <Typography style={styles.subtxt}>
                {this.getFieldValue(task.locationid)}
              </Typography>
              <Typography style={styles.subTitle}>ACCESS CONTROL</Typography>
              <Typography style={styles.subtxt}>
                {task.access_control ? "Yes" : "No"}
              </Typography>
              <Typography style={styles.subTitle}>NUMBER OF ASSETS</Typography>
              <Typography style={styles.subtxt}>
                {task.asset_ids ? task.asset_ids.length : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography style={styles.subTitle}>DURATION</Typography>
              <Typography style={styles.subtxt}>
                {this.getFieldValue(task.duration)}
              </Typography>
              <Typography style={styles.subTitle}>PRICE</Typography>
              <Typography style={styles.subtxt}>
                {this.getFieldValue(task.price)}
              </Typography>
              <Typography style={styles.subTitle}>LOCATION COMMENT</Typography>
              <Typography style={styles.subtxt}>
                {this.getFieldValue(task.location_comments)}
              </Typography>
              <Typography style={styles.subTitle}>JOB ORDER COMMENT</Typography>
              <Typography style={styles.subtxt}>
                {this.getFieldValue(task.job_order_comments)}
              </Typography>
            </Grid>
          </Grid>
          <Hidden mdUp>
            <div style={styles.resBtnDiv}>
              <EditDeleteBtnWrapper
                data-test-id="EditDeleteWrapper"
                isDisabled={this.isActionDisabled(task.status)}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                visibleclass={this.getActionClass(!this.state.isTechnician)}
              />
              <CustomBtn
                data-test-id="startJob"
                className={`primary ${this.getActionClass()}`}
                disabled={this.isActionDisabled(task.status)}
                onClick={this.startTask}
              >
                Start Job
              </CustomBtn>
              <DownloadBtnWrapper
                data-test-id="doenloadBtnWrapper"
                visibleClass={this.getCompletionClass()}
                onDownload={this.onDownload}
              />
            </div>
          </Hidden>
        </div>
        <div style={{ ...styles.container, ...styles.assetContainer }}>
          <Grid container alignItems="center">
            <Hidden smDown>
              <Grid item xs={6}>
                <CustomSearch
                  data-test-id="assetSearchWeb"
                  variant="outlined"
                  placeholder="Search"
                  color="secondary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Avatar variant="square" src={SearchGrayIcon} />
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.search}
                  onChange={this.onSearch}
                />
              </Grid>
              <Grid item style={styles.alignRight} xs={6}>
                <CustomBtn
                  data-test-id="completeJobWeb"
                  className={`primary mr-4 ${this.getActionClass()}`}
                  disabled={this.isChecklistCompletionDisabled(task.status)}
                  onClick={this.onComplete}
                >
                  Complete Job Order
                </CustomBtn>
                <FilterWrapper
                  data-test-id="filterWeb"
                  filter={this.state.filter}
                  floor={this.state.floor}
                  type={this.state.type}
                  onOpenClose={this.onOpenCloseFilter}
                  onCategory={this.onOptCollapse}
                  onType={this.onTypeChange}
                  onFloor={this.onFloorChange}
                  onApply={this.onApplyFilter}
                />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={4}>
                <Typography style={styles.assetTitle}>Assets</Typography>
              </Grid>
              <Grid item style={styles.alignRight} xs={8}>
                <CustomBtn
                  data-test-id="completeJob"
                  className={`primary ${this.getActionClass()}`}
                  disabled={this.isChecklistCompletionDisabled(task.status)}
                  onClick={this.onComplete}
                >
                  Complete Job Order
                </CustomBtn>
              </Grid>
              <Grid item style={styles.mt8} xs={10}>
                <CustomSearch
                  data-test-id="assetSearch"
                  variant="outlined"
                  placeholder="Search"
                  color="secondary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Avatar variant="square" src={SearchGrayIcon} />
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.search}
                  onChange={this.onSearch}
                />
              </Grid>
              <Grid item xs={2} style={{ ...styles.alignRight, ...styles.mt8 }}>
                <FilterWrapper
                  data-test-id="filter"
                  filter={this.state.filter}
                  floor={this.state.floor}
                  type={this.state.type}
                  onOpenClose={this.onOpenCloseFilter}
                  onCategory={this.onOptCollapse}
                  onType={this.onTypeChange}
                  onFloor={this.onFloorChange}
                  onApply={this.onApplyFilter}
                />
              </Grid>
              <Grid item style={styles.alignRight} xs={6}></Grid>
            </Hidden>
          </Grid>
          <AssetContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Asset Type</TableCell>
                  <TableCell align="center">Asset ID</TableCell>
                  <TableCell align="center">Mfg. Date</TableCell>
                  <TableCell align="center">Location No.</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Job Type</TableCell>
                  <TableCell
                    colSpan={3}
                    className={this.getCompletionClass()}
                  />
                  <TableCell
                    className={`red ${this.getActionClass()}`}
                    align="center"
                  >
                    See All Assets
                  </TableCell>
                  <TableCell
                    className={this.getActionClass(!this.state.isTechnician)}
                    align="center"
                  >
                    Duration
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.floor.filtered.map((a: number, index: number) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell align="left">Floor {a}</TableCell>
                      <TableCell colSpan={this.isStatusComplete() ? 8 : 6} />
                    </TableRow>
                    {this.getFilteredAssets(a).map(
                      (x: any, subIndex: number) => (
                        <TableRow key={subIndex}>
                          <TableCell align="left">
                            {x.asset.asset_type}
                          </TableCell>
                          <TableCell align="center">
                            {x.asset.asset_id}
                          </TableCell>
                          <TableCell align="center">
                            {x.asset.manufacturing_date}
                          </TableCell>
                          <TableCell align="center">
                            {x.asset.location_number}
                          </TableCell>
                          <TableCell align="center">
                            {x.asset_status || "Not-started"}
                          </TableCell>
                          <TableCell align="center">{x.job_type}</TableCell>
                          <TableCell className={this.getCompletionClass()}>
                            <CustomTooltip
                              placement="top-start"
                              title={
                                <React.Fragment>
                                  <Typography className="title">
                                    Notes
                                  </Typography>
                                  <Typography className="address">
                                    {this.getFieldValue(x.notes)}
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                              <Typography
                                className={this.getNoteClass(x.has_notes)}
                              >
                                Notes
                              </Typography>
                            </CustomTooltip>
                          </TableCell>
                          <TableCell
                            className={`${this.getCompletionClass()} ${
                              this.getResult(x).class
                            }`}
                          >
                            {this.getResult(x).status}
                          </TableCell>
                          <TableCell className={this.getCompletionClass()}>
                            <CustomIconBtn
                              data-test-id={`rightIcon${index}${subIndex}`}
                              onClick={() => this.toChecklist(x.checklistId)}
                            >
                              <Avatar
                                className="asset"
                                src={RightChevronIcon}
                              />
                            </CustomIconBtn>
                          </TableCell>
                          <TableCell
                            className={this.getActionClass()}
                            align="center"
                          >
                            <IconButton
                              data-test-id={`start${index}${subIndex}`}
                              className="red"
                              disabled={this.isChecklistCompletionDisabled(
                                task.status
                              )}
                              onClick={() =>
                                this.updateChecklistStatus(
                                  x.checklistId,
                                  x.asset_status
                                )
                              }
                            >
                              Start
                            </IconButton>
                          </TableCell>
                          <TableCell
                            className={this.getActionClass(
                              !this.state.isTechnician
                            )}
                            align="center"
                          >
                            {x.asset.duration}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </AssetContainer>
        </div>

        <ResponsiveDialog
          data-test-id="completeDialog"
          open={this.state.open.complete}
          okText="Submit"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.handleCompleteDialog}
        >
          <Typography style={dialogStyles.fBold}>
            Are you sure you want to complete the Job Order?
          </Typography>
          <Typography style={dialogStyles.f12}>
            {`There are ${this.getPendingAsset()} items pending.`}
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="deleteDialog"
          open={this.state.open.delete}
          okText="Delete Job Order"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.handleDeleteDialog}
        >
          <Typography style={dialogStyles.fBold}>
            Are you sure you want to delete the Job Order?
          </Typography>
          <Typography style={dialogStyles.f12}>
            This action can't be undone.
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="rejectDialog"
          open={this.state.open.reject}
          okText="Confirm"
          cancelText="Cancel"
          maxWidth="sm"
          handleDialog={this.handleRejectDialog}
          isConfirmDisabled={this.state.rejectDetail.isConfirmDisabled}
        >
          <Typography style={dialogStyles.fBold}>
            {`Are you sure you want to Reject "${task.location_name}"?`}
          </Typography>
          <Typography style={dialogStyles.f12}>{`Date: ${moment(
            task.start_time || new Date()
          ).format("DD/MM/YYYY")}`}</Typography>
          <Typography style={dialogStyles.f12}>{`Time: ${this.convertToHour(
            Number(task.duration || 0)
          )}`}</Typography>
          <Typography style={dialogStyles.fieldTitle}>Select Reason</Typography>
          <TextField
            data-test-id="rejectReason"
            select
            fullWidth
            variant="outlined"
            color="secondary"
            className="dField"
            placeholder="Select reason for rejecting"
            value={this.state.rejectDetail.reasonId}
            onChange={this.onRejectReasonChange}
          >
            {rejectReasonOptions.map((option: IOption, index: number) => (
              <MenuItem key={index} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          {this.state.rejectDetail.isSpecify && (
            <>
              <Typography style={dialogStyles.fieldTitle}>
                Please Specifiy
              </Typography>
              <TextField
                data-test-id="specificReason"
                fullWidth
                variant="outlined"
                color="secondary"
                className="dField"
                value={this.state.rejectDetail.specificReason}
                onChange={this.onSpecificReasonChange}
              />
            </>
          )}
        </ResponsiveDialog>
      </Layout>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  dFlex: { display: "flex" },
  mh108: { minHeight: 108 },
  alignRight: { textAlign: "right" as "right" },
  container: {
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: "16px 12px 16px 16px",
  },
  title: { fontWeight: "bold" as "bold", textDecorationLine: "underline" },
  subTitle: { fontWeight: "bold" as "bold", fontSize: 13 },
  subtxt: { fontSize: 12, alignItems: "center" },
  subIcon: { height: 20, width: 20, marginRight: 4 },
  btnDiv: { display: "inline-grid", gap: 8 },
  resBtnDiv: { display: "grid", gap: 8, marginTop: 8 },

  assetTitle: { fontSize: 14, fontWeight: "bold" as "bold" },
  assetContainer: { marginTop: 12, height: 420 },
  mt8: { marginTop: 8 },
};

export const dialogStyles = {
  fBold: { fontWeight: "bold" as "bold" },
  f12: { fontSize: 12 },
  fieldTitle: { fontSize: 10, fontWeight: "bold" as "bold", marginTop: 4 },
  input: { padding: 8, fontSize: 12 },
};

const fStyles = {
  avatar: { height: 24, width: 24 },
  title: { fontSize: 12, width: "100%" },
  option: { padding: "4px 8px", minHeight: 32 },
  checkbox: { padding: "0px 8px" },
  applyBtn: {
    fontSize: 12,
    color: "#d23637",
    fontWeight: "bold" as "bold",
    width: "100%",
    textAlign: "center" as "center",
  },
};
// Customizable Area End
