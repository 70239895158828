import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { UserType } from "../../landingpage/src/LayoutController.web";

// Customizable Area Start
export class JobType {
  id: string = "";
  asset_ids: Array<string> = [];
}
interface ResponseData {
  data: S;
}
interface AssetType {
  data: {
      id: string;
  }
}
interface CustomObjectArray{
  maintainer:{
    clients: Array<CustomerType>
  }
}
interface LocationType {
    location: {
      data:{
        id:string,
        attributes:{
          comments:string,
          address:string,
          work_hours_from:string,
          contact_details:string,
          access_control:boolean,
        }
      }
    }
}
interface DocType{
    "asset_ids": Array<number>,
    "id": string
}
interface JoblistkeyType {
  data: {
    id:string,
    attributes:{
      customer: string,
      opening_hours: string,
      contact_details: string,
      access_control: boolean,
      location_address:string,
      location_comments:string,
      locationid:number,
      job_order_comments:string,
      is_self_assign:boolean,
      job_order_id:string,
      price:string,
      duration:string,
      location_id:number,
      documents_type:Array<DocType>
    }
  }
  errors:string
}
export interface CustomerType{
  client_account_id: number;
  client_company_name:string;
  client_details: object;
  total_locations: number;
  total_assets:number;
  updated_at:string
}
export class JobTypeValue {
  value: string = "";
  label: string = "";
}
export const JobTypeList = [
  { value: 'Install', label: 'Install' },
  { value: 'Repair', label: 'Repair' },
  { value: 'Substitute', label: 'Substitute' },
  { value: 'Commissioning', label: 'Commissioning' },
  { value: 'Call-out', label: 'Call-out' },
  { value: 'Quarterly Maintenance', label: 'Quarterly Maintenance' },
  { value: 'Yearly Maintenance', label: 'Yearly Maintenance' },
  { value: 'Custom Maintenance', label: 'Custom Maintenance' },
  { value: 'Recharge', label: 'Recharge' },
  { value: 'Others', label: 'Others' },
]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
 
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  assetError:string;
  maintainerId:string;
  manageViewOpen: string[];
  showModalChild:any;
  errorEmailExistTech: string;
  createJobInfo: {
    isSelfAssign: boolean;
    jobOrderId: string;
    jobId: string;
    price: any;
    contactDetails: string;
    openingHours: any;
    accessControl: any;
    duration: any;
    jobOrderComments: any;
  jobTypeSelect: JobType[];
  customerSelect:any;
  locationSelect:any;
  locationAddress:any;
  locationComment:any;
  assetsIds:any;
  locationArray:any;
  }
  modalEmail: string,
  modalPasswordTech: string,
  
  isTechnician: boolean;
  allAssetList: Array<AssetType>;
  manageAssets: { open: boolean, index?: number, current: string[] };
  CustomerData:Array<CustomerType>;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TechnicianController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  createPriceApiCallId: string ="";
  createDurationApiCallId: string="";
  getAssestApiCallId: string = "";
  createJobApiCallId: string ="";
  getLocationApiCallId: string = "";
  apiViewCustomerCallId: string="";
  apiViewJobCallId: string="";
  updateJobApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];
    
    this.state = {
      // Customizable Area Start
      assetError:"",
      maintainerId:"",
      showModalChild:false,
      manageViewOpen: [],
      CustomerData:[],
      errorEmailExistTech: '',
      createJobInfo: {
        isSelfAssign: false,
        jobId:"",
        jobOrderId: "",
        price: "",
        contactDetails: "",
        openingHours: "",
        accessControl: "",
        duration: "",
        jobOrderComments: "",
        customerSelect:"",
        locationSelect:"",
        locationAddress:"",
        locationComment:"",
      jobTypeSelect: [new JobType()],
      assetsIds:[],
      locationArray:[]
      },
      modalEmail: '',
      modalPasswordTech: '',
      
      isTechnician: false,
      allAssetList: [],
      manageAssets: { open: false, current: [] },
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const userDataStr = localStorage.getItem("userData");
    if (userDataStr) {
      const userData = JSON.parse(userDataStr);
      this.setState({ isTechnician: userData.user_type === UserType.Technician });
      const maintainerId: string = userData.maintainer_id;
      this.getCustomer(maintainerId);
      this.setState({ maintainerId:maintainerId})
      this.getParamsIdJobHandle(maintainerId);
    }
   
    

  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
    if (apiRequestCallId != null) {
        switch (true) {
            case apiRequestCallId === this.createJobApiCallId:
              this.createTask(responseJson);
              break;
              case apiRequestCallId === this.updateJobApiCallId:
                this.createTask(responseJson);
                this.props.navigation.navigate("JobOrder");
                break;
            case apiRequestCallId === this.getAssestApiCallId:
                this.assetsGet(responseJson)
              break;
            case apiRequestCallId === this.createPriceApiCallId:
                this.priceGet(responseJson)
            break;
            case apiRequestCallId === this.createDurationApiCallId:
                this.durationGet(responseJson)
                break;
            case apiRequestCallId === this.getLocationApiCallId:
              this.getLocationData(responseJson)
              break;
            case apiRequestCallId === this.apiViewCustomerCallId:
              this.getCustomerData(responseJson)
              break;
            case apiRequestCallId === this.apiViewJobCallId:
              this.getAllJobData(responseJson)
              break
            default:
        }
      }
    }

  };

 
  getCalculatedPrice =(assetIds:any)=>{
    let headers = {
        "Content-Type": "application/json",
        "Token": localStorage.getItem("token"),
      }
      const httpBody = {
            "asset_ids": assetIds
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createPriceApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.priceApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeTechDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCalculatedDuration =(assetIds:any)=>{
    let headers = {
        "Content-Type": "application/json",
        "Token": localStorage.getItem("token"),
      }
      const httpBody = {
            "asset_ids":assetIds
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createDurationApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.durationEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeTechDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleManageAssestsBtn = (clientId:string, locationId:string) => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAssestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointManageAssests+ `?client_account_id=${clientId}`+`&location_id=${locationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiManageAssestsDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleLocation = (key:string) => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLocationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationApiEndPoint + key
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiManageAssestsDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

 
  assetsGet=(responseJson:any)=>{
    if (!responseJson.message) {
        const asset_Ids = responseJson.client_assets.map((x:AssetType)=>(x.data.id));
        this.setState({
          ...this.state,
          allAssetList: responseJson.client_assets,
          assetError:""
        });
      if(!this.state.createJobInfo.jobId){
        const jobTypeSelect = this.state.createJobInfo.jobTypeSelect.map((y:JobType)=>({...y, asset_ids: y.id ? asset_Ids : []}));
        this.setState({
          createJobInfo: { ...this.state.createJobInfo, jobTypeSelect: jobTypeSelect }
        })
      }
        
      }else{
        this.setState({
          assetError:responseJson.message
        })
      }
  }

  priceGet=(responseJson:any)=>{
    if(responseJson){
        this.setState({
            createJobInfo: {
              ...this.state.createJobInfo,
              price: responseJson.total_price
            },

          })
    }
  }
  durationGet=(responseJson:any)=>{
   
    if(responseJson){
        this.setState({
            createJobInfo: {
              ...this.state.createJobInfo,
              duration: responseJson.total_duration
            },

          })
    }
  }
  getLocationData=(responseJson:any)=>{
    if(responseJson.client_locations && !responseJson.message){
      this.setState({
          createJobInfo: {
            ...this.state.createJobInfo,
            locationArray: responseJson.client_locations
          },
          

        })
  }else{
    this.setState({
      createJobInfo: {
        ...this.state.createJobInfo, locationSelect:"",  locationAddress:"", locationComment:"",
        contactDetails:"", openingHours:"",locationArray:[],
        accessControl:""
      },
    })
  }

  }
  locationRelFunct=(ele:any)=>{
    let objectData:LocationType = this.state.createJobInfo.locationArray.find((location:LocationType) => location.location.data.id === ele);
   if(objectData){
    this.setState({
      createJobInfo: {
        ...this.state.createJobInfo,locationSelect:ele,
        locationAddress: objectData.location.data.attributes.address, locationComment:objectData.location.data.attributes.comments, 
        openingHours:objectData.location.data.attributes.work_hours_from,
        contactDetails:objectData.location.data.attributes.contact_details,
        accessControl:objectData.location.data.attributes.access_control===true?"Yes":"No",
      },


    })
   }
   this.handleManageAssestsBtn(this.state.createJobInfo.customerSelect, ele)
   
  }

  handleSubmit = (values: any, { resetForm }: any) => {
    resetForm({
      values: {
        jobOrderId: this.state.createJobInfo.jobOrderId,
        price: this.state.createJobInfo.price, // Keep the price value
        jobOrderComments: this.state.createJobInfo.jobOrderComments,
        contactDetails: this.state.createJobInfo.contactDetails,
        openingHours: this.state.createJobInfo.openingHours,
        customerSelect: this.state.createJobInfo.customerSelect,
        locationSelect: this.state.createJobInfo.locationSelect,
        jobTypeSelect: this.state.createJobInfo.jobTypeSelect,
        locationAddress: this.state.createJobInfo.locationAddress,
        locationComment:this.state.createJobInfo.locationComment,
        assetsIds:this.state.createJobInfo.assetsIds,
        accessControl: this.state.createJobInfo.accessControl,
        duration: this.state.createJobInfo.duration,
      },
    });
    if(this.state.createJobInfo.jobId){
    this.handleUpdateJob();
    }
    else{
      this.handlePostJob()
    }
  };
validateAsset=()=>{
  if(this.state.assetError!=""){
    return false

  }else{
  return true
  }
}
  handlePostJob = () => {
    const isValidAsset = this.validateAsset();
    if (!isValidAsset) {
      return; 
    }
    let headers = {
      "Content-Type": "application/json",
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
            "priority": "low",
            "location_id": this.state.createJobInfo.locationSelect,
            "location_address":this.state.createJobInfo.locationAddress,
            "location_comments":this.state.createJobInfo.locationComment,
             "duration":this.state.createJobInfo.duration,
             "price":this.state.createJobInfo.price,
             "job_order_comments":this.state.createJobInfo.jobOrderComments,
             "asset_ids":this.state.createJobInfo.assetsIds,
            "customer": this.state.createJobInfo.customerSelect,
            "contact_details": this.state.createJobInfo.contactDetails,
            "opening_hours": this.state.createJobInfo.openingHours,
            "access_control": this.state.createJobInfo.accessControl!="Yes"?false:true,
            "documents_type": this.state.createJobInfo.jobTypeSelect,
            "is_self_assign": this.state.createJobInfo.isSelfAssign,
            "client_account_id": this.state.createJobInfo.customerSelect,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTaskAPiEndPoint + this.state.maintainerId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeTechDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUpdateJob = () => {
    const isValidAssets = this.validateAsset();
    if (!isValidAssets) {
      return; // Exit the function if asset error is not valid
    }
    let headers = {
      "Content-Type": "application/json",
      "Token": localStorage.getItem("token"),
    }
    const httpBody = {
            "priority": "low",
            "location_id": this.state.createJobInfo.locationSelect,

            "location_address":this.state.createJobInfo.locationAddress,

            "location_comments":this.state.createJobInfo.locationComment,
            "opening_hours": this.state.createJobInfo.openingHours,
             "duration":this.state.createJobInfo.duration,
             "asset_ids":this.state.createJobInfo.assetsIds,
             "customer": this.state.createJobInfo.customerSelect,
             "price":this.state.createJobInfo.price,

             "job_order_comments":this.state.createJobInfo.jobOrderComments,
             "documents_type": this.state.createJobInfo.jobTypeSelect,
             "is_self_assign":this.state.createJobInfo.isSelfAssign,
             
            "contact_details": this.state.createJobInfo.contactDetails,
          
            "access_control": this.state.createJobInfo.accessControl!="Yes"?false:true,
            
            "client_account_id": this.state.createJobInfo.customerSelect,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTaskAPiEndPoint + this.state.createJobInfo.jobId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createTask =(responseJson:ResponseData)=>{
    if(responseJson?.data){
      this.setState({
        createJobInfo: {
          ...this.state.createJobInfo,
          jobTypeSelect:[new JobType()], jobOrderId:"", jobId:"", locationSelect:"", customerSelect:"", locationAddress:"", locationComment:"",
          contactDetails:"", accessControl:"", openingHours:"", jobOrderComments:"",price: "", duration: "", isSelfAssign: false, assetsIds: []
        },
      })
    }
  }

  getFilteredJobOptions = (optIndex:number) => { 
    return JobTypeList.filter((x: JobTypeValue) => !this.state.createJobInfo.jobTypeSelect.find((y: JobType, index: number) => optIndex !== index && y.id === x.value))
  }

  getAssetSelection = (assetIds: string[]) => {
    return this.state.allAssetList.filter((x: AssetType) => assetIds.find((y: string) => y === x.data.id));
}

  // NOTE: To check, whether add job type is visible or not 
  isAddJobType = () => (this.state.createJobInfo.jobTypeSelect.length !== JobTypeList.length)

  // NOTE: To check, whether particular asset selected or not
  isAssetChecked = (id:string) => (!!this.state.manageAssets.current.find((x:string) => x === id))

  // NOTE: To check, whether all asset selected or not
  isSelectAll = () => (this.state.manageAssets.current.length === this.state.allAssetList.length);

  // NOTE: To update price and duration on asset selection change
  onAssetChange = () => {
    let selectedAssetIds = this.state.createJobInfo.jobTypeSelect.flatMap((x:JobType)=> x.asset_ids);
    this.setState({createJobInfo: {...this.state.createJobInfo, assetsIds: selectedAssetIds }})
    this.getCalculatedPrice(selectedAssetIds);
    this.getCalculatedDuration(selectedAssetIds);
  }

  // NOTE: To manage view asset functionality
  onViewAssets = (index:number) => {  
    let temp = [...this.state.manageViewOpen];
    if (temp.includes(index.toString())) temp = temp.filter((x) => x !== index.toString());
    else temp.push(index.toString());
    this.setState({ manageViewOpen: temp });
  };

  // NOTE: To open asset selection page
  onManageAssets = (index:number) => {
      this.setState({manageAssets: { open: true, current: this.state.createJobInfo.jobTypeSelect[index].asset_ids, index }})
      
    }

  // NOTE: To check, whether asset selection is updated or not
  isAssetSelectionUpdated = (prev:string[], current:string[]) => {
    let isUpdated = prev.length !== current.length;
    if(!isUpdated){
      prev.sort((a,b) => (Number(a) - Number(b)));
      current.sort((a,b) => (Number(a) - Number(b)));
      for(let i = 0; i < current.length; i++) {
        if(prev[i] !== current[i]) {
          isUpdated = true;
          break;
        }
      }
    }
    return isUpdated
  }

  // NOTE: Required to manage back button click
  onManageAssetsClose = () => {
    const currentIndex = this.state.manageAssets.index
    if(typeof currentIndex === "number"){
      if (this.isAssetSelectionUpdated(this.state.createJobInfo.jobTypeSelect[currentIndex].asset_ids,this.state.manageAssets.current))
        this.setState({ showModalChild:true });
      else this.setState({manageAssets: { open: false, current:[], index: undefined }});
    }
  }

  // NOTE: Required to manage selection confirmation dialog
  onUpdateAssetConfirm = (isConfirm = false, isIcon = false) => {
    const currentIndex = this.state.manageAssets.index;
    if(isConfirm && typeof currentIndex === "number") {
      let jobList = [...this.state.createJobInfo.jobTypeSelect];
      jobList[currentIndex].asset_ids = [...this.state.manageAssets.current];
      this.setState((prevState)=>({
        showModalChild: false,
        manageAssets: { open: false, current:[], index: undefined },
        createJobInfo: {...prevState.createJobInfo, jobTypeSelect: jobList},
      }), this.onAssetChange);
    } 
    else if(isIcon) this.setState({ showModalChild: false });
    else this.setState({ manageAssets: { open: false, current:[], index: undefined }, showModalChild: false })
  };

  // NOTE: Required to update asset selection
  onSelectionChange = (e: any, id: any) => {
    const isChecked = e.target.checked;
    let selection = [...this.state.manageAssets.current];
    if(isChecked) selection.push(id);
    else selection = selection.filter((x:string) => x !== id);
    this.setState({ manageAssets: {...this.state.manageAssets, current: selection }});
  };

  // NOTE: Required to apply select all functionality
  onSelectAll = (e:any) => {
    const isChecked = e.target.checked;
    this.setState({ manageAssets: {
      ...this.state.manageAssets, 
      current: isChecked ? this.state.allAssetList.map((x:AssetType) => x.data.id) : [] 
    }})
  };
  
  // NOTE: Required to update job type selection
  handleInputChange = (value:string, index:any) => {
    let tempJobList = [...this.state.createJobInfo.jobTypeSelect];
    tempJobList[index] = { id:value, asset_ids: this.state.allAssetList.map((x:AssetType)=>(x.data.id))}
    this.setState((prevState)=>({createJobInfo :{...prevState.createJobInfo,jobTypeSelect: tempJobList}}), this.onAssetChange)
    return tempJobList
  };

  // NOTE: Required to add or remove job type fields
  updateInputBox = (index?:number) => {
    let tempList = [...this.state.createJobInfo.jobTypeSelect];
    if(index) tempList.splice(index,1);
    else tempList= [...tempList, new JobType()];
    this.setState((prevState)=>({createJobInfo :{...prevState.createJobInfo, jobTypeSelect: tempList}}), () => { if(index) this.onAssetChange() })
  };

  getCustomer = (keyId:string) => {
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewCustomerCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllCustomerApiEndPoint + keyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getCustomerData=(responseJson:CustomObjectArray)=>{
    if(responseJson.maintainer.clients){
      this.setState({
        CustomerData:responseJson.maintainer.clients
      })
    }
  }
  getParamsIdJobHandle=(maintainerId:string)=>{
    let urlID:string = this.props.navigation.getParam("id")
    if(urlID){
      this.getAllJobList(maintainerId)
    }
  }
  getAllJobList = (keyId:string) => {
    const jobIdfind:any = this.props.navigation.getParam("id");
    const headers = {
      "Token": localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiViewJobCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewAllJobApiEndPoint +`/${jobIdfind}?maintainer_id=` + keyId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getAllJobData =(responseJson:JoblistkeyType)=>{
    if (!responseJson.errors) {
     
      this.setState({
        createJobInfo: {...this.state.createJobInfo,
          isSelfAssign: responseJson.data.attributes.is_self_assign,
          jobId:responseJson.data.id,
          jobOrderId:responseJson.data.attributes.job_order_id,
          price: responseJson.data.attributes.price,
          contactDetails:responseJson.data.attributes.contact_details,
          openingHours: responseJson.data.attributes.opening_hours,
          accessControl: responseJson.data.attributes.access_control?"Yes":"No",
          duration: responseJson.data.attributes.duration,
          jobOrderComments:responseJson.data.attributes.job_order_comments,
          customerSelect:responseJson.data.attributes.customer,
          locationSelect:responseJson.data.attributes.location_id.toString(),
          locationAddress:responseJson.data.attributes.location_address,
          locationComment:responseJson.data.attributes.location_comments,
        jobTypeSelect: responseJson.data.attributes.documents_type.map((x:DocType)=>({ ...x, asset_ids: x.asset_ids.map((y:number)=>(y.toString()))})),
        },
      })
      this.handleLocation(responseJson.data.attributes.customer);
      this.handleManageAssestsBtn(responseJson.data.attributes.customer, responseJson.data.attributes.location_id.toString())
    }
  }
  // Customizable Area End
}
