import React from "react";

// Customizable Area Start
import {
  Avatar,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { ClearOutlined, EventNoteOutlined } from "@material-ui/icons";

import {
  BackChevronIcon,
  DownAccordionIcon,
  UpAccordionIcon,
} from "../../landingpage/src/assets";
import {
  CustomBtn,
  CustomIconBtn,
  dialogStyles,
} from "../../landingpage/src/JobDetail.web";
import { CustomTooltip } from "../../landingpage/src/CompanyLandingPage.web";
import Layout from "../../landingpage/src/Layout.web";
import ChecklistProcessController, {
  Props,
} from "./ChecklistProcessController.web";
import ResponsiveDialog from "../../landingpage/src/ResponsiveDialog.web";

export const BtnContainer = styled(Container)((props) => ({
  minWidth: "100%",
  padding: 0,
  display: "flex",
  gap: 8,
  justifyContent: "right",
  [props.theme.breakpoints.down("sm")]: { flexDirection: "column-reverse" },
}));

// Customizable Area End

export default class Customform4 extends ChecklistProcessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getAccordionIcon = (x: boolean) => (x ? UpAccordionIcon : DownAccordionIcon);

  getNoteIcon = (x: boolean) =>
    x ? (
      <Avatar variant="rounded" className="bg-pink">
        <ClearOutlined />
      </Avatar>
    ) : (
      <Avatar variant="rounded" className="bg-red">
        <EventNoteOutlined />
      </Avatar>
    );
  // Customizable Area End

  // Customizable Area Start
  render() {
    const { task, detail } = this.state;
    return (
      <Layout navigation={this.props.navigation}>
        <Grid container justifyContent="center">
          <Grid item xs={false} lg={3} />
          <Grid item xs={10} lg={6} style={styles.minW350}>
            <div style={{ ...styles.headerDiv, ...styles.grid }}>
              <Typography style={styles.title}>
                <CustomIconBtn data-test-id="back" onClick={this.goBack}>
                  <Avatar variant="square" src={BackChevronIcon} />
                </CustomIconBtn>
                {this.getFieldValue(detail.asset_id)}
              </Typography>
              <CustomTooltip
                title={
                  <React.Fragment>
                    <Typography className="title">Job Type</Typography>
                    <Typography component="div" className="job">
                      {this.getFieldValue(detail.job_type)}
                    </Typography>
                  </React.Fragment>
                }
                placement="bottom-end"
              >
                <Avatar variant="circle" style={styles.lgAvatar}>
                  {detail.job_type && this.getFieldValue(detail.job_type[0])}
                </Avatar>
              </CustomTooltip>
            </div>
            <Grid container style={styles.grid}>
              <Grid item xs={6}>
                <Typography style={styles.subTitle}>Location</Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(task.location_name)}
                </Typography>
                <Typography style={styles.subTitle}>Location Number</Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.location_number)}
                </Typography>
                <Typography style={styles.subTitle}>Plate Number</Typography>
                <Typography style={styles.subtxt} gutterBottom>
                  {this.getFieldValue(detail.plate_number)}
                </Typography>
              </Grid>
              <Grid item xs={6} style={styles.alignRight}>
                <Typography style={styles.subTitle}>
                  Manufacturing Date
                </Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.manufacturing_date)}
                </Typography>
                <Typography style={styles.subTitle}>
                  Next Recharge Date
                </Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.next_recharge_date)}
                </Typography>
                <Typography style={styles.subTitle}>
                  Last Maintenance Date
                </Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.last_maintenance_date)}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ display: this.getDisplay() }}>
                <Typography style={styles.subTitle}>Asset Type</Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.asset_type)}
                </Typography>
                <Typography style={styles.subTitle}>Brand</Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.brand)}
                </Typography>
                <Typography style={styles.subTitle}>Capacity</Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.capacity)}
                </Typography>
                <Typography style={styles.subTitle}>Cofrai Asset ID</Typography>
                <Typography style={styles.subtxt} gutterBottom>
                  {this.getFieldValue(detail.assetId)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ ...styles.alignRight, display: this.getDisplay() }}
              >
                <Typography style={styles.subTitle}>
                  Last Recharge Date
                </Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.last_recharge_date)}
                </Typography>
                <Typography style={styles.subTitle}>Expiry Date</Typography>
                <Typography style={styles.subtxt}>
                  {this.getFieldValue(detail.expiry_date)}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: this.getDisplay() }}>
                <Typography component="div" style={styles.comment}>
                  {this.getFieldValue(detail.comments)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  data-test-id="toggleDetail"
                  component="div"
                  style={{ ...styles.subTitle, ...styles.dFlex }}
                  onClick={this.toggleAssetDetail}
                >
                  {this.state.showAssetDetail ? `Show Less ` : `Show More `}
                  <Avatar
                    style={styles.accordionIcon}
                    src={this.getAccordionIcon(this.state.showAssetDetail)}
                  />
                </Typography>
              </Grid>
            </Grid>
            {this.state.checklist.map((x: any, index: number) => (
              <div key={index} style={styles.grid}>
                <Typography
                  component="div"
                  style={styles.subTitle}
                  gutterBottom
                >
                  {x.title}
                </Typography>
                <Typography component="div" style={styles.subtxt} gutterBottom>
                  {x.question}
                </Typography>
                <div style={{ ...styles.headerDiv, ...styles.mb8 }}>
                  <Checkbox
                    data-test-id={`checkbox${index}`}
                    style={{ padding: 4 }}
                    checked={x.isChecked}
                    onChange={() => this.onCheckboxChange(index)}
                    disabled={!task.isEdit}
                  />
                  <CustomIconBtn
                    data-test-id={`toggleNote${index}`}
                    onClick={() => this.toggleNotes(index)}
                  >
                    {this.getNoteIcon(
                      this.state.checklistNotes.includes(index)
                    )}
                  </CustomIconBtn>
                </div>
                <TextField
                  multiline
                  fullWidth
                  minRows={3}
                  data-test-id={`note${index}`}
                  variant="outlined"
                  color="secondary"
                  style={{
                    display: this.state.checklistNotes.includes(index)
                      ? "block"
                      : "none",
                  }}
                  InputProps={{ style: styles.note }}
                  value={x.note}
                  onChange={(e: any) => this.onNoteChange(e, index)}
                  disabled={!task.isEdit}
                />
              </div>
            ))}
            {!this.state.checklist.length && (
              <div style={styles.grid}>
                <Typography
                  component="div"
                  style={styles.subTitle}
                  gutterBottom
                >
                  Compliance
                </Typography>
                <Typography component="div" style={styles.subtxt} gutterBottom>
                  Did the asset pass the checklist?
                </Typography>
                <div style={{ ...styles.headerDiv, ...styles.mb8 }}>
                  <RadioGroup
                    data-test-id="compliance"
                    value={this.state.compliance.value}
                    onChange={this.onComplianceChange}
                  >
                    <FormControlLabel
                      value={1}
                      disabled={!task.isEdit}
                      control={<Radio />}
                      label={
                        <Typography style={styles.subtxt}>Success</Typography>
                      }
                    />
                    <FormControlLabel
                      value={0}
                      disabled={!task.isEdit}
                      control={<Radio />}
                      label={
                        <Typography style={styles.subtxt}>Failure</Typography>
                      }
                    />
                  </RadioGroup>
                  <CustomIconBtn
                    data-test-id="toggleComplianceNote"
                    style={styles.complianceNoteIcon}
                    onClick={this.toggleComplianceNote}
                  >
                    {this.getNoteIcon(this.state.compliance.open)}
                  </CustomIconBtn>
                </div>
                <TextField
                  multiline
                  fullWidth
                  minRows={3}
                  data-test-id="complianceNote"
                  variant="outlined"
                  color="secondary"
                  style={{
                    display: this.state.compliance.open ? "block" : "none",
                  }}
                  InputProps={{ style: styles.note }}
                  value={this.state.compliance.note}
                  onChange={this.onComplianceNoteChange}
                  disabled={!task.isEdit}
                />
              </div>
            )}
            <div style={styles.grid}>
              <Typography
                data-test-id="toggleAssetNote"
                gutterBottom
                component="div"
                style={{ ...styles.subTitle, ...styles.dFlex }}
                onClick={this.toggleAssetNote}
              >
                Asset Notes{" "}
                <Avatar
                  style={styles.accordionIcon}
                  src={this.getAccordionIcon(this.state.assetNote.open)}
                />
              </Typography>
              <TextField
                multiline
                fullWidth
                data-test-id="assetNote"
                minRows={3}
                variant="outlined"
                color="secondary"
                style={{
                  display: this.state.assetNote.open ? "block" : "none",
                }}
                InputProps={{ style: styles.note }}
                value={this.state.assetNote.value}
                onChange={this.onAssetNoteChange}
                disabled={!task.isEdit}
              />
            </div>
            <BtnContainer>
              <CustomBtn
                data-test-id="leaveBtn"
                className="secondary"
                onClick={this.onLeave}
              >
                Back To Job Order
              </CustomBtn>
              <CustomBtn
                data-test-id="submitBtn"
                className="primary"
                onClick={this.onSubmit}
                disabled={!task.isEdit}
              >
                Submit
              </CustomBtn>
            </BtnContainer>
          </Grid>
          <Grid item xs={false} lg={3} />
        </Grid>
        <ResponsiveDialog
          data-test-id="failureDialog"
          open={this.state.open.failure}
          okText="Submit Anyway"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onFailureDialog}
        >
          <Typography style={dialogStyles.fBold}>
            Some Questions Failed The Test
          </Typography>
          <Typography style={dialogStyles.f12}>
            Are you sure you want to submit?
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="allCompleteDialog"
          open={this.state.open.allComplete}
          okText="Submit"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onAllCompleteDialog}
        >
          <Typography style={dialogStyles.fBold}>
            All the assets passed the test
          </Typography>
          <Typography style={dialogStyles.f12}>
            Are you sure you want to continue?
          </Typography>
        </ResponsiveDialog>
        <ResponsiveDialog
          data-test-id="unsavedDialog"
          open={this.state.open.unsaved}
          okText="Yes, leave without saving"
          cancelText="Cancel"
          maxWidth="xs"
          handleDialog={this.onUnsavedDialog}
        >
          <Typography style={dialogStyles.fBold}>
            There are unsaved changes
          </Typography>
          <Typography style={dialogStyles.f12}>
            Are you sure you want to leave the checklist?
          </Typography>
        </ResponsiveDialog>
      </Layout>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = {
  gap8: { gap: 8 },
  minW350: { minWidth: 350 },
  grid: {
    backgroundColor: "#fff",
    borderRadius: 8,
    marginBottom: 8,
    padding: "8px 12px",
  },
  mb8: { marginBottom: 8 },
  headerDiv: { display: "flex", justifyContent: "space-between" },
  title: { fontWeight: "bold" as "bold", margin: "auto 0px" },
  alignRight: { textAlign: "right" as "right", justifyContent: "right" },
  fBold: { fontWeight: "bold" as "bold" },
  lgAvatar: { height: 36, width: 36, backgroundColor: "#5183d9", fontSize: 14 },
  subTitle: { fontWeight: "bold" as "bold", fontSize: 13 },
  subtxt: { fontSize: 12, alignItems: "center" },
  dFlex: { display: "flex" },
  accordionIcon: { height: 20, width: 22 },
  comment: {
    backgroundColor: "#fff",
    fontSize: 12,
    borderRadius: 8,
    border: "0.5px solid #000",
    padding: "6px 0px 6px 6px",
    marginBottom: 10,
    marginTop: 10,
    overflowY: "auto" as "auto",
    height: 68,
    maxHeight: 68,
  },
  note: {
    borderRadius: 8,
    color: "#777",
    backgroundColor: "#fff",
  },
  complianceNoteIcon: {
    height: "max-content",
    marginTop: "auto",
  },
};
// Customizable Area End
