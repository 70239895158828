import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { UserType } from "../../landingpage/src/LayoutController.web";

export interface IInvCertDetail {
  id: string;
  jobOrderId?: string | null;
  clientCompanyName?: string | null;
}

export class DeleteDetail {
  open: boolean = false;
  id: string = "";
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTechnician: boolean;
  deleteInv: DeleteDetail;
  maintainer_id: string;
  invoices: IInvCertDetail[];
  search: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InvoiceDbController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getInvoicesApiId = "";
  getInvoicesSearchApiId = "";
  getInvoicePDFApiId = "";
  deleteInvoiceApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isTechnician: true,
      deleteInv: new DeleteDetail(),
      maintainer_id: "",
      invoices: [],
      search: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getInvoicesApiId:
        case this.getInvoicesSearchApiId:
          this.handleGetInvoices(responseJson);
          break;
        case this.getInvoicePDFApiId:
          this.handleInvoicePDF(responseJson);
          break;
        case this.deleteInvoiceApiId:
          this.handleDeleteInvoice(responseJson);
          break;
      }
    }
  };
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    const userDataStr = localStorage.getItem("userData");
    if (userDataStr) {
      const userData = JSON.parse(userDataStr);
      this.setState(
        {
          isTechnician: userData.user_type === UserType.Technician,
          maintainer_id: userData.maintainer_id,
        },
        () => this.getInvoices()
      );
    }
  }

  getInvoices = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoicesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInvoicesApiEndpoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getInvoicesSearch = (search: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      search_query: search,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoicesSearchApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInvoicesSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getInvoicePDF = (id: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      invoice_number: id,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInvoicePDFApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getInvoicePDFApiEndpoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteInvoice = (id: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Token: localStorage.getItem("token"),
    };
    let urlParams = new URLSearchParams({
      invoice_number: id,
      maintainer_id: this.state.maintainer_id,
    }).toString();
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteInvoiceApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteInvoiceApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetInvoices = (res: any) => {
    if (res.data) {
      const invoices = res.data
        .filter((x: any) => !!x.attributes.invoice_number)
        .map(
          (x: any) =>
            ({
              id: x.attributes.invoice_number,
              jobOrderId: x.attributes.job_order_id,
              clientCompanyName: x.attributes.client_company_name,
            } as IInvCertDetail)
        );
      this.setState({ invoices });
    }
  };

  handleInvoicePDF = (res: any) => {
    if (res.invoice) window.open(res.invoice, "_blank");
  };

  handleDeleteInvoice = (res: any) => {
    if (res.meta) this.getInvoices();
  };

  onInvDelete = (id: string) => {
    this.setState({ deleteInv: { open: true, id } });
  };

  handleInvDeleteDialog = (isConfirm: boolean) => {
    if (isConfirm) this.deleteInvoice(this.state.deleteInv.id);
    this.setState({ deleteInv: new DeleteDetail() });
  };

  onSearch = (e: any) => {
    const search = e.target.value;
    this.setState({ search });
    if (search) this.getInvoicesSearch(search);
    else this.getInvoices();
  };
  // Customizable Area End
}
